import '../../components/globals/ProgressiveApplication.css';

import Button from '../../components/button/Button';
import React from 'react';
import StepWizard from './StepWizard';
import analytics from '../../firebase';
import { logEvent } from "firebase/analytics";

const JobApplicationLanguage = ({ totalSteps, languageOptions, setLanguages, saveLanguages, handleClose }) => {
    const isAnyLanguageChecked = Object.values(languageOptions).some(item => item.checked);
    logEvent(analytics, 'apply_language_viewed', {'click_source': 'job details'});

    const handleCheckboxChange = (language) => {
        setLanguages(prevLanguages => ({
            ...prevLanguages,
            [language]: {
                ...prevLanguages[language],
                checked: !prevLanguages[language]?.checked
            }
        }));
    };

    const handleSpokenProficiencyChange = (language, proficiencyLevel) => {
        setLanguages(prevLanguages => ({
            ...prevLanguages,
            [language]: {
                ...prevLanguages[language],
                spokenProficiency: proficiencyLevel
            }
        }));
    };

    const handleWrittenProficiencyChange = (language, proficiencyLevel) => {
        setLanguages(prevLanguages => ({
            ...prevLanguages,
            [language]: {
                ...prevLanguages[language],
                writtenProficiency: proficiencyLevel
            }
        }));
    };

    const handleCloseButtonClick = () => {
        const modalContainer = document.querySelector('.fab-modal-container');
        modalContainer.classList.remove('modal-active');
        handleClose()
    };

    const handleNext = () => {
        saveLanguages()
    }

    return (
        <div className="fab-modal-body fab-modal-education">
            <svg className='fab-modal-application-close' width="24" height="24" onClick={handleCloseButtonClick} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L12 10.9393L17.4697 5.46967C17.7626 5.17678 18.2374 5.17678 18.5303 5.46967C18.8232 5.76256 18.8232 6.23744 18.5303 6.53033L13.0607 12L18.5303 17.4697C18.8232 17.7626 18.8232 18.2374 18.5303 18.5303C18.2374 18.8232 17.7626 18.8232 17.4697 18.5303L12 13.0607L6.53033 18.5303C6.23744 18.8232 5.76256 18.8232 5.46967 18.5303C5.17678 18.2374 5.17678 17.7626 5.46967 17.4697L10.9393 12L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967Z" fill="#18181B" />
            </svg>
            {totalSteps > 1 && <StepWizard stepActive={1} totalSteps={totalSteps} />}
            <div className={totalSteps > 1 ? "fab-application-education" : "fab-application-education-margin"}>
                <h3 className="application-modal-title">Select the languages you know</h3>
                {Object.entries(languageOptions).map(([language, { checked, spokenProficiency, writtenProficiency }]) => (
                    <div className="language-checkbox-wrapper" key={language}>
                        <label htmlFor={`application-lang-${language}`} className="checkbox-label">
                            <input
                                type="checkbox"
                                id={`application-lang-${language}`}
                                className="application-checkbox"
                                checked={checked}
                                onChange={() => handleCheckboxChange(language)}
                            />
                            {language}
                        </label>
                        {checked && (
                            <LanguageProficiency
                                language={language}
                                spokenProficiency={spokenProficiency}
                                writtenProficiency={writtenProficiency}
                                onSpokenProficiencyChange={(level) => handleSpokenProficiencyChange(language, level)}
                                onWrittenProficiencyChange={(level) => handleWrittenProficiencyChange(language, level)}
                            />
                        )}
                    </div>
                ))}
            </div>
            <div className="application-footer">
                <Button variant='primary' size='lg' isDisabled={!isAnyLanguageChecked} onClick={handleNext}>Next</Button>
            </div>
        </div>
    );
}

const LanguageProficiency = ({ language, spokenProficiency, writtenProficiency, onSpokenProficiencyChange, onWrittenProficiencyChange }) => {
    return (
        <div className="application-proficiency-wrapper">
            <LanguageProficiencySection
                label="Spoken proficiency"
                value={spokenProficiency}
                onChange={onSpokenProficiencyChange}
                language={language}
            />
            <LanguageProficiencySection
                label="Written proficiency"
                value={writtenProficiency}
                onChange={onWrittenProficiencyChange}
                language={language}
            />
        </div>
    );
}

const LanguageProficiencySection = ({ label, value, onChange, language }) => {
    const handleProficiencyChange = (e) => {
        onChange(e.target.value);
    };

    return (
        <div className="application-lang-proficiency">
            <span className="application-proficiency-label">{label}</span>
            <div className="application-lang-radio-wrapper">
                <label htmlFor={`application-${language.toLowerCase()}-${label.toLowerCase()}-good`}>
                    <input
                        type="radio"
                        id={`application-${language.toLowerCase()}-${label.toLowerCase()}-good`}
                        name={`${language.toLowerCase()}-${label.toLowerCase()}-proficiency`}
                        value="Good"
                        checked={value === "Good"}
                        onChange={handleProficiencyChange}
                    />
                    Good
                </label>
                <label htmlFor={`application-${language.toLowerCase()}-${label.toLowerCase()}-fair`}>
                    <input
                        type="radio"
                        id={`application-${language.toLowerCase()}-${label.toLowerCase()}-fair`}
                        name={`${language.toLowerCase()}-${label.toLowerCase()}-proficiency`}
                        value="Fair"
                        checked={value === "Fair"}
                        onChange={handleProficiencyChange}
                    />
                    Fair
                </label>
                <label htmlFor={`application-${language.toLowerCase()}-${label.toLowerCase()}-poor`}>
                    <input
                        type="radio"
                        id={`application-${language.toLowerCase()}-${label.toLowerCase()}-poor`}
                        name={`${language.toLowerCase()}-${label.toLowerCase()}-proficiency`}
                        value="Poor"
                        checked={value === "Poor"}
                        onChange={handleProficiencyChange}
                    />
                    Poor
                </label>
            </div>
        </div>
    );
}

export default JobApplicationLanguage;
