import '../../components/globals/FeaturedComapnies.css';

import React, { useEffect, useState } from 'react';
import { SOMETHING_WENT_WRONG_MESSAGE, TRY_AGAIN_MESSAGE } from '../../common/constants';

import Button from '../../components/button/Button';
import Loader from '../../components/loader/Loader';
import Toast from '../../components/toast/Toast';
import analytics from '../../firebase';
import { getCompanies } from '../../services/CompanyService';
import { logEvent } from "firebase/analytics";
import { useNavigate } from 'react-router-dom';

const FeaturedCompanies = () => {
    const [featuredCompanies, setFeaturedCompanies] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessageHeader, setErrorMessageHeader] = useState('');
    const [errorMessageDescription, setErrorMessageDescription] = useState('');

    const navigate = useNavigate();
    const handleCompanies = () => {
        navigate('/companies');
    }

    const getAndSetFeaturedCompaniesData = async () => {
        try {
            setIsLoading(true);

            const response = await getCompanies('', [], '', [], 0, 4, true, true, true);
            const fCompanies = response.items.filter((company) => company.featured).slice(0, 4);
            const pCompanies = response.items.filter((company) => company.pinned).slice(0, 4);
            const mergedCompanies = [...pCompanies, ...fCompanies];
            setFeaturedCompanies(mergedCompanies);

            setIsLoading(false);
        } catch (error) {
            console.log(`error occurred while getting a list of featured companies: ${error}`);

            setIsLoading(false);
            setErrorMessageHeader(SOMETHING_WENT_WRONG_MESSAGE);
            setErrorMessageDescription(TRY_AGAIN_MESSAGE);
        }
    }

    useEffect(() => {
        getAndSetFeaturedCompaniesData();
    }, []);

    const getCuisineType = (cuisines) => {
        if (cuisines?.length > 0) {
            return cuisines[0]?.type;
        }

        return '';
    }

    const getCuisineImageUrl = (cuisines) => {
        if (cuisines?.length > 0) {
            return cuisines[0]?.image;
        }

        return '';
    }

    const resetErrorMessages = () => {
        setErrorMessageHeader('');
        setErrorMessageDescription('');
    }

    return (
        <>
            {
                (errorMessageHeader && errorMessageDescription) && <Toast
                    type='error'
                    header={errorMessageHeader}
                    message={errorMessageDescription}
                    onClose={resetErrorMessages}
                />
            }
            {isLoading && <Loader />}
            <div className='fab-container'>
                <div className='fab-featured-comapanies'>
                    <div className='fab-homepage-title-wrapper'>
                        <h3 className='fab-homepage-title'>Featured companies</h3>
                        <Button variant="outline" size="md" onClick={handleCompanies}>View all companies</Button>
                    </div>
                    <div>
                        <div className='fab-home-feature-cards'>
                            {featuredCompanies.map((company) => (
                                <div key={company.coyid} className='fab-featured-card-item' onClick={() => { logEvent(analytics, 'company_profile_viewed', { 'click_source': 'homepage' }); navigate(`/employer/${company?.coyid}`); }}>
                                    <div className='fab-featured-image-wrapper'>
                                        <div className='fab-featured-image' style={{ backgroundImage: `url('${getCuisineImageUrl(company.cuisines)}')` }} />
                                    </div>
                                    <div className='fab-featured-details'>
                                        <span className='fab-featured-pill'>{(company.featured || company.pinned) ? 'Featured' : ''}</span>
                                        <span className='fab-featured-company'>{company.dispm}</span>
                                        <span className='fab-featured-location'>{getCuisineType(company.cuisines)}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='fab-homepage-button-m'>
                        <Button variant="outline" size="md" onClick={handleCompanies}>View all companies</Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FeaturedCompanies;