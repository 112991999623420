import '../../components/globals/LatestJobs.css';

import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { getAllSavedJobs, saveJob, unsaveJob } from '../../services/jobService';

import Loader from '../../components/loader/Loader';
import Toast from '../../components/toast/Toast';
import analytics from '../../firebase';
import { isEmpty } from '../../common/helper';
import { logEvent } from "firebase/analytics";

const toastInitialState = {
    type: '',
    header: '',
    message: ''
}

const LatestJobs = ({ latestJobData, location }) => {
    const navigate = useNavigate();
    const [bookmarkedCards, setBookmarkedCards] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [savedJobs, setSavedJobs] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const [toastProps, setToastProps] = useState(toastInitialState)

    const fetchAllSavedJobs = async () => {
        try {
            const allJobs = await getAllSavedJobs();
            const jobsIds = allJobs.map(x => x.jobid);
            setSavedJobs(allJobs);
            setBookmarkedCards(jobsIds);
        }
        catch (error) {
            console.log(`Failed to fetch all saved jobs ${error}`)
        }
    }

    const saveJobs = async (cardId) => {
        try {
            const savedRes = await saveJob(cardId);
            if (savedRes.code === '00') {
                const savedJobsAll = fetchAllSavedJobs();
                setSavedJobs(savedJobsAll);
                setShowToast(true)
                setToastProps({
                    type: 'success',
                    header: 'Job saved successfully',
                    message: <>The job has been added to your <Link to='/saved-jobs'>Saved Jobs</Link> listing.</>
                })
            }
        }
        catch (error) {
            await fetchAllSavedJobs();
            setIsLoading(false);
            setShowToast(true)
            setToastProps({
                type: 'error',
                header: 'Something went wrong',
                message: 'Please try saving the job again'
            })
            console.log(`Failed to save a job ${error}`)
        }
    }

    const unsaveJobs = async (cardId, favId) => {
        try {
            const unsavedRes = await unsaveJob(cardId, favId);
            if (unsavedRes.code === '00') {
                const savedJobsAll = fetchAllSavedJobs();
                setSavedJobs(savedJobsAll);
                setShowToast(true)
                setToastProps({
                    type: 'success',
                    header: 'Job unsaved successfully',
                    message: <>The job has been removed from your <Link to='/saved-jobs'>Saved Jobs</Link> listing.</>
                })
            }
        }
        catch (error) {
            await fetchAllSavedJobs();
            setIsLoading(false);
            setShowToast(true)
            setToastProps({
                type: 'error',
                header: 'Something went wrong',
                message: 'Please try unsaving the job again'
            })
            console.log(`Failed to unsave a job ${error}`)
        }
    }

    const handleToastClose = () => {
        setShowToast(false)
        setToastProps(toastInitialState)
    }

    const handleBookmarkClick = async (e, cardId, bookmarkedCards, job) => {
        setBookmarkedCards((prevBookmarkedCards) =>
            prevBookmarkedCards.includes(cardId)
                ? prevBookmarkedCards.filter((id) => id !== cardId)
                : [...prevBookmarkedCards, cardId]
        );
        e.stopPropagation();
        setIsLoading(true);
        if (bookmarkedCards.includes(cardId)) {
            const savedJob = savedJobs.find(item => item.jobid === cardId);
            logEvent(analytics, 'job_unsaved', {'click_source': location, 'job_name': job.jobttl, 'job_id': job.jobid, 'company_name': job.coydispm, 'company_id': job.coyid});
            await unsaveJobs(cardId, savedJob.favid)
        }
        else {
            logEvent(analytics, 'job_saved', {'click_source': location, 'job_name': job.jobttl, 'job_id': job.jobid, 'company_name': job.coydispm, 'company_id': job.coyid});
            await saveJobs(cardId);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        const isLogin = JSON.parse(localStorage.getItem('isLogin'))
        if (isLogin) {
            fetchAllSavedJobs()
        }
    }, [])

    const handleApply = (job) => {
        logEvent(analytics, 'job_details_viewed', {'click_source': location, 'job_name': job.jobttl, 'job_id': job.jobid, 'company_name': job.coydispm, 'company_id': job.coyid});
        navigate(`/jobs/${job.jobid}`);
        navigate(0)
    }

    const IsUserLoggedIn = () => {
        return localStorage.getItem('isLogin') !== null;
    }

    return (

        <div className='fab-home-latestjobs'>
            {isLoading && <Loader></Loader>}
            {showToast && <Toast type={toastProps.type} header={toastProps.header} message={toastProps.message} onClose={handleToastClose} />}
            {latestJobData.map((job) => (
                <div key={job.jobid} className='fab-latestjob-item' onClick={() => { handleApply(job) }}>
                    <svg style={{ visibility: IsUserLoggedIn() ? '' : 'hidden' }} className={`fab-latestjob-bookmark ${bookmarkedCards.includes(job.jobid) ? 'fab-latestjob-bookmark-active' : ''}`}
                        onClick={(e) => handleBookmarkClick(e, job.jobid, bookmarkedCards, job)} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M6.32046 2.57741C8.18398 2.36114 10.0793 2.25 12.0002 2.25C13.9212 2.25 15.8165 2.36114 17.68 2.57741C19.1774 2.75119 20.2502 4.03722 20.2502 5.50699V21C20.2502 21.2599 20.1157 21.5013 19.8945 21.638C19.6734 21.7746 19.3973 21.7871 19.1648 21.6708L12.0002 18.0885L4.83565 21.6708C4.60316 21.7871 4.32706 21.7746 4.10595 21.638C3.88483 21.5013 3.75024 21.2599 3.75024 21V5.50699C3.75024 4.03722 4.82308 2.75119 6.32046 2.57741Z" fill="#FFFFFF" />
                    </svg>
                    <div className='fab-job-details'>
                        <span className='fab-job-company'>{job.coydispm}</span>
                        <span className='fab-job-title'>{job.jobttl}</span>
                        {!isEmpty(job.salmin) && <span className='fab-job-salary'>{job.salindi} {` $${job.salmin}`} {job.salperiod}</span>}
                        <span className='fab-job-location'>{job.loc}</span>
                    </div>
                    <div className='fab-job-pills'>
                        {job.jobtypes.map((jobType, index) => (
                            <span key={index} className='fab-job-pill-item'>{jobType}</span>
                        ))}
                        {job.timing && <span className='fab-job-pill-item'>{job.timing}</span>}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default LatestJobs;
