import '../../components/globals/EmployerProfile.css'

import React, { useEffect, useState } from 'react';

import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import { JOBS_PAGE_SIZE } from '../../common/constants';
import LatestJobs from '../homepage/LatestJobs';
import Loader from '../../components/loader/Loader';
import Pagination from "../../components/pagination/Pagination";
import { getCompanyCuisines } from '../../services/CompanyService';
import { getEmployerContent } from '../../services/EmployerService';
import { getJobsByCompany } from '../../services/jobService';
import sanitizeHtml from 'sanitize-html';
import { useParams } from 'react-router-dom';

const VideoPlayer = ({ iframeString }) => {
    return (
        <div
            dangerouslySetInnerHTML={{ __html: iframeString }}
        ></div>
    );
};

const EmployerProfile = () => {
    const { id } = useParams();
    const [currentPage, setCurrentPage] = useState(0);
    const [activeSection, setActiveSection] = useState('who-are-we');
    const [showEpp, setShowEpp] = useState(false);
    const [whoContent, setWhoContent] = useState({});
    const [whyContent, setWhyContent] = useState({});
    const [benefits, setBenefits] = useState([]);
    const [banner, setBanner] = useState('');
    const [jobs, setJobs] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const [videoUrl, setVideoUrl] = useState("");
    const [companyProfile, setCompanyProfile] = useState({
        company: '',
        brn: '',
        cuisineImageUrl: ''
    })

    const scrollToSection = (id) => {
        setActiveSection(id)
        const element = document.getElementById(id);
        const headerHeight = document.querySelector('.fab-header').offsetHeight;
        const scrollTop = element?.offsetTop - headerHeight;
        if (element) {
            window.scrollTo({ top: scrollTop, behavior: 'smooth' });
        }
    };

    const getCuisineImageUrl = (cuisines) => {
        if (cuisines?.length > 0) {
            return cuisines[0]?.image;
        }

        return '';
    }

    const getEmployerDetails = async () => {
        try {
            setShowLoader(true)
            const response = await getEmployerContent(id);
            const cuisines = await getCompanyCuisines(id);
            const data = response.data;
            if (data.content && Object.keys(data.content).length > 0) {
                setShowEpp(true)
                if (data.content.banner) {
                    setBanner(data.content.banner);
                }
                setVideoUrl(data.content.videourl)
                setWhoContent(data.content.sections[0])
                setWhyContent(data.content.sections[1])
                setBenefits(data.content.benefits)
                setShowLoader(false)
            }
            else if (data) {
                if (response.banner) {
                    setBanner(response.banner)
                } else {
                    const cuisineImageUrl = getCuisineImageUrl(cuisines);
                    setBanner(cuisineImageUrl)
                }
                setCompanyProfile({
                    company: data?.dispm,
                    brn: data?.brn,
                })
                setShowLoader(false)
            }
            setShowLoader(false)
        } catch (error) {
            setShowLoader(false)
            console.log("Failed to fetch employer content" + error)
        }
    }

    const getJobs = async () => {
        try {
            if (!showLoader) {
                setShowLoader(true)
            }
            const response = await getJobsByCompany(id, currentPage)
            setJobs(response)
            setShowLoader(false)
        }
        catch (error) {
            console.log("Failed to fetch jobs" + error)
            setShowLoader(false)
        }
    }

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;

            const sections = document.querySelectorAll('.fab-section');
            sections.forEach(section => {
                const top = section.offsetTop;
                const bottom = top + section.offsetHeight;

                if (scrollPosition >= top && scrollPosition < bottom) {
                    setActiveSection(section.id);
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        getEmployerDetails()
    }, [id])

    useEffect(() => {
        getJobs()
    }, [currentPage, id])

    const [companyBannerImageLoaded, setCompanyBannerImageLoaded] = useState(false);
    const [eppBannerImageLoaded, setEppBannerImageLoaded] = useState(false);
    const [whoAreWeSectionImageLoaded, setWhoAreWeSectionImageLoaded] = useState(false);

    return (
        <div className='fab-employer'>
            <Header />
            {showLoader && <Loader />}
            <div className='fab-employer-content'>
                {showEpp ? <>
                    {(!eppBannerImageLoaded && banner) && <Loader />}
                    <div className='fab-banner-image-container'>
                        <img
                            className='fab-banner-image'
                            src={banner}
                            alt=''
                            onLoad={() => setEppBannerImageLoaded(true)}
                        />
                    </div>
                    <div className='fab-items-menu'>
                        <ul className='fab-item-tab fab-container'>
                            {Object.keys(whoContent || {}).length > 0 && <li className={`fab-item ${activeSection === 'who-are-we' ? 'active' : ''}`} onClick={() => scrollToSection('who-are-we')}>Who are we?</li>}
                            {Object.keys(whyContent || {}).length > 0 && <li className={`fab-item ${activeSection === 'why-join-us' ? 'active' : ''}`} onClick={() => scrollToSection('why-join-us')}>Why join us?</li>}
                            {benefits.length > 0 && <li className={`fab-item ${activeSection === 'our-benefits' ? 'active' : ''}`} onClick={() => scrollToSection('our-benefits')}>Our benefits</li>}
                            {jobs?.totalData > 0 && <li className={`fab-item ${activeSection === 'available-jobs' ? 'active' : ''}`} onClick={() => scrollToSection('available-jobs')}>Jobs ({jobs?.totalData || 0})</li>}
                        </ul>
                    </div>
                    {videoUrl && <div className='fab-section-container'>
                        <VideoPlayer iframeString={videoUrl} />
                    </div>}
                    {
                        Object.keys(whoContent || {}).length > 0 &&
                        <>
                            {(!whoAreWeSectionImageLoaded && whoContent.image) && <Loader />}
                            <div id='who-are-we' className='fab-section fab-section-container'>
                                <img
                                    className='fab-section-image'
                                    src={whoContent.image}
                                    alt=''
                                    onLoad={() => setWhoAreWeSectionImageLoaded(true)}
                                />
                                <div className='fab-section-text'>
                                    <p className='fab-section-text-header'>Who are we?</p>
                                    <div className='fab-section-text-description' dangerouslySetInnerHTML={{ __html: sanitizeHtml(whoContent.desc.replace(/\n\n/g, '<br><br>').replace(/•/g, '<br>•').replace(/- /g, '<br>- ').replace(/✅/g, '<br>✅')) }}>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {Object.keys(whyContent || {}).length > 0 && <div id='why-join-us' className='fab-section fab-section-wju fab-section-container'>
                        <img className='fab-section-image fab-section-image-m' src={whyContent.image} alt='' />
                        <div className='fab-section-text'>
                            <p className='fab-section-text-header'>Why join us?</p>
                            <div className='fab-section-text-description' dangerouslySetInnerHTML={{ __html: sanitizeHtml(whyContent.desc.replace(/\n\n/g, '<br><br>').replace(/•/g, '<br>•').replace(/- /g, '<br>- ').replace(/✅/g, '<br>✅')) }}>
                            </div>
                        </div>
                        <img className='fab-section-image fab-section-image-desktop' src={whyContent.image} alt='' />
                    </div>}

                    {benefits.length > 0 && <div id='our-benefits' className='fab-section-benefits fab-section'>
                        <p className='fab-section-benefits-header'>Our benefits</p>
                        <div className='fab-section-benefits-row'>
                            {benefits.map((item) => {
                                return (<div className='fab-section-benefits-row-section'>
                                    <img className='fab-section-benefits-row-section-icon' src={`https://www.fastjobs.sg/img/frontend/partner/${item.icon}`} alt='' />
                                    <p className='fab-section-benefits-row-section-text'>{item.title}</p>
                                </div>)
                            })}
                        </div>
                    </div>}
                    {jobs?.totalData > 0 && <div id="available-jobs" className='fab-section-container fab-employer-jobs-container'>
                        <h2 className='fab-employer-jobs-header'>Available jobs</h2>
                        <p className='fab-employer-jobs-count'>{jobs?.totalData} jobs available</p>
                        <div>
                            <LatestJobs latestJobData={jobs.data || []} location='Company profile' />
                        </div>
                        <Pagination pageSize={JOBS_PAGE_SIZE} total={jobs?.totalData || 0} setCurrentPage={setCurrentPage} currentPage={currentPage} scrollToSection={scrollToSection} />
                    </div>}
                </> :
                    <>
                        {(!companyBannerImageLoaded && banner) && <Loader />}
                        {companyProfile &&
                            <div className='fab-company-profile'>
                                <div className='fab-company-banner'>
                                    <img
                                        className='fab-company-banner-image'
                                        src={banner}
                                        alt=''
                                        onLoad={() => setCompanyBannerImageLoaded(true)}
                                    />
                                </div>
                                <div className='fab-company-text'>
                                    <h3 className='fab-company-header'>
                                        {companyProfile.company}
                                    </h3>
                                    {companyProfile?.brn && <div className='fab-company-number'>
                                        {companyProfile.brn}
                                    </div>}
                                </div>
                            </div>
                        }
                        {jobs?.totalData > 0 && <div id="available-jobs" className='fab-cpp-jobs fab-company-jobs-container'>
                            <h2 className='fab-employer-jobs-header'>Available jobs</h2>
                            <p className='fab-employer-jobs-count'>{jobs?.totalData} jobs available</p>
                            <div>
                                <LatestJobs latestJobData={jobs.data || []} location='Company profile' />
                            </div>
                            <Pagination pageSize={JOBS_PAGE_SIZE} total={jobs?.totalData || 0} setCurrentPage={setCurrentPage} currentPage={currentPage} scrollToSection={scrollToSection} />
                        </div>}
                    </>}
            </div>
            <div className='fab-employer-profile-footer'><Footer /></div>
        </div>
    )
}

export default EmployerProfile;
