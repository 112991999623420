import '../../components/globals/ProgressiveApplication.css';

import React, { useEffect, useRef, useState } from 'react';
import { getUserFullName, getUserInfoAndToken } from '../../common/helper';
import { sendOTP, verifyPhoneNumber } from '../../services/ProfileService';

import Button from '../../components/button/Button';
import Loader from '../../components/loader/Loader';
import { MobileVerificationErrorMessageMapping } from '../../common/constants';
import analytics from '../../firebase';
import { logEvent } from "firebase/analytics";

const MobileVerifiation = ({ mobileNumber, setIsMobileVerified, handleClose }) => {
    const resendTimeout = 60;
    const { userId, username, token } = getUserInfoAndToken();
    const userFullName = getUserFullName();
    const initialized = useRef(false);
    const inputRefs = useRef([]);
    const [timer, setTimer] = useState(resendTimeout);
    const [otp, setOtp] = useState(['', '', '', '']);
    const [isError, setIsError] = useState(false);
    const [isOtpError, setIsOtpError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [otpErrorMessage, setOtpErrorMessage] = useState('');
    const [showLoader, setShowLoader] = useState(false)
    logEvent(analytics, 'apply_mobile_verify_viewed')
    const handleOtpChange = (index, event) => {
        setIsError(false)
        setErrorMessage('')
        const value = event.target.value
        if (!isNaN(value)) {
            const newOTP = [...otp];
            newOTP[index] = value;
            setOtp(newOTP);

            if (index < 3 && value !== '') {
                inputRefs.current[index + 1]?.focus();
            }
        }
    }

    const focusFirstInputField = ref => {
        inputRefs.current[0]?.focus();
    };

    const handleResend = () => {
        setTimer(resendTimeout);
        setOtp(['', '', '', ''])
        focusFirstInputField()
        sendOtpToPhone()
    };

    const sendOtpToPhone = async () => {
        try {
            setShowLoader(true)
            const response = await sendOTP(userId, username, token)
            if (response.code === "00") {
                setTimer(resendTimeout)
            }
            else if (response.code === "98") {
                setIsOtpError(true)
                setOtpErrorMessage(response?.message[0])
            }
            setShowLoader(false)
        }
        catch (error) {
            setIsOtpError(true)
            setShowLoader(false)
            setOtpErrorMessage('Something went wrong while sending OTP to mobile number')
            console.log(`Error occurred while sending OTP to user mobile number: ${error}`);
        }
    }

    const handleVerifyPhoneNumber = async () => {
        const otpValue = otp.join('');
        setIsError(false)
        setErrorMessage('')
        try {
            setShowLoader(true)
            const response = await verifyPhoneNumber(userId, username, token, otpValue);
            if (response.code === '00') {
                logEvent(analytics, 'apply_mobile_verify_successful');
                setIsMobileVerified(true)
                logEvent(analytics, 'mobile_verify_successful', {'userid': userId});
            }
            else if (response.code === '98') {
                setIsError(true)
                setErrorMessage(MobileVerificationErrorMessageMapping[response.message[0]])
            }
            setShowLoader(false)
        } catch (error) {
            setIsError(true)
            setShowLoader(false)
            setErrorMessage('Invalid code. Please try again.')
            console.log(`Error occurred while verifying mobile number: ${error}`);
        }
    };

    const handleCloseButtonClick = () => {
        const modalContainer = document.querySelector('.fab-modal-container');
        modalContainer.classList.remove('modal-active');
        handleClose()
    };

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            sendOtpToPhone();
        }
    }, [token])

    useEffect(() => {
        let intervalId;
        if (timer > 0) {
            intervalId = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
        }
        return () => clearInterval(intervalId);
    }, [timer]);

    useEffect(() => {
        focusFirstInputField();
    }, []);

    return (
        <div className="fab-modal-body fab-modal-verification">
            {showLoader && <Loader />}
            <svg className='fab-modal-application-close' width="24" height="24" onClick={handleCloseButtonClick} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L12 10.9393L17.4697 5.46967C17.7626 5.17678 18.2374 5.17678 18.5303 5.46967C18.8232 5.76256 18.8232 6.23744 18.5303 6.53033L13.0607 12L18.5303 17.4697C18.8232 17.7626 18.8232 18.2374 18.5303 18.5303C18.2374 18.8232 17.7626 18.8232 17.4697 18.5303L12 13.0607L6.53033 18.5303C6.23744 18.8232 5.76256 18.8232 5.46967 18.5303C5.17678 18.2374 5.17678 17.7626 5.46967 17.4697L10.9393 12L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967Z" fill="#18181B" />
            </svg>
            <div className="fab-verification-header">
                <h3>Verify your mobile number</h3>
                <span className='fab-verification-user-name'>Hey {userFullName}!</span>
                <p className='fab-verification-subtext'>Before you apply for this job, we'll need to verify your phone number.</p>
            </div>
            <div className='application-verification-wrapper'>
                <p className="application-verification-text">We have sent a verification code to <span>+65 {mobileNumber}</span></p>
                <span className="application-verification-sutext">The verification code might take a few minutes to arrive.</span>
                <span className="application-input-label">Enter the verification code below</span>
                <div className="application-inputs">
                    {otp.map((digit, index) => (
                        <input
                            key={index}
                            value={digit}
                            type="text"
                            maxLength="1"
                            pattern="[0-9]*"
                            inputmode="numeric"
                            className={"application-verification-input"}
                            ref={ref => inputRefs.current[index] = ref}
                            onChange={(event) => handleOtpChange(index, event)}
                        />
                    ))}
                </div>
                {isError && <div className='application-otp-error'>
                    <span>{errorMessage}</span>
                </div>}
                <div className="application-btns">
                    <Button variant="primary" size="lg" className="application-btn application-btn-verify" onClick={handleVerifyPhoneNumber}>Verify mobile number and apply</Button>
                    <p className="application-verfiy-count">Didn't get the code? Resend code in <span className="application-resend-count">{timer} seconds</span></p>
                    <Button variant="outline" size="md" className='fab-verification-resend-disabled' isDisabled={timer > 0} onClick={handleResend}>Resend code</Button>
                </div>
                {isOtpError && <p className='fab-verification-error'>{otpErrorMessage}</p>}
            </div>
        </div>
    )
}

export default MobileVerifiation;