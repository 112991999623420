import Button from "../../components/button/Button";

const TextFieldError = ({ defaultValue, value, onChangeHandler, onClickVerifyHandler, disableInput = false }) => {
    return (<div className='fab-profile-section-row-input-wrapper-error'>
        <input className='fab-profile-section-row-input-wrapper-error fab-profile-section-row-textfield-input' defaultValue={defaultValue} value={value} onChange={onChangeHandler} disabled={disableInput} />
        <Button className='fab-profile-section-row-button' onClick={onClickVerifyHandler}>
            <p className='fab-profile-section-row-button-text'>Verify</p>
        </Button>
    </div>)
}

export default TextFieldError;