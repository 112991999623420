import '../../components/globals/SignIn.css';
import '../../components/globals/ChangePassword.css';

import { Link, useNavigate } from 'react-router-dom';
import React, { useRef, useState } from 'react';
import { getUserInfoAndToken, isEmpty } from '../../common/helper';

import Button from '../../components/button/Button';
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Loader from '../../components/loader/Loader';
import TextField from '../../components/textfield/Textfield';
import Toast from '../../components/toast/Toast';
import UserSidebar from "./UserSidebar";
import analytics from '../../firebase';
import { changePassword } from '../../services/ProfileService';
import { logEvent } from "firebase/analytics";

const ChangePassword = () => {
    const dropdownRef = useRef();
    const navigate = useNavigate();
    const { userId, username, token } = getUserInfoAndToken();
    const activeLink = 'change-password';
    const [isOpen, setIsOpen] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [passwordValidation, setPasswordValidation] = useState({
        length: false,
        upperCase: false,
        lowerCase: false,
        number: false,
    });
    const [isTyping, setIsTyping] = useState(false);
    const [error, setError] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [showToast, setShowToast] = useState(false);
    logEvent(analytics, 'change_password_screen_viewed');
    const handleDropdownClick = () => {
        setIsOpen(!isOpen);
    };

    const handlePasswordChange = (event) => {
        const newPassword = event.target.value;
        setError('')
        if (!isEmpty(confirmPassword) && (event.target.value !== confirmPassword)) {
            setError("New password does not match. Please try again.")
        }
        setPassword(newPassword);

        if (newPassword.trim() !== "" && !isTyping) {
            setIsTyping(true);
        }

        const validation = {
            length: newPassword.length >= 8,
            upperCase: /[A-Z]/.test(newPassword),
            lowerCase: /[a-z]/.test(newPassword),
            number: /\d/.test(newPassword),
        };

        setPasswordValidation(validation);
    };

    const handleCurrentPasswordChange = (event) => {
        setCurrentPassword(event.target.value)
    }

    const handleConfirmPasswordChange = (event) => {
        setError('')
        setConfirmPassword(event.target.value)
        if (event.target.value !== password) {
            setError("New password does not match. Please try again.")
        }
    }

    const passwordRequirements = [
        {
            condition: passwordValidation.length,
            text: "Password must contain at least 8 characters.",
        },
        {
            condition: passwordValidation.upperCase,
            text: "Password must include at least one uppercase letter.",
        },
        {
            condition: passwordValidation.lowerCase,
            text: "Password must include at least one lowercase letter.",
        },
        {
            condition: passwordValidation.number,
            text: "Password must include at least one number.",
        },
    ];

    const resetPasswordState = () => {
        setConfirmPassword("");
        setCurrentPassword("");
        setPassword("");
        setPasswordValidation({
            length: false,
            upperCase: false,
            lowerCase: false,
            number: false,
        })
    }


    const handleOnClick = async () => {
        try {
            setError("")
            setShowLoader(true)
            logEvent(analytics, 'change_password_initiated');
            const response = await changePassword(userId, username, token, currentPassword, password, confirmPassword);
            if (response.code === '00') {
                logEvent(analytics, 'change_password_success');
                const token = response.data[0].t
                localStorage.setItem('token', token)
                setShowToast(true)
                resetPasswordState()
            }
            else if (response.code === '98') {
                setError('Password requirements not met. Please try again.')
                resetPasswordState()
            }
            else {
                setError('Something went wrong')
            }
            setShowLoader(false)
        }
        catch (error) {
            setShowLoader(false)
            setError('Password requirements not met. Please try again.')
        }
    }

    const handleToastClose = () => {
        setShowToast(false)
    }
    const onLogout = (e) => {
        e.preventDefault();
        localStorage.clear();
        logEvent(analytics, 'logout_success');
        setTimeout(() => {
            navigate('/')
            navigate(0)
        }, 100)
    }

    return (
        <div className='fab-cp-wraper'>
            <Header />
            {showLoader && <Loader />}
            {showToast && <Toast type={'success'} onClose={handleToastClose} header={'Password change successfull'} message={'You have successfully changed your password.'} />}
            <div className='fab-user-change-password-container'>
                <div className='fab-user-sidebar-options-m'>
                    <div className='fab-container'>
                        <div className='fab-sidebard-mobile-dropdown'>
                            <div className='fab-sidebard-dropdown' onClick={handleDropdownClick}>
                                <span>Change Password</span>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12.5303 16.2803C12.2374 16.5732 11.7626 16.5732 11.4697 16.2803L3.96967 8.78033C3.67678 8.48744 3.67678 8.01256 3.96967 7.71967C4.26256 7.42678 4.73744 7.42678 5.03033 7.71967L12 14.6893L18.9697 7.71967C19.2626 7.42678 19.7374 7.42678 20.0303 7.71967C20.3232 8.01256 20.3232 8.48744 20.0303 8.78033L12.5303 16.2803Z" fill="#18181B" />
                                </svg>
                            </div>
                            <div ref={dropdownRef} className={`fab-sidebar-dropdown-options ${isOpen ? 'fab-sidebar-dropdown-open' : ''}`}>
                                <Link className='fab-sidebar-dropdown-item'>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M6.24997 5C6.24997 2.92893 7.9289 1.25 9.99997 1.25C12.071 1.25 13.75 2.92893 13.75 5C13.75 7.07107 12.071 8.75 9.99997 8.75C7.9289 8.75 6.24997 7.07107 6.24997 5Z" fill="#3F3F46" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M3.12601 16.7544C3.19046 13.013 6.24329 10 9.99997 10C13.7567 10 16.8096 13.0131 16.8739 16.7547C16.8782 17.0028 16.7352 17.23 16.5097 17.3335C14.5272 18.2432 12.3221 18.75 10.0002 18.75C7.67819 18.75 5.47283 18.2431 3.49024 17.3332C3.26468 17.2297 3.12173 17.0026 3.12601 16.7544Z" fill="#3F3F46" />
                                    </svg>
                                    Profile
                                </Link>
                                <Link className='fab-sidebar-dropdown-item fab-sidebar-dropdown-item-active'>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M13.125 1.25C10.0184 1.25 7.5 3.7684 7.5 6.875C7.5 7.2031 7.52816 7.52513 7.58233 7.83866C7.63832 8.16273 7.55565 8.43546 7.39806 8.59306L1.98223 14.0089C1.51339 14.4777 1.25 15.1136 1.25 15.7767V18.125C1.25 18.4702 1.52982 18.75 1.875 18.75H5C5.34518 18.75 5.625 18.4702 5.625 18.125V16.875H6.875C7.22018 16.875 7.5 16.5952 7.5 16.25V15H8.75C8.91576 15 9.07473 14.9342 9.19194 14.8169L11.4069 12.6019C11.5645 12.4443 11.8373 12.3617 12.1613 12.4177C12.4749 12.4718 12.7969 12.5 13.125 12.5C16.2316 12.5 18.75 9.9816 18.75 6.875C18.75 3.7684 16.2316 1.25 13.125 1.25ZM13.125 3.75C12.7798 3.75 12.5 4.02982 12.5 4.375C12.5 4.72018 12.7798 5 13.125 5C14.1605 5 15 5.83947 15 6.875C15 7.22018 15.2798 7.5 15.625 7.5C15.9702 7.5 16.25 7.22018 16.25 6.875C16.25 5.14911 14.8509 3.75 13.125 3.75Z" fill="#3F3F46" />
                                    </svg>
                                    Change password
                                </Link>
                                <Link className='fab-sidebar-dropdown-item' to='/applied-jobs'>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M1.875 10C1.875 5.51269 5.51269 1.875 10 1.875C14.4873 1.875 18.125 5.51269 18.125 10C18.125 14.4873 14.4873 18.125 10 18.125C5.51269 18.125 1.875 14.4873 1.875 10ZM13.0086 8.48827C13.2092 8.20739 13.1442 7.81705 12.8633 7.61642C12.5824 7.41579 12.192 7.48084 11.9914 7.76173L9.29525 11.5364L7.94194 10.1831C7.69786 9.93898 7.30214 9.93898 7.05806 10.1831C6.81398 10.4271 6.81398 10.8229 7.05806 11.0669L8.93306 12.9419C9.06297 13.0719 9.24346 13.138 9.42655 13.1229C9.60964 13.1077 9.7768 13.0128 9.88358 12.8633L13.0086 8.48827Z" fill="#3F3F46" />
                                    </svg>
                                    Applied jobs
                                </Link>
                                <Link className='fab-sidebar-dropdown-item' to='/saved-jobs'>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.26685 2.14784C6.81978 1.96762 8.3992 1.875 10 1.875C11.6008 1.875 13.1802 1.96762 14.7332 2.14784C15.981 2.29266 16.875 3.36435 16.875 4.58916V17.5C16.875 17.7166 16.7628 17.9178 16.5786 18.0317C16.3943 18.1455 16.1642 18.1559 15.9705 18.059L10 15.0738L4.02951 18.059C3.83577 18.1559 3.60568 18.1455 3.42142 18.0317C3.23716 17.9178 3.125 17.7166 3.125 17.5V4.58916C3.125 3.36435 4.01903 2.29265 5.26685 2.14784Z" fill="#3F3F46" />
                                    </svg>
                                    Saved jobs
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='fab-container fab-change-wrapped'>
                    <div className='fab-user-sidebar-options'>
                        <UserSidebar linkActive={activeLink} />
                    </div>
                    <div className='fab-user-change-password'>
                        <h3>Change Password</h3>
                        {/* Error Alert */}
                        {error && <div className='fab-sign-error-alert fab-error-alert-active'>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M10 3.125C6.20304 3.125 3.125 6.20304 3.125 10C3.125 13.797 6.20304 16.875 10 16.875C13.797 16.875 16.875 13.797 16.875 10C16.875 6.20304 13.797 3.125 10 3.125ZM1.875 10C1.875 5.51269 5.51269 1.875 10 1.875C14.4873 1.875 18.125 5.51269 18.125 10C18.125 14.4873 14.4873 18.125 10 18.125C5.51269 18.125 1.875 14.4873 1.875 10ZM9.375 6.875C9.375 6.52982 9.65482 6.25 10 6.25H10.0062C10.3514 6.25 10.6312 6.52982 10.6312 6.875V6.88125C10.6312 7.22643 10.3514 7.50625 10.0062 7.50625H10C9.65482 7.50625 9.375 7.22643 9.375 6.88125V6.875ZM9.13007 8.7987C10.0853 8.3211 11.1608 9.18384 10.9018 10.2199L10.3109 12.5833L10.3455 12.566C10.6542 12.4116 11.0296 12.5367 11.184 12.8455C11.3384 13.1542 11.2133 13.5296 10.9045 13.684L10.8699 13.7013L10.8699 13.7013C9.91474 14.1789 8.83923 13.3162 9.09824 12.2801L9.68908 9.91673L9.65451 9.93402C9.34577 10.0884 8.97035 9.96325 8.81598 9.65451C8.66161 9.34577 8.78675 8.97035 9.09549 8.81598L9.13007 8.7987Z" fill="#B91C1C" />
                            </svg>
                            <span className='fab-sign-single-error'>{error}</span>
                        </div>}
                        <p className='fab-user-change-subtext'>Create a secure password to protect your account. Ensure it meets the following requirements:</p>
                        <div>
                            <div className="fab-sign-password-requirments">
                                {passwordRequirements.map((requirement, index) => (
                                    <span
                                        key={index}
                                        style={{ color: requirement.condition ? "green" : "" }}
                                    >
                                        {requirement.condition ? (
                                            <svg
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M0.6875 8C0.6875 3.96142 3.96142 0.6875 8 0.6875C12.0386 0.6875 15.3125 3.96142 15.3125 8C15.3125 12.0386 12.0386 15.3125 8 15.3125C3.96142 15.3125 0.6875 12.0386 0.6875 8ZM10.7077 6.63945C10.8883 6.38665 10.8297 6.03534 10.5769 5.85477C10.3242 5.67421 9.97284 5.73276 9.79227 5.98555L7.36572 9.38273L6.14775 8.16475C5.92808 7.94508 5.57192 7.94508 5.35225 8.16475C5.13258 8.38442 5.13258 8.74058 5.35225 8.96025L7.03975 10.6477C7.15667 10.7647 7.31911 10.8242 7.4839 10.8106C7.64868 10.7969 7.79912 10.7115 7.89523 10.5769L10.7077 6.63945Z"
                                                    fill="#059669"
                                                />
                                            </svg>
                                        ) : (
                                            <svg
                                                width="18"
                                                height="18"
                                                viewBox="0 0 18 18"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M9 2.8125C5.58274 2.8125 2.8125 5.58274 2.8125 9C2.8125 12.4173 5.58274 15.1875 9 15.1875C12.4173 15.1875 15.1875 12.4173 15.1875 9C15.1875 5.58274 12.4173 2.8125 9 2.8125ZM1.6875 9C1.6875 4.96142 4.96142 1.6875 9 1.6875C13.0386 1.6875 16.3125 4.96142 16.3125 9C16.3125 13.0386 13.0386 16.3125 9 16.3125C4.96142 16.3125 1.6875 13.0386 1.6875 9ZM6.1875 9C6.1875 8.68934 6.43934 8.4375 6.75 8.4375H11.25C11.5607 8.4375 11.8125 8.68934 11.8125 9C11.8125 9.31066 11.5607 9.5625 11.25 9.5625H6.75C6.43934 9.5625 6.1875 9.31066 6.1875 9Z"
                                                    fill="#A1A1AA"
                                                />
                                            </svg>
                                        )}
                                        {requirement.text}
                                    </span>
                                ))}
                            </div>
                            <div className='fab-user-change-fields'>
                                <div className="fab-sign-password">
                                    <label>Current password</label>
                                    <TextField
                                        isPassword={true}
                                        value={currentPassword}
                                        onChange={handleCurrentPasswordChange}
                                    />
                                </div>
                                <div className="fab-sign-password">
                                    <label>New password</label>
                                    <TextField
                                        isPassword={true}
                                        onChange={handlePasswordChange}
                                        value={password}
                                    />
                                </div>
                                <div className="fab-sign-password">
                                    <label>Confirm new password</label>
                                    <TextField
                                        isPassword={true}
                                        value={confirmPassword}
                                        onChange={handleConfirmPasswordChange}
                                    />
                                </div>
                            </div>
                            <div className='fab-user-buttons'>
                                <Button variant='primary' size='md' className='fab-btn-change-password-disabled' isDisabled={isEmpty(password) || isEmpty(currentPassword) || isEmpty(confirmPassword)} onClick={handleOnClick}>Change password</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <div className={`fab-dropdown-mobile-menu ${isOpen ? 'fab-profile-mobile-active' : ''}`} onClick={() => { setIsOpen(false) }}>
                <div className='fab-menu-mobile-container'>
                    <div className='fab-menu-header-container'>
                        <p>Navigation</p>
                    </div>
                    <div className='fab-menu-mobile-items' >
                        <Link className='fab-sidebar-dropdown-item' to="/profile">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M6.24997 5C6.24997 2.92893 7.9289 1.25 9.99997 1.25C12.071 1.25 13.75 2.92893 13.75 5C13.75 7.07107 12.071 8.75 9.99997 8.75C7.9289 8.75 6.24997 7.07107 6.24997 5Z" fill="#3F3F46" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M3.12601 16.7544C3.19046 13.013 6.24329 10 9.99997 10C13.7567 10 16.8096 13.0131 16.8739 16.7547C16.8782 17.0028 16.7352 17.23 16.5097 17.3335C14.5272 18.2432 12.3221 18.75 10.0002 18.75C7.67819 18.75 5.47283 18.2431 3.49024 17.3332C3.26468 17.2297 3.12173 17.0026 3.12601 16.7544Z" fill="#3F3F46" />
                            </svg>
                            Profile
                        </Link>
                        <Link className='fab-sidebar-dropdown-item ' to="/change-password">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M13.125 1.25C10.0184 1.25 7.5 3.7684 7.5 6.875C7.5 7.2031 7.52816 7.52513 7.58233 7.83866C7.63832 8.16273 7.55565 8.43546 7.39806 8.59306L1.98223 14.0089C1.51339 14.4777 1.25 15.1136 1.25 15.7767V18.125C1.25 18.4702 1.52982 18.75 1.875 18.75H5C5.34518 18.75 5.625 18.4702 5.625 18.125V16.875H6.875C7.22018 16.875 7.5 16.5952 7.5 16.25V15H8.75C8.91576 15 9.07473 14.9342 9.19194 14.8169L11.4069 12.6019C11.5645 12.4443 11.8373 12.3617 12.1613 12.4177C12.4749 12.4718 12.7969 12.5 13.125 12.5C16.2316 12.5 18.75 9.9816 18.75 6.875C18.75 3.7684 16.2316 1.25 13.125 1.25ZM13.125 3.75C12.7798 3.75 12.5 4.02982 12.5 4.375C12.5 4.72018 12.7798 5 13.125 5C14.1605 5 15 5.83947 15 6.875C15 7.22018 15.2798 7.5 15.625 7.5C15.9702 7.5 16.25 7.22018 16.25 6.875C16.25 5.14911 14.8509 3.75 13.125 3.75Z" fill="#3F3F46" />
                            </svg>
                            Change password
                        </Link>
                        <Link className='fab-sidebar-dropdown-item' to='/applied-jobs'>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M1.875 10C1.875 5.51269 5.51269 1.875 10 1.875C14.4873 1.875 18.125 5.51269 18.125 10C18.125 14.4873 14.4873 18.125 10 18.125C5.51269 18.125 1.875 14.4873 1.875 10ZM13.0086 8.48827C13.2092 8.20739 13.1442 7.81705 12.8633 7.61642C12.5824 7.41579 12.192 7.48084 11.9914 7.76173L9.29525 11.5364L7.94194 10.1831C7.69786 9.93898 7.30214 9.93898 7.05806 10.1831C6.81398 10.4271 6.81398 10.8229 7.05806 11.0669L8.93306 12.9419C9.06297 13.0719 9.24346 13.138 9.42655 13.1229C9.60964 13.1077 9.7768 13.0128 9.88358 12.8633L13.0086 8.48827Z" fill="#3F3F46" />
                            </svg>
                            Applied jobs
                        </Link>
                        <Link className='fab-sidebar-dropdown-item' to='/saved-jobs'>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M5.26685 2.14784C6.81978 1.96762 8.3992 1.875 10 1.875C11.6008 1.875 13.1802 1.96762 14.7332 2.14784C15.981 2.29266 16.875 3.36435 16.875 4.58916V17.5C16.875 17.7166 16.7628 17.9178 16.5786 18.0317C16.3943 18.1455 16.1642 18.1559 15.9705 18.059L10 15.0738L4.02951 18.059C3.83577 18.1559 3.60568 18.1455 3.42142 18.0317C3.23716 17.9178 3.125 17.7166 3.125 17.5V4.58916C3.125 3.36435 4.01903 2.29265 5.26685 2.14784Z" fill="#3F3F46" />
                            </svg>
                            Saved jobs
                        </Link>
                        <Link className='fab-sidebar-dropdown-item fab-profile-sidebar-logout-text' onClick={(e) => onLogout(e)}>
                            <svg width='16' height='18' viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path fillRule='evenodd' clipRule='evenodd' d='M3.25 2.125C2.55964 2.125 2 2.68464 2 3.375L2 14.625C2 15.3154 2.55964 15.875 3.25 15.875H8.25C8.94036 15.875 9.5 15.3154 9.5 14.625V11.5C9.5 11.1548 9.77982 10.875 10.125 10.875C10.4702 10.875 10.75 11.1548 10.75 11.5V14.625C10.75 16.0057 9.63071 17.125 8.25 17.125H3.25C1.86929 17.125 0.75 16.0057 0.75 14.625L0.75 3.375C0.750001 1.99429 1.86929 0.875 3.25 0.875L8.25 0.875C9.63071 0.875 10.75 1.99429 10.75 3.375V6.5C10.75 6.84518 10.4702 7.125 10.125 7.125C9.77982 7.125 9.5 6.84518 9.5 6.5V3.375C9.5 2.68464 8.94036 2.125 8.25 2.125L3.25 2.125ZM7.44194 6.05806C7.68602 6.30214 7.68602 6.69787 7.44194 6.94194L6.00888 8.375L15.125 8.375C15.4702 8.375 15.75 8.65482 15.75 9C15.75 9.34518 15.4702 9.625 15.125 9.625L6.00888 9.625L7.44194 11.0581C7.68602 11.3021 7.68602 11.6979 7.44194 11.9419C7.19786 12.186 6.80214 12.186 6.55806 11.9419L4.05806 9.44194C3.81398 9.19786 3.81398 8.80214 4.05806 8.55806L6.55806 6.05806C6.80214 5.81398 7.19787 5.81398 7.44194 6.05806Z' fill='#DC2626' />
                            </svg>
                            Logout
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword;