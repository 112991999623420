import React from 'react';
import './Loader.css';

const Loader = () => {
    return (
        <div className='fab-loader-overlay'>
            <div className="fab-loader-container">
                <div className="fab-loader">
                </div>
                <div className='fab-loading-text'>Loading</div>
            </div>
        </div>
    );
};

export default Loader;