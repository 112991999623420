import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "../../components/button/Button";
import moment from 'moment';

const WorkExperienceForm = ({
    jobTitle,
    setJobTitle,
    companyName,
    setCompanyName,
    fromWorkPeriod,
    setFromWorkPeriod,
    toWorkPeriod,
    setToWorkPeriod,
    currentlyWorkHere,
    setCurrentlyWorkHere,
    keyJobResponsibilities,
    setKeyJobResponsibilities,
    jobTitleErrorMessage,
    companyNameErrorMessage,
    fromWorkPeriodErrorMessage,
    toWorkPeriodErrorMessage,
    setJobTitleErrorMessage,
    setCompanyNameErrorMessage,
    setFromWorkPeriodErrorMessage,
    setToWorkPeriodErrorMessage,
    backDateErrorMessage,
    setBackDateErrorMessage,
    handleCancelClick,
    handleSaveClick
}) => {
    const currentDate = new Date();

    const handleJobTitleChange = (e) => {
        setJobTitleErrorMessage('');
        setJobTitle(e.target.value);
    };
    const handleCompanyNameChange = (e) => {
        setCompanyNameErrorMessage('');
        setCompanyName(e.target.value);
    }
    const handleFromWorkPeriodChange = (date) => {
        setFromWorkPeriodErrorMessage('');
        setBackDateErrorMessage('');
        setFromWorkPeriod(date);
    }
    const handleToWorkPeriodChange = (date) => {
        if (date) {
            setToWorkPeriodErrorMessage('');
        }
        setToWorkPeriod(date);
    }
    const handleKeyJobResponsibilitiesChange = (e) => setKeyJobResponsibilities(e.target.value);
    const handleCurrentlyWorkHereChange = () => {
        setBackDateErrorMessage('');
        setCurrentlyWorkHere(!currentlyWorkHere);
    }

    return (
        <div className='fb-we-form-container'>
            <div className='fb-form-section-wrapper'>
                <div className='fb-form-section-header'>
                    <label className='fb-form-section-header-text'>Job title<span>*</span></label>
                </div>
                <input type='text' className={`fb-form-section-input ${jobTitleErrorMessage ? 'fb-form-section-input-error' : ''} fb-form-section-input-text`} placeholder='Enter your job title' defaultValue={jobTitle} value={jobTitle} onChange={handleJobTitleChange} />
                {jobTitleErrorMessage && <label className='fab-form-field-error-message'>{jobTitleErrorMessage}</label>}
            </div>
            <div className='fb-form-section-wrapper'>
                <div className='fb-form-section-header'>
                    <label className='fb-form-section-header-text'>Company name<span>*</span></label>
                </div>
                <input type='text' className={`fb-form-section-input ${companyNameErrorMessage ? 'fb-form-section-input-error' : ''} fb-form-section-input-text`} placeholder='Enter your company name' defaultValue={companyName} value={companyName} onChange={handleCompanyNameChange} />
                {companyNameErrorMessage && <label className='fab-form-field-error-message'>{companyNameErrorMessage}</label>}
            </div>
            <div className='fb-form-section-wrapper'>
                <div className='fb-form-section-header'>
                    <label className='fb-form-section-header-text'>Work period</label>
                </div>
                <div className={`fb-form-wp-from-to-container ${(fromWorkPeriodErrorMessage || toWorkPeriodErrorMessage || backDateErrorMessage) ? 'fb-form-wp-from-to-container-height' : ''} `}>
                    <div className='fb-form-wp-date-wrapper'>
                        <div className='fb-form-wp-date-header'>
                            <label className='fb-form-wp-date-header-text'>From<span>*</span></label>
                        </div>
                        <div className='fb-form-wp-date-picker-container'>
                            <DatePicker
                                className={`fb-form-wp-date-picker-input-wrapper ${fromWorkPeriodErrorMessage ? 'fb-form-wp-date-picker-input-wrapper-error' : ''} fb-form-wp-date-picker-input-text`}
                                placeholderText='MM/YYYY'
                                dateFormat='MM/yyyy'
                                selected={(fromWorkPeriod && moment(fromWorkPeriod, 'MMM YYYY').toDate())}
                                onChange={(date) => handleFromWorkPeriodChange(date)}
                                maxDate={currentDate}
                                showMonthYearPicker
                            />
                        </div>
                        {fromWorkPeriodErrorMessage && <label className='fab-form-field-error-message'>{fromWorkPeriodErrorMessage}</label>}
                        {backDateErrorMessage && <label className='fab-form-field-error-message'>{backDateErrorMessage}</label>}
                    </div>
                    <div className='fb-form-wp-date-wrapper'>
                        <div className='fb-form-wp-date-header'>
                            <label className='fb-form-wp-date-header-text'>To<span>*</span></label>
                        </div>
                        <div className='fb-form-wp-date-picker-container'>
                            <DatePicker
                                className={`fb-form-wp-date-picker-input-wrapper ${(!currentlyWorkHere && toWorkPeriodErrorMessage) ? 'fb-form-wp-date-picker-input-wrapper-error' : ''} fb-form-wp-date-picker-input-text`}
                                placeholderText={currentlyWorkHere ? 'present' : 'MM/YYYY'}
                                dateFormat='MM/yyyy'
                                selected={currentlyWorkHere ? '' : (toWorkPeriod && moment(toWorkPeriod, 'MMM YYYY').toDate())}
                                onChange={(date) => handleToWorkPeriodChange(date)}
                                maxDate={currentDate}
                                showMonthYearPicker
                                disabled={currentlyWorkHere}
                            />
                        </div>
                        {(!currentlyWorkHere && toWorkPeriodErrorMessage) && <label className='fab-form-field-error-message'>{toWorkPeriodErrorMessage}</label>}
                    </div>
                </div>
                <div className='fb-form-wp-cwh-container'>
                    <div className='fb-form-wp-cwh-cb-wrapper'>
                        <input type='checkbox' className='fb-form-wp-cwh-cb' checked={currentlyWorkHere} onChange={handleCurrentlyWorkHereChange} />
                        <p className='fb-form-wp-cwh-cb-text'>I currently work here</p>
                    </div>
                </div>
            </div>
            <div className='fb-form-job-res-container'>
                <div className='fb-form-section-header'>
                    <label className='fb-form-section-header-text'>Key job responsibilities</label>
                </div>
                <div className='fb-form-job-res-ta-wrapper'>
                    <textarea className='fb-form-job-res-ta fb-form-job-res-ta-text' placeholder='List down your role and responsibilities within your company.' value={keyJobResponsibilities} onChange={handleKeyJobResponsibilitiesChange} />
                </div>
            </div>
            <div className='fb-save-cancel-container'>
                <Button className='fb-cancel-btn fb-cancel-btn-text' onClick={handleCancelClick}>
                    Cancel
                </Button>
                <Button className='fb-save-btn fb-save-btn-text' onClick={handleSaveClick}>
                    Save
                </Button>
            </div>
        </div>
    )
}

export default WorkExperienceForm;
