import './LanguageProficiency.css';

import { SOMETHING_WENT_WRONG_MESSAGE, TRY_UPDATE_CHANGES_AGAIN_MESSAGE, UPDATE_SUCCESS_MESSAGE } from '../../common/constants';
import { getProfileDetails, updateLanguageProficiencies } from '../../services/ProfileService';
import { useEffect, useState } from 'react';

import Button from '../../components/button/Button';
import Loader from '../../components/loader/Loader';
import Toast from '../../components/toast/Toast';
import analytics from '../../firebase';
import { getUserInfoAndToken } from '../../common/helper';
import { logEvent } from "firebase/analytics";

const NOT_SPECIFIED = 'Not Specified';

const LanguageProficiency = ({ user, setUser, codeTableLanguages, codeTableProficiencyLevels, scrollToLanguageProficiencySection }) => {
    const [displayForm, setDisplayForm] = useState(false);
    const [selectedLanguageProficiencies, setSelectedLanguageProficiencies] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessageHeader, setErrorMessageHeader] = useState('');
    const [errorMessageDescription, setErrorMessageDescription] = useState('');
    const [successMessageHeader, setSuccessMessageHeader] = useState('');
    const [successMessageDescription, setSuccessMessageDescription] = useState('');

    const convertForDisplay = (languages) => {
        if (languages) {
            return languages?.map((lang) => {
                const spoken = codeTableProficiencyLevels.filter((p) => p.codeid === parseInt(lang.spoken));
                const written = codeTableProficiencyLevels.filter((p) => p.codeid === parseInt(lang.written));

                return {
                    language: codeTableLanguages.filter((l) => l.codeid === parseInt(lang.languageid))[0].val,
                    proficiency: {
                        spoken: spoken.length > 0 ? spoken[0].val : NOT_SPECIFIED,
                        written: written.length > 0 ? written[0].val : NOT_SPECIFIED,
                    }
                }
            });
        }
        return []
    }

    useEffect(() => {
        const langs = user?.languages;
        if (codeTableLanguages.length > 0 && codeTableProficiencyLevels.length > 0) {
            setSelectedLanguageProficiencies(convertForDisplay(langs));
        }
    }, [user, codeTableLanguages, codeTableProficiencyLevels]);

    const handleLanguageChange = (language) => {
        const existing = selectedLanguageProficiencies.filter((selectedLanguageProficiency) => selectedLanguageProficiency.language === language.val);
        if (existing.length === 0) {
            setSelectedLanguageProficiencies([...selectedLanguageProficiencies, { language: language.val }]);
        } else {
            setSelectedLanguageProficiencies(
                selectedLanguageProficiencies.filter((selectedLanguageProficiency) => selectedLanguageProficiency.language !== language.val)
            );
        }
    };

    const handleLanguageProficiencyChange = (language, proficiencyType, proficiency) => {
        const prof = { spoken: null, written: null };
        const progLang = selectedLanguageProficiencies.filter((l) => l.language === language);
        if (proficiencyType === 'Spoken') {
            prof.spoken = proficiency;
            prof.written = progLang[0]?.proficiency?.written || prof.written;
        } else if (proficiencyType === 'Written') {
            prof.written = proficiency;
            prof.spoken = progLang[0]?.proficiency?.spoken || prof.spoken;
        }

        setSelectedLanguageProficiencies(
            selectedLanguageProficiencies.map((lang) => {
                if (lang.language === language) {
                    return { language: lang.language, proficiency: prof }
                }
                return lang
            })
        );
    };

    const handleCancelClick = () => {
        setSelectedLanguageProficiencies(convertForDisplay(user?.languages));
        setDisplayForm(false);
        scrollToLanguageProficiencySection();
    };

    const resetSuccessMessages = () => {
        setSuccessMessageHeader('');
        setSuccessMessageDescription('');
    }

    const resetErrorMessages = () => {
        setErrorMessageHeader('');
        setErrorMessageDescription('');
    }

    const handleSaveClick = async () => {
        try {
            setIsLoading(true);
            resetErrorMessages();
            resetSuccessMessages();

            const langWithProfs = [];
            selectedLanguageProficiencies.forEach((lang) => {
                const spoken = lang?.proficiency?.spoken === NOT_SPECIFIED ? '' : lang?.proficiency?.spoken;
                const written = lang?.proficiency?.written === NOT_SPECIFIED ? '' : lang?.proficiency?.written;
                langWithProfs.push({
                    language: lang.language,
                    proficiency: { spoken, written }
                })
            });

            const languagesToUpdate = [];
            for (const lp of langWithProfs) {
                const langIdArr = codeTableLanguages.filter((l) => l.val === lp.language);
                const spokenIdArr = codeTableProficiencyLevels.filter((l) => l.val === lp.proficiency.spoken);
                const writtenIdArr = codeTableProficiencyLevels.filter((l) => l.val === lp.proficiency.written);

                languagesToUpdate.push({
                    id: Object.values(langIdArr)[0].codeid,
                    spoken: spokenIdArr.length > 0 ? Object.values(spokenIdArr)[0].codeid : '',
                    written: writtenIdArr.length > 0 ? Object.values(writtenIdArr)[0].codeid : ''
                })
            }

            const { userId, username, token } = getUserInfoAndToken();
            const response = await updateLanguageProficiencies(userId, username, token, languagesToUpdate);
            if (response?.code === '00') {
                const params = { 'userid': userId, 'languages': JSON.stringify(languagesToUpdate) };
                logEvent(analytics, 'profile_language_updated', params);
                const profileResponse = await getProfileDetails(userId, username, token);
                if (profileResponse?.code === '00') {
                    const data = profileResponse?.data;
                    if (data?.length > 0) {
                        const user = data[0];
                        setUser(user);
                        setSelectedLanguageProficiencies(convertForDisplay(user?.languages));
                    }

                    setSuccessMessageHeader(UPDATE_SUCCESS_MESSAGE);
                    setSuccessMessageDescription('Your changes to Language proficiency has been saved!');
                }
                resetErrorMessages();
                setDisplayForm(false);

                setTimeout(() => {
                    scrollToLanguageProficiencySection();
                }, 100);
            } else {
                const messages = response?.message;
                if (messages?.length > 0) {
                    setErrorMessageHeader('Update error');
                    setErrorMessageDescription(messages.join('\n'));
                }
                setDisplayForm(true);
            }

            setIsLoading(false);
        } catch (error) {
            console.log(`error occurred while updating the skills: ${error}`);

            setIsLoading(false);
            setErrorMessageHeader(SOMETHING_WENT_WRONG_MESSAGE);
            setErrorMessageDescription(TRY_UPDATE_CHANGES_AGAIN_MESSAGE);
        }
    };

    const languageChecked = (language) => {
        return selectedLanguageProficiencies?.filter((selected) => selected.language === language).length > 0
    }

    const spokenProficiencyValue = (language) => {
        const spokenProficiency = selectedLanguageProficiencies.filter((lang) => lang.language === language);
        return spokenProficiency[0]?.proficiency?.spoken;
    }

    const spokenProficiencyChecked = (language, proficiency) => {
        return selectedLanguageProficiencies.filter((lang) => lang.language === language && lang.proficiency?.spoken === proficiency).length > 0;
    }

    const writtenProficiencyValue = (language) => {
        const spokenProficiency = selectedLanguageProficiencies.filter((lang) => lang.language === language);
        return spokenProficiency[0]?.proficiency?.written;
    }

    const writtenProficiencyChecked = (language, proficiency) => {
        return selectedLanguageProficiencies.filter((lang) => lang.language === language && lang.proficiency?.written === proficiency).length > 0;
    }

    const addButtonClicked = () => {
        setDisplayForm(true)
        logEvent(analytics, 'profile_language_initiated');
    }


    return (
        <>
            {
                (successMessageHeader && successMessageDescription) && <Toast
                    type='success'
                    header={successMessageHeader}
                    message={successMessageDescription}
                    onClose={resetSuccessMessages}
                />
            }
            {
                (errorMessageHeader && errorMessageDescription) && <Toast
                    type='error'
                    header={errorMessageHeader}
                    message={errorMessageDescription}
                    onClose={resetErrorMessages}
                />
            }
            {isLoading && <Loader></Loader>}
            <>
                <div className='fab-lp-container'>
                    <div className='fab-lp-header-btn-wrapper'>
                        <label className='fab-lp-section-title'>Language proficiency</label>
                        <Button className='fb-add-btn' onClick={addButtonClicked}>
                            <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path fillRule='evenodd' clipRule='evenodd' d='M7 0.125C7.34518 0.125 7.625 0.404822 7.625 0.75V6.375H13.25C13.5952 6.375 13.875 6.65482 13.875 7C13.875 7.34518 13.5952 7.625 13.25 7.625H7.625V13.25C7.625 13.5952 7.34518 13.875 7 13.875C6.65482 13.875 6.375 13.5952 6.375 13.25V7.625H0.75C0.404822 7.625 0.125 7.34518 0.125 7C0.125 6.65482 0.404822 6.375 0.75 6.375H6.375V0.75C6.375 0.404822 6.65482 0.125 7 0.125Z' fill='#D31145' />
                            </svg>
                            <p className='fb-add-btn-text'>Add</p>
                        </Button>
                    </div>
                    <p className='fab-description'>Add the languages you're good at!</p>
                </div>
                {
                    displayForm ?
                        <>
                            <div className='fab-lp-lang-form-container'>
                                <div className='fab-lp-lang-form-inner-container'>
                                    <ul>
                                        {
                                            codeTableLanguages.map((language, index) => (
                                                <>
                                                    <li key={index} className='fab-lp-lang-cb-header'>
                                                        <div className='fab-lp-lang-cb-wrapper'>
                                                            <input
                                                                type='checkbox'
                                                                className='fab-lp-lang-cb'
                                                                checked={languageChecked(language.val)}
                                                                onChange={() => handleLanguageChange(language)}
                                                            />
                                                        </div>
                                                        <div className='fab-lp-lang-title-wrapper'>
                                                            <label className='fab-lp-lang-title'>{language.val}</label>
                                                        </div>
                                                    </li>
                                                    {
                                                        languageChecked(language.val) && (
                                                            <div className='fab-lp-select-container'>
                                                                <div className='fab-lp-pro-wrapper'>
                                                                    <label className='fab-lp-pro-title'>Spoken proficiency</label>
                                                                    <ul className='fab-lp-pro-option-container'>
                                                                        {
                                                                            codeTableProficiencyLevels.map((option, index) => (
                                                                                <li className='fab-lp-pro-option-wrapper' key={index}>
                                                                                    <input
                                                                                        type='radio'
                                                                                        className='fab-lp-pro-option-btn'
                                                                                        value={spokenProficiencyValue(language.val)}
                                                                                        checked={spokenProficiencyChecked(language.val, option.val)}
                                                                                        onChange={() => handleLanguageProficiencyChange(language.val, 'Spoken', option.val)}
                                                                                    />
                                                                                    <label className='fab-lp-pro-option-text'>{option.val}</label>
                                                                                </li>
                                                                            ))
                                                                        }
                                                                    </ul>
                                                                </div>
                                                                <div className='fab-lp-pro-wrapper'>
                                                                    <label className='fab-lp-pro-title'>Written proficiency</label>
                                                                    <ul className='fab-lp-pro-option-container'>
                                                                        {
                                                                            codeTableProficiencyLevels.map((option, index) => (
                                                                                <li className='fab-lp-pro-option-wrapper' key={index}>
                                                                                    <input
                                                                                        type='radio'
                                                                                        className='fab-lp-pro-option-btn'
                                                                                        value={writtenProficiencyValue(language.val)}
                                                                                        checked={writtenProficiencyChecked(language.val, option.val)}
                                                                                        onChange={() => handleLanguageProficiencyChange(language.val, 'Written', option.val)}
                                                                                    />
                                                                                    <label className='fab-lp-pro-option-text'>{option.val}</label>
                                                                                </li>
                                                                            ))
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className='fb-save-cancel-container'>
                                <Button className='fb-cancel-btn fb-cancel-btn-text' onClick={handleCancelClick}>
                                    Cancel
                                </Button>
                                <Button className='fb-save-btn fb-save-btn-text' onClick={handleSaveClick}>
                                    Save
                                </Button>
                            </div>
                        </>
                        :
                        (selectedLanguageProficiencies?.length > 0) &&
                        <ul className='fab-lp-container'>
                            {selectedLanguageProficiencies.sort((a, b) => a.language < b.language ? -1 : (a.language > b.language ? 1 : 0)).map((lp, index) => (
                                <li key={index} className='fab-lp-list-option'>
                                    <span className='fab-lp-lang'>{lp.language}</span>
                                    <span className='fab-lp-proficiency'>Spoken Proficiency: {lp.proficiency.spoken}</span>
                                    <span className='fab-lp-proficiency'>Written Proficiency: {lp.proficiency.written}</span>
                                </li>
                            ))}
                        </ul>
                }
            </>
        </>
    )
}

export default LanguageProficiency;