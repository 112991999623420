import '../../components/globals/ResetPassword.css';
import '../../components/globals/SignIn.css';

import React, { useState } from 'react';

import Button from '../../components/button/Button';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import Loader from '../../components/loader/Loader';
import TextField from '../../components/textfield/Textfield';
import Toast from '../../components/toast/Toast';
import analytics from '../../firebase';
import { isEmpty } from '../../common/helper';
import { logEvent } from "firebase/analytics";
import { resetPassword } from '../../services/UserService';
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordValidation, setPasswordValidation] = useState({
        length: false,
        upperCase: false,
        lowerCase: false,
        number: false,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    const userId = urlParams.get('uid');
    const userName = urlParams.get('usrm');
    const token = urlParams.get('token');

    const getValidation = (password) => {
        const validation = {
            length: password.length >= 8,
            upperCase: /[A-Z]/.test(password),
            lowerCase: /[a-z]/.test(password),
            number: /\d/.test(password),
        };
        return validation
    }

    const passwordRequirements = [
        {
            condition: passwordValidation.length,
            text: "Password must contain at least 8 characters.",
        },
        {
            condition: passwordValidation.upperCase,
            text: "Password must include at least one uppercase letter.",
        },
        {
            condition: passwordValidation.lowerCase,
            text: "Password must include at least one lowercase letter.",
        },
        {
            condition: passwordValidation.number,
            text: "Password must include at least one number.",
        },
    ];

    const handleNewPasswordChange = (e) => {
        e.preventDefault();
        setError('')
        setPassword(e.target.value);
        if (!isEmpty(confirmPassword) && (e.target.value !== confirmPassword)) {
            setError("New password does not match. Please try again.")
        }
        const validation = getValidation(e.target.value);
        setPasswordValidation(validation);
    }

    const handleConfirmNewPasswordChange = (e) => {
        e.preventDefault();
        setError('')
        setConfirmPassword(e.target.value);
        if (e.target.value !== password) {
            setError("New password does not match. Please try again.")
        }
    }

    const handleToastClose = () => {
        setShowToast(false)
    }

    const handleResetClick = async () => {
        try {
            setIsLoading(true);
            logEvent(analytics, 'reset_password_initiated');
            const response = await resetPassword(userName, userId, token, password);
            if (response.data.code === '00') {
                logEvent(analytics, 'reset_password_successful');
                localStorage.setItem('FromPasswordReset', 'true')
                navigate('/login')
            }
            else if (response.data.code === "98") {
                setError(response.data.message[0])
            }
            else {
                setShowToast(true)
            }
            setIsLoading(false);
        } catch (error) {
            console.log(`error occurred while resetting the password: ${error}`)
            setIsLoading(false);
            setShowToast(true)
        }
    }

    return (
        <div className='fab-rp-wrapper'>
            <Header />
            {isLoading && <Loader />}
            {showToast && <Toast type={'error'} onClose={handleToastClose} header={'Failed to reset password'} message={'Something went wrong. Please try again'} />}
            <div className='fab-container'>
                <div className='fab-reset-password-wrapper'>
                    <div className='fab-reset-password-header'>
                        <h3>Reset Password</h3>
                        <p className='fab-reset-password-subtext'>Create a secure password to protect your account. Ensure it meets the following requirements:</p>
                        {error && <div className='fab-sign-error-alert fab-error-alert-active'>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M10 3.125C6.20304 3.125 3.125 6.20304 3.125 10C3.125 13.797 6.20304 16.875 10 16.875C13.797 16.875 16.875 13.797 16.875 10C16.875 6.20304 13.797 3.125 10 3.125ZM1.875 10C1.875 5.51269 5.51269 1.875 10 1.875C14.4873 1.875 18.125 5.51269 18.125 10C18.125 14.4873 14.4873 18.125 10 18.125C5.51269 18.125 1.875 14.4873 1.875 10ZM9.375 6.875C9.375 6.52982 9.65482 6.25 10 6.25H10.0062C10.3514 6.25 10.6312 6.52982 10.6312 6.875V6.88125C10.6312 7.22643 10.3514 7.50625 10.0062 7.50625H10C9.65482 7.50625 9.375 7.22643 9.375 6.88125V6.875ZM9.13007 8.7987C10.0853 8.3211 11.1608 9.18384 10.9018 10.2199L10.3109 12.5833L10.3455 12.566C10.6542 12.4116 11.0296 12.5367 11.184 12.8455C11.3384 13.1542 11.2133 13.5296 10.9045 13.684L10.8699 13.7013L10.8699 13.7013C9.91474 14.1789 8.83923 13.3162 9.09824 12.2801L9.68908 9.91673L9.65451 9.93402C9.34577 10.0884 8.97035 9.96325 8.81598 9.65451C8.66161 9.34577 8.78675 8.97035 9.09549 8.81598L9.13007 8.7987Z" fill="#B91C1C" />
                            </svg>
                            <span className='fab-sign-single-error'>{error}</span>
                        </div>}
                        <div className="fab-sign-password-requirments">
                            {passwordRequirements.map((requirement, index) => (
                                <span
                                    key={index}
                                    style={{ color: requirement.condition ? "green" : "" }}
                                >
                                    {requirement.condition ? (
                                        <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M0.6875 8C0.6875 3.96142 3.96142 0.6875 8 0.6875C12.0386 0.6875 15.3125 3.96142 15.3125 8C15.3125 12.0386 12.0386 15.3125 8 15.3125C3.96142 15.3125 0.6875 12.0386 0.6875 8ZM10.7077 6.63945C10.8883 6.38665 10.8297 6.03534 10.5769 5.85477C10.3242 5.67421 9.97284 5.73276 9.79227 5.98555L7.36572 9.38273L6.14775 8.16475C5.92808 7.94508 5.57192 7.94508 5.35225 8.16475C5.13258 8.38442 5.13258 8.74058 5.35225 8.96025L7.03975 10.6477C7.15667 10.7647 7.31911 10.8242 7.4839 10.8106C7.64868 10.7969 7.79912 10.7115 7.89523 10.5769L10.7077 6.63945Z"
                                                fill="#059669"
                                            />
                                        </svg>
                                    ) : (
                                        <svg
                                            width="18"
                                            height="18"
                                            viewBox="0 0 18 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M9 2.8125C5.58274 2.8125 2.8125 5.58274 2.8125 9C2.8125 12.4173 5.58274 15.1875 9 15.1875C12.4173 15.1875 15.1875 12.4173 15.1875 9C15.1875 5.58274 12.4173 2.8125 9 2.8125ZM1.6875 9C1.6875 4.96142 4.96142 1.6875 9 1.6875C13.0386 1.6875 16.3125 4.96142 16.3125 9C16.3125 13.0386 13.0386 16.3125 9 16.3125C4.96142 16.3125 1.6875 13.0386 1.6875 9ZM6.1875 9C6.1875 8.68934 6.43934 8.4375 6.75 8.4375H11.25C11.5607 8.4375 11.8125 8.68934 11.8125 9C11.8125 9.31066 11.5607 9.5625 11.25 9.5625H6.75C6.43934 9.5625 6.1875 9.31066 6.1875 9Z"
                                                fill="#A1A1AA"
                                            />
                                        </svg>
                                    )}
                                    {requirement.text}
                                </span>
                            ))}
                        </div>
                    </div>
                    <div className='fab-reset-password-fields'>
                        <div className="fab-sign-password">
                            <label>New password</label>
                            <TextField
                                isPassword={true}
                                value={password}
                                onChange={handleNewPasswordChange}
                            />
                        </div>
                        <div className="fab-sign-password">
                            <label>Confirm new password</label>
                            <TextField
                                isPassword={true}
                                value={confirmPassword}
                                onChange={handleConfirmNewPasswordChange}
                            />
                        </div>
                    </div>
                    <div className='fab-reset-password-button'>
                        <Button variant={'primary'} size="md" className={"fab-reset-button"} isDisabled={isEmpty(password) || isEmpty(confirmPassword) || (password !== confirmPassword)} onClick={handleResetClick}>
                            Reset password
                        </Button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ResetPassword;
