import '../globals/BrowseJobs.css';

import { getSalaryText, isEmpty } from '../../common/helper';

import Button from "../../components/button/Button";
import React from 'react';
import sanitizeHtml from 'sanitize-html';

const JobCard = ({ job, buttonText, bookmarkedCards, handleBookmarkClick, applied, withSchedule, scheduleTime, handleApply, changeWidth, isLoggedIn }) => {

    return (
        <div className='fab-job-card' style={{ width: changeWidth ? '100%' : '' }} onClick={() => { handleApply && handleApply(job.jobid, job) }}>
            <div key={job.jobid} className='fab-job-item'>
                <svg style={{ visibility: isLoggedIn ? '' : 'hidden' }} className={`fab-latestjob-bookmark ${bookmarkedCards.includes(job.jobid) ? 'fab-latestjob-bookmark-active' : ''}`}
                    onClick={(e) => handleBookmarkClick(job.jobid, bookmarkedCards, e, job)} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M6.32046 2.57741C8.18398 2.36114 10.0793 2.25 12.0002 2.25C13.9212 2.25 15.8165 2.36114 17.68 2.57741C19.1774 2.75119 20.2502 4.03722 20.2502 5.50699V21C20.2502 21.2599 20.1157 21.5013 19.8945 21.638C19.6734 21.7746 19.3973 21.7871 19.1648 21.6708L12.0002 18.0885L4.83565 21.6708C4.60316 21.7871 4.32706 21.7746 4.10595 21.638C3.88483 21.5013 3.75024 21.2599 3.75024 21V5.50699C3.75024 4.03722 4.82308 2.75119 6.32046 2.57741Z" fill="#FFFFFF" />
                </svg>
                <div className='fab-job-details'>
                    <div className='fab-job-card-title-container'>
                        <h4 className='fab-job-card-title'>{job.jobttl}</h4>
                    </div>
                    {!isEmpty(job.salmin) && <span className='fab-job-card-salary'>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M1.25 4.6875C1.25 3.82456 1.94956 3.125 2.8125 3.125H17.1875C18.0504 3.125 18.75 3.82456 18.75 4.6875V12.8125C18.75 13.6754 18.0504 14.375 17.1875 14.375H2.8125C1.94955 14.375 1.25 13.6754 1.25 12.8125V4.6875ZM16.25 13.125H3.75C3.75 12.4346 3.19036 11.875 2.5 11.875V5.625C3.19036 5.625 3.75 5.06536 3.75 4.375H16.25C16.25 5.06536 16.8096 5.625 17.5 5.625V11.875C16.8096 11.875 16.25 12.4346 16.25 13.125ZM10 6.875C8.96447 6.875 8.125 7.71447 8.125 8.75C8.125 9.78553 8.96447 10.625 10 10.625C11.0355 10.625 11.875 9.78553 11.875 8.75C11.875 7.71447 11.0355 6.875 10 6.875ZM6.875 8.75C6.875 7.02411 8.27411 5.625 10 5.625C11.7259 5.625 13.125 7.02411 13.125 8.75C13.125 10.4759 11.7259 11.875 10 11.875C8.27411 11.875 6.875 10.4759 6.875 8.75ZM4.375 8.75C4.375 8.40482 4.65482 8.125 5 8.125H5.00625C5.35143 8.125 5.63125 8.40482 5.63125 8.75V8.75625C5.63125 9.10143 5.35143 9.38125 5.00625 9.38125H5C4.65482 9.38125 4.375 9.10143 4.375 8.75625V8.75ZM14.375 8.75C14.375 8.40482 14.6548 8.125 15 8.125H15.0062C15.3514 8.125 15.6312 8.40482 15.6312 8.75V8.75625C15.6312 9.10143 15.3514 9.38125 15.0062 9.38125H15C14.6548 9.38125 14.375 9.10143 14.375 8.75625V8.75ZM1.25 15.625C1.25 15.2798 1.52982 15 1.875 15C6.48695 15 10.9558 15.6169 15.2031 16.773C15.4226 16.8327 15.625 16.667 15.625 16.4626V15.625C15.625 15.2798 15.9048 15 16.25 15C16.5952 15 16.875 15.2798 16.875 15.625V16.4626C16.875 17.514 15.867 18.2491 14.8748 17.9791C10.7335 16.8518 6.37504 16.25 1.875 16.25C1.52982 16.25 1.25 15.9702 1.25 15.625Z" fill="#D31145" />
                        </svg>
                        {getSalaryText(job)}
                    </span>}
                    <span className='fab-job-card-company'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <g clipPath="url(#clip0_1985_7033)">
                                <path d="M7.99998 4.66667V2H1.33331V14H14.6666V4.66667H7.99998ZM3.99998 12.6667H2.66665V11.3333H3.99998V12.6667ZM3.99998 10H2.66665V8.66667H3.99998V10ZM3.99998 7.33333H2.66665V6H3.99998V7.33333ZM3.99998 4.66667H2.66665V3.33333H3.99998V4.66667ZM6.66665 12.6667H5.33331V11.3333H6.66665V12.6667ZM6.66665 10H5.33331V8.66667H6.66665V10ZM6.66665 7.33333H5.33331V6H6.66665V7.33333ZM6.66665 4.66667H5.33331V3.33333H6.66665V4.66667ZM13.3333 12.6667H7.99998V11.3333H9.33331V10H7.99998V8.66667H9.33331V7.33333H7.99998V6H13.3333V12.6667ZM12 7.33333H10.6666V8.66667H12V7.33333ZM12 10H10.6666V11.3333H12V10Z" fill="#3F3F46" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1985_7033">
                                    <rect width="16" height="16" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        {job.coydispm}
                    </span>
                    <span className='fab-job-card-location'>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M10 3.125C6.8934 3.125 4.375 5.6434 4.375 8.75C4.375 11.4966 5.81723 13.6919 7.32212 15.2327C8.07062 15.9991 8.82091 16.5888 9.38405 16.9867C9.63237 17.1621 9.84321 17.2994 10 17.3974C10.1568 17.2994 10.3676 17.1621 10.6159 16.9867C11.1791 16.5888 11.9294 15.9991 12.6779 15.2327C14.1828 13.6919 15.625 11.4966 15.625 8.75C15.625 5.6434 13.1066 3.125 10 3.125ZM10 18.125C9.69976 18.6732 9.69954 18.673 9.6993 18.6729L9.69705 18.6717L9.69206 18.6689L9.67542 18.6596C9.66141 18.6517 9.64163 18.6405 9.61646 18.6259C9.56612 18.5967 9.49419 18.5542 9.40375 18.4985C9.22295 18.3872 8.96771 18.223 8.66282 18.0076C8.05409 17.5776 7.24188 16.9395 6.42788 16.1061C4.80777 14.4474 3.125 11.9551 3.125 8.75C3.125 4.95304 6.20304 1.875 10 1.875C13.797 1.875 16.875 4.95304 16.875 8.75C16.875 11.9551 15.1922 14.4474 13.5721 16.1061C12.7581 16.9395 11.9459 17.5776 11.3372 18.0076C11.0323 18.223 10.7771 18.3872 10.5963 18.4985C10.5058 18.5542 10.4339 18.5967 10.3835 18.6259C10.3584 18.6405 10.3386 18.6517 10.3246 18.6596L10.3079 18.6689L10.303 18.6717L10.3013 18.6726C10.3011 18.6727 10.3002 18.6732 10 18.125ZM10 18.125L10.3002 18.6732C10.1132 18.7756 9.88635 18.7754 9.6993 18.6729L10 18.125ZM10 6.875C8.96447 6.875 8.125 7.71447 8.125 8.75C8.125 9.78553 8.96447 10.625 10 10.625C11.0355 10.625 11.875 9.78553 11.875 8.75C11.875 7.71447 11.0355 6.875 10 6.875ZM6.875 8.75C6.875 7.02411 8.27411 5.625 10 5.625C11.7259 5.625 13.125 7.02411 13.125 8.75C13.125 10.4759 11.7259 11.875 10 11.875C8.27411 11.875 6.875 10.4759 6.875 8.75Z" fill="#3F3F46" />
                        </svg>
                        {job.loc ? job.loc : "Singapore"}
                    </span>
                </div>
                <div className='fab-job-pills'>
                    {job?.jobtypes?.map((pill, index) => (
                        <span key={index} className='fab-job-pill-item'>{pill}</span>
                    ))}
                </div>
                <div className='fab-job-card-description'>
                    <div className='fab-job-description fab-job-responsibilities'>
                        <div className="job-description-content" dangerouslySetInnerHTML={{ __html: sanitizeHtml(job.desc) }} />
                    </div>
                </div>
            </div>
            <div className='fab-job-date fab-job-card-footer'>
                <div>
                    <span className='fab-job-date-text'>Date updated: {job.pubdttme}</span>
                    {applied === true &&
                        <div>
                            <p className='fab-job-applied-via'>Applied on <span>{job.appdttme}</span> via <span>FAST Apply</span></p>
                            <span className='fab-job-applied-expiry'>This job application will be removed from your “Applied Jobs” on {job.removedttme}.</span>
                        </div>
                    }
                </div>
                {applied === true && withSchedule === true ?
                    <span className='fab-job-card-scheduled'>Interview scheduled for <br /> {scheduleTime}</span> :
                    applied === true && withSchedule === false ?
                        <span className='fab-job-card-noschedule'>No interview scheduled</span> :
                        <Button variant="outline" size="md" onClick={() => { handleApply(job.jobid) }}>{buttonText}</Button>
                }
            </div>
        </div>
    )
}

export default JobCard;