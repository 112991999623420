import '../../components/globals/SignIn.css';

import { Link, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { SignUpErrorMessageMapping, passwordValidationMessages } from '../../common/constants';
import { fbLoginRequest, getFbUserDetails, googleLogin, register } from '../../services/UserService';

import Button from '../../components/button/Button';
import Header from '../../components/header/Header';
import Loader from '../../components/loader/Loader';
import { LoginButton } from 'react-facebook';
import TextField from '../../components/textfield/Textfield';
import analytics from '../../firebase';
import { logEvent } from "firebase/analytics";
import { useGoogleLogin } from '@react-oauth/google'

const SignUp = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    upperCase: false,
    lowerCase: false,
    number: false,
  });
  const [isError, setIsError] = useState(false);
  const [errorMessages, setErrorMessage] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [onSignup, setOnSignup] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [marketingConsent, setMarketingConsent] = useState(false);
  const navigate = useNavigate();
  logEvent(analytics, 'signup_screen_viewed');

  const FbSuccess = async (response) => {
    setShowLoader(true);
    //get response from the API
    try {
      setShowLoader(true);
      const fbTokenResponse = await getFbUserDetails(response.authResponse.accessToken)
      const email = fbTokenResponse.data.usrm;
      //new login flow
      const fbLoginResponse = await fbLoginRequest(fbTokenResponse.data.id, response.authResponse.accessToken, email);
      console.log(fbLoginResponse);
      if ((fbLoginResponse.data.isSuccess === 1 && fbLoginResponse.data.code === "2" && fbLoginResponse.data.data?.length === 0) || fbLoginResponse.data.code === "9") {
        setShowLoader(false);
        navigate("/fb-signup", { state: { fbAccessToken: response.authResponse.accessToken, fbId: fbTokenResponse.data.id, email: email, name: fbTokenResponse.data.name } })
      }
      else if (fbLoginResponse.data.code === "00") {
        const data = fbLoginResponse.data.data[0];
        const userInfo = {
          'userName': data.fullm,
          'disName': data.dispm,
          'userEmail': data.usrm,
          'userId': data.usrid,
          'pic': data?.pic,
        }
        localStorage.setItem('user', JSON.stringify(userInfo));
        localStorage.setItem('isLogin', 'true');
        localStorage.setItem('token', data.t);
        localStorage.setItem('fromLoginSignup', 'true');
        setShowLoader(false);
        navigate('/jobs')
      }
      else {
        setShowLoader(false);
        setErrorMessage('Something went wrong while trying to sign-in with facebook.');
      }

      setShowLoader(false);
    }
    catch (error) {
      setShowLoader(false);
      setErrorMessage('Something went wrong while trying to sign-in with facebook.');
    }
  }

  const fbFailure = async (error) => {
  }

  const googleL = useGoogleLogin({
    async onSuccess(response) {
      try {
        setShowLoader(true);
        //get response from the API
        const apiResponse = await googleLogin(response.access_token);
        setShowLoader(false);
        if (apiResponse?.data?.isSuccess === 1 && apiResponse?.data?.code === "2" && apiResponse.data.data?.length === 0) {
          navigate("/google-signup", { state: { accessToken: response.access_token } })
        }
        else if (apiResponse?.data?.isSuccess === 1 && (apiResponse?.data?.code === "00" || apiResponse?.data?.code === "6")) {
          const data = apiResponse.data.data[0];
          const userInfo = {
            'userName': data.fullm,
            'disName': data.dispm,
            'userEmail': data.usrm,
            'userId': data.usrid,
            'pic': data?.pic,
          }
          localStorage.setItem('user', JSON.stringify(userInfo));
          localStorage.setItem('isLogin', 'true');
          localStorage.setItem('token', data.t);
          localStorage.setItem('fromLoginSignup', 'true');
          setShowLoader(false);
          logEvent(analytics, 'login_success', { 'click_source': 'google', 'userid': userInfo.userId });
          navigate("/jobs")
        }
        else {
          logEvent(analytics, 'login_failed', { 'error_message': 'Something went wrong while trying to sign-in with google.' });
          setErrorMessage('Something went wrong while trying to sign-in with google.');
        }

      }
      catch (error) {
        setPassword("")
        setShowLoader(false)
        const errorResponse = error.response
        let errorMessage = errorResponse.data.message[0] ? errorResponse.data.message[0] : "Invalid credentials"
        setErrorMessage(errorMessage)
      }
    },
    onError() {
      setErrorMessage('Something went wrong while trying to sign-in with google.');
    },
  });

  const handleEmailChange = (event) => {
    event.preventDefault();
    setIsError(false)
    setEmail(event.target.value)
  }

  const handleNameChange = (event) => {
    event.preventDefault();
    setIsError(false)
    setFullName(event.target.value)
  }

  const handlePhoneNumberChange = (event) => {
    event.preventDefault();
    setIsError(false)
    setPhoneNumber(event.target.value)
  }

  const onCheckboxClick = (event) => {
    const checkbox =
      event.target.type === "checkbox"
        ? event.target
        : event.target.querySelector('input[type="checkbox"]');
    setMarketingConsent(checkbox.checked)
  }

  const getValidation = (password) => {
    const validation = {
      length: password.length >= 8,
      upperCase: /[A-Z]/.test(password),
      lowerCase: /[a-z]/.test(password),
      number: /\d/.test(password),
    };
    return validation
  }

  const handlePasswordChange = (event) => {
    event.preventDefault();
    const newPassword = event.target.value;
    setIsError(false)
    setPassword(newPassword);

    if (newPassword.trim() !== "" && !isTyping) {
      setIsTyping(true);
    }

    const validation = getValidation(newPassword);
    setPasswordValidation(validation);
  };

  const handleConfirmPasswordChange = (event) => {
    event.preventDefault();
    const newPassword = event.target.value;
    setIsError(false)
    setErrorMessage([])
    setConfirmPassword(newPassword);
    if (newPassword !== password) {
      setIsError(true)
      setErrorMessage(['Passwords does not match'])
    }
  }

  const passwordRequirements = [
    {
      condition: passwordValidation.length,
      text: passwordValidationMessages.length,
    },
    {
      condition: passwordValidation.upperCase,
      text: passwordValidationMessages.upperCase,
    },
    {
      condition: passwordValidation.lowerCase,
      text: passwordValidationMessages.lowerCase,
    },
    {
      condition: passwordValidation.number,
      text: passwordValidationMessages.number,
    },
  ];

  const passwordsMatched = confirmPassword === password

  const onSignUp = async () => {
    if (passwordsMatched) {
      try {
        setShowLoader(true)
        setIsError(false)
        setOnSignup(true)
        logEvent(analytics, 'signup_initiated', { 'click_source': 'email' });
        const marketingConsentInfo = marketingConsent ? "1" : "0";
        const response = await register(fullName, password, email, phoneNumber, marketingConsentInfo)
        setShowLoader(false)
        if (response?.data?.code === "00") {
          const info = response?.data?.data[0]
          const userInfo = {
            'userName': fullName,
            'disName': info.dispm,
            'userEmail': email,
            'userId': info?.usrid,
            'pic': info?.pic,
          }
          localStorage.setItem('isLogin', 'true')
          localStorage.setItem('token', info.t)
          localStorage.setItem('user', JSON.stringify(userInfo))
          localStorage.setItem('fromSignup', 'true');
          logEvent(analytics, 'signup_success', { 'click_source': 'email', 'pdpa': marketingConsentInfo, 'userid': userInfo.userId });
          navigate("/verifyMobileNumber", { state: { phoneNumber: info.mobm, showOtpScreen: true } })
        }
        else if (response?.data?.code === "98") {
          setIsError(true)
          const errorList = response?.data.message;
          const mappedErrors = errorList?.map((error) => {
            if (SignUpErrorMessageMapping[error]) {
              return SignUpErrorMessageMapping[error]
            }
            else {
              return error
            }
          });
          const uniqueErrors = Array.from(new Set(mappedErrors));
          setErrorMessage(uniqueErrors)
        } else if (response?.data?.code === "8") {
          logEvent(analytics, 'signup_failed', { 'error_message': response?.data?.message });
          setIsError(true)
          setErrorMessage(response?.data?.message)
        }
        else {
          setIsError(true)
          logEvent(analytics, 'signup_failed', { 'error_message': 'Something went wrong' });
          setErrorMessage(["Something went wrong"])
        }
      } catch (error) {
        setShowLoader(false)
        const errorResponse = error.response
        const errorList = errorResponse?.data?.message;
        const mappedErrors = errorList?.map((error) => {
          if (SignUpErrorMessageMapping[error]) {
            return SignUpErrorMessageMapping[error]
          }
          else {
            return error
          }
        });
        const uniqueErrors = Array.from(new Set(mappedErrors));
        setIsError(true)
        if (uniqueErrors.length > 0) {
          setErrorMessage(uniqueErrors)
          logEvent(analytics, 'signup_failed', { 'error_message': uniqueErrors });
        }
        else {
          setErrorMessage(["Something went wrong"])
          logEvent(analytics, 'signup_failed', { 'error_message': 'Something went wrong' });
        }
      }
    }
    else {
      setIsError(true)
      setErrorMessage(['Passwords does not match'])
    }
  }


  return (
    <div>
      <Header />
      {showLoader && <Loader />}
      <div className="fab-sign-up-container">
        <div className="fab-sign-up-wrapper">
          <div className="fab-sign-header">
            <h3 className="fab-sign-title">Sign Up</h3>
            <p className="fab-sign-poweredby">
              powered by <span>Fast</span>
              <span>Jobs</span>
            </p>
          </div>
          <div className="fab-sign-form">
            <div className="fab-sign-socials">
              <LoginButton style={{ border: 0 }}
                scope="public_profile,email"
                onError={fbFailure}
                onSuccess={FbSuccess}>
                <Button className='fab-sign-fb' size='md'>
                  <svg width="20" height="20" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_1584_36113)">
                      <path d="M24.5 12.5C24.5 5.87258 19.1274 0.5 12.5 0.5C5.87258 0.5 0.5 5.87258 0.5 12.5C0.5 18.4895 4.8882 23.454 10.625 24.3542V15.9688H7.57812V12.5H10.625V9.85625C10.625 6.84875 12.4166 5.1875 15.1576 5.1875C16.4701 5.1875 17.8438 5.42188 17.8438 5.42188V8.375H16.3306C14.84 8.375 14.375 9.30008 14.375 10.25V12.5H17.7031L17.1711 15.9688H14.375V24.3542C20.1118 23.454 24.5 18.4895 24.5 12.5Z" fill="white" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1584_36113">
                        <rect width="24" height="24" fill="white" transform="translate(0.5 0.5)" />
                      </clipPath>
                    </defs>
                  </svg>
                  Continue with Facebook
                </Button>
              </LoginButton>
              <Button className='fab-sign-google' onClick={() => googleL()} size='md'>
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_1584_36118)">
                    <path d="M24.266 12.7764C24.266 11.9607 24.1999 11.1406 24.0588 10.3381H12.74V14.9591H19.2217C18.9528 16.4494 18.0885 17.7678 16.823 18.6056V21.6039H20.69C22.9608 19.5139 24.266 16.4274 24.266 12.7764Z" fill="#4285F4" />
                    <path d="M12.7401 24.5008C15.9766 24.5008 18.7059 23.4382 20.6945 21.6039L16.8276 18.6055C15.7517 19.3375 14.3627 19.752 12.7445 19.752C9.61388 19.752 6.95946 17.6399 6.00705 14.8003H2.0166V17.8912C4.05371 21.9434 8.2029 24.5008 12.7401 24.5008V24.5008Z" fill="#34A853" />
                    <path d="M6.00253 14.8003C5.49987 13.3099 5.49987 11.6961 6.00253 10.2057V7.11481H2.01649C0.31449 10.5056 0.31449 14.5004 2.01649 17.8912L6.00253 14.8003V14.8003Z" fill="#FBBC04" />
                    <path d="M12.7401 5.24966C14.4509 5.2232 16.1044 5.86697 17.3434 7.04867L20.7695 3.62262C18.6001 1.5855 15.7208 0.465534 12.7401 0.500809C8.2029 0.500809 4.05371 3.05822 2.0166 7.11481L6.00264 10.2058C6.95065 7.36173 9.60947 5.24966 12.7401 5.24966V5.24966Z" fill="#EA4335" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1584_36118">
                      <rect width="24" height="24" fill="white" transform="translate(0.5 0.5)" />
                    </clipPath>
                  </defs>
                </svg>
                Continue with Google
              </Button>
            </div>
            <div className="fab-sign-divider">
              <span></span>
              <span>or</span>
              <span></span>
            </div>
            <div className="fab-sign-fields">
              {isError && <div className="fab-sign-error-alert">
                <svg
                  svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.0823 3.12742C10.6012 2.29363 9.39783 2.29363 8.91679 3.12742L2.78818 13.7503C2.30741 14.5837 2.90884 15.625 3.87091 15.625H16.1281C17.0902 15.625 17.6916 14.5837 17.2109 13.7503L11.0823 3.12742ZM7.83406 2.50277C8.79613 0.835178 11.2029 0.835177 12.165 2.50277L18.2936 13.1257C19.2551 14.7924 18.0523 16.875 16.1281 16.875H3.87091C1.94677 16.875 0.743912 14.7924 1.70545 13.1257L7.83406 2.50277ZM9.99952 6.87499C10.3447 6.87499 10.6245 7.15481 10.6245 7.49999V10.625C10.6245 10.9702 10.3447 11.25 9.99952 11.25C9.65434 11.25 9.37452 10.9702 9.37452 10.625V7.49999C9.37452 7.15481 9.65434 6.87499 9.99952 6.87499ZM9.37452 13.125C9.37452 12.7798 9.65434 12.5 9.99952 12.5H10.0058C10.3509 12.5 10.6308 12.7798 10.6308 13.125V13.1312C10.6308 13.4764 10.3509 13.7562 10.0058 13.7562H9.99952C9.65434 13.7562 9.37452 13.4764 9.37452 13.1312V13.125Z"
                    fill="#B91C1C"
                  />
                </svg>
                <div className="fab-sign-multiple-error">
                  <span>Please correct the following errors:</span>
                  <ul className="fab-sign-error-list">
                    {errorMessages.map((error, index) => (
                      <li key={index}>{error}</li>
                    ))}
                  </ul>
                </div>
              </div>}
              <div className="fab-sign-name-number">
                <div className="fab-sign-name">
                  <label>Full name</label>
                  <TextField placeholder="e.g., John Smith" value={fullName} onChange={handleNameChange} />
                </div>
                <div className="fab-sign-number">
                  <label>Phone number</label>
                  <div className="fab-number-input-wrapper">
                    <span className="fab-number-country" value="+65">+65</span>
                    <input
                      type="number"
                      className="fab-number-input"
                      pattern="\d*"
                      inputMode="numeric"
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                    />
                  </div>
                </div>
              </div>
              <div className="fab-sign-email">
                <label>Email</label>
                <TextField type="email" placeholder="email@address.com" value={email} onChange={handleEmailChange} />
              </div>
              <div className="fab-sign-password">
                <label>Password</label>
                <TextField
                  placeholder="Enter your password"
                  isPassword={true}
                  onChange={handlePasswordChange}
                  value={password}
                />
              </div>
              {isTyping && (
                <div className="fab-sign-password-requirments">
                  {passwordRequirements.map((requirement, index) => (
                    <span
                      key={index}
                      style={{ color: requirement.condition ? "green" : (onSignup ? "red" : "") }}
                    >
                      {requirement.condition ? (
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.6875 8C0.6875 3.96142 3.96142 0.6875 8 0.6875C12.0386 0.6875 15.3125 3.96142 15.3125 8C15.3125 12.0386 12.0386 15.3125 8 15.3125C3.96142 15.3125 0.6875 12.0386 0.6875 8ZM10.7077 6.63945C10.8883 6.38665 10.8297 6.03534 10.5769 5.85477C10.3242 5.67421 9.97284 5.73276 9.79227 5.98555L7.36572 9.38273L6.14775 8.16475C5.92808 7.94508 5.57192 7.94508 5.35225 8.16475C5.13258 8.38442 5.13258 8.74058 5.35225 8.96025L7.03975 10.6477C7.15667 10.7647 7.31911 10.8242 7.4839 10.8106C7.64868 10.7969 7.79912 10.7115 7.89523 10.5769L10.7077 6.63945Z"
                            fill="#059669"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9 2.8125C5.58274 2.8125 2.8125 5.58274 2.8125 9C2.8125 12.4173 5.58274 15.1875 9 15.1875C12.4173 15.1875 15.1875 12.4173 15.1875 9C15.1875 5.58274 12.4173 2.8125 9 2.8125ZM1.6875 9C1.6875 4.96142 4.96142 1.6875 9 1.6875C13.0386 1.6875 16.3125 4.96142 16.3125 9C16.3125 13.0386 13.0386 16.3125 9 16.3125C4.96142 16.3125 1.6875 13.0386 1.6875 9ZM6.1875 9C6.1875 8.68934 6.43934 8.4375 6.75 8.4375H11.25C11.5607 8.4375 11.8125 8.68934 11.8125 9C11.8125 9.31066 11.5607 9.5625 11.25 9.5625H6.75C6.43934 9.5625 6.1875 9.31066 6.1875 9Z"
                            fill={onSignup ? "red" : "#A1A1AA"}
                          />
                        </svg>
                      )}
                      {requirement.text}
                    </span>
                  ))}
                </div>
              )}
              <div className="fab-sign-password fab-sign-confirm-password">
                <label>Confirm Password</label>
                <TextField
                  placeholder="Confirm your password"
                  isPassword={true}
                  onChange={handleConfirmPasswordChange}
                  value={confirmPassword}
                />
              </div>
              <label htmlFor="fab-sign-check" className="fab-sign-check-label">
                <input type="checkbox" id="fab-sign-check" onClick={onCheckboxClick} />I wish to receive
                marketing information from FastJobs and/or service providers on
                products or services offered by FastJobs or other parties.
              </label>
              <div>
                <Button variant="primary" size="lg" className="fab-sign-btn" onClick={onSignUp}>
                  Sign up
                </Button>
                <p className="fab-sign-privacy-terms">
                  By signing up and/or continuing with the installation or
                  launch process, you represent and warrant to Fastco Pte. Ltd.
                  that you accept our <a href="https://www.fastjobs.sg/legal/website-tnc.html" target="_blank" rel="noopener noreferrer">Terms of Service</a>,
                  <a href="https://www.fastjobs.sg/legal/member-tnc.html" target="_blank" rel="noopener noreferrer">Member Terms</a> and <a href="https://www.fastjobs.sg/legal/privacy.html" target="_blank" rel="noopener noreferrer" >Privacy Policy</a>.
                </p>
              </div>
            </div>
          </div>
          <span className="fab-sign-up">
            Already have an account?
            <Link to="/login" className="fab-sign-up-link">
              Login now!
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default SignUp;