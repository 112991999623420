import { routes } from '../common/routes';
import api from './axios';

// API to update profile.
export const updateProfile = async (uid, username, token, queryParams, formParams) => {
    const params = { v: process.env.REACT_APP_VERSION, uid };
    Object.entries(queryParams).forEach(([key, val]) => {
        params[key] = val;
    })

    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('usrm', username);
    formData.append('t', token);
    Object.entries(formParams).forEach(([key, val]) => {
        formData.append(key, val);
    })

    const response = await api.post(routes.profile.update, formData, { params });
    return response.data;
}

// API to send OTP
export const sendOTP = async (userId, userEmail, token) => {
    const params = { v: process.env.REACT_APP_VERSION, uid: userId };
    const formData = new FormData();
    formData.append('uid', userId);
    formData.append('usrm', userEmail);
    formData.append('t', token);

    const response = await api.post(routes.profile.sendOtp, formData, { params });
    return response.data;
}


// API to verify phone number
export const verifyPhoneNumber = async (userId, userEmail, token, otp) => {
    const params = { v: process.env.REACT_APP_VERSION, otp: otp };
    const formData = new FormData();
    formData.append('uid', userId);
    formData.append('usrm', userEmail);
    formData.append('t', token);

    const response = await api.post(routes.profile.verifyMobileNumber, formData, { params });
    return response.data;
}

// API to update highest education level
export const updateHighestEducation = async (userId, userEmail, token, education) => {
    const params = { v: process.env.REACT_APP_VERSION };
    const formData = new FormData();
    formData.append('uid', userId);
    formData.append('usrm', userEmail);
    formData.append('t', token);
    formData.append('edu', education);

    const response = await api.post(routes.profile.updateHighestEducation, formData, { params });
    return response.data;
}

// API to update employment status
export const updateEmploymentStatus = async (userId, userEmail, token, status) => {
    const params = { v: process.env.REACT_APP_VERSION };
    const formData = new FormData();
    formData.append('uid', userId);
    formData.append('usrm', userEmail);
    formData.append('t', token);
    formData.append('empsts', status);

    const response = await api.post(routes.profile.updateEmploymentStatus, formData, { params });
    return response.data;
}
// API to get profile details.
export const getProfileDetails = async (uid, username, token) => {
    const body = {
        uid,
        usrm: username,
        t: token
    };
    const params = { v: process.env.REACT_APP_VERSION, uid };
    const response = await api.post(routes.profile.view, body, { params });
    return response.data;
}

// API to upload profile picture.
export const uploadProfilePicture = async (uid, username, token, file) => {
    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('usrm', username);
    formData.append('t', token);
    formData.append('file', file);

    const params = { v: process.env.REACT_APP_VERSION, uid };
    const response = await api.post(routes.profile.uploadPicture, formData, {
        params,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
    });
    return response.data;
}

// API to update job preference.
export const updateJobPreference = async (uid, username, token, queryParams) => {
    const params = { v: process.env.REACT_APP_VERSION, uid };
    Object.entries(queryParams).forEach(([key, val]) => {
        params[key] = val;
    })

    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('usrm', username);
    formData.append('t', token);

    const response = await api.post(routes.profile.updateJobPreference, formData, { params });
    return response.data;
}

// API to update job preference.
export const updateWorkExperience = async (uid, username, token, queryParams) => {
    const params = { v: process.env.REACT_APP_VERSION, uid, exp: queryParams };

    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('usrm', username);
    formData.append('t', token);

    const response = await api.post(routes.profile.updateWorkExperience, formData, { params });
    return response.data;
}

// API to update job preference.
export const updateEducationDetails = async (uid, username, token, queryParams) => {
    const params = { v: process.env.REACT_APP_VERSION, uid, edu: queryParams };

    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('usrm', username);
    formData.append('t', token);

    const response = await api.post(routes.profile.updateEducationDetails, formData, { params });
    return response.data;
}

// API to update skills.
export const updateSkills = async (uid, username, token, skillids) => {
    const params = { v: process.env.REACT_APP_VERSION, uid, skillids };

    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('usrm', username);
    formData.append('t', token);

    const response = await api.post(routes.profile.updateSkills, formData, { params });
    return response.data;
}

// API to update language proficiencies.
export const updateLanguageProficiencies = async (uid, username, token, languages) => {
    const params = { v: process.env.REACT_APP_VERSION, lang: languages };

    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('usrm', username);
    formData.append('t', token);

    const response = await api.post(routes.profile.updateLanguages, formData, { params });
    return response.data;
}

// API to upload a resume.
export const uploadResume = async (uid, username, token, file) => {
    const params = { v: process.env.REACT_APP_VERSION };

    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('usrm', username);
    formData.append('t', token);
    formData.append('file', file);

    const response = await api.post(routes.profile.uploadResume, formData, {
        params,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
    });
    return response.data;
}

// API to generate a resume.
export const generateResume = async (uid, username, token) => {
    const params = { v: process.env.REACT_APP_VERSION };

    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('usrm', username);
    formData.append('t', token);

    const response = await api.post(routes.profile.generateResume, formData, { params });
    return response.data;
}

// API to delete a resume.
export const deleteResume = async (uid, username, token, rsmid) => {
    const params = { v: process.env.REACT_APP_VERSION, rsmid };

    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('usrm', username);
    formData.append('t', token);

    const response = await api.post(routes.profile.deleteResume, formData, { params });
    return response.data;
}

// API to upload a generated resume.
export const uploadGeneratedResume = async (uid, username, token) => {
    const params = { v: process.env.REACT_APP_VERSION };

    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('usrm', username);
    formData.append('t', token);

    const response = await api.post(routes.profile.uploadGeneratedResume, formData, { params });
    return response.data;
}

// API to upload a generated resume.
export const previewResume = async (uid, username, token, rsmid) => {
    const params = { v: process.env.REACT_APP_VERSION };

    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('usrm', username);
    formData.append('t', token);
    formData.append('rsmid', rsmid);

    const response = await api.post(routes.profile.previewResume, formData, { params });
    return response.data;
}

// API to verify email.
export const verifyEmail = async (uid, username, token) => {
    const params = { v: process.env.REACT_APP_VERSION };

    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('usrm', username);
    formData.append('t', token);

    const response = await api.post(routes.profile.verifyEmail, formData, { params });
    return response.data;
}


// API to verify email.
export const changePassword = async (uid, username, token, pass, newpass, confirmpass) => {
    const params = { v: process.env.REACT_APP_VERSION };

    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('usrm', username);
    formData.append('t', token);
    formData.append('pass', pass);
    formData.append('newpass', newpass);
    formData.append('confirmpass', confirmpass);

    const response = await api.post(routes.profile.changePassword, formData, { params });
    return response.data;
}
