import React from 'react';
import Button from "../../components/button/Button";
import { ALL_COMPANIES, ALL_CUISINES } from "../../common/constants";
import '../../components/globals/BrowseJobs.css'

const CompanyFiltersMobile = ({
    OnClickFilterIcon,
    alphabets,
    cuisines,
    onSearch,
    setIsDesktop,
    setSelectedAlphaFilters,
    setSelectedCuisineFilters,
    selectedAlphaFilters,
    selectedCuisineFilters,
    alphaFilters,
    cuisineFilters,
    setAlphaFilters,
    setCuisineFilters
}) => {
    const handleAlFiltersClick = (item) => {
        if (item !== ALL_COMPANIES) {
            // To unselect 'All companies' option if any other filters are selected
            setAlphaFilters(alphaFilters.filter((filter) => filter !== ALL_COMPANIES));
        } else {
            setAlphaFilters([ALL_COMPANIES]);
        }

        const filterExists = alphaFilters.includes(item);
        if (filterExists) {
            setAlphaFilters(alphaFilters.filter((filter) => filter !== item));
        } else {
            setAlphaFilters((previousFilters) => [...previousFilters, item]);
        }
    }

    const handleCuiFiltersClick = (item) => {
        if (item !== ALL_CUISINES) {
            // To unselect 'All cuisine' option if any other filters are selected
            setCuisineFilters(cuisineFilters.filter((filter) => filter !== ALL_CUISINES));
        } else {
            setCuisineFilters([ALL_CUISINES]);
        }

        const filterExists = cuisineFilters.includes(item);
        if (filterExists) {
            setCuisineFilters(cuisineFilters.filter((filter) => filter !== item));
        } else {
            setCuisineFilters((previousFilters) => [...previousFilters, item]);
        }
    }

    const onReset = () => {
        setAlphaFilters([ALL_COMPANIES])
        setCuisineFilters([ALL_CUISINES])
    }

    const onMobileSearch = () => {
        setIsDesktop(false)
        setSelectedAlphaFilters(alphaFilters)
        setSelectedCuisineFilters(cuisineFilters)
        onSearch(alphaFilters, cuisineFilters)
    }

    const handleBack = () => {
        setAlphaFilters(selectedAlphaFilters)
        setCuisineFilters(selectedCuisineFilters)
        OnClickFilterIcon()
    }

    return (
        <div className="fab-filter-data-mobile">
            <div className="fab-filter-data-header">
                <button className="fab-jobs-back-icon" onClick={handleBack}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0.71967 9.53033C0.426777 9.23744 0.426777 8.76256 0.71967 8.46967L8.21967 0.96967C8.51256 0.676777 8.98744 0.676777 9.28033 0.96967C9.57322 1.26256 9.57322 1.73744 9.28033 2.03033L2.31066 9L9.28033 15.9697C9.57322 16.2626 9.57322 16.7374 9.28033 17.0303C8.98744 17.3232 8.51256 17.3232 8.21967 17.0303L0.71967 9.53033Z" fill="#18181B" />
                    </svg>
                </button>
                <button className="fab-filters-reset-button" onClick={onReset}>
                    Reset all
                </button>
            </div>
            <div className="fab-filters-content">
                <h3 className='fab-filter-header-m'>Filters</h3>
                <div className='fab-filter-locations'>
                    <h3 className='fab-filters-subheader-m'>By alphabet</h3>
                    {alphabets.map((item, index) => (
                        <label key={index} htmlFor={`chckItemL${index}`} className="fab-dropdown-menu-item" onClick={(e) => e.stopPropagation()}>
                            <input
                                type="checkbox"
                                name=""
                                id={`chckItemL${index}`}
                                className='fab-dropdown-check'
                                checked={alphaFilters.includes(item)}
                                onClick={() => {
                                    handleAlFiltersClick(item)
                                }}
                            />
                            {item}
                        </label>
                    ))}
                </div>
                <div className='fab-filter-job-position'>
                    <h3 className='fab-filters-subheader-m'>By cuisine</h3>
                    {cuisines.map((item, index) => (
                        <label key={index} htmlFor={`chckItemJ${index}`} className="fab-dropdown-menu-item" onClick={(e) => e.stopPropagation()}>
                            <input
                                type="checkbox"
                                name=""
                                id={`chckItemJ${index}`}
                                className='fab-dropdown-check'
                                checked={cuisineFilters.includes(item)}
                                onClick={() => {
                                    handleCuiFiltersClick(item)
                                }}
                            />
                            {item}
                        </label>
                    ))}
                </div>
            </div>
            <div className="fab-filter-data-footer">
                <Button variant="primary" size="lg" onClick={onMobileSearch}>Search</Button>
            </div>
        </div>
    )
}

export default CompanyFiltersMobile;
