import Button from "../../components/button/Button";

const TextFieldSuccess = ({ defaultValue, value, onChangeHandler }) => {
    return (<div className='fab-profile-section-row-input-wrapper-success'>
        <input className='fab-profile-section-row-input-wrapper-success fab-profile-section-row-textfield-input' defaultValue={defaultValue} value={value} onChange={onChangeHandler} />
        <Button className='fab-profile-section-row-button'>
            <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path fillRule='evenodd' clipRule='evenodd' d='M10 3.125C6.20304 3.125 3.125 6.20304 3.125 10C3.125 13.797 6.20304 16.875 10 16.875C13.797 16.875 16.875 13.797 16.875 10C16.875 6.20304 13.797 3.125 10 3.125ZM1.875 10C1.875 5.51269 5.51269 1.875 10 1.875C14.4873 1.875 18.125 5.51269 18.125 10C18.125 14.4873 14.4873 18.125 10 18.125C5.51269 18.125 1.875 14.4873 1.875 10ZM12.8633 7.61642C13.1442 7.81705 13.2092 8.20739 13.0086 8.48827L9.88358 12.8633C9.7768 13.0128 9.60964 13.1077 9.42655 13.1229C9.24346 13.138 9.06297 13.0719 8.93306 12.9419L7.05806 11.0669C6.81398 10.8229 6.81398 10.4271 7.05806 10.1831C7.30214 9.93898 7.69786 9.93898 7.94194 10.1831L9.29525 11.5364L11.9914 7.76173C12.192 7.48084 12.5824 7.41579 12.8633 7.61642Z' fill='#059669' />
            </svg>
        </Button>
    </div>)
}

export default TextFieldSuccess;
