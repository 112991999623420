import { JobApplyModeCode } from "../common/constants"
import api from "./axios"
import axios from "axios";
import { formSearchKeyword } from "../common/helper"
import { routes } from "../common/routes";

const getJobDetails = async (jid) => {
    const params = { v: process.env.REACT_APP_VERSION, jid };
    const response = await api.post(routes.jobs.details, {}, { params });
    return response.data;
}

const getJobs = async (inputValue, locations, positions, categories, jobTypeIds, currentPage, minSalary, maxSalary) => {
    const keyword = formSearchKeyword(inputValue, positions)
    let formattedCategories = '';
    const locationString = locations.join(',')
    const offset = currentPage * 20;
    const params = { v: process.env.REACT_APP_VERSION, keyword: keyword, loc: locationString, jtid: jobTypeIds, offset: offset }
    if (categories.length > 0) {
        formattedCategories = `fl:desc:("${categories.join('" "')}")`;
        params.hkeyword = formattedCategories
    }
    if (minSalary) {
        params.minSalary = Number(minSalary)
    }
    if (maxSalary) {
        params.maxSalary = Number(maxSalary)
    }
    const response = await api.get(routes.jobs.search, { params: params });
    return response.data;
}

const getJobFeedBanners = async () => {
    const params = { page: 1, limit: 3, excludeExpiredBanners: true }
    const response = await api.get(routes.jobs.jobFeedBaners, { params: params });
    return response.data;
}

const getJobsByCompany = async (companyId, currentPage) => {
    const offset = currentPage * 20;
    const params = { v: process.env.REACT_APP_VERSION, coyid: companyId, offset: offset, excludeChildJobs: 1 }
    const response = await api.get(routes.jobs.search, { params: params });
    return response.data;
}

const getLatestJobs = async (count = 6) => {
    let latestJobs = [];
    try {
        const params = { v: process.env.REACT_APP_VERSION }
        const info = await api.get(routes.jobs.search, { params: params });
        if (info.data.data) {
            latestJobs = info.data.data;
            if (info.data.data.length > count) {
                latestJobs = latestJobs.slice(0, count);
            }
        }
    } catch (error) {
        console.log(`Get latest jobs error: ${error}`);
        // Todo: Handle error
    }
    return latestJobs;
}

const applyJob = async (jobId, applyMethod, mallIds) => {
    const applyMethodCode = JobApplyModeCode[applyMethod]
    const userInfo = JSON.parse(localStorage.getItem('user'));
    const userId = userInfo?.userId;
    const userEmail = userInfo?.userEmail;
    const token = JSON.parse(JSON.stringify(localStorage.getItem('token')));
    const params = { v: process.env.REACT_APP_VERSION, jid: jobId, typ: applyMethodCode, viewid: 1, mallids: mallIds.length > 0 ? mallIds.join(",") : undefined }
    const data = { t: token, usrm: userEmail, uid: userId }
    const response = await api.post(routes.jobs.apply, data, { params: params });
    return response.data;
}

// API to get a list of applied jobs.
const getAppliedJobs = async (uid, username, token, queryParams) => {
    const params = { v: process.env.REACT_APP_VERSION, ...queryParams };

    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('usrm', username);
    formData.append('t', token);

    const response = await api.post(routes.jobs.applied, formData, { params });
    return response.data;
}


const getAppliedJobsNew = async (currentPage) => {
    const userInfo = JSON.parse(localStorage.getItem('user'));
    const userId = userInfo?.userId;
    const userEmail = userInfo?.userEmail;
    const token = JSON.parse(JSON.stringify(localStorage.getItem('token')));
    const params = { v: process.env.REACT_APP_VERSION, locale: 'en', page: currentPage + 1 };
    const data = { t: token, usrm: userEmail, uid: userId };
    const response = await api.post(routes.profile.appliedJobs, data, { params: params });
    return response.data;
}

const getSavedJobs = async (currentPage) => {
    const userInfo = JSON.parse(localStorage.getItem('user'));
    const userId = userInfo?.userId;
    const userEmail = userInfo?.userEmail;
    const token = JSON.parse(JSON.stringify(localStorage.getItem('token')));
    const params = { v: process.env.REACT_APP_VERSION, locale: 'en', page: currentPage + 1 };
    const data = { t: token, usrm: userEmail, uid: userId };
    const response = await api.post(routes.profile.savedJobs, data, { params: params });
    return response.data;
}

const saveJob = async (jobid) => {
    const userInfo = JSON.parse(localStorage.getItem('user'));
    const userId = userInfo?.userId;
    const username = userInfo?.userEmail;
    const token = localStorage.getItem('token');
    const params = { v: process.env.REACT_APP_VERSION, jid: jobid };
    const data = { t: token, usrm: username, uid: userId };
    const response = await api.post(routes.jobs.saveJob, data, { params: params });
    return response.data;
}

const unsaveJob = async (jobid, fid) => {
    const userInfo = JSON.parse(localStorage.getItem('user'));
    const userId = userInfo?.userId;
    const username = userInfo?.userEmail;
    const token = localStorage.getItem('token');
    const params = { v: process.env.REACT_APP_VERSION, fid: fid, jid: jobid };
    const data = { t: token, usrm: username, uid: userId };
    const response = await api.post(routes.jobs.unsaveJob, data, { params: params });
    return response.data;
}

const getAppliedJobsPdf = async () => {
    const userInfo = JSON.parse(localStorage.getItem('user'));
    const userId = userInfo?.userId;
    const username = userInfo?.userEmail;
    const token = localStorage.getItem('token');
    const queryParams = { uid: userId, usrm: username, t: token };

    const response = await axios.get(process.env.REACT_APP_API_URL + routes.jobs.generatePdf, {
        responseType: 'blob',
        params: queryParams
    });
    return response.data;
}

const getAllSavedJobs = async () => {
    const userInfo = JSON.parse(localStorage.getItem('user'));
    const userId = userInfo?.userId;
    const username = userInfo?.userEmail;
    const token = localStorage.getItem('token');
    const data = { uid: userId, usrm: username, t: token };
    const response = await api.post(routes.profile.getAllSavedJobs, data);
    return response.data;
}


export { getJobDetails, getLatestJobs, getJobs, applyJob, getAppliedJobs, getAppliedJobsNew, getSavedJobs, getJobsByCompany, saveJob, getAppliedJobsPdf, unsaveJob, getAllSavedJobs, getJobFeedBanners };