export const JOBS_PAGE_SIZE = 20;

export const SignInErrorMessageMapping = {
    "usrm must be an email": "Invalid email",
    "pass should not be empty": "Password should not be empty"
}

export const SignUpErrorMessageMapping = {
    'fullm should not be empty': 'Please enter your full name.',
    'eml must be an email': 'Please insert a valid email address.',
    'eml should not be empty': 'Please insert a valid email address.',
    'phone must be a phone number': 'Please enter a valid 8-digit Singapore mobile number.',
    'phone should not be empty': 'Please enter a valid 8-digit Singapore mobile number.',
    'pass should not be empty': 'Please enter a valid password.',
    'This mobile number has been registered.': 'Mobile number has been registered already.'
};

export const MobileVerificationErrorMessageMapping = {
    "Verification code is not correct.": 'Invalid code. Please try again.',
};

export const JobApplyModeCode = {
    "FAST Apply": 1,
    'WhatsApp': 3,
}

export const passwordValidationMessages = {
    "length": "Password must contain at least 8 characters.",
    "upperCase": "Password must include at least one uppercase letter.",
    "lowerCase": "Password must include at least one lowercase letter.",
    "number": "Password must include at least one number.",
};

export const UPDATE_SUCCESS_MESSAGE = 'Update Successful';
export const SOMETHING_WENT_WRONG_MESSAGE = 'Something went wrong';
export const TRY_AGAIN_MESSAGE = 'Please try again';
export const TRY_UPDATE_CHANGES_AGAIN_MESSAGE = 'Please try updating the changes again';
export const PROFILE_PICTURE_UPLOAD_FAILED_MESSAGE = 'Please try uploading the profile picture again';
export const RESUME_UPLOAD_FAILED_MESSAGE = 'Please try uploading the resume again';
export const RESUME_GENERATION_FAILED_MESSAGE = 'Please try generating the resume again';
export const MAX_SKILLS_SELECTION_VALIDATION_MESSAGE = 'You are only allowed to select maximum of 10 skills.';

export const ALL_COMPANIES = 'All companies';
export const ALL_CUISINES = 'All cuisine';
export const byAlphabetFilterOptions = [
    ALL_COMPANIES,
    "#",
    "A-B",
    "C-D",
    "E-F",
    "G-H",
    "I-J",
    "K-L",
    "M-N",
    "O-P",
    "Q-R",
    "S-T",
    "U-V",
    "W-X",
    "Y-Z",
];
export const COMPANIES_PAGE_SIZE = 15;
