import '../../components/globals/JobApplySuccess.css';

import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';

import Button from "../../components/button/Button";
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import LatestJobs from '../homepage/LatestJobs';

const JobApplySuccess = ({ latestJobs, applicationMethod, company, jobPosition }) => {

    const navigate = useNavigate();
    const handleSeeMore = () => {
        navigate("/jobs");
    }

    useEffect(() => {
        const checkIfSuccess = JSON.parse(localStorage.getItem('jobSuccess'));
        if (checkIfSuccess) {
            localStorage.removeItem('jobSuccess');
        }
    }, []);

    return (
        <div className='fab-job-apply-success-container'>
            <Header />
            <div className='fab-container'>
                <div className='fab-job-success-content'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="160" height="160" viewBox="0 0 160 160" fill="none">
                        <path d="M73.9991 150C110.999 158 149.999 133 149.999 106C149.999 78.9998 131.999 92.9999 83.9991 41.9999C35.9991 -9.00011 -1.00105 47.9997 5.99912 87.9998C12.9993 128 36.9991 142 73.9991 150Z" fill="#ECFDF5" />
                        <path d="M126.051 80.75C126.051 106.569 105.12 127.5 79.3008 127.5C53.4815 127.5 32.5508 106.569 32.5508 80.75C32.5508 54.9307 53.4815 34 79.3008 34C105.12 34 126.051 54.9307 126.051 80.75Z" fill="#A7F3D0" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M77.301 119.499C103.12 119.499 124.051 98.5688 124.051 72.7494C124.051 71.2847 123.984 69.8356 123.852 68.4053C124.798 72.1128 125.301 75.9975 125.301 79.9997C125.301 105.819 104.37 126.75 78.5508 126.75C54.1963 126.75 34.1914 108.127 32 84.3439C37.1573 104.555 55.4839 119.499 77.301 119.499Z" fill="white" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M79.3008 125.5C104.016 125.5 124.051 105.465 124.051 80.75C124.051 56.0353 104.016 36 79.3008 36C54.586 36 34.5508 56.0353 34.5508 80.75C34.5508 105.465 54.586 125.5 79.3008 125.5ZM79.3008 127.5C105.12 127.5 126.051 106.569 126.051 80.75C126.051 54.9307 105.12 34 79.3008 34C53.4815 34 32.5508 54.9307 32.5508 80.75C32.5508 106.569 53.4815 127.5 79.3008 127.5Z" fill="#34D399" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M100.513 63.4573C102.496 65.4004 102.496 68.5508 100.513 70.4939L75.9746 94.5427C74.9954 95.5023 73.6588 96.0283 72.2743 95.9988C70.8899 95.9693 69.5779 95.3869 68.6422 94.3865L59.3345 84.4353C57.4398 82.4096 57.5794 79.2622 59.6463 77.4054C61.7133 75.5485 64.9248 75.6854 66.8194 77.711L72.5441 83.8315L93.3332 63.4573C95.3158 61.5142 98.5303 61.5142 100.513 63.4573Z" fill="white" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M101.573 64.9756C101.327 65.5266 100.973 66.0428 100.513 66.4939L75.9746 90.5427C74.9954 91.5023 73.6588 92.0283 72.2743 91.9988C70.8899 91.9693 69.5779 91.3869 68.6422 90.3865L59.3345 80.4353C58.9472 80.0213 58.645 79.5604 58.4268 79.0733C57.6444 80.8232 57.9325 82.9364 59.3345 84.4353L68.6422 94.3865C69.5779 95.3869 70.8899 95.9693 72.2743 95.9988C73.6588 96.0283 74.9954 95.5023 75.9746 94.5427L100.513 70.4939C102.035 69.0019 102.389 66.7981 101.573 64.9756Z" fill="#A7F3D0" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M92.6332 62.7431C95.0047 60.419 98.8415 60.419 101.213 62.7431C103.596 65.0783 103.596 68.8729 101.213 71.2081L76.6745 95.2569C75.501 96.407 73.9035 97.0337 72.2531 96.9986C70.6026 96.9635 69.0339 96.2692 67.9119 95.0696L58.6042 85.1184C56.3257 82.6824 56.4957 78.8915 58.9781 76.6615C61.4488 74.4418 65.2821 74.6035 67.5498 77.0279L72.5752 82.4009L92.6332 62.7431ZM99.8131 64.1715C98.2192 62.6095 95.6269 62.6095 94.0331 64.1715L73.2441 84.5457C73.0518 84.7342 72.7916 84.8371 72.5224 84.8313C72.2532 84.8254 71.9977 84.7113 71.8138 84.5146L66.0891 78.3941C64.5675 76.7673 61.9777 76.6552 60.3147 78.1493C58.6632 79.6329 58.5539 82.1369 60.0648 83.7522L69.3725 93.7034C70.1219 94.5047 71.1773 94.9752 72.2957 94.9991C73.4141 95.0229 74.4898 94.5976 75.2746 93.8285L99.8131 69.7797C101.396 68.2287 101.396 65.7225 99.8131 64.1715Z" fill="#34D399" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M12.9123 119.299C13.5666 119.299 14.097 118.769 14.097 118.114L14.097 113.994L18.0096 113.994C18.6639 113.994 19.1943 113.463 19.1943 112.809C19.1943 112.155 18.6639 111.624 18.0096 111.624L14.097 111.624L14.097 107.847C14.097 107.193 13.5666 106.663 12.9123 106.663C12.258 106.663 11.7276 107.193 11.7276 107.847L11.7276 111.624L7.74242 111.624C7.08814 111.624 6.55774 112.155 6.55774 112.809C6.55774 113.463 7.08814 113.994 7.74242 113.994L11.7276 113.994L11.7276 118.114C11.7276 118.769 12.258 119.299 12.9123 119.299Z" fill="#60A5FA" />
                        <circle cx="134" cy="40" r="5.25" stroke="#BFDBFE" strokeWidth="1.5" />
                        <circle cx="133.506" cy="135.506" r="5.50588" fill="#34D399" />
                        <circle cx="20" cy="34" r="7.5" stroke="#A7F3D0" />
                        <ellipse cx="27.6706" cy="147.671" rx="3.67059" ry="3.67059" fill="#A7F3D0" />
                    </svg>
                </div>
                <div className='fab-job-apply-message'>
                    <h2 className='fab-job-apply-info-text'>Your application was sent to {company}!</h2>
                    <div className='fab-job-apply-info-sub-text'>Your application along with your FABJobs profile was sent to {company} for the job position {jobPosition}. You can check the status of your application in <Link to='/applied-jobs'>Applied jobs</Link> or return to <Link to='/jobs'>browse for more jobs</Link>!</div>
                </div>
                <div className='fab-job-apply-method'>
                    <div className='fab-job-apply-method-title'>Your application method:</div>
                    <div className='fab-job-apply-method-name'>{applicationMethod}</div>
                </div>
                <div className='fab-container fab-latest-jobs'>
                    <div>
                        <div className='fab-homepage-title-wrapper'>
                            <h3 className='fab-latest-jobs-title'>More jobs we think you'll love</h3>
                        </div>
                        <LatestJobs latestJobData={latestJobs} location='Application Success page' />
                        <div className='fab-latest-job-button' style={{ display: 'flex' }}>
                            <Button variant="outline" size="md" onClick={handleSeeMore}>See more jobs like this</Button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default JobApplySuccess;