import React from 'react';
import Button from '../../components/button/Button';
import { getUserFullName } from '../../common/helper';
import '../../components/globals/ProgressiveApplicationInfo.css';

const ProgressiveApplicationInfo = ({ setShowProgressiveInfo }) => {
    const userFullName = getUserFullName();
    const handleNext = () => {
        setShowProgressiveInfo(false)
    }
    return (
        <div className='fab-modal-body fab-modal-education'>
            <div className='fab-progressive-info'>
                <svg xmlns="http://www.w3.org/2000/svg" width="160" height="160" viewBox="0 0 160 160" fill="none">
                    <rect width="160" height="160" fill="white" />
                    <path d="M105.297 140.335C68.161 148.394 29.2067 133.681 17.4328 93.0081C5.65895 52.3347 55.0846 5.2438 86.2968 45.3347C117.509 85.4255 136.296 64.3348 144.297 84.3347C152.297 104.335 142.432 132.276 105.297 140.335Z" fill="#F3F5FB" />
                    <path d="M49.4286 22H100.746L116.286 39.9866V73.0094V86.562V118.571C116.286 122.674 112.96 126 108.857 126H49.4286C45.3259 126 42 122.674 42 118.571V83.8638V72.103V29.4286C42 25.3259 45.3259 22 49.4286 22Z" fill="white" />
                    <path d="M115.357 119.5H42.9285C43.238 121.357 44.7856 125.071 48.4999 125.071H108.857C113.314 125.071 115.048 121.357 115.357 119.5Z" fill="#BCC8EC" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M99.8309 24H49.4286C46.4305 24 44 26.4305 44 29.4286V118.571C44 121.57 46.4305 124 49.4286 124H108.857C111.855 124 114.286 121.57 114.286 118.571V40.7309L99.8309 24ZM100.746 22H49.4286C45.3259 22 42 25.3259 42 29.4286V118.571C42 122.674 45.3259 126 49.4286 126H108.857C112.96 126 116.286 122.674 116.286 118.571V39.9866L100.746 22Z" fill="#839ADF" />
                    <path d="M100.152 34.7679V23.8572L114.428 40.3393H105.723C102.646 40.3393 100.152 37.8449 100.152 34.7679Z" fill="#BCC8EC" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M99.2231 34.7679V23.8572H101.08V34.7679C101.08 37.3321 103.159 39.4108 105.723 39.4108H114.429V41.2679H105.723C102.133 41.2679 99.2231 38.3577 99.2231 34.7679Z" fill="#839ADF" />
                    <rect x="68" y="50" width="32" height="4" rx="2" fill="#132D7D" />
                    <rect x="68" y="62" width="32" height="4" rx="2" fill="#132D7D" />
                    <rect x="68" y="74" width="32" height="4" rx="2" fill="#132D7D" />
                    <rect x="68" y="86" width="32" height="4" rx="2" fill="#132D7D" />
                    <rect x="84" y="98" width="16" height="4" rx="2" fill="#132D7D" />
                    <circle cx="62" cy="52" r="2" fill="#132D7D" />
                    <circle cx="62" cy="64" r="2" fill="#132D7D" />
                    <circle cx="62" cy="76" r="2" fill="#132D7D" />
                    <circle cx="62" cy="88" r="2" fill="#132D7D" />
                    <path d="M29.0118 33.5059C29.0118 36.5467 26.5467 39.0118 23.5059 39.0118C20.4651 39.0118 18 36.5467 18 33.5059C18 30.4651 20.4651 28 23.5059 28C26.5467 28 29.0118 30.4651 29.0118 33.5059Z" fill="#F3B2AA" />
                    <path d="M144 136C144 138.209 142.209 140 140 140C137.791 140 136 138.209 136 136C136 133.791 137.791 132 140 132C142.209 132 144 133.791 144 136Z" fill="#BCC8EC" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M26 117C28.7614 117 31 114.761 31 112C31 109.239 28.7614 107 26 107C23.2386 107 21 109.239 21 112C21 114.761 23.2386 117 26 117ZM26 120C30.4183 120 34 116.418 34 112C34 107.582 30.4183 104 26 104C21.5817 104 18 107.582 18 112C18 116.418 21.5817 120 26 120Z" fill="#BCC8EC" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M130.347 34.3469C129.884 34.8095 129.884 35.5596 130.347 36.0223L133.261 38.9361L130.494 41.7027C130.031 42.1654 130.031 42.9155 130.494 43.3781C130.957 43.8408 131.707 43.8408 132.17 43.3781L134.936 40.6115L137.607 43.2823C138.07 43.7449 138.82 43.7449 139.282 43.2823C139.745 42.8197 139.745 42.0696 139.282 41.6069L136.612 38.9361L139.43 36.1181C139.892 35.6554 139.892 34.9053 139.43 34.4427C138.967 33.9801 138.217 33.9801 137.754 34.4427L134.936 37.2607L132.022 34.3469C131.56 33.8842 130.81 33.8842 130.347 34.3469Z" fill="#F36958" />
                    <path d="M135.5 82.75C135.5 94.2099 126.21 103.5 114.75 103.5C103.29 103.5 94 94.2099 94 82.75C94 71.2901 103.29 62 114.75 62C126.21 62 135.5 71.2901 135.5 82.75Z" fill="#839ADF" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M124.165 75.0746C125.045 75.937 125.045 77.3353 124.165 78.1978L113.274 88.8718C112.839 89.2977 112.246 89.5312 111.631 89.5181C111.017 89.505 110.435 89.2465 110.019 88.8025L105.888 84.3857C105.047 83.4866 105.109 82.0896 106.027 81.2654C106.944 80.4412 108.369 80.502 109.21 81.4011L111.751 84.1177L120.978 75.0746C121.858 74.2121 123.285 74.2121 124.165 75.0746Z" fill="white" />
                </svg>
                <div className='fab-progressive-info-container'>
                    <h2 className='fab-progressive-info-header'>
                        Just a couple more steps to go!
                    </h2>
                    <p className='fab-progressive-info-name'>Hey {userFullName}!</p>
                    <p className='fab-progressive-info-text'>
                        Before you can proceed with your application, you'll need to fill up a few requirements to ensure the employer knows enough about you. This will help improve your chances at getting a response.
                    </p>
                </div>
            </div>
            <div className="application-footer">
                <Button variant='primary' size='lg' onClick={handleNext}>Next</Button>
            </div>
        </div>
    )
}

export default ProgressiveApplicationInfo;