import React, { useEffect } from 'react';
import Button from '../../components/button/Button';
import './ConfirmDeletionModal.css';

const ConfirmDeletionModal = ({ showModal, setShowModal, handleConfirm }) => {
    const handleCancel = () => {
        setShowModal(false)
    }

    useEffect(() => {
        if (showModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [showModal]);

    return (
        <div className={`profile-select-modal ${showModal ? 'show' : ''}`}>
            <div className='profile-select-modal-content'>
                <div className="profile-select-modal-header">
                    <h2 className='profile-select-modal-title'>Confirm deletion?</h2>
                    <div className="profile-select-modal-body">
                        <p>Are you sure you want to delete this entry? Please note that this action cannot be undone.</p>
                    </div>
                </div>
                <div className="profile-select-modal-footer">
                    <Button variant="outline" size="md" className="cancel-btn" onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button variant="primary" size="md" className="confirm-btn" onClick={handleConfirm}>
                        Confirm
                    </Button>
                </div>
            </div>
        </div>

    )
}

export default ConfirmDeletionModal;