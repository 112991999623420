import axios from './axios';
import { routes } from '../common/routes';

export const login = async (userName, password) => {
    let data = {
        usrm: userName,
        pass: password,
    }
    const params = { v: process.env.REACT_APP_VERSION };
    const response = await axios.post(routes.profile.login, data, { params });
    return response;
}

export const register = async (fullName, password, email, phoneNumber, marketingConsentInfo) => {
    let data = {
        fullm: fullName,
        eml: email,
        pass: password,
        phone: phoneNumber,
    }
    const params = { v: process.env.REACT_APP_VERSION, pdpa: marketingConsentInfo };
    const response = await axios.post(routes.profile.register, data, { params });
    return response;
}

export const googleVerifyToken = async (token) => {
    const bodyParams = { 'token': token };
    const response = await axios.post(routes.profile.googleVerify, bodyParams);
    return response;
}

export const googleLogin = async (token) => {
    const bodyParams = { 'token': token };
    const response = await axios.post(routes.profile.googleLogin, bodyParams);
    return response;
}

export const googleRegister = async (token, phone) => {
    const bodyParams = { 'token': token };
    const params = { phone: phone };
    const response = await axios.post(routes.profile.googleRegister, bodyParams, { params });
    return response;
}

export const fbVerifyToken = async (token, phone) => {
    const bodyParams = { 'token': token, 'phone': phone };
    const response = await axios.post(routes.profile.fbVerify, bodyParams);
    return response;
}

export const verifyUser = async (username) => {
    const bodyParams = { 'usrm': username };
    const response = await axios.post(routes.profile.verifyUser, bodyParams);
    return response;
}

export const getFbUserDetails = async (token) => {
    const bodyParams = { 'token': token };
    const response = await axios.post(routes.profile.getFbUserDetails, bodyParams);
    return response;
}

export const fbTokenRequest = async (token) => {
    const bodyParams = { 'token': token };
    const response = await axios.post(routes.profile.fbTokenRequest, bodyParams);
    return response;
}

export const fbLoginRequest = async (id, token, username) => {
    const bodyParams = { 'fbid': id, 'fbtoken': token, 'usrm': username };
    const queryParams = { 'v': '7.0.0' };
    return await axios.post(routes.profile.fbLogin, bodyParams, { params: queryParams });
}

export const fbRegisterRequest = async (id, token, email, phone, fullm) => {
    const bodyParams = { 'fbid': id, 'fbtoken': token, 'fullm': fullm, 'eml': email, 'phone': phone };
    const queryParams = { 'v': '7.0.0' };
    return await axios.post(routes.profile.fbRegister, bodyParams, { params: queryParams });
}

export const forgotPassword = async (email) => {
    let data = {
        usrm: email
    }
    const params = { v: process.env.REACT_APP_VERSION, client: 'fabjobs' };
    const response = await axios.post(routes.profile.forgotPassword, data, { params });
    return response;
}

// API to reset the password.
export const resetPassword = async (userName, userId, token, password) => {
    let formData = {
        usrm: userName,
        uid: userId,
        token,
        newpass: password,
        confirmpass: password
    }
    const params = { v: process.env.REACT_APP_VERSION };
    const response = await axios.post(routes.profile.resetPassword, formData, { params });
    return response;
}