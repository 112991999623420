import "../../components/globals/FbSignUp.css";

import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Button from '../../components/button/Button';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import Loader from '../../components/loader/Loader';
import Toast from '../../components/toast/Toast';
import { fbRegisterRequest } from '../../services/UserService';
import analytics from '../../firebase';
import { isEmpty } from '../../common/helper';
import { logEvent } from "firebase/analytics";

const toastInitialState = {
    type: '',
    header: '',
    message: ''
}

const FbSignUp = () => {
    const locationProps = useLocation();
    const navigate = useNavigate();
    const fbAccessToken = useState(locationProps?.state?.fbAccessToken || '');
    const fbId = useState(locationProps?.state?.fbId || '');
    const fbName = useState(locationProps?.state?.name || '');
    const fbEmail = useState(locationProps?.state?.email || '');

    const [errorMessage, setErrorMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [mobileNumber, setMobileNumber] = useState();
    const [showLoader, setShowLoader] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastProps, setToastProps] = useState(toastInitialState)


    const handleOnChange = (event) => {
        setIsError(false)
        setMobileNumber(event.target.value)
    }

    const handleToastClose = () => {
        setShowToast(false)
        setToastProps(toastInitialState)
    }

    const handleSignUp = () => {
        if (isEmpty(mobileNumber) || mobileNumber.length !== 8) {
            setIsError(true)
            setErrorMessage('Please enter a valid mobile number')
        }
        else {
            FbRegister()
        }
    }

    const FbRegister = async () => {
        // get response from the API
        try {
            setShowLoader(true);
            logEvent(analytics, 'signup_initiated', { 'click_source': 'facebook' });
            const apiResponse = await fbRegisterRequest(fbId[0], fbAccessToken[0], fbEmail[0], mobileNumber, fbName[0]);
            if (apiResponse?.data?.isSuccess === 1) {
                setShowLoader(false)
                const data = apiResponse.data.data[0];
                localStorage.setItem('isLogin', 'true');
                const userInfo = {
                    'userName': data.fullm,
                    'disName': data.dispm,
                    'userEmail': data.usrm,
                    'userId': data.usrid,
                    'pic': data?.pic,
                }
                localStorage.setItem('user', JSON.stringify(userInfo));
                localStorage.setItem('token', data.t);
                localStorage.setItem('fromSignup', 'true');
                logEvent(analytics, 'signup_success', { 'click_source': 'facebook', 'userid': userInfo.userId });
                logEvent(analytics, 'mobile_verify_screen_viewed', { 'click_source': 'signup', 'userid': userInfo.userId });
                navigate("/verifyMobileNumber", { state: { phoneNumber: mobileNumber, showOtpScreen: true } })
            }
            else {
                setShowLoader(false)
                setShowToast(true)
                setToastProps({
                    type: 'error',
                    header: 'Something went wrong',
                    message: 'Please try again'
                })
                logEvent(analytics, 'signup_failed', { 'error_message': 'Something went wrong' });
            }
        }
        catch (error) {
            setShowLoader(false)
            setShowToast(true)
            setToastProps({
                type: 'error',
                header: 'Something went wrong',
                message: 'Please try again'
            })
            logEvent(analytics, 'signup_failed', { 'error_message': 'Something went wrong' });
        }
    }


    return (
        <div className='fb-mobile-container '>
            <Header />
            {showLoader && <Loader></Loader>}
            {showToast && <Toast type={toastProps.type} header={toastProps.header} message={toastProps.message} onClose={handleToastClose} />}
            <div className='fb-mobile-content fab-container'>
                <div className='fb-mobile-header-container'>
                    <h2 className='fb-mobile-header'>Sign Up for FABJobs account</h2>
                    <div className='fb-mobile-divider'></div>
                </div>
                <div className='fab-mobile-header-subheading'>
                    Enter your mobile number below to complete your sign up. We will send you a verification code on this mobile number later to verify it's you.
                </div>
                <div className='fb-mobile-input-container'>
                    <label className='fb-mobile-label'>Enter your mobile number</label>
                    <div className={'fb-mobile-input-wrapper'}>
                        <div className="fb-mobile-number-country-wrapper">
                            <svg className="fb-number-country-icon" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 17 12" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.5 0.666504H16.5V11.3332H0.5V0.666504Z" fill="white" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.5 0.666504H16.5V5.99984H0.5V0.666504Z" fill="#DF0000" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M4.18853 1.55996C3.7676 1.65042 3.39061 1.88295 3.12084 2.2185C2.85108 2.55405 2.70498 2.9722 2.70706 3.40273C2.70915 3.83327 2.8593 4.24998 3.13229 4.5829C3.40529 4.91583 3.78452 5.14469 4.20631 5.23107C3.96468 5.30099 3.71162 5.32252 3.46166 5.29443C3.21169 5.26633 2.96972 5.18917 2.74964 5.06736C2.52956 4.94555 2.33569 4.78149 2.17915 4.5846C2.02261 4.3877 1.90648 4.16184 1.83742 3.91996C1.70026 3.43376 1.76118 2.91302 2.00687 2.47162C2.25256 2.03021 2.66303 1.70403 3.14853 1.5644C3.50409 1.46218 3.83298 1.45996 4.19075 1.55996H4.18853Z" fill="white" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M3.9003 3.11087L4.00919 3.4442L3.7203 3.23975L3.43586 3.44864L3.5403 3.11087L3.25586 2.9042L3.60919 2.89975L3.7203 2.56642L3.83141 2.89975H4.18253L3.9003 3.11087ZM4.28919 4.26642L4.4003 4.60198L4.11141 4.39753L3.82475 4.6042L3.93141 4.26864L3.64697 4.05975L4.0003 4.05753L4.10919 3.72198L4.2203 4.05531H4.57586L4.28919 4.26642ZM5.58919 4.25753L5.69808 4.59531L5.40919 4.38864L5.12475 4.59531L5.22919 4.25975L4.94475 4.05309L5.29808 4.04864L5.40919 3.71531L5.5203 4.04864H5.87141L5.58919 4.25753ZM5.97586 3.11087L6.08475 3.44642L5.79586 3.24198L5.51141 3.44864L5.61808 3.11309L5.33141 2.9042L5.68697 2.90198L5.79364 2.56642L5.90475 2.89975H6.2603L5.97586 3.11087ZM4.94697 2.34864L5.05808 2.68642L4.76919 2.47975L4.48253 2.68864L4.58919 2.35087L4.30475 2.14198L4.65586 2.13975L4.76697 1.8042L4.87808 2.13753H5.23364L4.94697 2.34864Z" fill="white" />
                            </svg>
                            <span className="fb-number-country" value="+65">+65</span>
                        </div>
                        <input
                            autoFocus
                            type="number"
                            pattern="\d*"
                            inputmode="numeric"
                            className={'fb-mobile-input'}
                            placeholder='0000 0000'
                            value={mobileNumber}
                            onChange={handleOnChange}
                        />
                    </div>
                    {isError && <div className="fab-verify-mobile-error-message">{errorMessage}</div>}
                </div>
                <div className='fab-verify-mobile-button'>
                    <Button variant="primary" size="lg" className='fb-mobile-sign-up' onClick={handleSignUp}>Sign Up</Button>
                </div>
            </div>
            <Footer />
        </div >
    )
}

export default FbSignUp;