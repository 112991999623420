import '../../components/globals/boilerplate.css';
import '../../components/globals/homepage.css';

import React, { useEffect, useRef, useState } from 'react';
import { formatFiltersData, getSelectedItems, mapLocationValues, updateDropdownWithAll } from '../../common/helper';
import { getCodeTableChecksum, getInformation } from '../../services/HomeService';

import Button from "../../components/button/Button";
import Carousel from '../../components/carousel/Carousel';
import Dropdown from '../../components/dropdown/Dropdown';
import FeaturedCompanies from './FeaturedCompanies';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import LatestJobs from './LatestJobs';
import TextField from '../../components/textfield/Textfield';
import Toast from '../../components/toast/Toast';
import analytics from '../../firebase';
import { getLatestJobs } from '../../services/jobService';
import { logEvent } from "firebase/analytics";
import { routes } from '../../common/routes';
import { useNavigate } from 'react-router-dom';

const CheckboxItem = ({ label, selectedItems, onClick }) => {
  return (
    <label className='fab-menu-mobile-label'>
      <input type="checkbox" checked={selectedItems?.includes(label)} onClick={(e) => onClick(e, label)} readOnly />
      {label}
    </label>
  );
};

const useNoScroll = (isActive) => {
  useEffect(() => {
    if (isActive) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isActive]);
};

const toastInitialState = {
  type: '',
  header: '',
  message: ''
}

const Homepage = () => {
  const navigate = useNavigate();
  const [bannerImages, setBannerImages] = useState([]);
  const [locations, setLocations] = useState([])
  const [jobPositions, setJobPositions] = useState([])
  const [employmentTypes, setEmploymentTypes] = useState([])
  const [inputValue, setInputValue] = useState('');
  const [selectedItems, setSelectedItems] = useState({
    locations: ['All locations'],
    employmentTypes: ['All employment types']
  });
  useEffect(() => {
    logEvent(analytics, 'homepage_screen_viewed');
  }, []);
  // State to manage selected filters from mobile or tablet view
  const [selectedFiltersMobile, setSelectedFiltersMobile] = useState({
    locations: selectedItems.locations,
    employmentTypes: selectedItems.employmentTypes
  });
  const [latestJobs, setLatestJobs] = useState([]);
  const [isFilterSticky, setFilterSticky] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [isEmploymentStatusActive, setIsEmploymentStatusActive] = useState(false);
  const [isLocationActive, setIsLocationActive] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastProps, setToastProps] = useState(toastInitialState)
  const locationRef = useRef(null);
  const employmentRef = useRef(null);

  const handleInputChange = (event) => {
    setInputValue(event.target.value)
  }

  const resetDropdowns = (placeholder) => {
    if (placeholder === 'Search by location') {
      setSelectedItems((prevItems) => ({
        ...prevItems,
        locations: ['All locations']
      }))
    }
    else if (placeholder === 'Employment type') {
      setSelectedItems((prevItems) => ({
        ...prevItems,
        employmentTypes: ['All employment types']
      }))
    }
  }

  const onCheckboxClick = (placeholder, itemName, isChecked) => {
    let items = []
    if (placeholder === 'Search by location') {
      items = getSelectedItems(isChecked, itemName, 'All locations', locations, selectedItems.locations)
      setSelectedItems((prevItems) => ({
        ...prevItems,
        locations: items
      }))
    }
    else if (placeholder === 'Employment type') {
      items = getSelectedItems(isChecked, itemName, 'All employment types', employmentTypes, selectedItems.employmentTypes)
      setSelectedItems((prevItems) => ({
        ...prevItems,
        employmentTypes: items
      }))
    }
  };


  const resetMobileDropdowns = (placeholder) => {
    if (placeholder === 'Search by location') {
      setSelectedFiltersMobile((prevItems) => ({
        ...prevItems,
        locations: ['All locations']
      }))
    }
    else if (placeholder === 'Employment type') {
      setSelectedFiltersMobile((prevItems) => ({
        ...prevItems,
        employmentTypes: ['All employment types']
      }))
    }
  }

  const onMobileCheckboxClick = (placeholder, itemName, isChecked) => {
    let items = []
    if (placeholder === 'Search by location') {
      items = getSelectedItems(isChecked, itemName, 'All locations', locations, selectedFiltersMobile.locations)
      setSelectedFiltersMobile((prevItems) => ({
        ...prevItems,
        locations: items
      }))
    }
    else if (placeholder === 'Employment type') {
      items = getSelectedItems(isChecked, itemName, 'All employment types', employmentTypes, selectedFiltersMobile.employmentTypes)
      setSelectedFiltersMobile((prevItems) => ({
        ...prevItems,
        employmentTypes: items
      }))
    }
  };

  const handleCheckboxClick = (e, itemName) => {
    e.stopPropagation();
    const checkbox =
      e.target.type === "checkbox"
        ? e.target
        : e.target.querySelector('input[type="checkbox"]');
    const isChecked = checkbox.checked;
    const placeholder = isEmploymentStatusActive ? 'Employment type' : 'Search by location';
    onMobileCheckboxClick(placeholder, itemName, isChecked)
  };

  const onSearch = () => {
    logEvent(analytics, 'job_search_initiated', { 'click_source': 'homepage' });
    navigate(routes.jobs.search, { state: { jobPosition: [], locations: selectedItems.locations, input: inputValue, jobTypes: selectedItems.employmentTypes } })
    logEvent(analytics, 'job_search_completed', { 'click_source': 'homepage', 'search_term': inputValue, 'locations': selectedItems.locations.join(','), 'employment_types': selectedItems.employmentTypes.join(',') });
  }

  const getSlides = async () => {
    try {
      const info = await getInformation();
      if (info.data) {
        let banners = info.data.imagesWithHref
        const activeBanners = banners.filter(banner => banner.active);
        const sortedRows = activeBanners.sort((a, b) => a.sortOrder - b.sortOrder);
        setBannerImages(sortedRows);
        const sortedJobTitles = info.data.jobPositions.sort((a, b) => {
          const keyA = Object.keys(a)[0];
          const keyB = Object.keys(b)[0];
          return keyA.localeCompare(keyB);
        });
        const jps = sortedJobTitles.map((jp) => Object.keys(jp));
        setJobPositions(jps);
      }
    }
    catch (error) {
      console.log(`Get info error: ${error}`);
    }
  }

  const getFilters = async () => {
    try {
      const checksum = await getCodeTableChecksum();
      if (checksum.data) {
        const employmentTypes = formatFiltersData(checksum.data.Job_Type.data);
        const updatedEmploymentTypes = updateDropdownWithAll('All employment types', employmentTypes);
        const locations = formatFiltersData(checksum.data.Region.data);
        const updatedLocations = mapLocationValues(locations)
        setEmploymentTypes(updatedEmploymentTypes);
        setLocations(updatedLocations);
        setSelectedItems(() => ({
          employmentTypes: ['All employment types'],
          locations: ['All locations']
        }))
      }
    }
    catch (error) {
      console.log(`Get filters error: ${error}`);
    }
  }

  const handleBrowseJobs = () => {
    navigate(routes.jobs.search);
  }

  const handleToastClose = () => {
    setShowToast(false)
    setToastProps(toastInitialState)
  }

  const handleApplyFilter = () => {
    setIsLocationActive(false);
    setIsEmploymentStatusActive(false)
    setSelectedItems({
      locations: selectedFiltersMobile.locations,
      employmentTypes: selectedFiltersMobile.employmentTypes
    })
  };

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;

    if (window.innerWidth < 600) {
      if (currentScrollPos > prevScrollPos) {
        setFilterSticky(false);
      } else {
        setFilterSticky(true);
      }
    } else {
      setFilterSticky(false);
    }

    setPrevScrollPos(currentScrollPos);
  };

  const checkIfLocationActive = () => {
    if (window.innerWidth <= 768) {
      setIsLocationActive(true)
      window.scrollTo({
        top: 0,
        // behavior: 'smooth'
      });
    }
    else {
      setIsLocationActive(false)
    }
  }

  const checkIfEmploymentActive = () => {
    if (window.innerWidth <= 768) {
      setIsEmploymentStatusActive(true)
      window.scrollTo({
        top: 0,
        // behavior: 'smooth'
      });
    }
    else {
      setIsEmploymentStatusActive(false)
    }
  }


  useEffect(() => {
    getSlides()
    getFilters()
  }, []);

  useEffect(() => {
    const fetchLatestJobs = async () => {
      const jobs = await getLatestJobs();
      setLatestJobs(jobs);
    };

    fetchLatestJobs();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  useNoScroll(isLocationActive || isEmploymentStatusActive);

  useEffect(() => {
    const redirectedResetPassword = JSON.parse(localStorage.getItem('fromResetPassword'));
    if (redirectedResetPassword) {
      setShowToast(true);
      setToastProps({
        type: 'success',
        header: 'Reset Password Email has been sent',
        message: 'If a FastJobs account exists, an email will be sent with further instructions. The reset password link is only valid for 24 hours.'
      })
      localStorage.removeItem('fromResetPassword');
    }
  }, []);

  useEffect(() => {
    const handleDocumentClick = (e) => {
      if (isLocationActive && locationRef.current && !locationRef.current.contains(e.target)) {
        setIsLocationActive(false);
        setSelectedFiltersMobile({
          locations: selectedItems.locations,
          employmentTypes: selectedItems.employmentTypes
        })
      }
      else if (isEmploymentStatusActive && employmentRef.current && !employmentRef.current.contains(e.target)) {
        setIsEmploymentStatusActive(false)
        setSelectedFiltersMobile({
          locations: selectedItems.locations,
          employmentTypes: selectedItems.employmentTypes
        })
      }
    };

    const handleEscapeKey = (e) => {
      if (e.key === "Escape") {
        setIsLocationActive(false);
        setIsEmploymentStatusActive(false)
        setSelectedFiltersMobile({
          locations: selectedItems.locations,
          employmentTypes: selectedItems.employmentTypes
        })
      }
    };

    if (isLocationActive || isEmploymentStatusActive) {
      document.body.classList.add("no-scroll");
      document.addEventListener("mousedown", handleDocumentClick);
      document.addEventListener("keydown", handleEscapeKey);
    } else {
      document.body.classList.remove("no-scroll");
      document.removeEventListener("mousedown", handleDocumentClick);
      document.removeEventListener("keydown", handleEscapeKey);
    }

    return () => {
      document.body.classList.remove("no-scroll");
      document.removeEventListener("mousedown", handleDocumentClick);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [isEmploymentStatusActive, isLocationActive, setIsEmploymentStatusActive, setIsLocationActive]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        onSearch();
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [onSearch]);


  return (
    <div className='fab-homepage'>
      <Header />
      {showToast && <Toast type={toastProps.type} header={toastProps.header} message={toastProps.message} onClose={handleToastClose} />}
      <div className={`fab-search-filters ${isFilterSticky === true ? 'fab-home-filters-sticky' : ""}`}>
        <div className='fab-container fab-filter-fields' >
          <TextField placeholder='Search for F&B jobs' isPassword={false} value={inputValue} onChange={handleInputChange} />
          <Dropdown menuItems={locations} showSlider={false} onClick={checkIfLocationActive} placeholder='Search by location' onCheckboxClick={onCheckboxClick} selectedItems={selectedItems.locations} setSelectedItems={setSelectedItems} resetDropdowns={resetDropdowns} />
          <Dropdown menuItems={employmentTypes} showSlider={false} onClick={checkIfEmploymentActive} placeholder='Employment type' onCheckboxClick={onCheckboxClick} selectedItems={selectedItems.employmentTypes} setSelectedItems={setSelectedItems} resetDropdowns={resetDropdowns} />
          <div className='fab-filter-buttons'>
            <Button variant="primary" size="lg" className='fab-filter-button' onClick={onSearch}>Search for jobs</Button>
          </div>
        </div>
      </div>
      <Carousel slides={bannerImages} />
      <FeaturedCompanies />
      {/* Latest jobs */}
      <div className='fab-container'>
        <div className='fab-latest-jobs'>
          <div className='fab-homepage-title-wrapper'>
            <h3 className='fab-homepage-title'>Latest jobs</h3>
            <Button variant="outline" size="md" onClick={handleBrowseJobs}>Browse jobs</Button>
          </div>
          <LatestJobs latestJobData={latestJobs} location='homepage' />
          <div className='fab-homepage-button-m'>
            <Button variant="outline" size="md" onClick={handleBrowseJobs}>Browse jobs</Button>
          </div>
        </div>
      </div>
      {/* Position */}
      <div className='fab-container'>
        <div className='fab-home-positions'>
          <div className='fab-homepage-title-wrapper'>
            <h3 className='fab-homepage-title'>Job position</h3>
          </div>
          <div className='fab-positions'>
            {jobPositions.map((position, index) => (
              <Button className='fab-position-item' key={index} variant="outline" onClick={() => {
                logEvent(analytics, 'homepage_job_position_clicked', { 'job_position_options': position });
                navigate(routes.jobs.search, { state: { jobPosition: position, locations: [], input: '', jobTypes: [] } })
              }}>{position}</Button>))}
          </div>
        </div>
      </div>
      <Footer />
      {/* All Location Mobile Filter */}
      <div className={`fab-dropdown-mobile-menu ${isLocationActive ? 'fab-location-mobile-active' : ''}`}>
        <div className='fab-menu-mobile-container' ref={locationRef}>
          <div className='fab-menu-header-container'>
            <p>Search by location</p>
          </div>
          <div className='fab-menu-mobile-items'>
            {locations.map((item, index) => (
              <CheckboxItem key={index} label={item} selectedItems={selectedFiltersMobile.locations} onClick={handleCheckboxClick} />
            ))}
          </div>
          <div className='fab-menu-mobile-buttons'>
            <Button variant='primary' size='lg' onClick={handleApplyFilter}>Apply filter</Button>
            <Button variant='outline' size='lg' onClick={() => { resetMobileDropdowns('Search by location') }}>Reset all</Button>
          </div>
        </div>
      </div>
      {/* Employment Status Mobile Filter */}
      <div className={`fab-dropdown-mobile-menu ${isEmploymentStatusActive ? 'fab-employment-status-mobile-active' : ''}`}>
        <div className='fab-menu-mobile-container' ref={employmentRef}>
          <div className='fab-menu-header-container'>
            <p>Employment type</p>
          </div>
          <div className='fab-menu-mobile-items'>
            {employmentTypes.map((item, index) => (
              <CheckboxItem key={index} label={item} selectedItems={selectedFiltersMobile.employmentTypes} onClick={handleCheckboxClick} />
            ))}
          </div>
          <div className='fab-menu-mobile-buttons'>
            <Button variant='primary' size='lg' onClick={handleApplyFilter}>Apply filter</Button>
            <Button variant='outline' size='lg' onClick={() => { resetMobileDropdowns('Employment type') }}>Reset all</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;