import React from 'react';
import Button from "../../components/button/Button";
import '../../components/globals/JobLocationSelectionModal.css';

const JobLocationSelectionModal = ({ jobLocations, handleClose, showLocationSelectionModal, handleSelectionModalNextButton, selectedMallIds, handleCheckboxClick, locationLimit }) => {
    const containerClassName = `fab-location-selection-modal-container ${!showLocationSelectionModal ? 'fab-location-modal-container-close' : ''}`;

    return (
        <div className={containerClassName}>
            <div className='fab-location-selection-modal-wrapper'>
                <div className='fab-selection-header-wrapper'>
                    <h3 className='fab-selection-header-text'>Which location would you like to apply to?</h3>
                    <svg onClick={handleClose} className='fab-selection-close-icon' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L12 10.9393L17.4697 5.46967C17.7626 5.17678 18.2374 5.17678 18.5303 5.46967C18.8232 5.76256 18.8232 6.23744 18.5303 6.53033L13.0607 12L18.5303 17.4697C18.8232 17.7626 18.8232 18.2374 18.5303 18.5303C18.2374 18.8232 17.7626 18.8232 17.4697 18.5303L12 13.0607L6.53033 18.5303C6.23744 18.8232 5.76256 18.8232 5.46967 18.5303C5.17678 18.2374 5.17678 17.7626 5.46967 17.4697L10.9393 12L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967Z" fill="#3F3F46" />
                    </svg>
                </div>
                <span className='fab-selection-sub-heading'>You can select up to {locationLimit} locations.</span>
                <div className='fab-selected-count'>{selectedMallIds.length} selected</div>
                <div className='fab-selected-location-content-wrapper'>
                    {jobLocations.map((value, index) => {
                        return (
                            <label key={index} htmlFor={`chckItemjb${index}`} className="fab-selected-location-item" onClick={(e) => e.stopPropagation()}>
                                <input type="checkbox" name="" id={`chckItemjb${index}`} checked={selectedMallIds?.includes(value)} className='fab-selected-location-check' onClick={(e) => handleCheckboxClick(e, value)} />
                                {value.name}
                            </label>
                        )
                    })}
                </div>
                {locationLimit === selectedMallIds.length && <div className='fab-selected-locations-error'>You have reached the limit for location selection.</div>}
                <div className='fab-selected-location-buttons'>
                    <button className="fab-location-cancel-button" onClick={handleClose}>
                        Cancel
                    </button>
                    <Button variant="primary" size="md" onClick={handleSelectionModalNextButton}>Next: Application method</Button>
                </div>
            </div>
        </div>
    )
}

export default JobLocationSelectionModal;