import '../../components/globals/ProgressiveApplication.css';

import Button from '../../components/button/Button';
import React from 'react';
import StepWizard from './StepWizard';
import analytics from '../../firebase';
import { logEvent } from 'firebase/analytics';

const renderEmploymentStatusOptions = (employmentStatusOptions, handleCheckboxClick, selectedValue) => {
    return employmentStatusOptions.map(option => (
        <label key={option.codeid} htmlFor={option.codeid}>
            <input type="radio" name="employment-status" id={option.codeid} checked={option === selectedValue} onClick={(e) => handleCheckboxClick(e, option)} />
            {option.val}
        </label>
    ));
};

const JobApplicationEmployment = ({ handlePrevStep, totalSteps, employmentStatusOptions, setSelectedEmploymentStatus, saveEmploymentStatus, selectedEmploymentStatus, handleClose, isFirstStep }) => {
    logEvent(analytics, 'apply_employment_status_viewed', {'click_source': 'job details'});
    const handleCloseButtonClick = () => {
        const modalContainer = document.querySelector('.fab-modal-container');
        modalContainer.classList.remove('modal-active');
        handleClose()
    };

    const handleCheckboxClick = (e, option) => {
        e.stopPropagation()
        setSelectedEmploymentStatus(option)
    }

    const handleNext = () => {
        saveEmploymentStatus()
    }

    return (
        <div className="fab-modal-body fab-modal-education">
            {(totalSteps > 1 && isFirstStep !== 2) && <svg onClick={handlePrevStep} className='fab-modal-application-back' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M7.71967 12.5303C7.42678 12.2374 7.42678 11.7626 7.71967 11.4697L15.2197 3.96967C15.5126 3.67678 15.9874 3.67678 16.2803 3.96967C16.5732 4.26256 16.5732 4.73744 16.2803 5.03033L9.31066 12L16.2803 18.9697C16.5732 19.2626 16.5732 19.7374 16.2803 20.0303C15.9874 20.3232 15.5126 20.3232 15.2197 20.0303L7.71967 12.5303Z" fill="#18181B" />
            </svg>}
            <svg className='fab-modal-application-close' width="24" height="24" onClick={handleCloseButtonClick} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L12 10.9393L17.4697 5.46967C17.7626 5.17678 18.2374 5.17678 18.5303 5.46967C18.8232 5.76256 18.8232 6.23744 18.5303 6.53033L13.0607 12L18.5303 17.4697C18.8232 17.7626 18.8232 18.2374 18.5303 18.5303C18.2374 18.8232 17.7626 18.8232 17.4697 18.5303L12 13.0607L6.53033 18.5303C6.23744 18.8232 5.76256 18.8232 5.46967 18.5303C5.17678 18.2374 5.17678 17.7626 5.46967 17.4697L10.9393 12L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967Z" fill="#18181B" />
            </svg>
            {totalSteps > 1 && <StepWizard stepActive={2} totalSteps={totalSteps} />}
            <div className={totalSteps > 1 ? "fab-application-education" : "fab-application-education-margin"}>
                <h3 className="application-modal-title">Confirm your employment status</h3>
                <div className='fab-application-employment-status'>
                    {renderEmploymentStatusOptions(employmentStatusOptions, handleCheckboxClick, selectedEmploymentStatus)}
                </div>
            </div>
            <div className="application-footer application-footer-space-between">
                {(totalSteps > 1 && isFirstStep !== 2) ? <Button variant='outline' size='lg' onClick={handlePrevStep} className='fab-progressive-button-back' >Back</Button> : <div></div>}
                <Button variant='primary' size='lg' isDisabled={selectedEmploymentStatus.length === 0} onClick={handleNext}>Next</Button>
            </div>
        </div>
    );
};

export default JobApplicationEmployment;
