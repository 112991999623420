import { React, useEffect, useState } from 'react';
import TextField from '../textfield/Textfield';
import Button from "../button/Button";

const MobileSearch = ({ handleOnFocus, OnClickFilterIcon, value, onSearchButtonClick }) => {
    const [searchValue, setSearchValue] = useState(value || '')

    const handleSearchTextChange = (e) => {
        setSearchValue(e.target.value)
    }

    const clearSearchText = () => {
        setSearchValue('')
    }

    const onSearch = () => {
        handleOnFocus()
        onSearchButtonClick(searchValue)
    }

    const handleBack = () => {
        handleOnFocus()
    }

    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.key === 'Enter') {
                onSearch();
            }
        };

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [onSearch]);


    return (
        <div className='fab-text-filter'>
            <div className='fab-text-filter-header'>
                <button className="fab-jobs-back-icon" onClick={handleBack}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0.71967 9.53033C0.426777 9.23744 0.426777 8.76256 0.71967 8.46967L8.21967 0.96967C8.51256 0.676777 8.98744 0.676777 9.28033 0.96967C9.57322 1.26256 9.57322 1.73744 9.28033 2.03033L2.31066 9L9.28033 15.9697C9.57322 16.2626 9.57322 16.7374 9.28033 17.0303C8.98744 17.3232 8.51256 17.3232 8.21967 17.0303L0.71967 9.53033Z" fill="#18181B" />
                    </svg>
                </button>
            </div>
            <div className='fab-text-filter-content'>
                <div className='fab-text-filter-fields'>
                    <TextField placeholder='Search' value={searchValue} onChange={handleSearchTextChange} onClear={clearSearchText} />
                    <button className="fab-text-filter-button" onClick={OnClickFilterIcon}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M2.15962 1.44854C4.38388 1.07135 6.66928 0.875 9.00006 0.875C11.3308 0.875 13.6162 1.07134 15.8404 1.44852C16.5924 1.57604 17.125 2.23063 17.125 2.97835V3.84835C17.125 4.51139 16.8616 5.14727 16.3928 5.61612L11.2411 10.7678C11.0067 11.0022 10.875 11.3201 10.875 11.6517V14.0912C10.875 15.0381 10.34 15.9038 9.49303 16.3273L8.02951 17.059C7.83577 17.1559 7.60568 17.1455 7.42142 17.0317C7.23716 16.9178 7.125 16.7166 7.125 16.5V11.6517C7.125 11.3201 6.9933 11.0022 6.75888 10.7678L1.60723 5.61612C1.13839 5.14728 0.875 4.51139 0.875 3.84835V2.97837C0.875 2.23065 1.40764 1.57606 2.15962 1.44854Z" fill="#18181B" />
                        </svg>
                        <span className="fab-filter-text">
                            Filter
                        </span>
                    </button>
                </div>
            </div>
            <div className="fab-text-filter-footer">
                <Button variant="primary" size="lg" onClick={onSearch}>Search</Button>
            </div>
        </div>
    )
}

export default MobileSearch;