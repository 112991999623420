import axios from './axios';
import { routes } from '../common/routes';

// API to get information which contains banner carousel images and job positions etc,.
export const getInformation = async () => {
    const response = await axios.get(routes.home.info);
    return response.data;
}

// API to get code table checksum which contains locations, employment type and job categories etc,.
export const getCodeTableChecksum = async () => {
    const response = await axios.get(routes.codeTable.fastCodes);
    return response.data;
}
