import React, { useRef, useState, useEffect } from 'react';
import './Dropdown.css';

const Dropdown = ({ menuItems, placeholder, selectedItems, setShowSlider, setSliderHeader, onCheckboxClick, resetDropdowns, onClick, id, ...props }) => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [dropdownText, setDropdownText] = useState(placeholder);
  const dropdownRef = useRef(null);
  const windowWidth = window.innerWidth

  const toggleMenu = () => {
    if (windowWidth <= 992) {
      if (setShowSlider) {
        setShowSlider(true)
        setSliderHeader(placeholder)
      }
      else {
        setMenuVisible(!isMenuVisible);
      }
    }
    else {
      setMenuVisible(!isMenuVisible);
    }
  }

  const handleOutsideClick = () => {
    setMenuVisible(false)
    if (selectedItems && selectedItems.length === 0) {
      if (resetDropdowns) {
        resetDropdowns(placeholder)
      }
    }
  }

  const handleCheckboxClick = (e, itemName) => {
    e.stopPropagation();
    const checkbox =
      e.target.type === "checkbox"
        ? e.target
        : e.target.querySelector('input[type="checkbox"]');
    const isChecked = checkbox.checked;
    if (onCheckboxClick) {
      onCheckboxClick(placeholder, itemName, isChecked)
    }
  };

  const displyMobileDropdownText = (items) => {
    const isAllOptionSelected = selectedItems?.includes("All locations") || selectedItems?.includes("All employment types") || selectedItems?.includes("All job positions") || selectedItems?.includes("All categories");
    return windowWidth <= 992 && items?.length > 0 && !isAllOptionSelected && setShowSlider
  }

  useEffect(() => {
    if (selectedItems) {
      const isAllOptionSelected = selectedItems?.includes("All locations") || selectedItems?.includes("All employment types") || selectedItems?.includes("All job positions");
      setDropdownText(isAllOptionSelected ? selectedItems[0] : selectedItems?.length > 0 ? selectedItems?.join(', ') : placeholder);
    }
  }, [selectedItems, placeholder]);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        handleOutsideClick()
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [selectedItems, dropdownRef]);


  return (
    <div id={id ? id : undefined} ref={dropdownRef} className="fab-dropdown-container" onClick={onClick}>
      <div className="fab-drodown" onClick={toggleMenu} style={{ position: 'relative' }}>
        <div className={"fab-drodown-container " + (displyMobileDropdownText(selectedItems) ? 'fab-drodown-container-m' : '')} style={{ borderColor: isMenuVisible ? '#1D4ED8' : '#D4D4D4' }}>
          {displyMobileDropdownText(selectedItems) ?
            <span className='fab-dropdown-text-m'>
              <div>{placeholder}</div>
              <div className='fab-circle-badge'>{selectedItems?.length}</div>
            </span> :
            <span className="fab-dropdown-text" style={{ color: dropdownText === placeholder ? '#A1A1AA' : '#18181B' }}>
              {dropdownText}
            </span>
          }
          <svg
            className="fab-dropdown-svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.4419 13.5669C10.1979 13.811 9.80214 13.811 9.55806 13.5669L3.30806 7.31694C3.06398 7.07286 3.06398 6.67714 3.30806 6.43306C3.55214 6.18898 3.94786 6.18898 4.19194 6.43306L10 12.2411L15.8081 6.43306C16.0521 6.18898 16.4479 6.18898 16.6919 6.43306C16.936 6.67714 16.936 7.07286 16.6919 7.31694L10.4419 13.5669Z"
              fill="#18181B"
            />
          </svg>
        </div>
        {isMenuVisible && (
          <div
            className="fab-dropdown-menu"
            style={{
              width: "100%",
              position: "absolute",
              top: "100%",
              left: "0",
            }}
          >
            {menuItems.map((item, index) => (
              <label key={index} htmlFor={`chckItem${index}`} className="fab-dropdown-menu-item" onClick={(e) => e.stopPropagation()}>
                <input type="checkbox" name="" id={`chckItem${index}`} checked={selectedItems?.includes(item)} className='fab-dropdown-check' onClick={(e) => handleCheckboxClick(e, item)} />
                {item}
              </label>
            ))}
          </div>
        )
        }
      </div >
    </div >
  );
};

export default Dropdown;