import './Skills.css';

import { MAX_SKILLS_SELECTION_VALIDATION_MESSAGE, SOMETHING_WENT_WRONG_MESSAGE, TRY_UPDATE_CHANGES_AGAIN_MESSAGE, UPDATE_SUCCESS_MESSAGE } from '../../common/constants';
import { useEffect, useState } from 'react';

import Button from '../../components/button/Button';
import Loader from '../../components/loader/Loader';
import Select from 'react-select';
import Toast from '../../components/toast/Toast';
import analytics from '../../firebase';
import { getUserInfoAndToken } from '../../common/helper';
import { logEvent } from "firebase/analytics";
import { updateSkills } from '../../services/ProfileService';

const Skills = ({ user, codeTableSkillOptions }) => {
    const [displayForm, setDisplayForm] = useState(false);
    const [existingSkills, setExistingSkills] = useState([]);
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessageHeader, setErrorMessageHeader] = useState('');
    const [errorMessageDescription, setErrorMessageDescription] = useState('');
    const [successMessageHeader, setSuccessMessageHeader] = useState('');
    const [successMessageDescription, setSuccessMessageDescription] = useState('');

    const getSkillsByIds = (skillIds) => {
        const ids = skillIds?.split(',');
        const existingSkills = [];
        for (const so of codeTableSkillOptions) {
            const eSkill = so.options.filter((o) => ids?.includes(o.codeid));
            existingSkills.push(...eSkill);
        }
        return existingSkills;
    }

    useEffect(() => {
        const skillsByIds = getSkillsByIds(user?.skillids);
        setSelectedSkills(skillsByIds);
        setExistingSkills(skillsByIds);
    }, [user, codeTableSkillOptions])

    const handleClickCancel = () => {
        setSelectedSkills(existingSkills);
        setDisplayForm(false);
    }

    const resetSuccessMessages = () => {
        setSuccessMessageHeader('');
        setSuccessMessageDescription('');
    }

    const resetErrorMessages = () => {
        setErrorMessageHeader('');
        setErrorMessageDescription('');
    }

    const handleClickSave = async () => {
        try {
            resetSuccessMessages();
            resetErrorMessages();
            setIsLoading(true);

            if (selectedSkills.length > 10) {
                setErrorMessageHeader('Update error');
                setErrorMessageDescription(MAX_SKILLS_SELECTION_VALIDATION_MESSAGE);

                setDisplayForm(true);
            } else {
                const selectedSkillIds = selectedSkills.map((ss) => ss.codeid).join(',');
                const { userId, username, token } = getUserInfoAndToken();
                const response = await updateSkills(userId, username, token, selectedSkillIds);
                if (response?.code === '00') {
                    logEvent(analytics, 'profile_skills_updated', { 'userid': userId, 'skills': selectedSkillIds })
                    const skillIds = response?.data?.skillids;
                    const skillsByIds = getSkillsByIds(skillIds);
                    setSelectedSkills(skillsByIds);
                    setExistingSkills(skillsByIds);

                    setDisplayForm(false);
                    resetErrorMessages();
                    setSuccessMessageHeader(UPDATE_SUCCESS_MESSAGE);
                    setSuccessMessageDescription('Your changes to Skills has been saved!');
                } else {
                    const messages = response?.message;
                    if (messages?.length > 0) {
                        setErrorMessageHeader('Update error');
                        setErrorMessageDescription(messages.join('\n'));
                    }

                    setDisplayForm(true);
                }
            }

            setIsLoading(false);
        } catch (error) {
            console.log(`error occurred while updating the skills: ${error}`);

            setIsLoading(false);
            setDisplayForm(true);
            setErrorMessageHeader(SOMETHING_WENT_WRONG_MESSAGE);
            setErrorMessageDescription(TRY_UPDATE_CHANGES_AGAIN_MESSAGE);
        }
    }
    const handleSelect = (selectedList) => {
        setSelectedSkills(selectedList);
    }

    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: '100%',
        }),
    };

    const formatGroupLabel = (data) => (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>{data.department}</span>
        </div>
    );

    const addButtonClicked = () => {
        setDisplayForm(true)
        logEvent(analytics, 'profile_skills_initiated');
    }


    return (
        <>
            {
                (successMessageHeader && successMessageDescription) && <Toast
                    type='success'
                    header={successMessageHeader}
                    message={successMessageDescription}
                    onClose={resetSuccessMessages}
                />
            }
            {
                (errorMessageHeader && errorMessageDescription) && <Toast
                    type='error'
                    header={errorMessageHeader}
                    message={errorMessageDescription}
                    onClose={resetErrorMessages}
                />
            }
            {isLoading && <Loader></Loader>}
            {/* <div className='fb-skills-section'> */}
            <>
                <div className='fb-skills-container'>
                    <div className='fb-header-btn-wrapper'>
                        <p className='fb-section-title'>Skills</p>
                        {
                            (!displayForm && selectedSkills.length > 0) ?
                                <Button className='fb-add-btn' onClick={() => setDisplayForm(true)}>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M16.2254 1.77459C15.8593 1.40847 15.2657 1.40847 14.8996 1.77459L13.9353 2.73889L15.2611 4.06472L16.2254 3.10041C16.5915 2.7343 16.5915 2.1407 16.2254 1.77459ZM14.3772 4.9486L13.0514 3.62278L2.92573 13.7485C2.55859 14.1156 2.28871 14.5684 2.14049 15.066L1.80384 16.1962L2.934 15.8595C3.4316 15.7113 3.88442 15.4414 4.25156 15.0743L14.3772 4.9486ZM14.0157 0.890704C14.87 0.036432 16.255 0.036432 17.1093 0.890704C17.9636 1.74498 17.9636 3.13002 17.1093 3.9843L5.13544 15.9582C4.62145 16.4722 3.9875 16.85 3.29085 17.0575L1.05344 17.724C0.833504 17.7895 0.595348 17.7292 0.433074 17.5669C0.270799 17.4047 0.21051 17.1665 0.276026 16.9466L0.94251 14.7092C1.15003 14.0125 1.52785 13.3786 2.04184 12.8646L14.0157 0.890704Z" fill="#D31145" />
                                    </svg>
                                    <p className='fb-add-btn-text'>Edit</p>
                                </Button>
                                :
                                <Button className='fb-add-btn' onClick={addButtonClicked}>
                                    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                        <path fillRule='evenodd' clipRule='evenodd' d='M7 0.125C7.34518 0.125 7.625 0.404822 7.625 0.75V6.375H13.25C13.5952 6.375 13.875 6.65482 13.875 7C13.875 7.34518 13.5952 7.625 13.25 7.625H7.625V13.25C7.625 13.5952 7.34518 13.875 7 13.875C6.65482 13.875 6.375 13.5952 6.375 13.25V7.625H0.75C0.404822 7.625 0.125 7.34518 0.125 7C0.125 6.65482 0.404822 6.375 0.75 6.375H6.375V0.75C6.375 0.404822 6.65482 0.125 7 0.125Z' fill='#D31145' />
                                    </svg>
                                    <p className='fb-add-btn-text'>Add</p>
                                </Button>
                        }
                    </div>
                    <p className='fb-description'>Strengthen your profile by adding your skills.</p>
                </div>
                {
                    displayForm ?
                        <div className='fab-skills-form-container'>
                            <div className='fab-skills-form-wrapper'>
                                <div className='fab-skills-form-header'>
                                    <p className='fab-skills-form-header-text'>Skills</p>
                                </div>
                                <Select
                                    options={codeTableSkillOptions}
                                    isMulti={true}
                                    getOptionValue={(option) => option.val}
                                    getOptionLabel={(option) => option.val}
                                    placeholder='Enter or select your skills'
                                    formatGroupLabel={formatGroupLabel}
                                    styles={customStyles}
                                    onChange={handleSelect}
                                    value={selectedSkills}
                                />
                            </div>
                            <div className='fb-save-cancel-container'>
                                <Button className='fb-cancel-btn fb-cancel-btn-text' onClick={handleClickCancel}>
                                    Cancel
                                </Button>
                                <Button className='fb-save-btn fb-save-btn-text' onClick={handleClickSave}>
                                    Save
                                </Button>
                            </div>
                        </div>
                        :
                        (existingSkills.length > 0) &&
                        <ul className='fab-skills-list-container'>
                            {existingSkills.map((option, index) => (
                                <li key={index} className='fab-skills-list-wrapper'>
                                    <label className='fab-skills-list-text'>{option.val}</label>
                                </li>
                            ))}
                        </ul>
                }
                {/* </div> */}
            </>
        </>
    )
}

export default Skills;
