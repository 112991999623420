import React from 'react';
import '../../components/globals/JobShareButtons.css';

const ShareJobButtons = () => {

    return (
        <div className="fab-share-buttons-container">
            <div className="sharethis-inline-share-buttons">
            </div>
        </div>
    );
};

export default ShareJobButtons;
