import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";

// const firebaseConfig = {
//     apiKey: "AIzaSyAlJEx48Rqo6YFG9iRU4kEdZKiIBNLAcJk",
//     authDomain: "testfabjobs.firebaseapp.com",
//     projectId: "testfabjobs",
//     storageBucket: "testfabjobs.appspot.com",
//     messagingSenderId: "100927184186",
//     appId: "1:100927184186:web:2e1207b001a46fd63ac403",
//     measurementId: "G-X1SZQ39NZY"
//   };

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  
  export default analytics;