import { Navigate, Route, Routes } from 'react-router-dom';
import { FacebookProvider } from 'react-facebook';
import AppliedJobs from './containers/user-setting/AppliedJobs';
import BrowseJobs from './containers/browsejobs/BrowseJobs';
import ChangePassword from './containers/user-setting/ChangePassword';
import CompanyListing from "./containers/company/CompanyListing";
import FbSignUp from './containers/sign-in/FbSignUp';
import GoogleSignUp from './containers/sign-in/GoogleSignUp';
import ForgotPassword from './containers/sign-in/ForgotPassword';
import Homepage from './containers/homepage/Homepage';
import JobAdDetails from './containers/job-ad-details/JobAdDetails';
import Profile from './containers/profile/Profile';
import React from 'react';
import SavedJobs from './containers/user-setting/SavedJobs';
import { SecuredRoute, AuthenticatedRoute } from './components/securedroute/SecuredRoute';
import SignIn from './containers/sign-in/SignIn';
import SignUp from './containers/sign-in/SignUp';
import VerifyMobileNumber from "./containers/sign-in/VerifyMobileNumber";
import EmployerProfile from './containers/EmployerProfile/EmployerProfile';
import NotFound from './containers/notfound/NotFound';
import ResetPassword from './containers/sign-in/ResetPassword';

const isUserLoggedIn = () => {
  const isLogin = JSON.parse(localStorage.getItem('isLogin'));
  return isLogin && isLogin === true ? true : false
};


function App() {
  return (
    <FacebookProvider appId="779120065513866">
      <div>
        <Routes>
          <Route path="/" exact element={<Homepage />} />
          <Route path="/jobs/:jobId" element={<JobAdDetails />} />
          <Route path="/jobs" element={<BrowseJobs />} />
          <Route path="/companies" element={<CompanyListing />} />
          <Route path="/login" element={<AuthenticatedRoute />} >
            <Route path="/login" element={<SignIn />} />
          </Route>
          <Route path="/signup" element={<AuthenticatedRoute />} >
            <Route path="/signup" element={<SignUp />} />
          </Route>
          <Route path="/reset-password" element={<AuthenticatedRoute />} >
            <Route path="/reset-password" element={<ResetPassword />} />
          </Route>
          <Route path="/fb-signup" element={isUserLoggedIn() ? <Navigate to="/notfound" /> : <FbSignUp />} />
          <Route path="/google-signup" element={isUserLoggedIn() ? <Navigate to="/notfound" /> : <GoogleSignUp />} />
          <Route path="/verifyMobileNumber" element={<VerifyMobileNumber />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/employer/:id" element={<EmployerProfile />} />
          <Route path="/change-password" element={<SecuredRoute />} >
            <Route path="/change-password" element={<ChangePassword />} />
          </Route>
          <Route path="/applied-jobs" element={<SecuredRoute />} >
            <Route path="/applied-jobs" element={<AppliedJobs />} />
          </Route>
          <Route path="/saved-jobs" element={<SecuredRoute />} >
            <Route path="/saved-jobs" element={<SavedJobs />} />
          </Route>
          <Route path="/profile" element={< SecuredRoute redirectedTo={"/login"} />} >
            <Route path="/profile" element={<Profile />} />
          </Route>
          <Route path="/notfound" element={<NotFound />}></Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </FacebookProvider>
  );
}

export default App;