import './Resume.css'

import { RESUME_GENERATION_FAILED_MESSAGE, RESUME_UPLOAD_FAILED_MESSAGE, SOMETHING_WENT_WRONG_MESSAGE, TRY_AGAIN_MESSAGE, TRY_UPDATE_CHANGES_AGAIN_MESSAGE, UPDATE_SUCCESS_MESSAGE } from '../../common/constants';
import { deleteResume, generateResume, previewResume, uploadGeneratedResume, uploadResume } from '../../services/ProfileService';
import { useEffect, useRef, useState } from 'react';

import Button from '../../components/button/Button';
import Loader from '../../components/loader/Loader';
import Toast from '../../components/toast/Toast';
import analytics from '../../firebase';
import { getUserInfoAndToken } from '../../common/helper';
import { logEvent } from "firebase/analytics";

const ALLOWED_FILE_EXTENSIONS = 'doc, docx, .pdf, .txt';

const Resume = ({ user }) => {
    const fileInputRef = useRef(null);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [fileErrorMessage, setFileErrorMessage] = useState('');
    const [disableUploadBtn, setDisableUploadBtn] = useState(true);
    const [selectedResumeDetails, setSelectedResumeDetails] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessageHeader, setErrorMessageHeader] = useState('');
    const [errorMessageDescription, setErrorMessageDescription] = useState('');
    const [successMessageHeader, setSuccessMessageHeader] = useState('');
    const [successMessageDescription, setSuccessMessageDescription] = useState('');

    useEffect(() => {
        const rsm = user?.rsm;
        if (rsm?.length > 0) {
            setSelectedResumeDetails(rsm[0])
        }
    }, [user]);

    const handleButtonClick = (e) => {
        e.preventDefault();
        fileInputRef.current.click();
    };
    const handleFileChange = (e) => {
        if (file) {
            e.preventDefault();
            setFile(null);
        }

        const f = e.target.files[0];
        if (f) {
            setFile(f);
            setFileName(f.name);
            setDisableUploadBtn(false);
            e.target.value = null;
        }
    };

    const resetSuccessMessages = () => {
        setSuccessMessageHeader('');
        setSuccessMessageDescription('');
    }

    const resetErrorMessages = () => {
        setErrorMessageHeader('');
        setErrorMessageDescription('');
    }

    const handleResumeRemove = async () => {
        try {
            logEvent(analytics, 'profile_resume_removed');
            resetSuccessMessages();
            resetErrorMessages();
            setIsLoading(true);

            const { userId, username, token } = getUserInfoAndToken();
            const response = await deleteResume(userId, username, token, selectedResumeDetails?.rsmid);
            if (response?.code === '00') {
                setSelectedResumeDetails('');

                resetErrorMessages();
                setSuccessMessageHeader(UPDATE_SUCCESS_MESSAGE);
                setSuccessMessageDescription('Your changes to Resume has been saved!');
            } else {
                const messages = response?.message;
                if (messages?.length > 0) {
                    setErrorMessageHeader('Update error');
                    setErrorMessageDescription(messages.join('\n'));
                }
            }

            setIsLoading(false);
        } catch (error) {
            console.log(`error occurred while removing the resume: ${error}`);

            setIsLoading(false);
            setErrorMessageHeader(SOMETHING_WENT_WRONG_MESSAGE);
            setErrorMessageDescription(TRY_UPDATE_CHANGES_AGAIN_MESSAGE);
        }
    };

    const handleResumeX = (e) => {
        e.preventDefault();
        setFile(null);
        setFileName('');
        setDisableUploadBtn(true);
    }

    const handleResumeUpload = async (e) => {
        e.preventDefault();

        try {
            resetSuccessMessages();
            resetErrorMessages();
            setIsLoading(true);

            const { userId, username, token } = getUserInfoAndToken();
            logEvent(analytics, 'profile_resume_upload_initiated');
            const response = await uploadResume(userId, username, token, file);
            if (response?.isSuccess) {
                logEvent(analytics, 'profile_resume_upload_success')
                setFileName('');
                setSelectedResumeDetails({ ...response, rsmname: response?.filename });

                setDisableUploadBtn(true);
                resetErrorMessages();
                setSuccessMessageHeader(UPDATE_SUCCESS_MESSAGE);
                setSuccessMessageDescription('Your changes to Resume has been saved!');
            } else {
                const message = response?.message;
                if (message) {
                    setErrorMessageHeader('Upload error');
                    setErrorMessageDescription(message);
                    logEvent(analytics, 'profile_resume_upload_failed', { 'error_message': message });
                }

                setDisableUploadBtn(false);
            }

            setIsLoading(false);
        } catch (error) {
            console.log(`error occurred while uploading a resume: ${error}`);

            setIsLoading(false);
            setDisableUploadBtn(false);
            setErrorMessageHeader(SOMETHING_WENT_WRONG_MESSAGE);
            setErrorMessageDescription(RESUME_UPLOAD_FAILED_MESSAGE);
        }
    }

    const managePreviewResume = async (e) => {
        e.preventDefault();
        try {
            logEvent(analytics, 'profile_resume_viewed');
            resetErrorMessages();
            setIsLoading(true);

            const { userId, username, token } = getUserInfoAndToken();
            const previewResponse = await previewResume(userId, username, token, selectedResumeDetails?.rsmid);

            const byteArray = new Uint8Array(previewResponse.data);
            const blob = new Blob([byteArray], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', selectedResumeDetails?.rsmname);
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);

            setIsLoading(false);
        } catch (error) {
            console.log(`error occurred while previewing the resume: ${error}`);

            setIsLoading(false);
            setErrorMessageHeader(SOMETHING_WENT_WRONG_MESSAGE);
            setErrorMessageDescription(TRY_AGAIN_MESSAGE);
        }
    }

    const uploadGenResume = async () => {
        try {
            resetSuccessMessages();
            resetErrorMessages();
            setIsLoading(true);

            const { userId, username, token } = getUserInfoAndToken();
            const response = await uploadGeneratedResume(userId, username, token);
            if (response?.isSuccess) {
                setSelectedResumeDetails({ ...response, rsmname: response?.filename });

                resetErrorMessages();
                setSuccessMessageHeader(UPDATE_SUCCESS_MESSAGE);
                setSuccessMessageDescription('Your changes to Resume has been saved!');
            } else {
                const messages = response?.message;
                if (messages?.length > 0) {
                    setErrorMessageHeader('Update error');
                    setErrorMessageDescription(messages.join('\n'));
                }
            }

            setIsLoading(false);
        } catch (error) {
            console.log(`error occurred while uploading the generated resume: ${error}`);

            setIsLoading(false);
            setErrorMessageHeader(SOMETHING_WENT_WRONG_MESSAGE);
            setErrorMessageDescription(RESUME_GENERATION_FAILED_MESSAGE);
        }
    }

    const handleGenerateMyResume = async () => {
        let resumeGenerationSuccessful = true;

        try {
            resetErrorMessages();
            setIsLoading(true);

            const { userId, username, token } = getUserInfoAndToken();
            logEvent(analytics, 'profile_resume_generate_initiated');
            const response = await generateResume(userId, username, token);
            if (response?.code !== '00') {
                const messages = response?.message;
                if (messages?.length > 0) {
                    setErrorMessageHeader('Update error');
                    setErrorMessageDescription(messages.join('\n'));
                    logEvent(analytics, 'profile_resume_generate_failed', { 'error_message': errorMessageDescription })
                }

                resumeGenerationSuccessful = false;
            }
            logEvent(analytics, 'profile_resume_generate_success')
            setIsLoading(false);
        } catch (error) {
            console.log(`error occurred while generating my resume: ${error}`);

            setIsLoading(false);
            setErrorMessageHeader(SOMETHING_WENT_WRONG_MESSAGE);
            setErrorMessageDescription(RESUME_GENERATION_FAILED_MESSAGE);
        };

        if (resumeGenerationSuccessful) {
            await uploadGenResume();
        }
    }

    return (
        <>
            {
                (successMessageHeader && successMessageDescription) && <Toast
                    type='success'
                    header={successMessageHeader}
                    message={successMessageDescription}
                    onClose={resetSuccessMessages}
                />
            }
            {
                (errorMessageHeader && errorMessageDescription) && <Toast
                    type='error'
                    header={errorMessageHeader}
                    message={errorMessageDescription}
                    onClose={resetErrorMessages}
                />
            }
            {isLoading && <Loader></Loader>}
            <div className='fab-resume-container'>
                <label className='fab-resume-header-wrapper'>
                    <span className='fab-resume-header'>Resume</span>
                </label>
                <label className='fab-resume-desc'>Uploading a resume will help the employer to know more about you.</label>
            </div>
            {
                (selectedResumeDetails) &&
                <div className='fab-resume-result-container'>
                    <div className='fab-resume-svg-text'>
                        <div className='fab-resume-list-file-icon-wrapper'>
                            <svg width="22" height="28" viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M2.5 0C1.11929 0 0 1.11929 0 2.5V25.5C0 26.8807 1.11929 28 2.5 28H19.5C20.8807 28 22 26.8807 22 25.5V15C22 12.2386 19.7614 10 17 10H14.5C13.1193 10 12 8.88071 12 7.5V5C12 2.23858 9.76142 0 7 0H2.5ZM5 18C5 17.4477 5.44772 17 6 17H16C16.5523 17 17 17.4477 17 18C17 18.5523 16.5523 19 16 19H6C5.44772 19 5 18.5523 5 18ZM6 21C5.44772 21 5 21.4477 5 22C5 22.5523 5.44772 23 6 23H11C11.5523 23 12 22.5523 12 22C12 21.4477 11.5523 21 11 21H6Z" fill="#3F3F46" />
                                <path d="M12.2949 0.421206C13.3573 1.64864 14 3.24926 14 5V7.5C14 7.77614 14.2239 8 14.5 8H17C18.7507 8 20.3514 8.64272 21.5788 9.70508C20.395 5.17317 16.8268 1.60503 12.2949 0.421206Z" fill="#3F3F46" />
                            </svg>
                        </div>
                        <div className='fab-resume-list-file-name-wrapper'>
                            <a href='' className='fab-resume-list-file-name-text' onClick={managePreviewResume}>{selectedResumeDetails?.rsmname}</a>
                        </div>
                    </div>
                    <Button className='fab-resume-list-remove-wrapper' onClick={handleResumeRemove} >
                        <svg className='fab-resume-list-remove-icon' width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M8.0001 1.5C7.54373 1.5 7.08908 1.5073 6.63626 1.52178C6.00742 1.5419 5.5001 2.06994 5.5001 2.73182V2.82569C6.32707 2.77547 7.16065 2.75 8.0001 2.75C8.83955 2.75 9.67313 2.77547 10.5001 2.82569V2.73182C10.5001 2.06994 9.99279 1.5419 9.36394 1.52178C8.91113 1.5073 8.45648 1.5 8.0001 1.5ZM11.7501 2.92081V2.73182C11.7501 1.4281 10.7397 0.315152 9.40391 0.272423C8.93778 0.257512 8.46981 0.25 8.0001 0.25C7.5304 0.25 7.06242 0.257512 6.5963 0.272423C5.26054 0.315152 4.2501 1.4281 4.2501 2.73182V2.92081C3.4545 2.99364 2.66548 3.08942 1.88373 3.20748C1.59429 3.25119 1.30584 3.29796 1.01843 3.34775C0.678313 3.40666 0.450358 3.73014 0.509273 4.07025C0.568189 4.41036 0.891665 4.63832 1.23178 4.5794C1.28978 4.56936 1.34783 4.55944 1.40592 4.54964L2.24377 15.4417C2.34397 16.7442 3.43007 17.75 4.73641 17.75H11.2638C12.5701 17.75 13.6562 16.7442 13.7564 15.4417L14.5943 4.54964C14.6524 4.55944 14.7104 4.56936 14.7684 4.5794C15.1085 4.63832 15.432 4.41036 15.4909 4.07025C15.5498 3.73014 15.3219 3.40666 14.9818 3.34775C14.6944 3.29796 14.4059 3.25119 14.1165 3.20748C13.3347 3.08942 12.5457 2.99364 11.7501 2.92081ZM13.3551 4.36101C12.6023 4.25866 11.8427 4.17759 11.0769 4.11841C10.0618 4.03996 9.03566 4 8.0001 4C6.96455 4 5.93845 4.03996 4.92326 4.11841C4.15748 4.17759 3.39789 4.25866 2.6451 4.36101L3.49009 15.3459C3.54019 15.9971 4.08324 16.5 4.73641 16.5H11.2638C11.917 16.5 12.46 15.9971 12.5101 15.3459L13.3551 4.36101ZM5.69243 5.87546C6.03735 5.8622 6.32772 6.13106 6.34099 6.47598L6.62945 13.976C6.64271 14.3209 6.37385 14.6113 6.02893 14.6245C5.68401 14.6378 5.39364 14.3689 5.38037 14.024L5.09191 6.52402C5.07864 6.1791 5.3475 5.88873 5.69243 5.87546ZM10.3078 5.87546C10.6527 5.88873 10.9216 6.1791 10.9083 6.52402L10.6198 14.024C10.6066 14.3689 10.3162 14.6378 9.97127 14.6245C9.62635 14.6113 9.35749 14.3209 9.37076 13.976L9.65922 6.47598C9.67248 6.13106 9.96285 5.8622 10.3078 5.87546Z" fill="#D31145" />
                        </svg>
                        <span className='fab-resume-list-remove-text'>Remove</span>
                    </Button>
                </div>
            }
            <div className='fab-upload-resume-container'>
                <label className='fab-upload-resume-header'>
                    Upload your resume
                </label>
                <label className='fab-upload-resume-desc'>
                    Valid file format: .doc, .docx, .pdf, .txt
                    Maximum file size: 18MB
                </label>
                <div className='fab-upload-resume-wrapper'>
                    <div className='fab-upload-resume-input-wrapper'>
                        <input type='file' ref={fileInputRef} onChange={handleFileChange} accept={ALLOWED_FILE_EXTENSIONS} />
                        {
                            (fileName && file?.name) ?
                                <span className='fab-resume-select-file-input fab-resume-select-file-input-text'>
                                    <div className='fab-selected-file-container'>
                                        <svg className='fab-selected-file-icon' width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M1.8125 0.75C1.29473 0.75 0.875 1.16973 0.875 1.6875V10.3125C0.875 10.8303 1.29473 11.25 1.8125 11.25H8.1875C8.70527 11.25 9.125 10.8303 9.125 10.3125V6.375C9.125 5.33947 8.28553 4.5 7.25 4.5H6.3125C5.79473 4.5 5.375 4.08027 5.375 3.5625V2.625C5.375 1.58947 4.53553 0.75 3.5 0.75H1.8125ZM2.75 7.5C2.75 7.29289 2.91789 7.125 3.125 7.125H6.875C7.08211 7.125 7.25 7.29289 7.25 7.5C7.25 7.70711 7.08211 7.875 6.875 7.875H3.125C2.91789 7.875 2.75 7.70711 2.75 7.5ZM3.125 8.625C2.91789 8.625 2.75 8.79289 2.75 9C2.75 9.20711 2.91789 9.375 3.125 9.375H5C5.20711 9.375 5.375 9.20711 5.375 9C5.375 8.79289 5.20711 8.625 5 8.625H3.125Z" fill="#3F3F46" />
                                            <path d="M5.4856 0.907952C5.88398 1.36824 6.125 1.96847 6.125 2.625V3.5625C6.125 3.66605 6.20895 3.75 6.3125 3.75H7.25C7.90653 3.75 8.50676 3.99102 8.96705 4.3894C8.52311 2.68994 7.18506 1.35189 5.4856 0.907952Z" fill="#3F3F46" />
                                        </svg>
                                        <label className='fab-selected-file-name'>{file.name}</label>
                                        <svg className='fab-selected-file-remove-icon' width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleResumeX} >
                                            <path fillRule="evenodd" clipRule="evenodd" d="M0.734835 0.734835C0.881282 0.588388 1.11872 0.588388 1.26517 0.734835L4 3.46967L6.73484 0.734835C6.88128 0.588389 7.11872 0.588389 7.26517 0.734835C7.41161 0.881282 7.41161 1.11872 7.26517 1.26517L4.53033 4L7.26517 6.73483C7.41161 6.88128 7.41161 7.11872 7.26517 7.26517C7.11872 7.41161 6.88128 7.41161 6.73484 7.26517L4 4.53033L1.26517 7.26517C1.11872 7.41161 0.881282 7.41161 0.734835 7.26517C0.588388 7.11872 0.588388 6.88128 0.734835 6.73483L3.46967 4L0.734835 1.26517C0.588388 1.11872 0.588388 0.881282 0.734835 0.734835Z" fill="#3F3F46" />
                                        </svg>
                                    </div>
                                </span> :
                                <span className='fab-resume-select-file-input fab-resume-select-file-input-text'>
                                    Select file
                                </span>
                        }
                        <Button className='fab-resume-browse-btn-wrapper' onClick={handleButtonClick} >
                            <svg className='fab-resume-browse-icon' width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M2 1C1.44772 1 1 1.44772 1 2V4.26756C1.29417 4.09739 1.63571 4 2 4H12C12.3643 4 12.7058 4.09739 13 4.26756V4C13 3.44772 12.5523 3 12 3H8.41421C8.01639 3 7.63486 2.84197 7.35355 2.56066L5.93934 1.14645C5.84557 1.05268 5.71839 1 5.58579 1H2ZM13 6C13 5.44772 12.5523 5 12 5H2C1.44772 5 1 5.44772 1 6V10C1 10.5523 1.44772 11 2 11H12C12.5523 11 13 10.5523 13 10V6ZM14 10C14 11.1046 13.1046 12 12 12H2C0.895431 12 0 11.1046 0 10V2C0 0.89543 0.895431 0 2 0H5.58579C5.98361 0 6.36514 0.158035 6.64645 0.43934L8.06066 1.85355C8.15443 1.94732 8.28161 2 8.41421 2H12C13.1046 2 14 2.89543 14 4V10Z" fill="#D31145" />
                            </svg>
                            <span className='fab-resume-browse-btn'>Browse</span>
                        </Button>
                    </div>
                    <Button className={`fab-upload-resume-btn ${disableUploadBtn ? 'fab-upload-resume-btn-disabled' : ''}`} onClick={handleResumeUpload} isDisabled={disableUploadBtn} >
                        <span className='fab-upload-resume-btn-text'>Upload my resume</span>
                    </Button>
                </div>
                {fileErrorMessage && <label className='fab-form-field-error-message'>{fileErrorMessage}</label>}
            </div>
            <div className='fab-upload-resume-divider-wrapper'>
                <div className='fab-upload-resume-divider' />
                <label className='fab-upload-resume-or'>or</label>
                <div className='fab-upload-resume-divider' />
            </div>
            <div className='fab-generate-resume-wrapper'>
                <label className='fab-generate-resume-text'>
                    Generate your own resume with the details in this profile.
                </label>
                <Button className='fab-generate-resume-btn' onClick={handleGenerateMyResume} >
                    <span className='fab-generate-resume-btn-text'>Generate my resume</span>
                </Button>
            </div>
        </>
    )
}

export default Resume;
