import React from 'react';
import '../../components/globals/ProgressiveApplication.css';

const StepWizard = ({ stepActive, totalSteps }) => {
    return (
        <div className="application-wizard-wrapper">
            <div className="wizard-progress">
                {[...Array(totalSteps)].map((_, index) => (
                    <div key={index} className={`step ${stepActive === index + 1 ? 'in-progress' : stepActive > index + 1 ? 'complete' : ''}`}>
                        <div className="wizard-circle">
                            <div></div>
                        </div>
                        <span>Step {index + 1}</span>
                    </div>))}
            </div>
        </div>
    )
}

export default StepWizard;
