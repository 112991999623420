import React from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import Button from '../../components/button/Button';
import "../../components/globals/NotFound.css";

const NotFound = () => {
    const navigate = useNavigate();

    const handleOnclick = () => {
        navigate('/')
    }

    return (
        <div className='fb-not-found-container'>
            <Header />
            <div className='fb-not-found-parent'>
                <div className='fb-not-found-content fab-container'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200" fill="none">
                        <path d="M120 185.002C68.75 198.371 -1.52588e-05 167.502 9.99998 103.752C20 40.0014 46.25 1.25166 105 38.6493C163.75 76.0469 190 81.7086 190 113.752C190 145.795 171.25 171.632 120 185.002Z" fill="#FAFAFA" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M125.932 37.5H61.7857C56.6574 37.5 52.5 41.6574 52.5 46.7857V100.129C52.5 101.83 54.2702 102.954 55.8099 102.229L69.0945 95.9776C70.3503 95.3867 71.8046 95.3885 73.0589 95.9826L92.2061 105.052C92.8208 105.344 93.5322 105.35 94.1525 105.071L114.434 95.9447C115.797 95.3313 117.372 95.4116 118.665 96.1605L136.897 106.716C137.675 107.166 138.642 107.127 139.381 106.615L144.357 103.17C144.983 102.737 145.357 102.023 145.357 101.262V59.9832L125.932 37.5ZM141.714 116.294C143.254 115.228 145.357 116.33 145.357 118.203V158.214C145.357 163.343 141.2 167.5 136.071 167.5H61.7857C56.6574 167.5 52.5 163.343 52.5 158.214V114.83C52.5 114.318 52.8531 113.883 53.3162 113.665L70.0732 105.78C70.7011 105.484 71.4283 105.485 72.0554 105.782L91.1374 114.821C92.3668 115.403 93.7897 115.417 95.0302 114.859L114.988 105.878C115.669 105.571 116.457 105.611 117.104 105.986L136.067 116.964C137.622 117.865 139.558 117.787 141.036 116.764L141.714 116.294Z" fill="white" />
                        <path d="M144.196 159.375H53.6606C54.0475 161.696 55.9821 166.339 60.6249 166.339H136.071C141.643 166.339 143.809 161.696 144.196 159.375Z" fill="#E5E5E5" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M69.0945 95.9776C70.3503 95.3867 71.8046 95.3885 73.0589 95.9826L92.2061 105.052C92.8208 105.344 93.5322 105.35 94.1525 105.071L114.434 95.9447C115.797 95.3313 117.372 95.4116 118.665 96.1605L136.897 106.716C137.675 107.166 138.642 107.127 139.381 106.615L144.357 103.17C144.983 102.737 145.357 102.023 145.357 101.262V59.9832L125.932 37.5H61.7857C56.6574 37.5 52.5 41.6574 52.5 46.7857V100.129C52.5 101.83 54.2702 102.954 55.8099 102.229L69.0945 95.9776ZM124.87 39.8214H61.7857C57.9394 39.8214 54.8214 42.9394 54.8214 46.7857V100.129L68.106 93.8772C69.9897 92.9907 72.1712 92.9935 74.0527 93.8847L93.1998 102.954L113.481 93.8277C115.526 92.9077 117.888 93.0282 119.829 94.1515L138.06 104.706L143.036 101.262V60.8472L124.87 39.8214ZM53.3162 113.665C52.8531 113.883 52.5 114.318 52.5 114.83V158.214C52.5 163.343 56.6574 167.5 61.7857 167.5H136.071C141.2 167.5 145.357 163.343 145.357 158.214V118.203C145.357 116.33 143.254 115.228 141.714 116.294L141.036 116.764C139.558 117.787 137.622 117.865 136.067 116.964L117.104 105.986C116.457 105.611 115.669 105.571 114.988 105.878L95.0302 114.859C93.7897 115.417 92.3668 115.403 91.1374 114.821L72.0554 105.782C71.4283 105.485 70.7011 105.484 70.0732 105.78L53.3162 113.665ZM91.1374 114.821L90.1437 116.919L71.0616 107.88L54.8214 115.523V158.214C54.8214 162.061 57.9394 165.179 61.7857 165.179H136.071C139.918 165.179 143.036 162.061 143.036 158.214L143.036 118.203L142.357 118.672C140.14 120.207 137.237 120.324 134.903 118.974L115.941 107.995L95.9829 116.976C94.1221 117.813 91.9877 117.792 90.1437 116.919L91.1374 114.821Z" fill="#A1A1AA" />
                        <path d="M125.189 53.4587V39.8203L143.035 60.423H132.154C128.307 60.423 125.189 57.305 125.189 53.4587Z" fill="#E5E5E5" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M124.029 53.4587V39.8203H126.35V53.4587C126.35 56.6639 128.949 59.2623 132.154 59.2623H143.036V61.5837H132.154C127.666 61.5837 124.029 57.946 124.029 53.4587Z" fill="#A1A1AA" />
                        <path d="M139.957 92.461C141.807 89.8515 145.68 89.8515 147.531 92.461L169.414 123.319C171.595 126.393 169.396 130.647 165.627 130.647H121.86C118.091 130.647 115.893 126.393 118.073 123.319L139.957 92.461Z" fill="#D31145" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M143.744 100.18C142.462 100.18 141.422 101.219 141.422 102.501V114.108C141.422 115.39 142.462 116.43 143.744 116.43C145.026 116.43 146.065 115.39 146.065 114.108V102.501C146.065 101.219 145.026 100.18 143.744 100.18ZM143.744 123.394C145.026 123.394 146.065 122.355 146.065 121.073C146.065 119.79 145.026 118.751 143.744 118.751C142.462 118.751 141.422 119.79 141.422 121.073C141.422 122.355 142.462 123.394 143.744 123.394Z" fill="white" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M27.9335 141.789C28.5118 142.367 29.4494 142.367 30.0277 141.789L33.67 138.147L37.1283 141.605C37.7066 142.183 38.6442 142.183 39.2225 141.605C39.8008 141.027 39.8008 140.089 39.2225 139.511L35.7642 136.052L39.1027 132.714C39.6811 132.136 39.6811 131.198 39.1027 130.62C38.5244 130.041 37.5868 130.041 37.0085 130.62L33.67 133.958L30.1475 130.436C29.5692 129.857 28.6316 129.857 28.0533 130.436C27.475 131.014 27.475 131.952 28.0533 132.53L31.5757 136.052L27.9335 139.695C27.3552 140.273 27.3552 141.211 27.9335 141.789Z" fill="#D4D4D4" />
                        <path d="M168.765 39.3824C168.765 43.1834 165.683 46.2647 161.882 46.2647C158.081 46.2647 155 43.1834 155 39.3824C155 35.5813 158.081 32.5 161.882 32.5C165.683 32.5 168.765 35.5813 168.765 39.3824Z" fill="#E5E5E5" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M30 49.1667C33.6819 49.1667 36.6667 46.1819 36.6667 42.5C36.6667 38.8181 33.6819 35.8333 30 35.8333C26.3181 35.8333 23.3333 38.8181 23.3333 42.5C23.3333 46.1819 26.3181 49.1667 30 49.1667ZM30 52.5C35.5228 52.5 40 48.0228 40 42.5C40 36.9772 35.5228 32.5 30 32.5C24.4772 32.5 20 36.9772 20 42.5C20 48.0228 24.4772 52.5 30 52.5Z" fill="#E5E5E5" />
                        <circle cx="164.588" cy="167.088" r="4.58823" fill="#E5E5E5" />
                    </svg>
                </div>
                <div className='fb-not-found-text-message'>
                    <h2 className='fab-not-found-header'>404 Error</h2>
                    <div className='fab-not-found-text'>Uh oh! It appears the page you seek doesn't exist.</div>
                    <div className='fab-not-found-text'>You can click the button below to return back to homepage or report this 404 error to Customer Support email <a href="mailto:customercare@fastjobs.sg" className="fab-not-found-customer-care">customercare@fastjobs.sg</a>.</div>
                </div>
                <div className='fab-not-found-button'>
                    <Button variant="primary" size="lg" className='fab-verify-mobile-send-button' onClick={handleOnclick}>Return to homepage</Button>
                </div>
            </div>
            <Footer />
        </div >
    )
}

export default NotFound;