import '../../components/globals/SignIn.css';

import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { fbLoginRequest, getFbUserDetails, googleLogin, login } from '../../services/UserService';

import Button from '../../components/button/Button';
import Header from '../../components/header/Header';
import Loader from '../../components/loader/Loader';
import { LoginButton } from 'react-facebook';
import { SignInErrorMessageMapping } from '../../common/constants';
import TextField from '../../components/textfield/Textfield';
import Toast from '../../components/toast/Toast';
import analytics from '../../firebase';
import { isEmpty } from '../../common/helper';
import { logEvent } from "firebase/analytics";
import { useGoogleLogin } from '@react-oauth/google'

const toastInitialState = {
    type: '',
    header: '',
    message: ''
}

const SignIn = () => {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastProps, setToastProps] = useState(toastInitialState)
    const navigate = useNavigate();

    useEffect(() => {
        logEvent(analytics, 'login_screen_viewed');
    }, []);

    const handleToastClose = () => {
        setShowToast(false)
        setToastProps(toastInitialState)
    }

    const FbSuccess = async (response) => {
        setShowLoader(true);
        //get response from the API
        try {
            setShowLoader(true);
            logEvent(analytics, 'login_initiated', { 'click_source': 'facebook' });
            const fbTokenResponse = await getFbUserDetails(response.authResponse.accessToken)
            const email = fbTokenResponse.data.usrm;
            //new login flow
            const fbLoginResponse = await fbLoginRequest(fbTokenResponse.data.id, response.authResponse.accessToken, email);
            if ((fbLoginResponse.data.isSuccess === 1 && fbLoginResponse.data.code === "2" && fbLoginResponse.data.data?.length === 0) || fbLoginResponse.data.code === "9") {
                setShowLoader(false);
                navigate("/fb-signup", { state: { fbAccessToken: response.authResponse.accessToken, fbId: fbTokenResponse.data.id, email: email, name: fbTokenResponse.data.name } })
            }
            else if (fbLoginResponse.data.code === "00") {
                const data = fbLoginResponse.data.data[0];
                const userInfo = {
                    'userName': data.fullm,
                    'disName': data.dispm,
                    'userEmail': data.usrm,
                    'userId': data.usrid,
                    'pic': data?.pic,
                }
                localStorage.setItem('user', JSON.stringify(userInfo));
                localStorage.setItem('isLogin', 'true');
                localStorage.setItem('token', data.t);
                localStorage.setItem('fromLoginSignup', 'true');
                logEvent(analytics, 'login_success', { 'click_source': 'facebook', 'userid': userInfo.userId });
                setShowLoader(false);
                navigate('/jobs')
            }
            else {
                setShowLoader(false);
                logEvent(analytics, 'login_failed', { 'error_message': 'Something went wrong while trying to sign-in with facebook.' });
                setErrorMessage('Something went wrong while trying to sign-in with facebook.');
            }

            setShowLoader(false);
        }
        catch (error) {
            setPassword("")
            setShowLoader(false)
            const errorResponse = error?.response
            let errorMessage = errorResponse?.data?.message[0] ? SignInErrorMessageMapping[errorResponse?.data?.message[0]] : "Invalid credentials"
            setErrorMessage(errorMessage)
            logEvent(analytics, 'login_failed', { 'error_message': errorMessage });
        }
    }

    const fbFailure = async (error) => {
    }

    const googleL = useGoogleLogin({
        async onSuccess(response) {
            try {
                setShowLoader(true);
                logEvent(analytics, 'login_initiated', { 'click_source': 'google' });
                //get response from the API
                const apiResponse = await googleLogin(response.access_token);
                setShowLoader(false);
                if (apiResponse?.data?.isSuccess === 1 && apiResponse?.data?.code === "2" && apiResponse.data.data?.length === 0) {
                    navigate("/google-signup", { state: { accessToken: response.access_token } })
                }
                else if (apiResponse?.data?.isSuccess === 1 && (apiResponse?.data?.code === "00" || apiResponse?.data?.code === "6")) {
                    const data = apiResponse.data.data[0];
                    const userInfo = {
                        'userName': data.fullm,
                        'userEmail': data.usrm,
                        'userId': data.usrid,
                        'disName': data?.dispm,
                        'pic': data?.pic,
                    }
                    localStorage.setItem('isLogin', 'true');
                    localStorage.setItem('user', JSON.stringify(userInfo));
                    localStorage.setItem('token', data.t);
                    localStorage.setItem('fromLoginSignup', 'true');
                    logEvent(analytics, 'login_success', { 'click_source': 'google', 'userid': userInfo.userId });
                    setShowLoader(false);
                    navigate("/jobs");
                }
                else {
                    logEvent(analytics, 'login_failed', { 'error_message': 'Something went wrong while trying to sign-in with google.' });
                    setErrorMessage('Something went wrong while trying to sign-in with google.');
                }
            }
            catch (error) {
                setPassword("")
                setShowLoader(false)
                const errorResponse = error.response
                let errorMessage = errorResponse.data.message[0] ? SignInErrorMessageMapping[errorResponse.data.message[0]] : "Invalid credentials"
                setErrorMessage(errorMessage)
                logEvent(analytics, 'login_failed', { 'error_message': errorMessage });
            }
        },
        onError() {
            setErrorMessage('Something went wrong while trying to sign-in with google.');
        },
    });

    const handleUsernameChange = (event) => {
        event.preventDefault();
        setErrorMessage('')
        setUserName(event.target.value)
    }

    const handlePasswordChange = (event) => {
        event.preventDefault();
        setErrorMessage('')
        setPassword(event.target.value)
    }

    const handleLogin = () => {
        setErrorMessage('')
        if (isEmpty(userName) && isEmpty(password)) {
            setErrorMessage('Fields should not be empty')
        }
        else if (isEmpty(password)) {
            setErrorMessage('Password should not be empty')
        }
        else if (isEmpty(userName)) {
            setErrorMessage('Email should not be empty')
        }
        else {
            onLogin()
        }
    }


    const onLogin = async () => {
        try {
            setShowLoader(true)
            logEvent(analytics, 'login_initiated', { 'click_source': 'email' });
            const response = await login(userName, password)
            setShowLoader(false)
            setErrorMessage('')
            if (response?.data?.code === "00") {
                const data = response?.data?.data[0];
                const userInfo = {
                    'userName': data?.fullm,
                    'disName': data?.dispm,
                    'userEmail': userName,
                    'userId': data?.usrid,
                    'pic': data?.pic,
                }
                localStorage.setItem('isLogin', 'true')
                localStorage.setItem('token', data?.t)
                localStorage.setItem('user', JSON.stringify(userInfo))
                localStorage.setItem('fromLoginSignup', 'true');
                logEvent(analytics, 'login_success', { 'click_source': 'email', 'userid': userInfo.userId });
                navigate('/jobs');
            }
            else {
                logEvent(analytics, 'login_failed', { 'error_message': response?.data?.message[0] });
                setErrorMessage(response?.data?.message[0])
            }
        } catch (error) {
            setPassword("")
            setShowLoader(false)
            const errorResponse = error?.response
            let errorMessage = (errorResponse?.data?.message && errorResponse?.data?.message[0]) ? SignInErrorMessageMapping[errorResponse?.data?.message[0]] : "Invalid email and/or password"
            setErrorMessage(errorMessage)
            logEvent(analytics, 'login_failed', { 'error_message': errorMessage });
        }
    }

    useEffect(() => {
        const redirectedResetPassword = JSON.parse(localStorage.getItem('FromPasswordReset'));
        if (redirectedResetPassword) {
            setShowToast(true);
            setToastProps({
                type: 'success',
                header: 'Password reset successfull',
                message: 'You can now log in using your new password.'
            })
            localStorage.removeItem('FromPasswordReset');
        }
    }, []);

    return (
        <div>
            <Header />
            {showLoader && <Loader />}
            {showToast && <Toast type={toastProps.type} header={toastProps.header} message={toastProps.message} onClose={handleToastClose} />}
            <div className='fab-sign-in-container'>
                <div className='fab-sign-in'>
                    <div className='fab-sign-header'>
                        <h3 className='fab-sign-title'>Login</h3>
                        <p className='fab-sign-poweredby'>powered by <span>Fast</span><span>Jobs</span></p>
                    </div>
                    <div className='fab-sign-form'>
                        <div className='fab-sign-socials'>
                            <LoginButton style={{ border: 0 }}
                                scope="public_profile,email"
                                onError={fbFailure}
                                onSuccess={FbSuccess}>
                                <Button className='fab-sign-fb' size='md'>
                                    <svg width="20" height="20" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_1584_36113)">
                                            <path d="M24.5 12.5C24.5 5.87258 19.1274 0.5 12.5 0.5C5.87258 0.5 0.5 5.87258 0.5 12.5C0.5 18.4895 4.8882 23.454 10.625 24.3542V15.9688H7.57812V12.5H10.625V9.85625C10.625 6.84875 12.4166 5.1875 15.1576 5.1875C16.4701 5.1875 17.8438 5.42188 17.8438 5.42188V8.375H16.3306C14.84 8.375 14.375 9.30008 14.375 10.25V12.5H17.7031L17.1711 15.9688H14.375V24.3542C20.1118 23.454 24.5 18.4895 24.5 12.5Z" fill="white" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1584_36113">
                                                <rect width="24" height="24" fill="white" transform="translate(0.5 0.5)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    Login with Facebook
                                </Button>
                            </LoginButton>
                            <Button className='fab-sign-google' onClick={() => googleL()} size='md'>
                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_1584_36118)">
                                        <path d="M24.266 12.7764C24.266 11.9607 24.1999 11.1406 24.0588 10.3381H12.74V14.9591H19.2217C18.9528 16.4494 18.0885 17.7678 16.823 18.6056V21.6039H20.69C22.9608 19.5139 24.266 16.4274 24.266 12.7764Z" fill="#4285F4" />
                                        <path d="M12.7401 24.5008C15.9766 24.5008 18.7059 23.4382 20.6945 21.6039L16.8276 18.6055C15.7517 19.3375 14.3627 19.752 12.7445 19.752C9.61388 19.752 6.95946 17.6399 6.00705 14.8003H2.0166V17.8912C4.05371 21.9434 8.2029 24.5008 12.7401 24.5008V24.5008Z" fill="#34A853" />
                                        <path d="M6.00253 14.8003C5.49987 13.3099 5.49987 11.6961 6.00253 10.2057V7.11481H2.01649C0.31449 10.5056 0.31449 14.5004 2.01649 17.8912L6.00253 14.8003V14.8003Z" fill="#FBBC04" />
                                        <path d="M12.7401 5.24966C14.4509 5.2232 16.1044 5.86697 17.3434 7.04867L20.7695 3.62262C18.6001 1.5855 15.7208 0.465534 12.7401 0.500809C8.2029 0.500809 4.05371 3.05822 2.0166 7.11481L6.00264 10.2058C6.95065 7.36173 9.60947 5.24966 12.7401 5.24966V5.24966Z" fill="#EA4335" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1584_36118">
                                            <rect width="24" height="24" fill="white" transform="translate(0.5 0.5)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                Login with Google
                            </Button>
                        </div>
                        <div className='fab-sign-divider'>
                            <span></span>
                            <span>or</span>
                            <span></span>
                        </div>
                        <div className='fab-sign-fields'>
                            {/* Error Alert */}
                            {!isEmpty(errorMessage) && <div className='fab-sign-error-alert'>
                                <svg svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M11.0823 3.12742C10.6012 2.29363 9.39783 2.29363 8.91679 3.12742L2.78818 13.7503C2.30741 14.5837 2.90884 15.625 3.87091 15.625H16.1281C17.0902 15.625 17.6916 14.5837 17.2109 13.7503L11.0823 3.12742ZM7.83406 2.50277C8.79613 0.835178 11.2029 0.835177 12.165 2.50277L18.2936 13.1257C19.2551 14.7924 18.0523 16.875 16.1281 16.875H3.87091C1.94677 16.875 0.743912 14.7924 1.70545 13.1257L7.83406 2.50277ZM9.99952 6.87499C10.3447 6.87499 10.6245 7.15481 10.6245 7.49999V10.625C10.6245 10.9702 10.3447 11.25 9.99952 11.25C9.65434 11.25 9.37452 10.9702 9.37452 10.625V7.49999C9.37452 7.15481 9.65434 6.87499 9.99952 6.87499ZM9.37452 13.125C9.37452 12.7798 9.65434 12.5 9.99952 12.5H10.0058C10.3509 12.5 10.6308 12.7798 10.6308 13.125V13.1312C10.6308 13.4764 10.3509 13.7562 10.0058 13.7562H9.99952C9.65434 13.7562 9.37452 13.4764 9.37452 13.1312V13.125Z" fill="#B91C1C" />
                                </svg>
                                <span className='fab-sign-single-error'>{errorMessage}</span>
                            </div>}
                            <div className='fab-sign-email'>
                                <label>Email</label>
                                <TextField type='email' placeholder='email@address.com' value={userName} onChange={handleUsernameChange} />
                            </div>
                            <div className='fab-sign-password'>
                                <label>Password</label>
                                <TextField placeholder='Enter your password' isPassword={true} value={password} onChange={handlePasswordChange} />
                                <div className='fab-sign-forgot'>
                                    <Link to="/forgot-password" className='fab-sign-forgot-link'>Forgot password?</Link>
                                </div>
                            </div>
                            <Button variant="primary" size="lg" className='fab-sign-btn' onClick={handleLogin}>Login</Button>
                        </div>
                    </div>
                    <span className='fab-sign-up'>
                        Don’t have an account?
                        <Link to="/signup" className='fab-sign-up-link'>Sign up now!</Link>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default SignIn;