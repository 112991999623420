import './Carousel.css';

import { useEffect, useState } from 'react';

import analytics from '../../firebase';
import { logEvent } from 'firebase/analytics';

const Carousel = ({ slides }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const goToSlide = (index) => {
        setCurrentIndex(index);
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
    };

    useEffect(() => {
        const intervalId = setInterval(handleNext, 20000);

        return () => clearInterval(intervalId);
    }, [currentIndex, slides]);

    const onClickImage = (imageUrl) => {
        logEvent(analytics, 'banner_clicked', { 'image_url': imageUrl });
    }

    return (
        <div className="fab-container">
            <div className="fab-carousel-container">
                {slides.length > 1 && <>
                    <div className='fab-carousel-left-arrow' onClick={() => handlePrev()}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M7.71967 12.5303C7.42678 12.2374 7.42678 11.7626 7.71967 11.4697L15.2197 3.96967C15.5126 3.67678 15.9874 3.67678 16.2803 3.96967C16.5732 4.26256 16.5732 4.73744 16.2803 5.03033L9.31066 12L16.2803 18.9697C16.5732 19.2626 16.5732 19.7374 16.2803 20.0303C15.9874 20.3232 15.5126 20.3232 15.2197 20.0303L7.71967 12.5303Z" fill="white" />
                        </svg>
                    </div>
                    <div className='fab-carousel-right-arrow' onClick={() => handleNext()}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M16.2803 11.4697C16.5732 11.7626 16.5732 12.2374 16.2803 12.5303L8.78033 20.0303C8.48744 20.3232 8.01256 20.3232 7.71967 20.0303C7.42678 19.7374 7.42678 19.2626 7.71967 18.9697L14.6893 12L7.71967 5.03033C7.42678 4.73743 7.42678 4.26256 7.71967 3.96967C8.01257 3.67678 8.48744 3.67678 8.78033 3.96967L16.2803 11.4697Z" fill="white" />
                        </svg>
                    </div>
                </>}
                {
                    (slides[currentIndex]?.imageUrl && slides[currentIndex]?.clickable) ? (
                        <div className='fab-carousel-wrapper'>
                            <a
                                href={slides[currentIndex]?.imageUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                className='fab-carousel-slide'
                                style={{
                                    backgroundImage: `url('${slides[currentIndex]?.imagePath}')`,
                                    display: 'block',
                                    textDecoration: 'none',
                                    cursor: 'pointer'
                                }}
                                onClick={() => onClickImage(slides[currentIndex]?.imageUrl)}
                            ></a>
                        </div>
                    ) :
                        (
                            <div className='fab-carousel-wrapper'>
                                <div
                                    className='fab-carousel-slide'
                                    style={{
                                        backgroundImage: `url('${slides[currentIndex]?.imagePath}')`,
                                    }}
                                ></div>
                            </div>
                        )
                }
                {slides.length > 1 && <div className='fab-carousel-dot'>
                    {slides.map((slide, slideIndex) => (
                        <div
                            key={slideIndex}
                            onClick={() => goToSlide(slideIndex)}
                            className={currentIndex === slideIndex ? 'fab-carousel-dot-active' : ''}
                        ></div>
                    ))}
                </div>}
            </div>
        </div >
    )
}

export default Carousel;