import "../../components/globals/boilerplate.css";
import "../../components/globals/homepage.css";
import "../../components/globals/company-listing.css";

import { ALL_COMPANIES, ALL_CUISINES, COMPANIES_PAGE_SIZE, SOMETHING_WENT_WRONG_MESSAGE, TRY_AGAIN_MESSAGE, byAlphabetFilterOptions } from "../../common/constants";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import Button from "../../components/button/Button";
import CompanyFiltersMobile from "./CompanyFiltersMobile";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Loader from "../../components/loader/Loader";
import MobileSearch from '../../components/mobilesearch/MobileSearch';
import NoResults from "../../components/NoResults/NoResults";
import Pagination from "../../components/pagination/Pagination";
import TextField from "../../components/textfield/Textfield";
import Toast from "../../components/toast/Toast";
import analytics from '../../firebase';
import { getCompanies } from "../../services/CompanyService";
import { getInformation } from "../../services/HomeService";
import { getSalaryText } from "../../common/helper";
import { logEvent } from "firebase/analytics";

const useNoScroll = (isActive) => {
  useEffect(() => {
    if (isActive) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isActive]);
};

const CompanyListing = () => {
  const navigate = useNavigate();
  const locationProps = useLocation()
  const params = new URLSearchParams(locationProps.search);
  const pageParam = parseInt(params.get('page')) || 0;
  const updatedPage = pageParam > 0 ? pageParam - 1 : 0;
  const [currentPage, setCurrentPage] = useState(updatedPage);

  const [cuisineFilterOptions, setCuisineFilterOptions] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [companiesTotal, setCompaniesTotal] = useState(0);
  const [searchCompanyText, setSearchCompanyText] = useState('');
  const [inSearchMode, setInSearchMode] = useState(false);
  const [selectedAlphaFilters, setSelectedAlphaFilters] = useState([ALL_COMPANIES]);
  const [selectedCuisineFilters, setSelectedCuisineFilters] = useState([ALL_CUISINES]);

  // State to manage selected filters from mobile or tablet view
  const [alphaFilters, setAlphaFilters] = useState(selectedAlphaFilters);
  const [cuisineFilters, setCuisineFilters] = useState(selectedCuisineFilters);

  const [isFilterOptionsLoading, setIsFilterOptionsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isAlphabetsVisible, setAlphabetsVisibility] = useState(true);
  const [isCuisinesVisible, setCuisineVisibility] = useState(true);

  const [isFilterSticky, setFilterSticky] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);

  const [errorMessageHeader, setErrorMessageHeader] = useState('');
  const [errorMessageDescription, setErrorMessageDescription] = useState('');

  const [isMenuActive, setIsMenuActive] = useState(false);
  const [isAlphabetActive, setisAlphabetActive] = useState(false);
  const [isFetchCompaniesCompleted, setIsFetchCompaniesCompleted] = useState(false);

  const [showMobileSearch, setShowMobileSearch] = useState(false);
  const [showFilterList, setshowFilterList] = useState(false);

  const [isDesktop, setIsDesktop] = useState(true);

  const companyListContainerRef = useRef(null);
  const alphabetSliderRef = useRef(null);
  const cuisineSliderRef = useRef(null);

  const windowWidth = window.innerWidth;
  logEvent(analytics, 'company_screen_viewed');

  const resetErrorMessages = () => {
    setErrorMessageHeader('');
    setErrorMessageDescription('');
  }

  const getAndSetOptions = async () => {
    let optionsLoadedSuccessfully = true;
    try {
      setIsFilterOptionsLoading(true);

      const info = await getInformation();
      if (info?.data?.cuisines) {
        const cuisineTypes = info?.data?.cuisines.filter(cuisine => cuisine.active != false).map((cuisine) => cuisine?.type);
        setCuisineFilterOptions([ALL_CUISINES, ...cuisineTypes]);
      }

      setIsFilterOptionsLoading(false);
    } catch (error) {
      console.log(`error occurred while getting an info: ${error}`);

      optionsLoadedSuccessfully = false;
      setIsFilterOptionsLoading(false);
      setErrorMessageHeader(SOMETHING_WENT_WRONG_MESSAGE);
      setErrorMessageDescription(TRY_AGAIN_MESSAGE);
    }

    return optionsLoadedSuccessfully;
  }

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;

    if (window.innerWidth < 600) {
      if (currentScrollPos > prevScrollPos) {
        setFilterSticky(false);
      } else {
        setFilterSticky(true);
      }
    } else {
      setFilterSticky(false);
    }

    setPrevScrollPos(currentScrollPos);
  };

  const getCuisineType = (cuisines) => {
    if (cuisines?.length > 0) {
      return cuisines[0]?.type;
    }

    return '';
  }

  const getJobLinkToDisplay = (job) => {
    let jobLink = job?.jobttl;

    const salaryText = getSalaryText(job);
    if (salaryText) {
      jobLink = jobLink.concat(` | ${salaryText}`);
    }

    return jobLink;
  }

  const handleSearchTextChange = (e) => {
    setSearchCompanyText(e.target.value);
  }

  const resetSearchValues = () => {
    setSearchCompanyText('');
    setInSearchMode(false);
    setCurrentPage(0)
  }

  const handleCompanySearchClear = () => {
    resetSearchValues();
    resetErrorMessages();
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleAllCuisineClick = () => {
    setIsMenuActive(true);
    window.scrollTo({
      top: 0,
      // behavior: 'smooth'
    });
  };

  const handleAlphabetClick = () => {
    setisAlphabetActive(true);
    window.scrollTo({
      top: 0,
      // behavior: 'smooth'
    });
  };

  const displayMobileAlphabetDropdownText = (items) => {
    return windowWidth <= 992 && items?.length > 0 && !items.includes(ALL_COMPANIES);
  }

  const displayMobileCuisinesDropdownText = (items) => {
    return windowWidth <= 992 && items?.length > 0 && !items.includes(ALL_CUISINES);
  }

  const handleOnFocus = () => {
    if (window.innerWidth <= 992) {
      setShowMobileSearch(!showMobileSearch)
    }
  }

  const handleOnclickFilterIcon = () => {
    setshowFilterList(!showFilterList)
  }

  const getCuisineImageUrl = (cuisines) => {
    if (cuisines?.length > 0) {
      return cuisines[0]?.image;
    }

    return '';
  }

  const handleDesktopAlphaFiltersClick = async (filterItem) => {
    setCurrentPage(0)
    if (filterItem !== ALL_COMPANIES) {
      // To unselect 'All companies' option if any other filters are selected
      setSelectedAlphaFilters(selectedAlphaFilters.filter((filter) => filter !== ALL_COMPANIES));
    } else {
      setSelectedAlphaFilters([ALL_COMPANIES]);
    }

    const filterExists = selectedAlphaFilters.includes(filterItem);
    if (filterExists) {
      setSelectedAlphaFilters(selectedAlphaFilters.filter((filter) => filter !== filterItem));
    } else {
      setSelectedAlphaFilters((previousFilters) => [...previousFilters, filterItem]);
    }
    logEvent(analytics, 'company_filter_applied', { 'alphabet_options': JSON.stringify(selectedAlphaFilters), 'cuisine_options': JSON.stringify(selectedCuisineFilters) });
  }

  const handleDesktopCuisineFiltersClick = async (filterItem) => {
    setCurrentPage(0)
    if (filterItem !== ALL_CUISINES) {
      // To unselect 'All cuisine' option if any other filters are selected
      setSelectedCuisineFilters(selectedCuisineFilters.filter((filter) => filter !== ALL_CUISINES));
    } else {
      setSelectedCuisineFilters([ALL_CUISINES]);
    }

    const filterExists = selectedCuisineFilters.includes(filterItem);
    if (filterExists) {
      setSelectedCuisineFilters(selectedCuisineFilters.filter((filter) => filter !== filterItem));
    } else {
      setSelectedCuisineFilters((previousFilters) => [...previousFilters, filterItem]);
    }
    logEvent(analytics, 'company_filter_applied', { 'alphabet_options': JSON.stringify(selectedAlphaFilters), 'cuisine_options': JSON.stringify(selectedCuisineFilters) });
  }

  const handleAlFiltersClick = (item) => {
    if (item !== ALL_COMPANIES) {
      // To unselect 'All companies' option if any other filters are selected
      setAlphaFilters(alphaFilters.filter((filter) => filter !== ALL_COMPANIES));
    } else {
      setAlphaFilters([ALL_COMPANIES]);
    }

    const filterExists = alphaFilters.includes(item);
    if (filterExists) {
      setAlphaFilters(alphaFilters.filter((filter) => filter !== item));
    } else {
      setAlphaFilters((previousFilters) => [...previousFilters, item]);
    }
    logEvent(analytics, 'company_filter_applied', { 'alphabet_options': JSON.stringify(selectedAlphaFilters), 'cuisine_options': JSON.stringify(selectedCuisineFilters) });
  }

  const handleCuiFiltersClick = (item) => {
    if (item !== ALL_CUISINES) {
      // To unselect 'All cuisine' option if any other filters are selected
      setCuisineFilters(cuisineFilters.filter((filter) => filter !== ALL_CUISINES));
    } else {
      setCuisineFilters([ALL_CUISINES]);
    }

    const filterExists = cuisineFilters.includes(item);
    if (filterExists) {
      setCuisineFilters(cuisineFilters.filter((filter) => filter !== item));
    } else {
      setCuisineFilters((previousFilters) => [...previousFilters, item]);
    }
    logEvent(analytics, 'company_filter_applied', { 'alphabet_options': JSON.stringify(selectedAlphaFilters), 'cuisine_options': JSON.stringify(selectedCuisineFilters) });
  }

  const handleReset = () => {
    resetSearchValues()
    setSelectedAlphaFilters([ALL_COMPANIES]);
    setSelectedCuisineFilters([ALL_CUISINES]);
  }

  // Handle mobile filter list search button
  const handleMobileCompanySearchAndFiltersClick = (alphas, cuis) => {
    setShowMobileSearch(false);
    setshowFilterList(false);
    setCurrentPage(0)
    setSelectedAlphaFilters(alphas)
    setSelectedCuisineFilters(cuis)
    handleMobileSearchAndFiltersClick(alphas, cuis, 0)
  }

  // Handle mobile slider filters apply button
  const handleApplyFilter = () => {
    setisAlphabetActive(false);
    setIsMenuActive(false);
    setCurrentPage(0)
    setSelectedAlphaFilters(alphaFilters)
    setSelectedCuisineFilters(cuisineFilters)
    handleMobileSearchAndFiltersClick(alphaFilters, cuisineFilters, 0);
  };

  // Handle mobile filters search
  const handleMobileSearchAndFiltersClick = async (alphas, cuis, page) => {
    setIsFetchCompaniesCompleted(false)
    try {
      setCompanies([])
      setCompaniesTotal(0)
      setIsLoading(true);
      let byAlphaFilters = '', byNumberFilter = '';
      const allCompanies = alphas.includes(ALL_COMPANIES);
      if (!allCompanies) {
        byAlphaFilters = alphas
          .filter((alphaFilter) => (alphaFilter !== '#' && alphaFilter !== ALL_COMPANIES))
          .map((alphaFilter) => {
            const [from, to] = alphaFilter.split('-');
            return { from, to };
          });

        byNumberFilter = alphas.includes('#');
      }

      let byCuisineFilters = '';
      const allCuisines = cuis.includes(ALL_CUISINES);
      if (!allCuisines) {
        byCuisineFilters = cuis;
      }
      logEvent(analytics, 'company_search_clicked');
      const response = await getCompanies(searchCompanyText, byAlphaFilters, byNumberFilter, byCuisineFilters, page);
      logEvent(analytics, 'company_search_completed', { 'search_term': searchCompanyText });
      setSelectedAlphaFilters(alphas)
      setSelectedCuisineFilters(cuis)
      setCompaniesTotal(response.totalItems);
      setCompanies(response.items);
      setIsLoading(false)
      setIsFetchCompaniesCompleted(true)
    } catch (error) {
      console.log(`error occurred while getting a list of companies with search keyword and by alphabet filters: ${error}`);
      setIsFetchCompaniesCompleted(true)
      setCompaniesTotal(0);
      setCompanies([]);
      setIsLoading(false);
      setInSearchMode(false);
      setErrorMessageHeader(SOMETHING_WENT_WRONG_MESSAGE);
      setErrorMessageDescription(TRY_AGAIN_MESSAGE);
    }
  }

  // Handle mobile view search keyword page button  
  const handleMobileSearchButtonClick = (searchValue) => {
    if (searchValue) {
      setCurrentPage(0);
      setInSearchMode(true)
    }
    else {
      setInSearchMode(false)
    }
    setSearchCompanyText(searchValue)
    handleMobileSearchClick(0, searchValue)
  }

  // Mobile view search keyword handler 
  const handleMobileSearchClick = async (page, searchValue) => {
    try {
      setCompanies([])
      setCompaniesTotal(0)
      setIsLoading(true);
      setIsFetchCompaniesCompleted(false)
      let byAlphaFilters = '', byNumberFilter = '';
      const allCompanies = selectedAlphaFilters.includes(ALL_COMPANIES);
      if (!allCompanies) {
        byAlphaFilters = selectedAlphaFilters
          .filter((alphaFilter) => (alphaFilter !== '#' && alphaFilter !== ALL_COMPANIES))
          .map((alphaFilter) => {
            const [from, to] = alphaFilter.split('-');
            return { from, to };
          });

        byNumberFilter = selectedAlphaFilters.includes('#');
      }

      let byCuisineFilters = '';
      const allCuisines = selectedCuisineFilters.includes(ALL_CUISINES);
      if (!allCuisines) {
        byCuisineFilters = selectedCuisineFilters;
      }
      logEvent(analytics, 'company_search_clicked');
      const response = await getCompanies(searchValue, byAlphaFilters, byNumberFilter, byCuisineFilters, page);
      logEvent(analytics, 'company_search_completed', { 'search_term': searchValue });
      setCompaniesTotal(response.totalItems);
      setCompanies(response.items);
      setIsLoading(false)
      setIsFetchCompaniesCompleted(true)
    } catch (error) {
      console.log(`error occurred while getting a list of companies with search keyword and by alphabet filters: ${error}`);
      setIsFetchCompaniesCompleted(true)
      setCompaniesTotal(0);
      setCompanies([]);
      setIsLoading(false);
      setInSearchMode(false);
      setErrorMessageHeader(SOMETHING_WENT_WRONG_MESSAGE);
      setErrorMessageDescription(TRY_AGAIN_MESSAGE);
    }
  }

  // Desktop view on click search button
  const handleOnSearchButtonClick = () => {
    if (searchCompanyText) {
      setCurrentPage(0);
      setInSearchMode(true)
    }
    handleCompanySearchAndFiltersClick()
  }

  // Desktop view search handler
  const handleCompanySearchAndFiltersClick = async () => {
    try {
      setCompanies([])
      setCompaniesTotal(0)
      setIsLoading(true);
      setIsFetchCompaniesCompleted(false)
      let byAlphaFilters = '', byNumberFilter = '';
      const allCompanies = selectedAlphaFilters.includes(ALL_COMPANIES);
      if (!allCompanies) {
        byAlphaFilters = selectedAlphaFilters
          .filter((alphaFilter) => (alphaFilter !== '#' && alphaFilter !== ALL_COMPANIES))
          .map((alphaFilter) => {
            const [from, to] = alphaFilter.split('-');
            return { from, to };
          });

        byNumberFilter = selectedAlphaFilters.includes('#');
      }

      let byCuisineFilters = '';
      const allCuisines = selectedCuisineFilters.includes(ALL_CUISINES);
      if (!allCuisines) {
        byCuisineFilters = selectedCuisineFilters;
      }
      logEvent(analytics, 'company_search_clicked');

      const response = await getCompanies(searchCompanyText, byAlphaFilters, byNumberFilter, byCuisineFilters, currentPage);
      logEvent(analytics, 'company_search_completed', { 'search_term': searchCompanyText });
      setCompaniesTotal(response.totalItems);
      setCompanies(response.items);
      setIsLoading(false)
      setIsFetchCompaniesCompleted(true)
    } catch (error) {
      console.log(`error occurred while getting a list of companies with search keyword and by alphabet filters: ${error}`);
      setIsFetchCompaniesCompleted(true)
      setCompaniesTotal(0);
      setCompanies([]);
      setIsLoading(false);
      setInSearchMode(false);
      setErrorMessageHeader(SOMETHING_WENT_WRONG_MESSAGE);
      setErrorMessageDescription(TRY_AGAIN_MESSAGE);
    }
  }

  useNoScroll(isMenuActive || isAlphabetActive);

  useEffect(() => {
    if (selectedAlphaFilters.length === 0) {
      setSelectedAlphaFilters([ALL_COMPANIES]);
    }
    if (selectedCuisineFilters.length === 0) {
      setSelectedCuisineFilters([ALL_CUISINES]);
    }

    if (isDesktop) {
      handleCompanySearchAndFiltersClick();
    }
  }, [selectedAlphaFilters, selectedCuisineFilters, currentPage, inSearchMode]);

  useEffect(() => {
    if (!isDesktop) {
      handleCompanySearchAndFiltersClick();
    }
  }, [currentPage])

  useEffect(() => {
    setTimeout(() => {
      scrollToTop();
    }, 0);
    navigate(`?page=${currentPage + 1}`, { replace: true });
  }, [currentPage, selectedAlphaFilters, selectedCuisineFilters])

  useEffect(() => {
    getAndSetOptions();
  }, []);

  useEffect(() => {
    const handleDocumentClick = (e) => {
      if (isAlphabetActive && alphabetSliderRef.current && !alphabetSliderRef.current.contains(e.target)) {
        setisAlphabetActive(false);
        setAlphaFilters(selectedAlphaFilters)
      }
    };

    const handleEscapeKey = (e) => {
      if (e.key === "Escape") {
        setisAlphabetActive(false);
        setAlphaFilters(selectedAlphaFilters)
      }
    };

    if (isAlphabetActive) {
      document.addEventListener("mousedown", handleDocumentClick);
      document.addEventListener("keydown", handleEscapeKey);
    } else {
      document.removeEventListener("mousedown", handleDocumentClick);
      document.removeEventListener("keydown", handleEscapeKey);
    }

    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [isAlphabetActive]);

  useEffect(() => {
    const handleDocumentClick = (e) => {
      if (isMenuActive && cuisineSliderRef.current && !cuisineSliderRef.current.contains(e.target)) {
        setIsMenuActive(false);
        setCuisineFilters(selectedCuisineFilters)
      }
    };

    const handleEscapeKey = (e) => {
      if (e.key === "Escape") {
        setIsMenuActive(false);
        setCuisineFilters(selectedCuisineFilters)
      }
    };

    if (isMenuActive) {
      document.addEventListener("mousedown", handleDocumentClick);
      document.addEventListener("keydown", handleEscapeKey);
    } else {
      document.removeEventListener("mousedown", handleDocumentClick);
      document.removeEventListener("keydown", handleEscapeKey);
    }

    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [isMenuActive]);

  useEffect(() => {
    const params = new URLSearchParams(locationProps.search);
    if (!params.has('page')) {
      params.set('page', '1');
      navigate(`?${params.toString()}`, { replace: true });
    }
  }, [locationProps.search, navigate]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        handleOnSearchButtonClick()
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleOnSearchButtonClick]);

  if (showFilterList) {
    return (
      <CompanyFiltersMobile
        OnClickFilterIcon={handleOnclickFilterIcon}
        alphabets={byAlphabetFilterOptions}
        cuisines={cuisineFilterOptions}
        onSearch={handleMobileCompanySearchAndFiltersClick}
        setIsDesktop={setIsDesktop}
        selectedAlphaFilters={selectedAlphaFilters}
        selectedCuisineFilters={selectedCuisineFilters}
        setSelectedAlphaFilters={setSelectedAlphaFilters}
        setSelectedCuisineFilters={setSelectedCuisineFilters}
        alphaFilters={alphaFilters}
        cuisineFilters={cuisineFilters}
        setAlphaFilters={setAlphaFilters}
        setCuisineFilters={setCuisineFilters}
      />
    )
  }

  if (showMobileSearch) {
    return (
      <MobileSearch handleOnFocus={handleOnFocus} OnClickFilterIcon={handleOnclickFilterIcon} value={searchCompanyText} onSearchButtonClick={handleMobileSearchButtonClick} />
    )
  }

  return (
    <div className="fab-comapny-list-container" ref={companyListContainerRef}>
      {
        (errorMessageHeader && errorMessageDescription) && <Toast
          type='error'
          header={errorMessageHeader}
          message={errorMessageDescription}
          onClose={resetErrorMessages}
        />
      }
      {(isLoading || isFilterOptionsLoading) && <Loader />}
      <Header />
      <div
        className={`fab-company-listing-filters ${isFilterSticky === true ? "fab-comapny-filter-sticky" : ""
          }`}
      >
        <div className="fab-container fab-company-filter-wrapper">
          <h1 className="fab-companies-header">Companies</h1>
          <p className="fab-companies-subtext">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum
            magnam tempore quasi corrupti, commodi perferendis.
          </p>
          <div className="fab-filter-fields">
            <TextField placeholder="Search for company" handleOnFocus={handleOnFocus} onChange={handleSearchTextChange} onClear={handleCompanySearchClear} value={searchCompanyText} hideRemoveIcon={window.innerWidth <= 992 ? true : false} />
            <div className='fab-alphabet-cuisine-select-mobile'>
              <div className={`fab-alphabet-mobile ${displayMobileAlphabetDropdownText(selectedAlphaFilters) ? 'fab-alphabet-dropdown-text-m' : ''}`} onClick={handleAlphabetClick}>
                <div className='fab-alphabet-content'>
                  {
                    displayMobileAlphabetDropdownText(selectedAlphaFilters) ?
                      <span className='fab-dropdown-text-m'>
                        <div>By alphabet</div>
                        <div className='fab-circle-badge'>{selectedAlphaFilters?.length}</div>
                      </span>
                      :
                      <span>By alphabet</span>
                  }
                </div>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.4419 13.5669C10.1979 13.811 9.80214 13.811 9.55806 13.5669L3.30806 7.31694C3.06398 7.07286 3.06398 6.67714 3.30806 6.43306C3.55214 6.18898 3.94786 6.18898 4.19194 6.43306L10 12.2411L15.8081 6.43306C16.0521 6.18898 16.4479 6.18898 16.6919 6.43306C16.936 6.67714 16.936 7.07286 16.6919 7.31694L10.4419 13.5669Z"
                    fill="#18181B"
                  />
                </svg>
              </div>
              <div className={`fab-cuisine-mobile ${displayMobileCuisinesDropdownText(selectedCuisineFilters) ? 'fab-cuisine-dropdown-text-m' : ''}`} onClick={handleAllCuisineClick}>
                <div className='fab-cuisine-content'>
                  {
                    displayMobileCuisinesDropdownText(selectedCuisineFilters) ?
                      <span className='fab-dropdown-text-m'>
                        <div>By cuisine</div>
                        <div className='fab-circle-badge'>{selectedCuisineFilters?.length}</div>
                      </span>
                      :
                      <span>By cuisine</span>
                  }
                </div>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.4419 13.5669C10.1979 13.811 9.80214 13.811 9.55806 13.5669L3.30806 7.31694C3.06398 7.07286 3.06398 6.67714 3.30806 6.43306C3.55214 6.18898 3.94786 6.18898 4.19194 6.43306L10 12.2411L15.8081 6.43306C16.0521 6.18898 16.4479 6.18898 16.6919 6.43306C16.936 6.67714 16.936 7.07286 16.6919 7.31694L10.4419 13.5669Z"
                    fill="#18181B"
                  />
                </svg>
              </div>
            </div>
            <div className="fab-filter-buttons">
              <Button variant="primary" size="lg" className="fab-filter-button" onClick={handleOnSearchButtonClick}>
                Search company
              </Button>
            </div>
          </div>
        </div>
      </div>
      {
        (inSearchMode && companiesTotal > 0) && <div className="fab-container">
          <h4 className="fab-comapnies-result-text">{inSearchMode ? `Showing ${companiesTotal} search results` : ''}</h4>
        </div>
      }
      <div className="fab-cp-wrapper">
        <div className="fab-container fab-company-container">
          <div>
            <div className="fab-comapnies">
              {
                companies?.map((company, index) => (
                  <div className="fab-company-card" key={index} onClick={() => { logEvent(analytics, 'company_profile_viewed', { 'click_source': 'company listing' }); navigate(`/employer/${company?.coyid}`); }}>
                    <div className="fab-company-image-wrapper">
                      {
                        getCuisineImageUrl(company.cuisines) && <div
                          className="fab-company-image"
                          style={{ backgroundImage: `url('${getCuisineImageUrl(company.cuisines)}')` }}
                        ></div>
                      }
                    </div>
                    <div className="fab-company-details-wrapper">
                      <div className="fab-company-card-details">
                        <span
                          className="fab-featured-pill"
                          style={{
                            background: company.featured ? '' : "#FFF",
                            height: "20px",
                          }}
                        >
                          {company.featured ? 'Featured' : ''}
                        </span>
                        <span className="fab-featured-company">
                          {company.dispm}
                        </span>
                        <span className="fab-featured-location">
                          {getCuisineType(company.cuisines)}
                        </span>
                        <div className="fab-company-latest-jobs">
                          {
                            company.jobs.length > 0 ? company.jobs.map((job, index) => (
                              <div
                                key={index}
                                className="fab-company-latest-job-item"
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M5.5 2.5L13 2.5C13.1326 2.5 13.2598 2.55268 13.3536 2.64645C13.4473 2.74021 13.5 2.86739 13.5 3V10.5C13.5 10.7761 13.2761 11 13 11C12.7239 11 12.5 10.7761 12.5 10.5V4.20711L3.35355 13.3536C3.15829 13.5488 2.84171 13.5488 2.64645 13.3536C2.45118 13.1583 2.45118 12.8417 2.64645 12.6464L11.7929 3.5L5.5 3.5C5.22386 3.5 5 3.27614 5 3C5 2.72386 5.22386 2.5 5.5 2.5Z"
                                    fill="#18181B"
                                  />
                                </svg>
                                <a href={`/jobs/${job?.jobid}`}>
                                  <span>{getJobLinkToDisplay(job)}</span>
                                </a>
                              </div>
                            )) : <p className='fab-no-jobs-to-disp-text'>
                              No jobs to display.
                            </p>
                          }
                        </div>
                      </div>
                      <div className="fab-company-btn-see-more">
                        <a href={`/employer/${company?.coyid}`}>See more</a>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
            {
              (isFetchCompaniesCompleted && companies?.length === 0) && <NoResults justifyContent='start' />
            }
            {
              (companies?.length > 0) && <div className="fab-companies-pagination"><Pagination pageSize={COMPANIES_PAGE_SIZE} total={companiesTotal} setCurrentPage={setCurrentPage} currentPage={currentPage} /></div>
            }
          </div>
          <div className="fab-companies-filters-desktop">
            <h3 className="fab-companies-filter-text-label">Company filter</h3>
            <div className="fab-company-cuisine-filters">
              <div className="fab-comapny-filter-header">
                <h4>By alphabet</h4>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => setAlphabetsVisibility(!isAlphabetsVisible)}
                  style={{
                    transform: !isAlphabetsVisible
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  }}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.55806 6.43306C9.80214 6.18898 10.1979 6.18898 10.4419 6.43306L16.6919 12.6831C16.936 12.9271 16.936 13.3229 16.6919 13.5669C16.4479 13.811 16.0521 13.811 15.8081 13.5669L10 7.75888L4.19194 13.5669C3.94786 13.811 3.55214 13.811 3.30806 13.5669C3.06398 13.3229 3.06398 12.9271 3.30806 12.6831L9.55806 6.43306Z"
                    fill="#D31145"
                  />
                </svg>
              </div>
              {isAlphabetsVisible && (
                <div className="fab-company-filter-checklist">
                  {byAlphabetFilterOptions.map((item, index) => (
                    <label key={index} htmlFor={`fab-alphabet-${index}`}>
                      <input
                        type="checkbox"
                        name=""
                        readOnly
                        id={`fab-alphabet-${index}`}
                        checked={selectedAlphaFilters.includes(item)}
                        onClick={() => handleDesktopAlphaFiltersClick(item)}
                      />
                      {item}
                    </label>
                  ))}
                </div>
              )}
            </div>
            <div className="fab-company-cuisine-filters">
              <div className="fab-comapny-filter-header">
                <h4>By cuisine</h4>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => setCuisineVisibility(!isCuisinesVisible)}
                  style={{
                    transform: !isCuisinesVisible
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  }}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.55806 6.43306C9.80214 6.18898 10.1979 6.18898 10.4419 6.43306L16.6919 12.6831C16.936 12.9271 16.936 13.3229 16.6919 13.5669C16.4479 13.811 16.0521 13.811 15.8081 13.5669L10 7.75888L4.19194 13.5669C3.94786 13.811 3.55214 13.811 3.30806 13.5669C3.06398 13.3229 3.06398 12.9271 3.30806 12.6831L9.55806 6.43306Z"
                    fill="#D31145"
                  />
                </svg>
              </div>
              {isCuisinesVisible && (
                <div className="fab-company-filter-checklist">
                  {cuisineFilterOptions.map((item, index) => (
                    <label key={index} htmlFor={`fab-cuisine-${index}`}>
                      <input
                        type="checkbox"
                        name=""
                        readOnly
                        id={`fab-cuisine-${index}`}
                        checked={selectedCuisineFilters.includes(item)}
                        onClick={() => handleDesktopCuisineFiltersClick(item)}
                      />
                      {item}
                    </label>
                  ))}
                </div>
              )}
            </div>
            <div className="fab-reset-filters-button-desktop">
              <Button variant="outline" size="md" onClick={handleReset}>Reset all filters</Button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <div className={`fab-dropdown-mobile-menu ${isAlphabetActive ? 'fab-alphabet-mobile-active' : ''}`}>
        <div className='fab-menu-mobile-container' ref={alphabetSliderRef}>
          <div className='fab-menu-header-container'>
            <p>By alphabet</p>
          </div>
          <div className='fab-menu-mobile-items'>
            {byAlphabetFilterOptions.map((item, index) => (
              <label key={index} className='fab-menu-mobile-label' onClick={(e) => e.stopPropagation()}>
                <input
                  type="checkbox"
                  readOnly
                  id={`alphabet-${index}`}
                  onClick={() => {
                    setIsDesktop(false);
                    handleAlFiltersClick(item);
                  }}
                  checked={alphaFilters.includes(item)}
                />
                {item}
              </label>
            ))}
          </div>
          <div className='fab-menu-mobile-buttons'>
            <Button variant='primary' size='lg' onClick={handleApplyFilter}>Apply filter</Button>
            <Button variant='outline' size='lg' onClick={() => {
              setAlphaFilters([ALL_COMPANIES]);
            }}>Reset all</Button>
          </div>
        </div>
      </div>
      <div className={`fab-dropdown-mobile-menu ${isMenuActive ? 'fab-cuisine-mobile-active' : ''}`}>
        <div className='fab-menu-mobile-container' ref={cuisineSliderRef}>
          <div className='fab-menu-header-container'>
            <p>By cuisine</p>
          </div>
          <div className='fab-menu-mobile-items'>
            {cuisineFilterOptions.map((item, index) => (
              <label key={index} className='fab-menu-mobile-label' onClick={(e) => e.stopPropagation()}>
                <input
                  type="checkbox"
                  readOnly
                  id={`cuisine-type-${index}`}
                  onClick={() => {
                    setIsDesktop(false);
                    handleCuiFiltersClick(item);
                  }}
                  checked={cuisineFilters.includes(item)}
                />
                {item}
              </label>
            ))}
          </div>
          <div className='fab-menu-mobile-buttons'>
            <Button variant='primary' size='lg' onClick={handleApplyFilter}>Apply filter</Button>
            <Button variant='outline' size='lg' onClick={() => {
              setCuisineFilters([ALL_CUISINES]);
            }}>Reset all</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyListing;