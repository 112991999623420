import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const isUserLoggedIn = () => {
    const isLogin = JSON.parse(localStorage.getItem('isLogin'))
    return isLogin && isLogin === true ? true : false
};


export const SecuredRoute = ({ redirectedTo }) => {
    return isUserLoggedIn() ? <Outlet /> : <Navigate to={redirectedTo ? redirectedTo : "/notfound"} />
};

export const AuthenticatedRoute = () => {
    return isUserLoggedIn() ? <Navigate to="/" /> : <Outlet />
};
