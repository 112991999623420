export const routes = {
    home: {
        info: '/info',
    },
    codeTable: {
        fastCodes: '/code-table/fast-codes',
    },
    jobs: {
        search: '/jobs',
        details: '/jobs/search-by-id',
        apply: '/jobs/job-apply',
        applied: '/jobs/applied',
        generatePdf: '/jobs/generatepdf',
        saveJob: '/jobs/save-job',
        unsaveJob: '/jobs/unsave-job',
        jobFeedBaners: '/feed-banners'
    },
    profile: {
        login: '/profile/login',
        register: '/profile/register',
        forgotPassword: '/profile/forgot-password',
        changePassword: '/profile/change-password',
        googleVerify: '/profile/google-token-request',
        googleLogin: '/profile/google-login',
        googleRegister: '/profile/google-register',
        fbVerify: '/profile/fb-token-request',
        view: '/profile/view',
        sendOtp: '/profile/send-otp',
        verifyMobileNumber: '/profile/verify-phone',
        update: '/profile/update',
        updateHighestEducation: '/profile/update-highest-education',
        updateEmploymentStatus: '/profile/update-employment-status',
        uploadPicture: '/profile/upload-picture',
        updateJobPreference: '/profile/update-preference',
        updateWorkExperience: '/profile/update-work-experience',
        updateEducationDetails: '/profile/update-education',
        updateSkills: '/profile/update-skill',
        appliedJobs: '/profile/applied-jobs',
        savedJobs: '/profile/saved-jobs',
        updateLanguages: '/profile/update-language',
        uploadResume: '/profile/upload-resume',
        generateResume: '/profile/generate-resume',
        deleteResume: '/profile/delete-resume',
        uploadGeneratedResume: '/profile/upload-generated-resume',
        previewResume: '/profile/preview-resume',
        verifyEmail: '/profile/verify-email',
        getAllSavedJobs: '/profile/saved-jobs-all',
        verifyUser: '/profile/verify-username',
        fbTokenRequest: '/profile/fb-token-request',
        getFbUserDetails: '/profile/get-fb-userdetails',
        resetPassword: '/profile/reset-password',
        fbLogin: '/profile/fb-login',
        fbRegister: '/profile/facebook-register',
    },
    company: {
        list: '/companies',
        getCuisines: (id) => `/companies/${id}/cuisines`
    },
    employer: {
        employerProfile: '/employer-profile/content'
    }
};
