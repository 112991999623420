import '../../components/globals/UserSidebar.css';

import { Link, useNavigate } from 'react-router-dom';

import analytics from '../../firebase';
import { logEvent } from "firebase/analytics";

const SidebarLink = ({ linkActive, to, children }) => {
    return (
        <Link className={`fab-sidebar-item ${linkActive === to ? 'fab-sidebar-item-active' : ''}`} to={`/${to}`}>
            {children}
        </Link>
    );
};

const UserSidebar = ({ linkActive }) => {
    const navigate = useNavigate();
    const onLogout = (e) => {
        e.preventDefault();
        localStorage.clear();
        logEvent(analytics, 'logout_success');
        setTimeout(() => {
            navigate('/')
            navigate(0)
        }, 100)
    }
    return (
        <div className='fab-side-user-menu'>
            <SidebarLink linkActive={linkActive} to="profile">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M6.24997 5C6.24997 2.92893 7.9289 1.25 9.99997 1.25C12.071 1.25 13.75 2.92893 13.75 5C13.75 7.07107 12.071 8.75 9.99997 8.75C7.9289 8.75 6.24997 7.07107 6.24997 5Z" fill="#3F3F46" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M3.12601 16.7544C3.19046 13.013 6.24329 10 9.99997 10C13.7567 10 16.8096 13.0131 16.8739 16.7547C16.8782 17.0028 16.7352 17.23 16.5097 17.3335C14.5272 18.2432 12.3221 18.75 10.0002 18.75C7.67819 18.75 5.47283 18.2431 3.49024 17.3332C3.26468 17.2297 3.12173 17.0026 3.12601 16.7544Z" fill="#3F3F46" />
                </svg>
                Profile
            </SidebarLink>
            <SidebarLink linkActive={linkActive} to="change-password">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M13.125 1.25C10.0184 1.25 7.5 3.7684 7.5 6.875C7.5 7.2031 7.52816 7.52513 7.58233 7.83866C7.63832 8.16273 7.55565 8.43546 7.39806 8.59306L1.98223 14.0089C1.51339 14.4777 1.25 15.1136 1.25 15.7767V18.125C1.25 18.4702 1.52982 18.75 1.875 18.75H5C5.34518 18.75 5.625 18.4702 5.625 18.125V16.875H6.875C7.22018 16.875 7.5 16.5952 7.5 16.25V15H8.75C8.91576 15 9.07473 14.9342 9.19194 14.8169L11.4069 12.6019C11.5645 12.4443 11.8373 12.3617 12.1613 12.4177C12.4749 12.4718 12.7969 12.5 13.125 12.5C16.2316 12.5 18.75 9.9816 18.75 6.875C18.75 3.7684 16.2316 1.25 13.125 1.25ZM13.125 3.75C12.7798 3.75 12.5 4.02982 12.5 4.375C12.5 4.72018 12.7798 5 13.125 5C14.1605 5 15 5.83947 15 6.875C15 7.22018 15.2798 7.5 15.625 7.5C15.9702 7.5 16.25 7.22018 16.25 6.875C16.25 5.14911 14.8509 3.75 13.125 3.75Z" fill="#3F3F46" />
                </svg>
                Change password
            </SidebarLink>
            <SidebarLink linkActive={linkActive} to="applied-jobs">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M1.875 10C1.875 5.51269 5.51269 1.875 10 1.875C14.4873 1.875 18.125 5.51269 18.125 10C18.125 14.4873 14.4873 18.125 10 18.125C5.51269 18.125 1.875 14.4873 1.875 10ZM13.0086 8.48827C13.2092 8.20739 13.1442 7.81705 12.8633 7.61642C12.5824 7.41579 12.192 7.48084 11.9914 7.76173L9.29525 11.5364L7.94194 10.1831C7.69786 9.93898 7.30214 9.93898 7.05806 10.1831C6.81398 10.4271 6.81398 10.8229 7.05806 11.0669L8.93306 12.9419C9.06297 13.0719 9.24346 13.138 9.42655 13.1229C9.60964 13.1077 9.7768 13.0128 9.88358 12.8633L13.0086 8.48827Z" fill="#3F3F46" />
                </svg>
                Applied jobs
            </SidebarLink>
            <SidebarLink linkActive={linkActive} to="saved-jobs">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M5.26685 2.14784C6.81978 1.96762 8.3992 1.875 10 1.875C11.6008 1.875 13.1802 1.96762 14.7332 2.14784C15.981 2.29266 16.875 3.36435 16.875 4.58916V17.5C16.875 17.7166 16.7628 17.9178 16.5786 18.0317C16.3943 18.1455 16.1642 18.1559 15.9705 18.059L10 15.0738L4.02951 18.059C3.83577 18.1559 3.60568 18.1455 3.42142 18.0317C3.23716 17.9178 3.125 17.7166 3.125 17.5V4.58916C3.125 3.36435 4.01903 2.29265 5.26685 2.14784Z" fill="#3F3F46" />
                </svg>
                Saved jobs
            </SidebarLink>
            <Link className='fab-sidebar-item fab-sidebar-logout' onClick={(e) => onLogout(e)}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M6.25 3.125C5.55964 3.125 5 3.68464 5 4.375L5 15.625C5 16.3154 5.55964 16.875 6.25 16.875H11.25C11.9404 16.875 12.5 16.3154 12.5 15.625V12.5C12.5 12.1548 12.7798 11.875 13.125 11.875C13.4702 11.875 13.75 12.1548 13.75 12.5V15.625C13.75 17.0057 12.6307 18.125 11.25 18.125H6.25C4.86929 18.125 3.75 17.0057 3.75 15.625L3.75 4.375C3.75 2.99429 4.86929 1.875 6.25 1.875L11.25 1.875C12.6307 1.875 13.75 2.99429 13.75 4.375V7.5C13.75 7.84518 13.4702 8.125 13.125 8.125C12.7798 8.125 12.5 7.84518 12.5 7.5V4.375C12.5 3.68464 11.9404 3.125 11.25 3.125L6.25 3.125ZM10.4419 7.05806C10.686 7.30214 10.686 7.69787 10.4419 7.94194L9.00888 9.375L18.125 9.375C18.4702 9.375 18.75 9.65482 18.75 10C18.75 10.3452 18.4702 10.625 18.125 10.625L9.00888 10.625L10.4419 12.0581C10.686 12.3021 10.686 12.6979 10.4419 12.9419C10.1979 13.186 9.80214 13.186 9.55806 12.9419L7.05806 10.4419C6.81398 10.1979 6.81398 9.80214 7.05806 9.55806L9.55806 7.05806C9.80214 6.81398 10.1979 6.81398 10.4419 7.05806Z" fill="#DC2626" />
                </svg>
                Logout
            </Link>
        </div>
    )
}

export default UserSidebar;
