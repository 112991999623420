import React, { useEffect } from 'react';
import SlideDrawer from '../../components/slidedrawer/SlideDrawer';
import Button from '../../components/button/Button';
import { getSelectedItems } from '../../common/helper'
import '../../components/dropdown/Dropdown.css';


const JobSliderMobile = ({ showSlider, setShowSlider, selectedFiltersMobile, setSelectedFiltersMobile, sliderHeader, locations, jobPositions, employmentTypes, jobCategories, selectedItems, setSelectedItems, getJobs, setCurrentPage, setMinSalary, setMaxSalary, minSalaryMobile, setMaxSalaryMobile, maxSalaryMobile, setMinSalaryMobile, getJobsWithGivenSalaryRange }) => {
    const onClickUpdate = () => {
        setShowSlider(false)
        setCurrentPage(0)
        setSelectedItems({
            locations: selectedFiltersMobile.locations,
            jobPositions: selectedFiltersMobile.jobPositions,
            jobCategories: selectedFiltersMobile.jobCategories,
            employmentTypes: selectedFiltersMobile.employmentTypes
        })
        getJobs()
    }
    const handleMinPriceChange = (e) => {
        setMinSalaryMobile(e.target.value);
    };

    const handleMaxPriceChange = (e) => {
        setMaxSalaryMobile(e.target.value);
    };

    const onClickApply = () => {
        setShowSlider(false)
        setCurrentPage(0)
        setMinSalary(minSalaryMobile)
        setMaxSalary(maxSalaryMobile)
        getJobsWithGivenSalaryRange(0, minSalaryMobile, maxSalaryMobile);
    }

    const onClickReset = () => {
        if (sliderHeader === 'Location') {
            setSelectedFiltersMobile((prevItems) => ({
                ...prevItems,
                locations: ['All locations']
            }))
        }
        else if (sliderHeader === 'Job position') {
            setSelectedFiltersMobile((prevItems) => ({
                ...prevItems,
                jobPositions: ['All job positions'],
            }))
        }
        else if (sliderHeader === 'Employment type') {
            setSelectedFiltersMobile((prevItems) => ({
                ...prevItems,
                employmentTypes: ['All employment types']
            }))
        }
        else if (sliderHeader === 'Job categories') {
            setSelectedFiltersMobile((prevItems) => ({
                ...prevItems,
                jobCategories: ['All categories'],
            }))
        }
        else if (sliderHeader === 'Salary range') {
            setMinSalaryMobile('');
            setMaxSalaryMobile('');
            setCurrentPage(0)
            setShowSlider(false)
            getJobsWithGivenSalaryRange(0, '', '')
        }
    }

    const handleCheckboxClick = (e, itemName) => {
        e.stopPropagation();
        const checkbox = e.target.type === 'checkbox' ? e.target : e.target.querySelector('input[type="checkbox"]');
        const isChecked = checkbox.checked;
        if (sliderHeader === 'Location') {
            const checkedItems = getSelectedItems(isChecked, itemName, 'All locations', locations, selectedFiltersMobile.locations)
            setSelectedFiltersMobile((prevItems) => ({
                ...prevItems,
                locations: checkedItems
            }))
        }
        else if (sliderHeader === 'Job position') {
            const checkedItems = getSelectedItems(isChecked, itemName, 'All job positions', jobPositions, selectedFiltersMobile.jobPositions)
            setSelectedFiltersMobile((prevItems) => ({
                ...prevItems,
                jobPositions: checkedItems
            }))
        }
        else if (sliderHeader === 'Employment type') {
            const checkedItems = getSelectedItems(isChecked, itemName, 'All employment types', employmentTypes, selectedFiltersMobile.employmentTypes)
            setSelectedFiltersMobile((prevItems) => ({
                ...prevItems,
                employmentTypes: checkedItems
            }))
        }
    };

    const handleRadioClick = (e, itemName) => {
        e.stopPropagation()
        setSelectedFiltersMobile((prevItems) => ({
            ...prevItems,
            jobCategories: [itemName]
        }))
    }

    const handleOutsideClick = () => {
        setSelectedFiltersMobile({
            locations: selectedItems.locations,
            jobPositions: selectedItems.jobPositions,
            jobCategories: selectedItems.jobCategories,
            employmentTypes: selectedItems.employmentTypes
        })
    }

    useEffect(() => {
        if (showSlider) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [showSlider]);

    return (
        <div>
            <SlideDrawer showSlider={showSlider} setShowSlider={setShowSlider} handleOutsideClick={handleOutsideClick}>
                <h3 className='fab-slider-header'>{sliderHeader}</h3>
                <div className='fab-slider-content'>
                    {sliderHeader === 'Location' && locations.map((item, index) => (
                        <label key={index} htmlFor={`chckItem${index}`} className="fab-dropdown-menu-item" onClick={(e) => e.stopPropagation()} >
                            <input type="checkbox" name="" id={`chckItem${index}`} checked={selectedFiltersMobile.locations.includes(item)} className='fab-dropdown-check' onClick={(e) => handleCheckboxClick(e, item)} />
                            {item}
                        </label>
                    ))}
                    {sliderHeader === 'Job position' && jobPositions.map((item, index) => (
                        <label key={index} htmlFor={`chckItem${index}`} className="fab-dropdown-menu-item" onClick={(e) => e.stopPropagation()}>
                            <input type="checkbox" name="" id={`chckItem${index}`} checked={selectedFiltersMobile.jobPositions.includes(item)} className='fab-dropdown-check' onClick={(e) => handleCheckboxClick(e, item)} />
                            {item}
                        </label>
                    ))}
                    {sliderHeader === 'Employment type' && employmentTypes.map((item, index) => (
                        <label key={index} htmlFor={`chckItem${index}`} className="fab-dropdown-menu-item" onClick={(e) => e.stopPropagation()}>
                            <input type="checkbox" name="" id={`chckItem${index}`} checked={selectedFiltersMobile.employmentTypes.includes(item)} className='fab-dropdown-check' onClick={(e) => handleCheckboxClick(e, item)} />
                            {item}
                        </label>
                    ))}
                    {sliderHeader === 'Job categories' && jobCategories.map((item, index) => (
                        <label key={index} htmlFor={`chckItem${index}`} className="fab-dropdown-menu-item" onClick={(e) => e.stopPropagation()}>
                            <input type="radio" name="" id={`chckItem${index}`} checked={selectedFiltersMobile.jobCategories.includes(item)} className='fab-dropdown-check' onClick={(e) => handleRadioClick(e, item)} />
                            {item}
                        </label>
                    ))}
                    {sliderHeader === 'Salary range' && <>
                        <div className='fab-salary-range-filter'>
                            <input
                                type="number"
                                pattern="\d*"
                                value={minSalaryMobile}
                                onChange={handleMinPriceChange}
                                inputmode="numeric"
                                placeholder="Minimum"
                                className="fab-jobs-salary-input"
                            />
                            <span className="fab-jobs-salary-separator">-</span>
                            <input
                                type="number"
                                pattern="\d*"
                                value={maxSalaryMobile}
                                onChange={handleMaxPriceChange}
                                inputmode="numeric"
                                placeholder="Maximum"
                                className="fab-jobs-salary-input"
                            />
                        </div>
                    </>}
                </div>
                <div className='fab-slider-buttons-container'>
                    <div className='fab-slider-buttons'>
                        {sliderHeader === 'Salary range' ? <Button variant="primary" size="lg" onClick={onClickApply}>Apply</Button> : <Button variant="primary" size="lg" onClick={onClickUpdate}>Update</Button>}
                        <Button variant="outline" size="lg" onClick={onClickReset}>Reset</Button>
                    </div>
                </div>
            </SlideDrawer>
        </div>
    )
}

export default JobSliderMobile;