import '../../components/globals/Profile.css'

import { Link, useNavigate } from 'react-router-dom';
import { SOMETHING_WENT_WRONG_MESSAGE, TRY_AGAIN_MESSAGE } from '../../common/constants';
import { useEffect, useRef, useState } from 'react';

import AboutMe from './AboutMe';
import EducationDetails from './EducationDetails';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import JobPreference from './JobPreference';
import LanguageProficiency from './LanguageProficiency';
import Loader from '../../components/loader/Loader';
import Resume from './Resume';
import Skills from './Skills';
import Toast from '../../components/toast/Toast';
import WorkExperience from './WorkExperience';
import analytics from '../../firebase';
import { getCodeTableChecksum } from '../../services/HomeService';
import { getProfileDetails } from '../../services/ProfileService';
import { getUserInfoAndToken } from '../../common/helper';
import { logEvent } from "firebase/analytics";

const useNoScroll = (isActive) => {
    useEffect(() => {
        if (isActive) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [isActive]);
};


const Profile = () => {
    const [activeLink, setActiveLink] = useState('about-me');
    const [user, setUser] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isScroll, setIsScroll] = useState(false);

    const [codeTableEmploymentStatusOptions, setCodeTableEmploymentStatusOptions] = useState([]);
    const [codeTableHighestEducationOptions, setCodeTableHighestEducationOptions] = useState([]);
    const [codeTableGenderOptions, setCodeTableGenderOptions] = useState([]);
    const [codeTableJobTypeOptions, setCodeTableJobTypeOptions] = useState([]);
    const [codeTableExpectedSalaryPerOptions, setCodeTableExpectedSalaryPerOptions] = useState([]);
    const [codeTableEducationLevelOptions, setCodeTableEducationLevelOptions] = useState([]);
    const [codeTableSkillOptions, setCodeTableSkillOptions] = useState([]);
    const [codeTableLanguages, setCodeTableLanguages] = useState([]);
    const [codeTableProficiencyLevels, setCodeTableProficiencyLevels] = useState([]);

    const profileSection = useRef(null);
    const aboutMeSection = useRef(null);
    const jobPreferenceSection = useRef(null);
    const workExperienceSection = useRef(null);
    const educationDetailsSection = useRef(null);
    const skillsSection = useRef(null);
    const languageProficiencySection = useRef(null);
    const resumeSection = useRef(null);

    const [errorMessageHeader, setErrorMessageHeader] = useState('');
    const [errorMessageDescription, setErrorMessageDescription] = useState('');

    const navigate = useNavigate();
    logEvent(analytics, 'profile_screen_viewed');

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef();
    const [isProfileOptions, setIsProfileOptions] = useState(false);

    const handleDropdownClick = () => {
        setIsProfileOptions(true);
    };

    const sectionRefs = {
        'about-me': aboutMeSection,
        'job-preference': jobPreferenceSection,
        'work-preference': workExperienceSection,
        'education-details': educationDetailsSection,
        'skills': skillsSection,
        'language-proficiency': languageProficiencySection,
        'resume': resumeSection
    };

    const handleScroll = () => {
        const scrollPosition = window.scrollY + window.innerHeight / 4;
        const activeSection = Object.keys(sectionRefs).find(key => {
            const ref = sectionRefs[key];
            const headerHeight = document.querySelector('.fab-header').offsetHeight;
            return ref.current.offsetTop <= scrollPosition && ref.current.offsetTop + ref.current.offsetHeight > scrollPosition - headerHeight;
        });
        setActiveLink(activeSection)
    }

    const handleClick = (ref, linkToActive) => {
        setIsScroll(true)
        setActiveLink(linkToActive)
        ref.current.scrollIntoView({ behavior: 'smooth' });
        setActiveLink(linkToActive);
        const headerHeight = document.querySelector('.fab-header').offsetHeight;
        const scrollTop = ref?.current?.offsetTop - headerHeight;
        window.scrollTo({ top: scrollTop, behavior: 'smooth' });
        setTimeout(() => {
            setIsScroll(false);
        }, 700);
    }

    const resetErrorMessages = () => {
        setErrorMessageHeader('');
        setErrorMessageDescription('');
    }

    const getAndSetDropdownOptions = async () => {
        try {
            resetErrorMessages();
            setIsLoading(true);
            const response = await getCodeTableChecksum();
            if (response?.code === '00') {
                const data = response?.data;
                if (data) {
                    const employmentStatusOptions = Object.values(data.en.Employment_Status.data);
                    setCodeTableEmploymentStatusOptions(employmentStatusOptions);

                    const highestEducationOptions = Object.values(data.Education_Level.data);
                    setCodeTableHighestEducationOptions(highestEducationOptions);

                    const genderOptions = Object.values(data.en.Gender.data);
                    setCodeTableGenderOptions(genderOptions);

                    const jobTypeOptions = Object.values(data.Job_Type.data);
                    setCodeTableJobTypeOptions(jobTypeOptions);

                    const salaryPeriodOptions = Object.values(data.Salary_Period.data);
                    setCodeTableExpectedSalaryPerOptions(salaryPeriodOptions);

                    const educationLevelOptions = Object.values(data.Education_Level.data);
                    setCodeTableEducationLevelOptions(educationLevelOptions);

                    const skillsWithIds = Object.values(data.Job_Skill.data).map((js) => ({
                        department: js.val,
                        options: Object.values(js.data)
                    }));
                    setCodeTableSkillOptions(skillsWithIds);

                    const ctl = Object.values(data.en.Job_Language.data);
                    setCodeTableLanguages(ctl);

                    const pls = Object.values(data.en.Proficiency_Lvl.data);
                    setCodeTableProficiencyLevels(pls);
                }

                resetErrorMessages();
            }
            setIsLoading(false);
        } catch (error) {
            console.log(`error occurred while fetching the code table data: ${error}`);

            setIsLoading(false);
            setErrorMessageHeader(SOMETHING_WENT_WRONG_MESSAGE);
            setErrorMessageDescription(TRY_AGAIN_MESSAGE);
        }
    }

    useEffect(() => {
        getAndSetDropdownOptions();
    }, []);

    const getProfileData = async () => {
        try {
            resetErrorMessages();
            setIsLoading(true);

            const { userId, username, token } = getUserInfoAndToken();
            const response = await getProfileDetails(userId, username, token);
            if (response?.code === '00') {
                const data = response?.data;
                if (data?.length > 0) {
                    const user = data[0];
                    setUser(user);
                }

                resetErrorMessages();
            }

            setIsLoading(false);
        } catch (error) {
            console.log(`error occurred while fetching the profile data: ${error}`);

            setIsLoading(false);
            setErrorMessageHeader(SOMETHING_WENT_WRONG_MESSAGE);
            setErrorMessageDescription(TRY_AGAIN_MESSAGE);
        }
    }

    useEffect(() => {
        getProfileData();
    }, [setUser]);

    useEffect(() => {
        if (!isScroll) {
            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            }
        }
    }, [isScroll]);

    const scrollToLanguageProficiencySection = () => {
        handleClick(languageProficiencySection, 'language-proficiency')
    }

    const onLogout = (e) => {
        e.preventDefault();
        localStorage.clear();
        logEvent(analytics, 'logout_success');
        setTimeout(() => {
            navigate('/')
            navigate(0)
        }, 100)
    }

    useNoScroll(isProfileOptions);

    const handleWorkExperienceScroll = () => handleClick(workExperienceSection, 'work-preference')

    return (
        <div className='fab-profile-wrapper'>
            {
                (errorMessageHeader && errorMessageDescription) && <Toast
                    type='error'
                    header={errorMessageHeader}
                    message={errorMessageDescription}
                    onClose={resetErrorMessages}
                />
            }
            {isLoading && <Loader></Loader>}
            <Header />
            <div className='fab-user-sidebar-options-m'>
                <div className='fab-container fab-profile-mobile-menu'>
                    <div className='fab-sidebard-mobile-dropdown'>
                        <div className='fab-sidebard-dropdown' onClick={handleDropdownClick}>
                            <span>Profile</span>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M12.5303 16.2803C12.2374 16.5732 11.7626 16.5732 11.4697 16.2803L3.96967 8.78033C3.67678 8.48744 3.67678 8.01256 3.96967 7.71967C4.26256 7.42678 4.73744 7.42678 5.03033 7.71967L12 14.6893L18.9697 7.71967C19.2626 7.42678 19.7374 7.42678 20.0303 7.71967C20.3232 8.01256 20.3232 8.48744 20.0303 8.78033L12.5303 16.2803Z" fill="#18181B" />
                            </svg>
                        </div>
                        <div ref={dropdownRef} className={`fab-sidebar-dropdown-options ${isOpen ? 'fab-sidebar-dropdown-open' : ''}`}>
                            <Link className='fab-sidebar-dropdown-item' to="/profile">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M6.24997 5C6.24997 2.92893 7.9289 1.25 9.99997 1.25C12.071 1.25 13.75 2.92893 13.75 5C13.75 7.07107 12.071 8.75 9.99997 8.75C7.9289 8.75 6.24997 7.07107 6.24997 5Z" fill="#3F3F46" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M3.12601 16.7544C3.19046 13.013 6.24329 10 9.99997 10C13.7567 10 16.8096 13.0131 16.8739 16.7547C16.8782 17.0028 16.7352 17.23 16.5097 17.3335C14.5272 18.2432 12.3221 18.75 10.0002 18.75C7.67819 18.75 5.47283 18.2431 3.49024 17.3332C3.26468 17.2297 3.12173 17.0026 3.12601 16.7544Z" fill="#3F3F46" />
                                </svg>
                                Profile
                            </Link>
                            <Link className='fab-sidebar-dropdown-item ' to="/change-password">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M13.125 1.25C10.0184 1.25 7.5 3.7684 7.5 6.875C7.5 7.2031 7.52816 7.52513 7.58233 7.83866C7.63832 8.16273 7.55565 8.43546 7.39806 8.59306L1.98223 14.0089C1.51339 14.4777 1.25 15.1136 1.25 15.7767V18.125C1.25 18.4702 1.52982 18.75 1.875 18.75H5C5.34518 18.75 5.625 18.4702 5.625 18.125V16.875H6.875C7.22018 16.875 7.5 16.5952 7.5 16.25V15H8.75C8.91576 15 9.07473 14.9342 9.19194 14.8169L11.4069 12.6019C11.5645 12.4443 11.8373 12.3617 12.1613 12.4177C12.4749 12.4718 12.7969 12.5 13.125 12.5C16.2316 12.5 18.75 9.9816 18.75 6.875C18.75 3.7684 16.2316 1.25 13.125 1.25ZM13.125 3.75C12.7798 3.75 12.5 4.02982 12.5 4.375C12.5 4.72018 12.7798 5 13.125 5C14.1605 5 15 5.83947 15 6.875C15 7.22018 15.2798 7.5 15.625 7.5C15.9702 7.5 16.25 7.22018 16.25 6.875C16.25 5.14911 14.8509 3.75 13.125 3.75Z" fill="#3F3F46" />
                                </svg>
                                Change password
                            </Link>
                            <Link className='fab-sidebar-dropdown-item' to='/applied-jobs'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M1.875 10C1.875 5.51269 5.51269 1.875 10 1.875C14.4873 1.875 18.125 5.51269 18.125 10C18.125 14.4873 14.4873 18.125 10 18.125C5.51269 18.125 1.875 14.4873 1.875 10ZM13.0086 8.48827C13.2092 8.20739 13.1442 7.81705 12.8633 7.61642C12.5824 7.41579 12.192 7.48084 11.9914 7.76173L9.29525 11.5364L7.94194 10.1831C7.69786 9.93898 7.30214 9.93898 7.05806 10.1831C6.81398 10.4271 6.81398 10.8229 7.05806 11.0669L8.93306 12.9419C9.06297 13.0719 9.24346 13.138 9.42655 13.1229C9.60964 13.1077 9.7768 13.0128 9.88358 12.8633L13.0086 8.48827Z" fill="#3F3F46" />
                                </svg>
                                Applied jobs
                            </Link>
                            <Link className='fab-sidebar-dropdown-item' to='/saved-jobs'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M5.26685 2.14784C6.81978 1.96762 8.3992 1.875 10 1.875C11.6008 1.875 13.1802 1.96762 14.7332 2.14784C15.981 2.29266 16.875 3.36435 16.875 4.58916V17.5C16.875 17.7166 16.7628 17.9178 16.5786 18.0317C16.3943 18.1455 16.1642 18.1559 15.9705 18.059L10 15.0738L4.02951 18.059C3.83577 18.1559 3.60568 18.1455 3.42142 18.0317C3.23716 17.9178 3.125 17.7166 3.125 17.5V4.58916C3.125 3.36435 4.01903 2.29265 5.26685 2.14784Z" fill="#3F3F46" />
                                </svg>
                                Saved jobs
                            </Link>
                        </div>
                    </div>
                    <div className='scrollable-div'>
                        <Link
                            className={activeLink === 'about-me' ? 'fab-profile-sidebar-profile-text' : 'fab-profile-sidebar-item fab-profile-sidebar-profile-text'}
                            onClick={() => handleClick(aboutMeSection, 'about-me')}
                        >About me</Link>
                        <Link
                            className={activeLink === 'job-preference' ? 'fab-profile-sidebar-profile-text' : 'fab-profile-sidebar-item'}
                            onClick={() => handleClick(jobPreferenceSection, 'job-preference')}
                        >Job preference</Link>
                        <Link
                            className={activeLink === 'work-preference' ? 'fab-profile-sidebar-profile-text' : 'fab-profile-sidebar-item'}
                            onClick={() => handleClick(workExperienceSection, 'work-preference')}
                        >Work preference</Link>
                        <Link
                            className={activeLink === 'education-details' ? 'fab-profile-sidebar-profile-text' : 'fab-profile-sidebar-item'}
                            onClick={() => handleClick(educationDetailsSection, 'education-details')}
                        >Education details</Link>
                        <Link
                            className={activeLink === 'skills' ? 'fab-profile-sidebar-profile-text' : 'fab-profile-sidebar-item'}
                            onClick={() => handleClick(skillsSection, 'skills')}
                        >Skills</Link>
                        <Link
                            className={activeLink === 'language-proficiency' ? 'fab-profile-sidebar-profile-text' : 'fab-profile-sidebar-item'}
                            onClick={() => handleClick(languageProficiencySection, 'language-proficiency')}
                        >Language proficiency</Link>
                    </div>
                </div>
            </div>
            <div className='fab-profile-container fab-container'>
                <div className='fab-profile-vertical-bar'>
                    <div className='fab-profile-sidebar'>
                        <div className='fab-profile-sidebar-menu fab-profile-sidebar-profile'>
                            <span className='fab-profile-sidebar-icon'>
                                <svg width='14' height='18' viewBox='0 0 14 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <path fillRule='evenodd' clipRule='evenodd' d='M3.24997 4C3.24997 1.92893 4.9289 0.25 6.99997 0.25C9.07104 0.25 10.75 1.92893 10.75 4C10.75 6.07107 9.07104 7.75 6.99997 7.75C4.9289 7.75 3.24997 6.07107 3.24997 4Z' fill='#3F3F46' />
                                    <path fillRule='evenodd' clipRule='evenodd' d='M0.126009 15.7544C0.190459 12.013 3.24329 9 6.99997 9C10.7567 9 13.8096 12.0131 13.8739 15.7547C13.8782 16.0028 13.7352 16.23 13.5097 16.3335C11.5272 17.2432 9.32207 17.75 7.00024 17.75C4.67819 17.75 2.47283 17.2431 0.490239 16.3332C0.26468 16.2297 0.121734 16.0026 0.126009 15.7544Z' fill='#3F3F46' />
                                </svg>
                            </span>
                            <Link
                                className='fab-profile-sidebar-profile-text'
                                onClick={() => handleClick(profileSection)}
                            >
                                Profile
                            </Link>
                        </div>
                        <div className='fab-profile-sidebar-tab'>
                            <Link
                                className={activeLink === 'about-me' ? 'fab-profile-sidebar-profile-text' : 'fab-profile-sidebar-item'}
                                onClick={() => handleClick(aboutMeSection, 'about-me')}
                            >About me</Link>
                        </div>
                        <div className='fab-profile-sidebar-tab'>
                            <Link
                                className={activeLink === 'job-preference' ? 'fab-profile-sidebar-profile-text' : 'fab-profile-sidebar-item'}
                                onClick={() => handleClick(jobPreferenceSection, 'job-preference')}
                            >Job preference</Link>
                        </div>
                        <div className='fab-profile-sidebar-tab'>
                            <Link
                                className={activeLink === 'work-preference' ? 'fab-profile-sidebar-profile-text' : 'fab-profile-sidebar-item'}
                                onClick={() => handleClick(workExperienceSection, 'work-preference')}
                            >Work experience</Link>
                        </div>
                        <div className='fab-profile-sidebar-tab'>
                            <Link
                                className={activeLink === 'education-details' ? 'fab-profile-sidebar-profile-text' : 'fab-profile-sidebar-item'}
                                onClick={() => handleClick(educationDetailsSection, 'education-details')}
                            >Education details</Link>
                        </div>
                        <div className='fab-profile-sidebar-tab'>
                            <Link
                                className={activeLink === 'skills' ? 'fab-profile-sidebar-profile-text' : 'fab-profile-sidebar-item'}
                                onClick={() => handleClick(skillsSection, 'skills')}
                            >Skills</Link>
                        </div>
                        <div className='fab-profile-sidebar-tab'>
                            <Link
                                className={activeLink === 'language-proficiency' ? 'fab-profile-sidebar-profile-text' : 'fab-profile-sidebar-item'}
                                onClick={() => handleClick(languageProficiencySection, 'language-proficiency')}
                            >Language proficiency</Link>
                        </div>
                        <div className='fab-profile-sidebar-tab'>
                            <Link
                                className={activeLink === 'resume' ? 'fab-profile-sidebar-profile-text' : 'fab-profile-sidebar-item'}
                                onClick={() => handleClick(resumeSection, 'resume')}
                            >Resume</Link>
                        </div>
                        <div className='fab-profile-sidebar-menu'>
                            <span className='fab-profile-sidebar-icon'>
                                <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <path fillRule='evenodd' clipRule='evenodd' d='M12.125 0.25C9.0184 0.25 6.5 2.7684 6.5 5.875C6.5 6.2031 6.52816 6.52513 6.58233 6.83866C6.63832 7.16273 6.55565 7.43546 6.39806 7.59306L0.982233 13.0089C0.513392 13.4777 0.25 14.1136 0.25 14.7767V17.125C0.25 17.4702 0.529822 17.75 0.875 17.75H4C4.34518 17.75 4.625 17.4702 4.625 17.125V15.875H5.875C6.22018 15.875 6.5 15.5952 6.5 15.25V14H7.75C7.91576 14 8.07473 13.9342 8.19194 13.8169L10.4069 11.6019C10.5645 11.4443 10.8373 11.3617 11.1613 11.4177C11.4749 11.4718 11.7969 11.5 12.125 11.5C15.2316 11.5 17.75 8.9816 17.75 5.875C17.75 2.7684 15.2316 0.25 12.125 0.25ZM12.125 2.75C11.7798 2.75 11.5 3.02982 11.5 3.375C11.5 3.72018 11.7798 4 12.125 4C13.1605 4 14 4.83947 14 5.875C14 6.22018 14.2798 6.5 14.625 6.5C14.9702 6.5 15.25 6.22018 15.25 5.875C15.25 4.14911 13.8509 2.75 12.125 2.75Z' fill='#3F3F46' />
                                </svg>
                            </span>
                            <Link className='fab-profile-sidebar-text' to='/change-password'>
                                Change password
                            </Link>
                        </div>
                        <div className='fab-profile-sidebar-menu'>
                            <span className='fab-profile-sidebar-icon'>
                                <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <path fillRule='evenodd' clipRule='evenodd' d='M0.875 9C0.875 4.51269 4.51269 0.875 9 0.875C13.4873 0.875 17.125 4.51269 17.125 9C17.125 13.4873 13.4873 17.125 9 17.125C4.51269 17.125 0.875 13.4873 0.875 9ZM12.0086 7.48827C12.2092 7.20739 12.1442 6.81705 11.8633 6.61642C11.5824 6.41579 11.192 6.48084 10.9914 6.76173L8.29525 10.5364L6.94194 9.18306C6.69786 8.93898 6.30214 8.93898 6.05806 9.18306C5.81398 9.42714 5.81398 9.82286 6.05806 10.0669L7.93306 11.9419C8.06297 12.0719 8.24346 12.138 8.42655 12.1229C8.60964 12.1077 8.7768 12.0128 8.88358 11.8633L12.0086 7.48827Z' fill='#3F3F46' />
                                </svg>
                            </span>
                            <Link className='fab-profile-sidebar-text' to='/applied-jobs'>
                                Applied jobs
                            </Link>
                        </div>
                        <div className='fab-profile-sidebar-menu'>
                            <span className='fab-profile-sidebar-icon'>
                                <svg width='14' height='18' viewBox='0 0 14 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <path fillRule='evenodd' clipRule='evenodd' d='M2.26685 1.14784C3.81978 0.967619 5.3992 0.875 7 0.875C8.6008 0.875 10.1802 0.967619 11.7332 1.14784C12.981 1.29266 13.875 2.36435 13.875 3.58916V16.5C13.875 16.7166 13.7628 16.9178 13.5786 17.0317C13.3943 17.1455 13.1642 17.1559 12.9705 17.059L7 14.0738L1.02951 17.059C0.835766 17.1559 0.605678 17.1455 0.421418 17.0317C0.237158 16.9178 0.125 16.7166 0.125 16.5V3.58916C0.125 2.36435 1.01903 1.29265 2.26685 1.14784Z' fill='#3F3F46' />
                                </svg>
                            </span>
                            <Link className='fab-profile-sidebar-text' to='/saved-jobs'>
                                Saved jobs
                            </Link>
                        </div>
                        <div className='fab-profile-sidebar-menu'>
                            <span className='fab-profile-sidebar-icon'>
                                <svg width='16' height='18' viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <path fillRule='evenodd' clipRule='evenodd' d='M3.25 2.125C2.55964 2.125 2 2.68464 2 3.375L2 14.625C2 15.3154 2.55964 15.875 3.25 15.875H8.25C8.94036 15.875 9.5 15.3154 9.5 14.625V11.5C9.5 11.1548 9.77982 10.875 10.125 10.875C10.4702 10.875 10.75 11.1548 10.75 11.5V14.625C10.75 16.0057 9.63071 17.125 8.25 17.125H3.25C1.86929 17.125 0.75 16.0057 0.75 14.625L0.75 3.375C0.750001 1.99429 1.86929 0.875 3.25 0.875L8.25 0.875C9.63071 0.875 10.75 1.99429 10.75 3.375V6.5C10.75 6.84518 10.4702 7.125 10.125 7.125C9.77982 7.125 9.5 6.84518 9.5 6.5V3.375C9.5 2.68464 8.94036 2.125 8.25 2.125L3.25 2.125ZM7.44194 6.05806C7.68602 6.30214 7.68602 6.69787 7.44194 6.94194L6.00888 8.375L15.125 8.375C15.4702 8.375 15.75 8.65482 15.75 9C15.75 9.34518 15.4702 9.625 15.125 9.625L6.00888 9.625L7.44194 11.0581C7.68602 11.3021 7.68602 11.6979 7.44194 11.9419C7.19786 12.186 6.80214 12.186 6.55806 11.9419L4.05806 9.44194C3.81398 9.19786 3.81398 8.80214 4.05806 8.55806L6.55806 6.05806C6.80214 5.81398 7.19787 5.81398 7.44194 6.05806Z' fill='#DC2626' />
                                </svg>
                            </span>
                            <Link className='fab-profile-sidebar-logout-text' onClick={(e) => onLogout(e)}>
                                Logout
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='fab-profile-layout' ref={profileSection}>
                    <div ref={aboutMeSection} className='fab-profile-section-item'>
                        <AboutMe
                            user={user}
                            setUser={setUser}
                            codeTableEmploymentStatusOptions={codeTableEmploymentStatusOptions}
                            codeTableHighestEducationOptions={codeTableHighestEducationOptions}
                            codeTableGenderOptions={codeTableGenderOptions}
                        />
                    </div>
                    <div ref={jobPreferenceSection} className='fab-profile-section-item'>
                        <JobPreference
                            user={user}
                            setUser={setUser}
                            codeTableJobTypeOptions={codeTableJobTypeOptions}
                            codeTableExpectedSalaryPerOptions={codeTableExpectedSalaryPerOptions}
                        />
                    </div>
                    <div ref={workExperienceSection} className='fab-profile-section-item'>
                        <WorkExperience user={user} handleScroll={handleWorkExperienceScroll} />
                    </div>
                    <div ref={educationDetailsSection} className='fab-profile-section-item'>
                        <EducationDetails
                            user={user}
                            codeTableEducationLevelOptions={codeTableEducationLevelOptions}
                        />
                    </div>
                    <div ref={skillsSection} className='fab-profile-section-item'>
                        <Skills
                            user={user}
                            codeTableSkillOptions={codeTableSkillOptions}
                        />
                    </div>
                    <div ref={languageProficiencySection} className='fab-profile-section-item'>
                        <LanguageProficiency
                            user={user}
                            setUser={setUser}
                            codeTableLanguages={codeTableLanguages}
                            codeTableProficiencyLevels={codeTableProficiencyLevels}
                            scrollToLanguageProficiencySection={scrollToLanguageProficiencySection}
                        />
                    </div>
                    <div ref={resumeSection} className='fab-profile-section-item fab-profile-section-resume'>
                        <Resume user={user} />
                    </div>
                </div>
            </div>
            <Footer />
            <div className={`fab-dropdown-mobile-menu ${isProfileOptions ? 'fab-profile-mobile-active' : ''}`} onClick={() => { setIsProfileOptions(false) }}>
                <div className='fab-menu-mobile-container'>
                    <div className='fab-menu-header-container'>
                        <p>Navigation</p>
                    </div>
                    <div className='fab-menu-mobile-items' >
                        <Link className='fab-sidebar-dropdown-item' to="/profile">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M6.24997 5C6.24997 2.92893 7.9289 1.25 9.99997 1.25C12.071 1.25 13.75 2.92893 13.75 5C13.75 7.07107 12.071 8.75 9.99997 8.75C7.9289 8.75 6.24997 7.07107 6.24997 5Z" fill="#3F3F46" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M3.12601 16.7544C3.19046 13.013 6.24329 10 9.99997 10C13.7567 10 16.8096 13.0131 16.8739 16.7547C16.8782 17.0028 16.7352 17.23 16.5097 17.3335C14.5272 18.2432 12.3221 18.75 10.0002 18.75C7.67819 18.75 5.47283 18.2431 3.49024 17.3332C3.26468 17.2297 3.12173 17.0026 3.12601 16.7544Z" fill="#3F3F46" />
                            </svg>
                            Profile
                        </Link>
                        <Link className='fab-sidebar-dropdown-item ' to="/change-password">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M13.125 1.25C10.0184 1.25 7.5 3.7684 7.5 6.875C7.5 7.2031 7.52816 7.52513 7.58233 7.83866C7.63832 8.16273 7.55565 8.43546 7.39806 8.59306L1.98223 14.0089C1.51339 14.4777 1.25 15.1136 1.25 15.7767V18.125C1.25 18.4702 1.52982 18.75 1.875 18.75H5C5.34518 18.75 5.625 18.4702 5.625 18.125V16.875H6.875C7.22018 16.875 7.5 16.5952 7.5 16.25V15H8.75C8.91576 15 9.07473 14.9342 9.19194 14.8169L11.4069 12.6019C11.5645 12.4443 11.8373 12.3617 12.1613 12.4177C12.4749 12.4718 12.7969 12.5 13.125 12.5C16.2316 12.5 18.75 9.9816 18.75 6.875C18.75 3.7684 16.2316 1.25 13.125 1.25ZM13.125 3.75C12.7798 3.75 12.5 4.02982 12.5 4.375C12.5 4.72018 12.7798 5 13.125 5C14.1605 5 15 5.83947 15 6.875C15 7.22018 15.2798 7.5 15.625 7.5C15.9702 7.5 16.25 7.22018 16.25 6.875C16.25 5.14911 14.8509 3.75 13.125 3.75Z" fill="#3F3F46" />
                            </svg>
                            Change password
                        </Link>
                        <Link className='fab-sidebar-dropdown-item' to='/applied-jobs'>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M1.875 10C1.875 5.51269 5.51269 1.875 10 1.875C14.4873 1.875 18.125 5.51269 18.125 10C18.125 14.4873 14.4873 18.125 10 18.125C5.51269 18.125 1.875 14.4873 1.875 10ZM13.0086 8.48827C13.2092 8.20739 13.1442 7.81705 12.8633 7.61642C12.5824 7.41579 12.192 7.48084 11.9914 7.76173L9.29525 11.5364L7.94194 10.1831C7.69786 9.93898 7.30214 9.93898 7.05806 10.1831C6.81398 10.4271 6.81398 10.8229 7.05806 11.0669L8.93306 12.9419C9.06297 13.0719 9.24346 13.138 9.42655 13.1229C9.60964 13.1077 9.7768 13.0128 9.88358 12.8633L13.0086 8.48827Z" fill="#3F3F46" />
                            </svg>
                            Applied jobs
                        </Link>
                        <Link className='fab-sidebar-dropdown-item' to='/saved-jobs'>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M5.26685 2.14784C6.81978 1.96762 8.3992 1.875 10 1.875C11.6008 1.875 13.1802 1.96762 14.7332 2.14784C15.981 2.29266 16.875 3.36435 16.875 4.58916V17.5C16.875 17.7166 16.7628 17.9178 16.5786 18.0317C16.3943 18.1455 16.1642 18.1559 15.9705 18.059L10 15.0738L4.02951 18.059C3.83577 18.1559 3.60568 18.1455 3.42142 18.0317C3.23716 17.9178 3.125 17.7166 3.125 17.5V4.58916C3.125 3.36435 4.01903 2.29265 5.26685 2.14784Z" fill="#3F3F46" />
                            </svg>
                            Saved jobs
                        </Link>
                        <Link className='fab-sidebar-dropdown-item fab-profile-sidebar-logout-text' onClick={(e) => onLogout(e)}>
                            <svg width='16' height='18' viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path fillRule='evenodd' clipRule='evenodd' d='M3.25 2.125C2.55964 2.125 2 2.68464 2 3.375L2 14.625C2 15.3154 2.55964 15.875 3.25 15.875H8.25C8.94036 15.875 9.5 15.3154 9.5 14.625V11.5C9.5 11.1548 9.77982 10.875 10.125 10.875C10.4702 10.875 10.75 11.1548 10.75 11.5V14.625C10.75 16.0057 9.63071 17.125 8.25 17.125H3.25C1.86929 17.125 0.75 16.0057 0.75 14.625L0.75 3.375C0.750001 1.99429 1.86929 0.875 3.25 0.875L8.25 0.875C9.63071 0.875 10.75 1.99429 10.75 3.375V6.5C10.75 6.84518 10.4702 7.125 10.125 7.125C9.77982 7.125 9.5 6.84518 9.5 6.5V3.375C9.5 2.68464 8.94036 2.125 8.25 2.125L3.25 2.125ZM7.44194 6.05806C7.68602 6.30214 7.68602 6.69787 7.44194 6.94194L6.00888 8.375L15.125 8.375C15.4702 8.375 15.75 8.65482 15.75 9C15.75 9.34518 15.4702 9.625 15.125 9.625L6.00888 9.625L7.44194 11.0581C7.68602 11.3021 7.68602 11.6979 7.44194 11.9419C7.19786 12.186 6.80214 12.186 6.55806 11.9419L4.05806 9.44194C3.81398 9.19786 3.81398 8.80214 4.05806 8.55806L6.55806 6.05806C6.80214 5.81398 7.19787 5.81398 7.44194 6.05806Z' fill='#DC2626' />
                            </svg>
                            Logout
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile;