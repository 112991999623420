import React, { useRef, useState, useEffect } from "react";
import "./Textfield.css";

const TextField = ({ placeholder, isPassword, onChange, value, handleOnFocus, onClear, hideRemoveIcon, ...props }) => {
  const inputRef = useRef();
  const [inputValue, setInputValue] = useState(value || "");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  useEffect(() => {
    setInputValue(value || "");
  }, [value]);

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);

    if (onChange) {
      onChange(event);
    }
  };

  const clearInput = () => {
    setInputValue("");
    inputRef.current.focus();

    if (onClear) {
      onClear()
    }
  };

  const handleOnInputFocus = (event) => {
    event.preventDefault();
    if (handleOnFocus) {
      handleOnFocus()
    }
  }

  const checkHideRemoveIcon = () => {
    if (hideRemoveIcon) {
      return hideRemoveIcon
    }
    else {
      return false
    }
  }

  return (
    <div className="fab-input-container">
      <div className="fab-input-wrapper">
        <input
          type={isPasswordVisible ? "text" : isPassword ? "password" : "text"}
          placeholder={placeholder}
          className="fab-input"
          ref={inputRef}
          value={inputValue}
          onChange={handleInputChange}
          onFocus={handleOnInputFocus}
          style={{ fontSize: "16px", maxWidth: "100%", width: "100%" }}
          {...props}
        />
        {isPassword && (
          <button
            className="fab-password-toggle"
            onClick={() => setIsPasswordVisible((prev) => !prev)}
          >
            {isPasswordVisible ? (
              <svg
                fill="none"
                height="21"
                viewBox="0 0 20 21"
                width="20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="#3f3f46">
                  <path d="m10 13c1.3807 0 2.5-1.1193 2.5-2.5 0-1.38071-1.1193-2.5-2.5-2.5-1.38071 0-2.5 1.11929-2.5 2.5 0 1.3807 1.11929 2.5 2.5 2.5z" />
                  <path
                    clipRule="evenodd"
                    d="m1.10284 10.0389c1.23938-3.72581 4.75375-6.4139 8.89756-6.4139 4.1419 0 7.655 2.6856 8.8959 6.4087.1003.3009.1004.6264.0003.9274-1.2394 3.7258-4.7538 6.4139-8.89763 6.4139-4.14191 0-7.65494-2.6856-8.89585-6.4087-.10029-.3009-.10038-.6264-.00028-.9274zm13.27216.4611c0 2.4162-1.9588 4.375-4.375 4.375-2.41625 0-4.375-1.9588-4.375-4.375 0-2.41625 1.95875-4.375 4.375-4.375 2.4162 0 4.375 1.95875 4.375 4.375z"
                    fillRule="evenodd"
                  />
                </g>
              </svg>
            ) : (
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.28033 2.71967C2.98744 2.42678 2.51256 2.42678 2.21967 2.71967C1.92678 3.01256 1.92678 3.48744 2.21967 3.78033L16.7197 18.2803C17.0126 18.5732 17.4874 18.5732 17.7803 18.2803C18.0732 17.9874 18.0732 17.5126 17.7803 17.2197L16.0352 15.4745C17.5064 14.3594 18.6595 12.8465 19.3344 11.0959C19.4814 10.7144 19.4816 10.2913 19.3347 9.90962C17.892 6.16051 14.256 3.5 9.99859 3.5C8.28207 3.5 6.66657 3.93249 5.2551 4.69444L3.28033 2.71967ZM7.75194 7.19128L8.84367 8.28301C9.18951 8.10223 9.58291 8 10.0002 8C11.3809 8 12.5002 9.11929 12.5002 10.5C12.5002 10.9173 12.398 11.3107 12.2172 11.6565L13.3091 12.7484C13.7454 12.1077 14.0004 11.3336 14.0004 10.5C14.0004 8.29086 12.2095 6.5 10.0004 6.5C9.16675 6.5 8.39268 6.75501 7.75194 7.19128Z"
                  fill="#3F3F46"
                />
                <path
                  d="M10.7484 14.4302L13.2711 16.9529C12.2462 17.3074 11.1458 17.5 10.0004 17.5C5.74298 17.5 2.10698 14.8395 0.664255 11.0904C0.517392 10.7087 0.517518 10.2856 0.66461 9.90408C1.15603 8.62932 1.90108 7.48057 2.83791 6.51969L6.0702 9.75198C6.02436 9.9943 6.00037 10.2443 6.00037 10.5C6.00037 12.7091 7.79123 14.5 10.0004 14.5C10.256 14.5 10.5061 14.476 10.7484 14.4302Z"
                  fill="#3F3F46"
                />
              </svg>
            )}
          </button>
        )}
        {(inputValue.trim().length > 0 && !checkHideRemoveIcon()) ? (
          <svg
            className="fab-input-svg fab-input-close"
            onClick={clearInput}
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.13617 5.46967C6.42907 5.17678 6.90394 5.17678 7.19683 5.46967L12.6665 10.9393L18.1362 5.46967C18.4291 5.17678 18.9039 5.17678 19.1968 5.46967C19.4897 5.76256 19.4897 6.23744 19.1968 6.53033L13.7272 12L19.1968 17.4697C19.4897 17.7626 19.4897 18.2374 19.1968 18.5303C18.9039 18.8232 18.4291 18.8232 18.1362 18.5303L12.6665 13.0607L7.19683 18.5303C6.90394 18.8232 6.42907 18.8232 6.13617 18.5303C5.84328 18.2374 5.84328 17.7626 6.13617 17.4697L11.6058 12L6.13617 6.53033C5.84328 6.23744 5.84328 5.76256 6.13617 5.46967Z"
              fill="#18181B"
            />
          </svg>
        ) : (
          <svg
            className="fab-input-svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.1666 3.75C7.4387 3.75 4.41663 6.77208 4.41663 10.5C4.41663 14.2279 7.4387 17.25 11.1666 17.25C13.0308 17.25 14.7173 16.4953 15.9396 15.273C17.1619 14.0506 17.9166 12.3642 17.9166 10.5C17.9166 6.77208 14.8945 3.75 11.1666 3.75ZM2.91663 10.5C2.91663 5.94365 6.61028 2.25 11.1666 2.25C15.723 2.25 19.4166 5.94365 19.4166 10.5C19.4166 12.5078 18.6986 14.3491 17.5066 15.7793L22.197 20.4697C22.4898 20.7626 22.4898 21.2374 22.197 21.5303C21.9041 21.8232 21.4292 21.8232 21.1363 21.5303L16.4459 16.8399C15.0157 18.032 13.1745 18.75 11.1666 18.75C6.61028 18.75 2.91663 15.0563 2.91663 10.5Z"
              fill="#18181B"
            />
          </svg>
        )}
      </div>
    </div>
  );
};

export default TextField;