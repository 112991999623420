import moment from 'moment';

/* 
    isEmpty function checks whether the given value is empty
*/
export const isEmpty = (value) => {
    return (!value || value.length === 0);
}

export const formatFiltersData = (data) => {
    const results = Object.values(data).map(item => item.val);
    return results;
}

export const getFilterDataWithCodeIds = (data) => {
    const results = Object.values(data).map(item => item);
    return results;
}

export const formSearchKeyword = (inputValue, selectedJobPositions) => {
    let keyword = []
    if (!isEmpty(inputValue)) {
        keyword.push(inputValue)
    }
    let combinedArray = [...selectedJobPositions, ...keyword]
    let combinedString = combinedArray.join(',')
    return combinedString
}


export const getSalaryText = (job) => {
    let salaryText = ''
    if (!isEmpty(job.salmin)) {
        salaryText = '$' + job.salmin
    }
    if (!isEmpty(job.salindi)) {
        salaryText = job.salindi + ' ' + salaryText
    }
    if (!isEmpty(job.salperiod)) {
        salaryText = salaryText + '/' + job.salperiod
    }
    return salaryText
}

export const mapLocationValues = (locations) => {
    return locations.map((value) => (value === 'All' ? 'All locations' : value));
}

export const updateDropdownWithAll = (value, items) => {
    return [value, ...items];
}

export const getSelectedItems = (isChecked, itemName, value, menuItems, selectedValues) => {
    let items = [];
    if (itemName === value) {
        items = isChecked ? [value] : (selectedValues.filter(item => item !== value))
    }
    else {
        if (isChecked) {
            let updatedItems = [...selectedValues, itemName]
            items = updatedItems?.includes(value) ? updatedItems.filter(item => item !== value) : [...updatedItems];
            if (updatedItems.length === menuItems.length - 1) {
                items = [value]
            }
        }
        else {
            items = selectedValues.filter(item => item !== itemName)
        }
    }
    return items
}

export const mapAndGetLocationsWithMallIds = (data, ids) => {
    const filteredLocations = ids
        .filter(mallid => data[mallid])
        .map(mallid => data[mallid]);
    return filteredLocations
}

export const getUserInfoAndToken = () => {
    const userInfo = JSON.parse(localStorage.getItem('user'));
    const userId = userInfo?.userId;
    const username = userInfo?.userEmail;
    const displayName = userInfo?.disName;
    const fullName = userInfo?.userName;
    const token = localStorage.getItem('token');
    return { userId, username, token, displayName, fullName };
}

export const getUserFullName = () => {
    const userInfo = JSON.parse(localStorage.getItem('user'));
    const username = userInfo?.userName;
    return username
}
/**
 * Converts YYYY-MM date format to Mon Year. Example: 2024-07 to Jul 2024
 */
export const formatDateForDisplay = (dateString) => {
    const date = new Date(dateString);
    const options = { month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
};

/**
 * Converts Jul 2024 date format to YYYY-MM. Example: Jul 2024 to 2024-07
 */
export const convertToISOFormat = (dateString) => {
    const [month, year] = dateString.split(' ');
    const date = new Date(`${month} 1, ${year}`);
    if (isNaN(date.getTime())) {
        return null;
    }

    const isoYear = date.getFullYear();
    const isoMonth = (date.getMonth() + 1).toString().padStart(2, '0');

    const isoDate = `${isoYear}-${isoMonth}`;
    return isoDate;
}

/**
 * Converts date to 22-Apr-1989 format.
 */
export const formatDateForAPI = (date) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };

    return date.toLocaleDateString('en-GB', options)
        .split(' ')
        .map((part, index) => index === 1 ? part.charAt(0).toUpperCase() + part.slice(1) : part)
        .join('-');
}

/**
 * Parses date from 22-Apr-1989 format to Date object.
 */
export const parseDate = (dateString) => {
    const months = {
        'Jan': 0, 'Feb': 1, 'Mar': 2, 'Apr': 3, 'May': 4, 'Jun': 5,
        'Jul': 6, 'Aug': 7, 'Sep': 8, 'Oct': 9, 'Nov': 10, 'Dec': 11
    };

    const parts = dateString.split('-');
    const day = parseInt(parts[0], 10);
    const month = parts[1];
    const year = parseInt(parts[2], 10);

    return new Date(year, months[month], day);
}

/**
 * Returns year from the give Date object.
 */
export const getYearFromDate = (dateString) => {
    const date = new Date(dateString);
    return date.getFullYear();
};

/**
 * Converts date from MMYYYY to Month Year. Example: 031991 to Mar 1991
 */
export const parseAndFormatMMYYYYDate = (dateString) => {
    const date = moment(dateString, 'MMYYYY');
    const formattedDate = date.format('MMM YYYY');
    return formattedDate;
}

/**
 * Converts date from Month Year to MMYYYY. Example: Mar 1991 to 031991
 */
export const parseAndFormatMonYearDate = (dateString) => {
    const date = moment(dateString, 'MMM YYYY');
    const formattedDate = date.format('MMYYYY');
    return formattedDate;
}

/**
 * Converts date to Mon Year format. Example: Fri Mar 01 2024 00:00:00 GMT+0530 (India Standard Time) to Mar 2024
 */
export const parseAndFormatDateToMonYear = (date) => {
    return moment(date).format('MMM YYYY');
}
