import './ApplicationMethodModal.css';

import Button from '../button/Button';
import analytics from '../../firebase';
import { logEvent } from 'firebase/analytics';
import { useState } from 'react';

const ApplicationMethodModal = ({ showApplicationMethodModal, setShowApplicationMethodModal, handleJobApply, setApplicationMethod, whatsAppNo, outlets }) => {
    const [isDisabled, setIsDisabled] = useState(true);
    const [selectedMethod, setSelectedMethod] = useState(null);
    const displayWhatsAppOption = whatsAppNo.length > 0 ? true : false

    const handleRadioChange = (event) => {
        const selectedRadio = event.target.id;
        setSelectedMethod(selectedRadio);
        const applicationMethod = selectedRadio === 'fab-application-method-fast' ? 'FAST Apply' : 'WhatsApp'
        setApplicationMethod(applicationMethod)
        if (selectedRadio === 'fab-application-method-fast' || selectedRadio === 'fab-application-method-whatspap') {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    };

    const handleModalClose = () => {
        setShowApplicationMethodModal(false)
    };

    const handleApply = () => {
        setShowApplicationMethodModal(false)
        const outletNames = outlets.map((item)=> item.name);
        const outletNamesCommaSeparated = outletNames.join(", ");
        logEvent(analytics, 'job_apply_outlet_selected', {'outlets': outletNamesCommaSeparated});
        handleJobApply()
    }

    const containerClassName = `fab-modal-container ${!showApplicationMethodModal ? 'fab-modal-container-close' : ''}`;

    return (
        <div className={containerClassName}>
            <div className='fab-modal-wrapper'>
                <h3>Application method</h3>
                <svg className='fab-modal-close' onClick={handleModalClose} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L12 10.9393L17.4697 5.46967C17.7626 5.17678 18.2374 5.17678 18.5303 5.46967C18.8232 5.76256 18.8232 6.23744 18.5303 6.53033L13.0607 12L18.5303 17.4697C18.8232 17.7626 18.8232 18.2374 18.5303 18.5303C18.2374 18.8232 17.7626 18.8232 17.4697 18.5303L12 13.0607L6.53033 18.5303C6.23744 18.8232 5.76256 18.8232 5.46967 18.5303C5.17678 18.2374 5.17678 17.7626 5.46967 17.4697L10.9393 12L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967Z" fill="#3F3F46" />
                </svg>
                <span className='fab-modal-subtext'>Select your preferred method of application below:</span>
                <div className='fab-modal-content'>
                    <div className='fab-modal-method-radios'>
                        <label
                            htmlFor="fab-application-method-fast"
                            className={selectedMethod === 'fab-application-method-fast' ? 'fab-metho-radio-active' : ''}>
                            <input type="radio" name="fab-method-radio"
                                id="fab-application-method-fast"
                                onChange={handleRadioChange}
                                checked={selectedMethod === 'fab-application-method-fast'} />
                            <span>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M14.6152 1.59495C14.9165 1.76289 15.0643 2.11463 14.9736 2.44736L12.982 9.75003H20.25C20.5487 9.75003 20.8189 9.92721 20.9379 10.2011C21.0569 10.475 21.0021 10.7934 20.7983 11.0118L10.2983 22.2618C10.063 22.5139 9.68604 22.573 9.38481 22.4051C9.08357 22.2372 8.9357 21.8854 9.02644 21.5527L11.0181 14.25H3.75002C3.45137 14.25 3.18118 14.0728 3.06216 13.7989C2.94313 13.525 2.99795 13.2066 3.20173 12.9883L13.7017 1.73829C13.937 1.48615 14.314 1.42701 14.6152 1.59495Z" fill="#FA4094" />
                                </svg>
                                FAST Apply
                            </span>
                        </label>
                        {displayWhatsAppOption && <label
                            htmlFor="fab-application-method-whatspap"
                            className={selectedMethod === 'fab-application-method-whatspap' ? 'fab-metho-radio-active' : ''}>
                            <input type="radio" name="fab-method-radio"
                                id="fab-application-method-whatspap"
                                onChange={handleRadioChange}
                                checked={selectedMethod === 'fab-application-method-whatspap'} />
                            <span>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.6476 20.1945L7.00873 20.4085C8.52618 21.309 10.2659 21.7852 12.04 21.7862H12.0438C17.4921 21.7862 21.9264 17.3529 21.9288 11.9042C21.9296 9.26363 20.9025 6.78071 19.0361 4.91297C17.1696 3.04528 14.688 2.01606 12.0476 2.01498C6.59497 2.01498 2.16076 6.44764 2.1586 11.8962C2.15786 13.7633 2.68025 15.5817 3.66983 17.1551L3.90511 17.5289L2.90623 21.1754L6.6476 20.1945ZM0.0507812 24L1.73805 17.8393C0.69737 16.0363 0.149831 13.9908 0.150629 11.8954C0.153252 5.34007 5.4882 0.00683594 12.0438 0.00683594C15.2251 0.0082045 18.2111 1.24641 20.4567 3.49354C22.7019 5.74083 23.938 8.72801 23.9369 11.905C23.9341 18.46 18.5984 23.7942 12.0438 23.7942C12.0434 23.7942 12.0441 23.7942 12.0438 23.7942H12.0387C10.0484 23.7934 8.09258 23.2942 6.35553 22.3469L0.0507812 24Z" fill="white" />
                                    <path d="M0.552975 11.8859C0.552975 13.9242 1.08572 15.893 2.10487 17.6302L0.460324 23.583L6.55209 21.9848C8.2198 22.9113 10.1191 23.3745 12.0416 23.3745C18.365 23.3745 23.5303 18.2324 23.5303 11.9091C23.5303 8.82843 22.3258 5.95627 20.1717 3.77899C17.9944 1.62486 15.1223 0.42041 12.0416 0.42041C5.71823 0.42041 0.552975 5.56251 0.552975 11.8859Z" fill="url(#paint0_linear_2371_10465)" />
                                    <path d="M0.14308 11.8766C0.14308 13.988 0.694924 16.0274 1.75063 17.8269L0.0471067 23.9932L6.35732 22.3376C8.08483 23.2974 10.0523 23.7772 12.0437 23.7772C18.5939 23.7772 23.9443 18.4507 23.9443 11.9006C23.9443 8.70949 22.6967 5.73434 20.4653 3.47897C18.21 1.24761 15.2348 -4.00543e-05 12.0437 -4.00543e-05C5.49356 -4.00543e-05 0.14308 5.32645 0.14308 11.8766ZM3.91001 17.515L3.67008 17.1551C2.68636 15.5715 2.15851 13.7481 2.15851 11.9006C2.15851 6.43014 6.59725 2.01539 12.0437 2.01539C14.683 2.01539 17.1783 3.0471 19.0257 4.89457C20.8972 6.76604 21.9289 9.26134 21.9289 11.9006C21.9289 17.347 17.4902 21.7858 12.0437 21.7858C10.2682 21.7858 8.51671 21.3059 7.00514 20.3942L6.64524 20.1782L2.9023 21.162L3.91001 17.515Z" fill="url(#paint1_linear_2371_10465)" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M9.07175 6.92958C8.84913 6.43473 8.61482 6.42481 8.40303 6.41614C8.22985 6.40873 8.03153 6.40919 7.83354 6.40919C7.63544 6.40919 7.31343 6.4836 7.0412 6.78098C6.76874 7.07836 6.00098 7.79719 6.00098 9.25922C6.00098 10.7214 7.06595 12.1342 7.21438 12.3326C7.36298 12.5308 9.27019 15.6269 12.2907 16.8181C14.801 17.808 15.3118 17.6111 15.8566 17.5616C16.4015 17.512 17.6148 16.8428 17.8624 16.1489C18.1101 15.4551 18.1101 14.8604 18.0358 14.7361C17.9615 14.6122 17.7634 14.5379 17.4662 14.3893C17.169 14.2407 15.708 13.5217 15.4356 13.4227C15.1632 13.3236 14.965 13.2741 14.7669 13.5716C14.5688 13.8688 13.9996 14.5379 13.8262 14.7361C13.6528 14.9347 13.4795 14.9595 13.1823 14.8109C12.8851 14.6618 11.928 14.3483 10.7926 13.336C9.9092 12.5484 9.31279 11.5757 9.13938 11.2782C8.96603 10.981 9.12085 10.82 9.26991 10.6719C9.40334 10.5387 9.56711 10.325 9.71572 10.1515C9.86398 9.97794 9.91353 9.85409 10.0126 9.65593C10.1116 9.45749 10.0621 9.28397 9.98783 9.13536C9.91353 8.98676 9.336 7.51715 9.07175 6.92958Z" fill="white" />
                                    <defs>
                                        <linearGradient id="paint0_linear_2371_10465" x1="11.994" y1="23.583" x2="11.994" y2="0.42041" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#20B038" />
                                            <stop offset="1" stop-color="#60D66A" />
                                        </linearGradient>
                                        <linearGradient id="paint1_linear_2371_10465" x1="11.9947" y1="23.9932" x2="11.9947" y2="-4.00543e-05" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#F9F9F9" />
                                            <stop offset="1" stop-color="white" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                                WhatsApp
                            </span>
                        </label>}
                    </div>
                    <div className='fab-method-buttons'>
                        <Button variant='primary' size='lg' isDisabled={isDisabled} onClick={handleApply}>Apply now</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApplicationMethodModal;
