import '../../components/globals/ForgotPassword.css';

import { Link, useNavigate } from 'react-router-dom';

import Button from '../../components/button/Button';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import Loader from '../../components/loader/Loader';
import TextField from '../../components/textfield/Textfield';
import analytics from '../../firebase';
import { forgotPassword } from '../../services/UserService';
import { logEvent } from "firebase/analytics";
import { useState } from 'react';

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    logEvent(analytics, 'forgot_password_viewed');
    const handleOnChange = (event) => {
        setError('')
        setEmail(event.target.value)
    }

    const handleOnClear = () => {
        setError('')
        setEmail("")
    }

    const handleOnClick = async () => {
        setIsLoading(true)
        try {
            setError('')
            const response = await forgotPassword(email);
            logEvent(analytics, 'forgot_password_initiated');
            if (response.data.code === '00') {
                localStorage.setItem('fromResetPassword', 'true');

                navigate('/')
            }
            else if (response.data.code === '98') {
                setError('Please enter a valid email address')
            }
            setIsLoading(false)
        }
        catch (error) {
            setIsLoading(false)
            setError('Something went wrong')
        }
    }

    return (
        <div className='fab-fp-wrapper'>
            <Header />
            {isLoading && <Loader></Loader>}
            <div className='fab-container fab-forgot-password'>
                <div>
                    <div className='fab-forgot-texts'>
                        <h2>Reset password</h2>
                        <p>Enter the email address associated with your account, and we'll email you a link to reset your password.</p>
                    </div>
                    <div className='fab-forgot-content'>
                        {error && <div className='fab-sign-error-alert fab-error-alert-active'>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M10 3.125C6.20304 3.125 3.125 6.20304 3.125 10C3.125 13.797 6.20304 16.875 10 16.875C13.797 16.875 16.875 13.797 16.875 10C16.875 6.20304 13.797 3.125 10 3.125ZM1.875 10C1.875 5.51269 5.51269 1.875 10 1.875C14.4873 1.875 18.125 5.51269 18.125 10C18.125 14.4873 14.4873 18.125 10 18.125C5.51269 18.125 1.875 14.4873 1.875 10ZM9.375 6.875C9.375 6.52982 9.65482 6.25 10 6.25H10.0062C10.3514 6.25 10.6312 6.52982 10.6312 6.875V6.88125C10.6312 7.22643 10.3514 7.50625 10.0062 7.50625H10C9.65482 7.50625 9.375 7.22643 9.375 6.88125V6.875ZM9.13007 8.7987C10.0853 8.3211 11.1608 9.18384 10.9018 10.2199L10.3109 12.5833L10.3455 12.566C10.6542 12.4116 11.0296 12.5367 11.184 12.8455C11.3384 13.1542 11.2133 13.5296 10.9045 13.684L10.8699 13.7013L10.8699 13.7013C9.91474 14.1789 8.83923 13.3162 9.09824 12.2801L9.68908 9.91673L9.65451 9.93402C9.34577 10.0884 8.97035 9.96325 8.81598 9.65451C8.66161 9.34577 8.78675 8.97035 9.09549 8.81598L9.13007 8.7987Z" fill="#B91C1C" />
                            </svg>
                            <span className='fab-sign-single-error'>{error}</span>
                        </div>}
                        <label htmlFor="">Enter your email address below</label>
                        <TextField placeholder="example@email.com" value={email} onChange={handleOnChange} onClear={handleOnClear} />
                        <div className="fab-forgot-buttons">
                            <Button variant="primary" size="md" onClick={handleOnClick}>Send reset link</Button>
                        </div>
                        <p className='fab-forgot-login'>
                            Already have an account?
                            <Link to='/login'>
                                Log in now.
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ForgotPassword;
