import '../../components/globals/AppliedSaveJobs.css';
import '../../components/globals/UserSidebar.css';

import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { getAllSavedJobs, getSavedJobs, unsaveJob } from '../../services/jobService';

import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { JOBS_PAGE_SIZE } from '../../common/constants';
import JobCard from "../../components/jobcard/JobCard";
import Loader from '../../components/loader/Loader';
import NoResults from '../../components/NoResults/NoResults';
import Pagination from '../../components/pagination/Pagination';
import Toast from "../../components/toast/Toast";
import UserSidebar from "./UserSidebar";
import analytics from '../../firebase';
import { logEvent } from "firebase/analytics";

const toastInitialState = {
    type: '',
    header: '',
    message: ''
}

const SavedJobs = () => {
    const [currentPage, setCurrentPage] = useState(0)
    const [bookmarkedCards, setBookmarkedCards] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [jobsTotal, setJobsTotal] = useState(0);
    const [jobs, setJobs] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const [toastProps, setToastProps] = useState(toastInitialState)
    const [isLoading, setIsLoading] = useState(false);
    const dropdownRef = useRef();
    const navigate = useNavigate();
    const activeLink = 'saved-jobs'
    logEvent(analytics, 'saved_job_screen_viewed');

    const getSavedJobsData = async () => {
        try {
            setIsLoading(true);
            const response = await getSavedJobs(currentPage);
            const jobsIds = response.data.map(x => x.jobid);
            setJobsTotal(response.totalData);
            setJobs(response.data);
            setBookmarkedCards(jobsIds);
            setIsLoading(false);
        } catch (error) {
            console.log(`Get saved jobs error: ${error}`);
            setIsLoading(false)
            setShowToast(true)
            setToastProps({
                type: 'error',
                header: 'Something went wrong',
                message: 'Please try again'
            })
        }
    }

    const onJobApply = (jobId, job) => {
        logEvent(analytics, 'job_details_viewed', { 'click_source': 'Saved Jobs', 'job_name': job.jobttl, 'job_id': job.jobid, 'company_name': job.coydispm, 'company_id': job.coyid });
        navigate(`/jobs/${jobId}`);
    }

    const handleToastClose = () => {
        setShowToast(false)
        setToastProps(toastInitialState)
    }

    const fetchAllSavedJobs = async () => {
        try {
            const allJobs = await getAllSavedJobs();
            const jobsIds = allJobs.map(x => x.jobid);
            setJobs(allJobs);
            setBookmarkedCards(jobsIds);
        }
        catch (error) {
            console.log(`Failed to fetch all saved jobs ${error}`)
        }
    }

    const unsaveJobs = async (cardId, favId) => {
        try {
            const unsavedRes = await unsaveJob(cardId, favId);
            if (unsavedRes.code === '00') {
                await fetchAllSavedJobs();
                setShowToast(true)
                setToastProps({
                    type: 'success',
                    header: 'Job unsaved successfully',
                    message: <>The job has been removed from your <Link to='/saved-jobs'>Saved Jobs</Link> listing.</>
                })
            }
        }
        catch (error) {
            console.log(`Failed to unsave a job ${error}`)
            await fetchAllSavedJobs();
            setIsLoading(false);
            setShowToast(true)
            setToastProps({
                type: 'error',
                header: 'Something went wrong',
                message: 'Please try unsaving the job again'
            })
        }
    }

    const handleBookmarkClick = async (cardId, bookmarkedCards, e, job) => {
        e.stopPropagation();
        setBookmarkedCards((prevBookmarkedCards) =>
            prevBookmarkedCards.includes(cardId)
                ? prevBookmarkedCards.filter((id) => id !== cardId)
                : [...prevBookmarkedCards, cardId]
        );
        logEvent(analytics, 'job_unsaved', { 'click_source': 'saved jobs', 'job_name': job.jobttl, 'job_id': job.jobid, 'company_name': job.coydispm, 'company_id': job.coyid });
        setIsLoading(true);
        const savedJob = jobs?.find(x => x.jobid === cardId);
        await unsaveJobs(cardId, savedJob.favid);

        setIsLoading(false);
    };

    const handleDropdownClick = () => {
        setIsOpen(!isOpen);
    };
    const onLogout = (e) => {
        e.preventDefault();
        localStorage.clear();
        logEvent(analytics, 'logout_success');
        setTimeout(() => {
            navigate('/')
            navigate(0)
        }, 100)
    }

    useEffect(() => {
        getSavedJobsData();
    }, [currentPage])

    return (
        <div className='fab-aj-wrapper'>
            {isLoading && <Loader></Loader>}
            {showToast && <Toast type={toastProps.type} header={toastProps.header} message={toastProps.message} onClose={handleToastClose} />}
            <Header />
            <div className={'fab-saved-applied-jobs-container'}>
                <div className='fab-user-sidebar-options-m'>
                    <div className='fab-container'>
                        <div className='fab-sidebard-mobile-dropdown'>
                            <div className='fab-sidebard-dropdown' onClick={handleDropdownClick}>
                                <span>Saved jobs</span>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12.5303 16.2803C12.2374 16.5732 11.7626 16.5732 11.4697 16.2803L3.96967 8.78033C3.67678 8.48744 3.67678 8.01256 3.96967 7.71967C4.26256 7.42678 4.73744 7.42678 5.03033 7.71967L12 14.6893L18.9697 7.71967C19.2626 7.42678 19.7374 7.42678 20.0303 7.71967C20.3232 8.01256 20.3232 8.48744 20.0303 8.78033L12.5303 16.2803Z" fill="#18181B" />
                                </svg>
                            </div>
                            <div ref={dropdownRef} className={`fab-sidebar-dropdown-options ${isOpen ? 'fab-sidebar-dropdown-open' : ''}`}>
                                <Link className='fab-sidebar-dropdown-item' to="/profile">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M6.24997 5C6.24997 2.92893 7.9289 1.25 9.99997 1.25C12.071 1.25 13.75 2.92893 13.75 5C13.75 7.07107 12.071 8.75 9.99997 8.75C7.9289 8.75 6.24997 7.07107 6.24997 5Z" fill="#3F3F46" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M3.12601 16.7544C3.19046 13.013 6.24329 10 9.99997 10C13.7567 10 16.8096 13.0131 16.8739 16.7547C16.8782 17.0028 16.7352 17.23 16.5097 17.3335C14.5272 18.2432 12.3221 18.75 10.0002 18.75C7.67819 18.75 5.47283 18.2431 3.49024 17.3332C3.26468 17.2297 3.12173 17.0026 3.12601 16.7544Z" fill="#3F3F46" />
                                    </svg>
                                    Profile
                                </Link>
                                <Link className='fab-sidebar-dropdown-item' to="/change-password">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M13.125 1.25C10.0184 1.25 7.5 3.7684 7.5 6.875C7.5 7.2031 7.52816 7.52513 7.58233 7.83866C7.63832 8.16273 7.55565 8.43546 7.39806 8.59306L1.98223 14.0089C1.51339 14.4777 1.25 15.1136 1.25 15.7767V18.125C1.25 18.4702 1.52982 18.75 1.875 18.75H5C5.34518 18.75 5.625 18.4702 5.625 18.125V16.875H6.875C7.22018 16.875 7.5 16.5952 7.5 16.25V15H8.75C8.91576 15 9.07473 14.9342 9.19194 14.8169L11.4069 12.6019C11.5645 12.4443 11.8373 12.3617 12.1613 12.4177C12.4749 12.4718 12.7969 12.5 13.125 12.5C16.2316 12.5 18.75 9.9816 18.75 6.875C18.75 3.7684 16.2316 1.25 13.125 1.25ZM13.125 3.75C12.7798 3.75 12.5 4.02982 12.5 4.375C12.5 4.72018 12.7798 5 13.125 5C14.1605 5 15 5.83947 15 6.875C15 7.22018 15.2798 7.5 15.625 7.5C15.9702 7.5 16.25 7.22018 16.25 6.875C16.25 5.14911 14.8509 3.75 13.125 3.75Z" fill="#3F3F46" />
                                    </svg>
                                    Change password
                                </Link>
                                <Link className='fab-sidebar-dropdown-item' to='/applied-jobs'>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M1.875 10C1.875 5.51269 5.51269 1.875 10 1.875C14.4873 1.875 18.125 5.51269 18.125 10C18.125 14.4873 14.4873 18.125 10 18.125C5.51269 18.125 1.875 14.4873 1.875 10ZM13.0086 8.48827C13.2092 8.20739 13.1442 7.81705 12.8633 7.61642C12.5824 7.41579 12.192 7.48084 11.9914 7.76173L9.29525 11.5364L7.94194 10.1831C7.69786 9.93898 7.30214 9.93898 7.05806 10.1831C6.81398 10.4271 6.81398 10.8229 7.05806 11.0669L8.93306 12.9419C9.06297 13.0719 9.24346 13.138 9.42655 13.1229C9.60964 13.1077 9.7768 13.0128 9.88358 12.8633L13.0086 8.48827Z" fill="#3F3F46" />
                                    </svg>
                                    Applied jobs
                                </Link>
                                <Link className='fab-sidebar-dropdown-item fab-sidebar-dropdown-item-active' to='/saved-jobs'>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.26685 2.14784C6.81978 1.96762 8.3992 1.875 10 1.875C11.6008 1.875 13.1802 1.96762 14.7332 2.14784C15.981 2.29266 16.875 3.36435 16.875 4.58916V17.5C16.875 17.7166 16.7628 17.9178 16.5786 18.0317C16.3943 18.1455 16.1642 18.1559 15.9705 18.059L10 15.0738L4.02951 18.059C3.83577 18.1559 3.60568 18.1455 3.42142 18.0317C3.23716 17.9178 3.125 17.7166 3.125 17.5V4.58916C3.125 3.36435 4.01903 2.29265 5.26685 2.14784Z" fill="#3F3F46" />
                                    </svg>
                                    Saved jobs
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='fab-container'>
                    <div className='fab-applied-wrapped'>
                        <div className='fab-user-sidebar-options'>
                            <UserSidebar linkActive={activeLink} />
                        </div>
                        <div>
                            <div className='fab-applied-title-wrapper'>
                                <h3>Saved jobs</h3>
                            </div>
                            {/* Cards */}
                            {(!isLoading) && jobs?.length === 0 && <div className='fab-container fab-no-results-container'>
                                <NoResults justifyContent='start' />
                            </div>}
                            <div className='fab-jobs-ad-container fab-applied-cards'>
                                {jobs?.map((job) => (
                                    <JobCard job={job} buttonText={'Apply now'} applied={job.applied} withSchedule={job.withSchedule} bookmarkedCards={bookmarkedCards} handleBookmarkClick={handleBookmarkClick} handleApply={onJobApply} changeWidth={true} isSavedJobs={true} isLoggedIn={true} />
                                ))}
                            </div>
                            {/* Add Pagination */}
                            {jobs?.length > 0 && <div className='fab-applied-pagination'>
                                {<Pagination pageSize={JOBS_PAGE_SIZE} total={jobsTotal} setCurrentPage={setCurrentPage} currentPage={currentPage} />}
                            </div>}
                        </div>
                    </div>
                </div>
                {(!isLoading && jobs?.length > 0) && <div className='fab-applied-position'><Footer /></div>}
            </div>
            <div className={`fab-dropdown-mobile-menu ${isOpen ? 'fab-profile-mobile-active' : ''}`} onClick={() => { setIsOpen(false) }}>
                <div className='fab-menu-mobile-container'>
                    <div className='fab-menu-header-container'>
                        <p>Navigation</p>
                    </div>
                    <div className='fab-menu-mobile-items' >
                        <Link className='fab-sidebar-dropdown-item' to="/profile">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M6.24997 5C6.24997 2.92893 7.9289 1.25 9.99997 1.25C12.071 1.25 13.75 2.92893 13.75 5C13.75 7.07107 12.071 8.75 9.99997 8.75C7.9289 8.75 6.24997 7.07107 6.24997 5Z" fill="#3F3F46" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M3.12601 16.7544C3.19046 13.013 6.24329 10 9.99997 10C13.7567 10 16.8096 13.0131 16.8739 16.7547C16.8782 17.0028 16.7352 17.23 16.5097 17.3335C14.5272 18.2432 12.3221 18.75 10.0002 18.75C7.67819 18.75 5.47283 18.2431 3.49024 17.3332C3.26468 17.2297 3.12173 17.0026 3.12601 16.7544Z" fill="#3F3F46" />
                            </svg>
                            Profile
                        </Link>
                        <Link className='fab-sidebar-dropdown-item ' to="/change-password">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M13.125 1.25C10.0184 1.25 7.5 3.7684 7.5 6.875C7.5 7.2031 7.52816 7.52513 7.58233 7.83866C7.63832 8.16273 7.55565 8.43546 7.39806 8.59306L1.98223 14.0089C1.51339 14.4777 1.25 15.1136 1.25 15.7767V18.125C1.25 18.4702 1.52982 18.75 1.875 18.75H5C5.34518 18.75 5.625 18.4702 5.625 18.125V16.875H6.875C7.22018 16.875 7.5 16.5952 7.5 16.25V15H8.75C8.91576 15 9.07473 14.9342 9.19194 14.8169L11.4069 12.6019C11.5645 12.4443 11.8373 12.3617 12.1613 12.4177C12.4749 12.4718 12.7969 12.5 13.125 12.5C16.2316 12.5 18.75 9.9816 18.75 6.875C18.75 3.7684 16.2316 1.25 13.125 1.25ZM13.125 3.75C12.7798 3.75 12.5 4.02982 12.5 4.375C12.5 4.72018 12.7798 5 13.125 5C14.1605 5 15 5.83947 15 6.875C15 7.22018 15.2798 7.5 15.625 7.5C15.9702 7.5 16.25 7.22018 16.25 6.875C16.25 5.14911 14.8509 3.75 13.125 3.75Z" fill="#3F3F46" />
                            </svg>
                            Change password
                        </Link>
                        <Link className='fab-sidebar-dropdown-item' to='/applied-jobs'>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M1.875 10C1.875 5.51269 5.51269 1.875 10 1.875C14.4873 1.875 18.125 5.51269 18.125 10C18.125 14.4873 14.4873 18.125 10 18.125C5.51269 18.125 1.875 14.4873 1.875 10ZM13.0086 8.48827C13.2092 8.20739 13.1442 7.81705 12.8633 7.61642C12.5824 7.41579 12.192 7.48084 11.9914 7.76173L9.29525 11.5364L7.94194 10.1831C7.69786 9.93898 7.30214 9.93898 7.05806 10.1831C6.81398 10.4271 6.81398 10.8229 7.05806 11.0669L8.93306 12.9419C9.06297 13.0719 9.24346 13.138 9.42655 13.1229C9.60964 13.1077 9.7768 13.0128 9.88358 12.8633L13.0086 8.48827Z" fill="#3F3F46" />
                            </svg>
                            Applied jobs
                        </Link>
                        <Link className='fab-sidebar-dropdown-item' to='/saved-jobs'>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M5.26685 2.14784C6.81978 1.96762 8.3992 1.875 10 1.875C11.6008 1.875 13.1802 1.96762 14.7332 2.14784C15.981 2.29266 16.875 3.36435 16.875 4.58916V17.5C16.875 17.7166 16.7628 17.9178 16.5786 18.0317C16.3943 18.1455 16.1642 18.1559 15.9705 18.059L10 15.0738L4.02951 18.059C3.83577 18.1559 3.60568 18.1455 3.42142 18.0317C3.23716 17.9178 3.125 17.7166 3.125 17.5V4.58916C3.125 3.36435 4.01903 2.29265 5.26685 2.14784Z" fill="#3F3F46" />
                            </svg>
                            Saved jobs
                        </Link>
                        <Link className='fab-sidebar-dropdown-item fab-profile-sidebar-logout-text' onClick={(e) => onLogout(e)}>
                            <svg width='16' height='18' viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path fillRule='evenodd' clipRule='evenodd' d='M3.25 2.125C2.55964 2.125 2 2.68464 2 3.375L2 14.625C2 15.3154 2.55964 15.875 3.25 15.875H8.25C8.94036 15.875 9.5 15.3154 9.5 14.625V11.5C9.5 11.1548 9.77982 10.875 10.125 10.875C10.4702 10.875 10.75 11.1548 10.75 11.5V14.625C10.75 16.0057 9.63071 17.125 8.25 17.125H3.25C1.86929 17.125 0.75 16.0057 0.75 14.625L0.75 3.375C0.750001 1.99429 1.86929 0.875 3.25 0.875L8.25 0.875C9.63071 0.875 10.75 1.99429 10.75 3.375V6.5C10.75 6.84518 10.4702 7.125 10.125 7.125C9.77982 7.125 9.5 6.84518 9.5 6.5V3.375C9.5 2.68464 8.94036 2.125 8.25 2.125L3.25 2.125ZM7.44194 6.05806C7.68602 6.30214 7.68602 6.69787 7.44194 6.94194L6.00888 8.375L15.125 8.375C15.4702 8.375 15.75 8.65482 15.75 9C15.75 9.34518 15.4702 9.625 15.125 9.625L6.00888 9.625L7.44194 11.0581C7.68602 11.3021 7.68602 11.6979 7.44194 11.9419C7.19786 12.186 6.80214 12.186 6.55806 11.9419L4.05806 9.44194C3.81398 9.19786 3.81398 8.80214 4.05806 8.55806L6.55806 6.05806C6.80214 5.81398 7.19787 5.81398 7.44194 6.05806Z' fill='#DC2626' />
                            </svg>
                            Logout
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SavedJobs;