import React from 'react';
import Button from '../../components/button/Button';
import '../../components/globals/JobLocationSelectionModalMobile.css';

const JobLocationSelectionModalMobile = ({ jobLocations, setShowLocationSelectionModal, handleSelectionModalNextButton, selectedMallIds, handleCheckboxClick, locationLimit }) => {
    const handleBackClick = () => {
        setShowLocationSelectionModal(false)
    }

    return (
        <div className="fab-location-modal-mobile">
            <div className="fab-location-modal-header-mobile">
                <button className="fab-location-back-wrapper" onClick={handleBackClick}>
                    <svg className="fab-location-back-icon" xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0.71967 9.53033C0.426777 9.23744 0.426777 8.76256 0.71967 8.46967L8.21967 0.96967C8.51256 0.676777 8.98744 0.676777 9.28033 0.96967C9.57322 1.26256 9.57322 1.73744 9.28033 2.03033L2.31066 9L9.28033 15.9697C9.57322 16.2626 9.57322 16.7374 9.28033 17.0303C8.98744 17.3232 8.51256 17.3232 8.21967 17.0303L0.71967 9.53033Z" fill="#18181B" />
                    </svg>
                    <span className='fab-location-back-text'>Back</span>
                </button>
            </div>
            <div className="fab-selection-location-modal-content-mobile">
                <h3 className='fab-location-mobile-header'>Which location would you like to apply to?</h3>
                <span className='fab-selection-sub-heading'>You can select up to {locationLimit} locations</span>
                <div className='fab-selected-count'>{selectedMallIds.length} selected</div>
                <div className='fab-selected-location-content-wrapper-mobile'>
                    {jobLocations.map((value, index) => {
                        return (
                            <label key={index} htmlFor={`chckItem${index}`} className="fab-selected-location-item" onClick={(e) => e.stopPropagation()}>
                                <input type="checkbox" name="" id={`chckItem${index}`} checked={selectedMallIds?.includes(value)} className='fab-selected-location-check' onClick={(e) => handleCheckboxClick(e, value)} />
                                {value.name}
                            </label>
                        )
                    })}
                </div>
            </div>
            <div className='fab-selected-locations-footer'>
                {locationLimit === selectedMallIds.length && <div className='fab-selected-locations-error-mobile'>You have reached the limit for location selection.</div>}
                <div className='fab-selected-location-buttons-mobile'>
                    <Button variant="primary" size="md" onClick={handleSelectionModalNextButton}>Next: Application method</Button>
                </div>
            </div>
        </div>
    )
}

export default JobLocationSelectionModalMobile;