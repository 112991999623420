import '../../components/globals/ProgressiveApplication.css';

import React, { useEffect, useState } from 'react';
import { updateEmploymentStatus, updateHighestEducation, updateLanguageProficiencies } from '../../services/ProfileService';

import JobApplicationEducation from './JobApplicationEducation';
import JobApplicationEmployment from './JobApplicationEmployment';
import JobApplicationLanguage from "./JobApplicationLanguage";
import Loader from '../../components/loader/Loader';
import MobileVerification from "./MobileVerification";
import ProgressiveApplicationInfo from './ProgressiveApplicationInfo';
import Toast from '../../components/toast/Toast';
import analytics from '../../firebase';
import { getCodeTableChecksum } from '../../services/HomeService';
import { getUserInfoAndToken } from '../../common/helper';
import { logEvent } from "firebase/analytics";

const toastInitialState = {
    type: '',
    header: '',
    message: ''
}

const ProgressiveApplication = ({ isOpen, handleApply, isMobileVerified, mobileNumber, isLanguageFilled, isEducationFilled, isEmploymentstatusFilled, setIsMobileVerified, setIsApplicationOpen }) => {
    const { userId, username, token } = getUserInfoAndToken();
    const [activeStep, setActiveStep] = useState(1)
    const [employmentStatus, setEmploymentStatus] = useState([]);
    const [selectedEmploymentStatus, setSelectedEmploymentStatus] = useState('');
    const [highestEducation, setHighestEducation] = useState([]);
    const [selectedHighestEducation, setSelectedHighestEducation] = useState('');
    const [languages, setLanguages] = useState([]);
    const [languagesWithIds, setLanguagesWithIds] = useState([])
    const [proficiencyLevel, setProficiencyLevel] = useState([])
    const [isFirstStep, setIsFirstStep] = useState(1)
    const [isLastStep, setIsLastStep] = useState(1)
    const [showLoader, setShowLoader] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastProps, setToastProps] = useState(toastInitialState)
    const [showProgressiveInfo, setShowProgressiveInfo] = useState(!isLanguageFilled || !isEducationFilled || !isEmploymentstatusFilled);

    const handleClose = () => {
        setIsApplicationOpen(false)
    }

    const handleNextStep = () => {
        let nextStep = activeStep + 1;

        while (nextStep <= totalSteps() && !isStepApplicable(nextStep)) {
            nextStep++;
        }

        setActiveStep(nextStep);
    };

    const handlePrevStep = () => {
        let prevStep = activeStep - 1;

        while (prevStep >= 1 && !isStepApplicable(prevStep)) {
            prevStep--;
        }

        setActiveStep(prevStep);
    };

    const handleToastClose = () => {
        setShowToast(false)
        setToastProps(toastInitialState)
    }

    const totalSteps = () => {
        let count = 3;
        if (isLanguageFilled) count--;
        if (isEmploymentstatusFilled) count--;
        if (isEducationFilled) count--;

        return count;
    };

    const isStepApplicable = (step) => {
        switch (step) {
            case 1:
                return !isLanguageFilled;
            case 2:
                return !isEmploymentstatusFilled;
            case 3:
                return !isEducationFilled;
            default:
                return false;
        }
    };

    const mapAndGetSelectedLanguageIds = () => {
        const checkedValues = [];

        for (const [language, data] of Object.entries(languages)) {
            if (data.checked) {
                const languageId = languagesWithIds.find(lang => lang.val === language)?.codeid;
                const spokenId = data.spokenProficiency ? proficiencyLevel.find(proficiency => proficiency.val === data.spokenProficiency)?.codeid : '';
                const writtenId = data.writtenProficiency ? proficiencyLevel.find(proficiency => proficiency.val === data.writtenProficiency)?.codeid : '';

                checkedValues.push({ id: languageId, spoken: spokenId, written: writtenId });
            }
        }

        return checkedValues;
    };

    const getLanguageNames = () => {
        const languageValues = [];

        for (const [language, data] of Object.entries(languages)) {
            if (data.checked) {
                const lanName = languagesWithIds.find(lang => lang.val === language)?.val;
                languageValues.push(lanName);
            }
        }

        return languageValues;
    }


    const saveLanguages = async () => {
        try {
            setShowLoader(true)
            const languages = mapAndGetSelectedLanguageIds()
            const response = await updateLanguageProficiencies(userId, username, token, languages)
            if (response?.code === '00') {
                logEvent(analytics, 'apply_language_updated', {'languages': JSON.stringify(getLanguageNames())});
                if (isLastStep === 1) {
                    setShowProgressiveInfo(false)
                    handleApply()
                }
                else {
                    handleNextStep()
                }
                setShowLoader(false)
            }
            else if (response?.code === '98') {
                setShowLoader(false)
                setShowToast(true)
                setToastProps({
                    type: 'error',
                    header: 'Languages update failed',
                    message: response.message[0]
                })

            }
        }
        catch (error) {
            //TODO
            setShowLoader(false)
            console.log(`Error occurred while updating the languages: ${error}`);
        }
    }

    const saveEmploymentStatus = async () => {
        try {
            setShowLoader(true)
            const statusId = selectedEmploymentStatus.codeid
            const response = await updateEmploymentStatus(userId, username, token, statusId)
            if (response?.code === '00' || response?.code === '98') {
                logEvent(analytics, 'apply_employment_status_updated', {'employment_status': selectedEmploymentStatus.val});
                if (isLastStep === 2) {
                    setShowProgressiveInfo(false)
                    handleApply()
                }
                else {
                    handleNextStep()
                }
                setShowLoader(false)
            }
        }
        catch (error) {
            //TODO
            setShowLoader(false)
            console.log(`Error occurred while updating the languages: ${error}`);
        }
    }

    const saveHighestEducation = async () => {
        try {
            setShowLoader(true)
            const educationId = selectedHighestEducation.codeid
            const response = await updateHighestEducation(userId, username, token, educationId)
            if (response?.code === '00') {
                logEvent(analytics, 'apply_education_updated', {'education_level': selectedHighestEducation.val});
                handleApply()
                setShowLoader(false)
                setShowProgressiveInfo(false)
            }
        }
        catch (error) {
            //TODO
            setShowLoader(false)
            console.log(`Error occurred while updating the languages: ${error}`);
        }
    }


    const transformLanguages = (languageOptions) => {
        const transformedData = {};

        languageOptions.forEach(language => {
            transformedData[language.val] = { checked: false, spokenProficiency: '', writtenProficiency: '' };
        });
        return transformedData
    }

    const getAndSetDropdownOptions = async () => {
        setShowLoader(true)
        const response = await getCodeTableChecksum();
        if (response?.code === '00') {
            setShowLoader(false)
            const data = response?.data;
            if (data) {
                const employmentStatusOptions = Object.values(data.en.Employment_Status.data);
                setEmploymentStatus(employmentStatusOptions);

                const highestEducationOptions = Object.values(data.Education_Level.data);
                setHighestEducation(highestEducationOptions);


                const languageOptions = Object.values(data.en.Job_Language.data);
                const formattedLanguages = transformLanguages(languageOptions)
                setLanguagesWithIds(languageOptions)
                setLanguages(formattedLanguages);

                const proficiencies = Object.values(data.en.Proficiency_Lvl.data)
                setProficiencyLevel(proficiencies)
            }
        }
    }

    useEffect(() => {
        getAndSetDropdownOptions();

        let firstAvailableStep = 1;
        for (let i = 1; i <= 3; i++) {
            if (isStepApplicable(i)) {
                firstAvailableStep = i;
                setActiveStep(firstAvailableStep);
                setIsFirstStep(firstAvailableStep)
                break;
            }
        }
        let lastAvailableStep = 1;

        for (let i = 2; i <= 3; i++) {
            if (isStepApplicable(i)) {
                lastAvailableStep = i;
            }
        }
        setIsLastStep(lastAvailableStep);
    }, []);

    if (!isMobileVerified) {
        logEvent(analytics, 'mobile_verify_screen_viewed', {'click_source': 'profile', 'userid': userId});
        return (
            <div className={`fab-modal-container fab-progressive-application ${isOpen ? 'modal-active' : ''}`}>
                <MobileVerification mobileNumber={mobileNumber} setIsMobileVerified={setIsMobileVerified} handleClose={handleClose}
                />
            </div>)
    }

    if (isMobileVerified && showProgressiveInfo) {
        return (
            <div className={`fab-modal-container fab-progressive-application ${isOpen ? 'modal-active' : ''}`}>
                <ProgressiveApplicationInfo setShowProgressiveInfo={setShowProgressiveInfo} />
            </div>
        )
    }

    else if (isMobileVerified && !showProgressiveInfo && !isLanguageFilled && activeStep === 1) {
        return (
            <div className={`fab-modal-container fab-progressive-application ${isOpen ? 'modal-active' : ''}`}>
                {showLoader && <Loader />}
                {showToast && <Toast type={toastProps.type} header={toastProps.header} message={toastProps.message} onClose={handleToastClose} />}
                <JobApplicationLanguage
                    handlePrevStep={handlePrevStep}
                    totalSteps={totalSteps()}
                    languageOptions={languages}
                    setLanguages={setLanguages}
                    saveLanguages={saveLanguages}
                    handleClose={handleClose}
                    isFirstStep={isFirstStep}
                />
            </div>
        )
    }

    else if (isMobileVerified && !showProgressiveInfo && !isEmploymentstatusFilled && activeStep === 2) {
        return (
            <div className={`fab-modal-container fab-progressive-application ${isOpen ? 'modal-active' : ''}`}>
                {showLoader && <Loader />}
                <JobApplicationEmployment
                    handlePrevStep={handlePrevStep}
                    totalSteps={totalSteps()}
                    employmentStatusOptions={employmentStatus}
                    setSelectedEmploymentStatus={setSelectedEmploymentStatus}
                    saveEmploymentStatus={saveEmploymentStatus}
                    selectedEmploymentStatus={selectedEmploymentStatus}
                    handleClose={handleClose}
                    isFirstStep={isFirstStep}
                />
            </div>
        )
    }

    else if (isMobileVerified && !showProgressiveInfo && !isEducationFilled && activeStep === 3) {
        return (
            <div className={`fab-modal-container fab-progressive-application ${isOpen ? 'modal-active' : ''}`}>
                {showLoader && <Loader />}
                <JobApplicationEducation
                    handlePrevStep={handlePrevStep}
                    totalSteps={totalSteps()}
                    eductionOptions={highestEducation}
                    setSelectedHighestEducation={setSelectedHighestEducation}
                    saveHighestEducation={saveHighestEducation}
                    selectedHighestEducation={selectedHighestEducation}
                    handleClose={handleClose}
                    isFirstStep={isFirstStep}
                />
            </div>
        )
    }
}

export default ProgressiveApplication;
