import React from 'react';
import '../../components/globals/JobCategories.css';

const JobCategories = ({ jobCategories, selectedItems, setSelectedItems, setCurrentPage }) => {

    const handleCheckboxClick = (e, category) => {
        e.stopPropagation();
        setSelectedItems((prevItems) => ({
            ...prevItems,
            jobCategories: [category]
        }))
        setCurrentPage(0)
    };

    return (
        <div className='fab-jobs-categories-container'>
            <h3 className='fab-jobs-categories-title'>Job categories</h3>
            <div className='fab-jobs-categories'>
                {jobCategories.map((category, index) => (
                    <div className='fab-category' key={index}>
                        <input type="radio" id={index} name="drone" checked={selectedItems.jobCategories.includes(category)} value={category} className='fab-category-radio' onClick={(e) => handleCheckboxClick(e, category)} readOnly />
                        <label className='fab-category-radio-text' htmlFor={index}>{category}</label>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default JobCategories;