import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import './Pagination.css';


const Pagination = ({ total, pageSize, setCurrentPage, currentPage, scrollToSection }) => {
    const pageCount = Math.ceil(total / pageSize);
    const navigate = useNavigate();
    const changePage = (index) => {
        setCurrentPage(index.selected);
        if (scrollToSection) {
            scrollToSection('available-jobs')
        }
        else {
            navigate(`?page=${index.selected + 1}`);
        }
    };

    useEffect(() => {
        if (!scrollToSection) {
            const handlePopstate = () => {
                const urlParams = new URLSearchParams(window.location.search);
                const page = parseInt(urlParams.get('page')) || 0; // Extract page number from URL
                const updatedPage = page > 0 ? page - 1 : 0;
                setCurrentPage(updatedPage); // Update local state for current page
            };

            window.addEventListener('popstate', handlePopstate);

            return () => {
                window.removeEventListener('popstate', handlePopstate);
            };
        }
    }, [currentPage]);


    return (
        <div className='fab-pagination-container'>
            <ReactPaginate
                breakLabel="..."
                previousLabel={window.innerWidth < 768 ? <div className='fab-prev-icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0.433058 7.44194C0.188981 7.19786 0.188981 6.80214 0.433058 6.55806L6.68306 0.308058C6.92714 0.0639806 7.32286 0.0639806 7.56694 0.308058C7.81102 0.552137 7.81102 0.947864 7.56694 1.19194L1.75888 7L7.56694 12.8081C7.81102 13.0521 7.81102 13.4479 7.56694 13.6919C7.32286 13.936 6.92714 13.936 6.68306 13.6919L0.433058 7.44194Z" fill="#3F3F46" />
                    </svg>
                </div> : "Previous"}
                nextLabel={window.innerWidth < 768 ? <div className='fab-next-icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="40" viewBox="0 0 36 40" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M21.5669 19.5581C21.811 19.8021 21.811 20.1979 21.5669 20.4419L15.3169 26.6919C15.0729 26.936 14.6771 26.936 14.4331 26.6919C14.189 26.4479 14.189 26.0521 14.4331 25.8081L20.2411 20L14.4331 14.1919C14.189 13.9479 14.189 13.5521 14.4331 13.3081C14.6771 13.064 15.0729 13.064 15.3169 13.3081L21.5669 19.5581Z" fill="#3F3F46" />
                    </svg>
                </div> : "Next"}
                onPageChange={changePage}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                containerClassName={'fab-pagination-container'}
                previousLinkClassName={'fab-prev-button'}
                nextLinkClassName={'fab-next-button'}
                disabledClassName={'fab-pagination-disabled'}
                activeClassName={'fab-page-active'}
                forcePage={currentPage}
            />
        </div >
    )
}

export default Pagination;