import React, { useRef, useEffect } from "react";
import "./SlideDrawer.css";

const SlideDrawer = ({ showSlider, setShowSlider, handleOutsideClick, children }) => {
    const sliderRef = useRef(null);

    useEffect(() => {
        const handleDocumentClick = (e) => {
            if (showSlider && sliderRef.current && !sliderRef.current.contains(e.target)) {
                setShowSlider(false);
                handleOutsideClick()
            }
        };

        const handleEscapeKey = (e) => {
            if (e.key === "Escape") {
                setShowSlider(false);
                handleOutsideClick()
            }
        };

        if (showSlider) {
            document.body.classList.add("no-scroll");
            document.addEventListener("mousedown", handleDocumentClick);
            document.addEventListener("keydown", handleEscapeKey);
        } else {
            document.body.classList.remove("no-scroll");
            document.removeEventListener("mousedown", handleDocumentClick);
            document.removeEventListener("keydown", handleEscapeKey);
        }

        return () => {
            document.body.classList.remove("no-scroll");
            document.removeEventListener("mousedown", handleDocumentClick);
            document.removeEventListener("keydown", handleEscapeKey);
        };
    }, [showSlider, setShowSlider]);

    return (
        showSlider &&
        (
            <div className="fab-slider-container" >
                <div className="fab-slide-drawer" ref={sliderRef}>
                    {children}
                </div>
            </div>
        )
    )
}

export default SlideDrawer;