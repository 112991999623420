import { React } from 'react';
import Button from "../../components/button/Button";
import { getSelectedItems } from '../../common/helper';
import '../../components/dropdown/Dropdown.css';

const JobFiltersMobile = ({ selectedFiltersMobile, setSelectedFiltersMobile, OnClickFilterIcon, locations, jobPositions, employmentTypes, jobCategories, selectedItems, setSelectedItems, onSearchButtonClick, setOpenSearchPage_m, minSalaryMobile, setMaxSalaryMobile, maxSalaryMobile, setMinSalaryMobile, setMinSalary, setMaxSalary, minSalary, maxSalary, getJobsWithGivenSalaryRange }) => {
    const handleLocationClick = (e, itemName) => {
        e.stopPropagation();
        const checkbox = e.target.type === 'checkbox' ? e.target : e.target.querySelector('input[type="checkbox"]');
        const isChecked = checkbox.checked;
        const checkedItems = getSelectedItems(isChecked, itemName, 'All locations', locations, selectedFiltersMobile.locations)
        setSelectedFiltersMobile((prevItems) => ({
            ...prevItems,
            locations: checkedItems
        }))
    };

    const handleMinPriceChange = (e) => {
        setMinSalaryMobile(e.target.value);
    };

    const handleMaxPriceChange = (e) => {
        setMaxSalaryMobile(e.target.value);
    };

    const handlePositionClick = (e, itemName) => {
        e.stopPropagation();
        const checkbox = e.target.type === 'checkbox' ? e.target : e.target.querySelector('input[type="checkbox"]');
        const isChecked = checkbox.checked;
        const checkedItems = getSelectedItems(isChecked, itemName, 'All job positions', jobPositions, selectedFiltersMobile.jobPositions)
        setSelectedFiltersMobile((prevItems) => ({
            ...prevItems,
            jobPositions: checkedItems
        }))
    };

    const handleJobCategoryClick = (e, itemName) => {
        e.stopPropagation();
        setSelectedFiltersMobile((prevItems) => ({
            ...prevItems,
            jobCategories: [itemName]
        }))
    };

    const handleEmploymentTypeClick = (e, itemName) => {
        e.stopPropagation();
        const checkbox = e.target.type === 'checkbox' ? e.target : e.target.querySelector('input[type="checkbox"]');
        const isChecked = checkbox.checked;
        const checkedItems = getSelectedItems(isChecked, itemName, 'All employment types', employmentTypes, selectedFiltersMobile.employmentTypes)
        setSelectedFiltersMobile((prevItems) => ({
            ...prevItems,
            employmentTypes: checkedItems
        }))
    };

    const onReset = () => {
        setSelectedFiltersMobile({
            locations: ['All locations'],
            jobPositions: ['All job positions'],
            jobCategories: ['All categories'],
            employmentTypes: ['All employment types']
        })
        setMaxSalaryMobile('')
        setMinSalaryMobile('')
    }

    const onSearch = () => {
        OnClickFilterIcon()
        setOpenSearchPage_m(false)
        setSelectedItems({
            locations: selectedFiltersMobile.locations,
            jobPositions: selectedFiltersMobile.jobPositions,
            jobCategories: selectedFiltersMobile.jobCategories,
            employmentTypes: selectedFiltersMobile.employmentTypes
        })
        onSearchButtonClick()
    }

    const onApplyButtonClick = () => {
        OnClickFilterIcon()
        setOpenSearchPage_m(false)
        setMaxSalary(maxSalaryMobile)
        setMinSalary(minSalaryMobile)
        getJobsWithGivenSalaryRange(0, minSalaryMobile, maxSalaryMobile)
    }

    const handleBack = () => {
        OnClickFilterIcon()
        setSelectedFiltersMobile({
            locations: selectedItems.locations,
            jobPositions: selectedItems.jobPositions,
            jobCategories: selectedItems.jobCategories,
            employmentTypes: selectedItems.employmentTypes
        })
        setMaxSalaryMobile(maxSalary)
        setMinSalaryMobile(minSalary)
    }

    return (
        <div className="fab-filter-data-mobile">
            <div className="fab-filter-data-header">
                <button className="fab-jobs-back-icon" onClick={handleBack}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0.71967 9.53033C0.426777 9.23744 0.426777 8.76256 0.71967 8.46967L8.21967 0.96967C8.51256 0.676777 8.98744 0.676777 9.28033 0.96967C9.57322 1.26256 9.57322 1.73744 9.28033 2.03033L2.31066 9L9.28033 15.9697C9.57322 16.2626 9.57322 16.7374 9.28033 17.0303C8.98744 17.3232 8.51256 17.3232 8.21967 17.0303L0.71967 9.53033Z" fill="#18181B" />
                    </svg>
                </button>
                <button className="fab-filters-reset-button" onClick={onReset}>
                    Reset all
                </button>
            </div>
            <div className="fab-filters-content">
                <h3 className='fab-filter-header-m'>Filters</h3>
                <div className='fab-filter-locations'>
                    <h3 className='fab-filters-subheader-m'>Location</h3>
                    {locations.map((item, index) => (
                        <label key={index} htmlFor={`chckItemL${index}`} className="fab-dropdown-menu-item" onClick={(e) => e.stopPropagation()}>
                            <input type="checkbox" name="" id={`chckItemL${index}`} checked={selectedFiltersMobile.locations.includes(item)} className='fab-dropdown-check' onClick={(e) => handleLocationClick(e, item)} />
                            {item}
                        </label>
                    ))}
                </div>
                <div className='fab-filter-job-position'>
                    <h3 className='fab-filters-subheader-m'>Job position</h3>
                    {jobPositions.map((item, index) => (
                        <label key={index} htmlFor={`chckItemJ${index}`} className="fab-dropdown-menu-item" onClick={(e) => e.stopPropagation()}>
                            <input type="checkbox" name="" id={`chckItemJ${index}`} checked={selectedFiltersMobile.jobPositions.includes(item)} className='fab-dropdown-check' onClick={(e) => handlePositionClick(e, item)} />
                            {item}
                        </label>
                    ))}
                </div>
                <div className='fab-filter-employment-type'>
                    <h3 className='fab-filters-subheader-m'>Employment type</h3>
                    {employmentTypes.map((item, index) => (
                        <label key={index} htmlFor={`chckItemE${index}`} className="fab-dropdown-menu-item" onClick={(e) => e.stopPropagation()}>
                            <input type="checkbox" name="" id={`chckItemE${index}`} checked={selectedFiltersMobile.employmentTypes.includes(item)} className='fab-dropdown-check' onClick={(e) => handleEmploymentTypeClick(e, item)} />
                            {item}
                        </label>
                    ))}
                </div>
                <div className='fab-filter-job-categories'>
                    <h3 className='fab-filters-subheader-m'>Job categories</h3>
                    {jobCategories.map((item, index) => (
                        <label key={index} htmlFor={`chckItemC${index}`} className="fab-dropdown-menu-item" onClick={(e) => e.stopPropagation()}>
                            <input type="radio" name="" id={`chckItemC${index}`} checked={selectedFiltersMobile.jobCategories.includes(item)} className='fab-dropdown-check' onClick={(e) => handleJobCategoryClick(e, item)} />
                            {item}
                        </label>
                    ))}
                </div>
                <div className='fab-filter-job-categories'>
                    <h3 className='fab-filters-subheader-m'>Salary range</h3>
                    <div className='fab-salary-range-filter'>
                        <input
                            type="number"
                            pattern="\d*"
                            inputmode="numeric"
                            value={minSalaryMobile}
                            onChange={handleMinPriceChange}
                            placeholder="Minimum"
                            className="fab-jobs-salary-input"
                        />
                        <span className="fab-jobs-salary-separator">-</span>
                        <input
                            type="number"
                            pattern="\d*"
                            value={maxSalaryMobile}
                            onChange={handleMaxPriceChange}
                            inputmode="numeric"
                            placeholder="Maximum"
                            className="fab-jobs-salary-input"
                        />
                    </div>
                    <Button className="fab-salary-range-apply-button" variant="outline" size="md" onClick={onApplyButtonClick}>Apply</Button>
                </div>
            </div>
            <div className="fab-filter-data-footer">
                <Button variant="primary" size="lg" onClick={onSearch}>Search</Button>
            </div>
        </div>
    )
}

export default JobFiltersMobile;