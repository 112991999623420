import { COMPANIES_PAGE_SIZE } from "../common/constants";
import { routes } from "../common/routes";
import api from "./axios";

// API to get a list of companies.
export const getCompanies = async (
    searchKeyword,
    alphabetFilters,
    numberFilter,
    cuisineFilters,
    currentPage = 0,
    limit = COMPANIES_PAGE_SIZE,
    excludeJobs = false,
    currentlyFeaturedOnly = false
) => {
    let params = {};
    params['currentlyFeaturedOnly'] = false;
    if (searchKeyword) {
        params['keyword'] = searchKeyword;
    }
    if (alphabetFilters) {
        params['alphaFilters'] = alphabetFilters;
    }
    if (numberFilter) {
        params['numFilter'] = true;
    }
    if (cuisineFilters) {
        params['cuisines'] = cuisineFilters;
    }
    if (currentPage >= 0) {
        params['page'] = currentPage + 1;
        params['limit'] = limit;
    }
    if (excludeJobs) {
        params['excludeJobs'] = excludeJobs;
    }
    if (currentlyFeaturedOnly) {
        params['currentlyFeaturedOnly'] = currentlyFeaturedOnly;
    }

    const response = await api.get(routes.company.list, { params });
    return response.data;
}

export const getCompanyCuisines = async (companyId) => {
    const response = await api.get(routes.company.getCuisines(companyId));
    return response.data;
}
