import './Button.css';

const Button = ({ variant, size, onClick, className, children, isDisabled }) => {
  const variantClassMap = {
    primary: 'fab-btn--primary',
    outline: 'fab-btn--outline',
    // Add more variants as needed
  };

  const sizeClassMap = {
    lg: 'fab-btn--lg',
    md: 'fab-btn--md',
  };

  const classNames = ['fab-btn'];

  if (variant && variantClassMap[variant]) {
    classNames.push(variantClassMap[variant]);
  }

  if (size && sizeClassMap[size]) {
    classNames.push(sizeClassMap[size]);
  }

  if (className) {
    classNames.push(className);
  }

  if (isDisabled === true) {
    classNames.push('fab-button--disabled');
  }

  const buttonClass = classNames.join(' ');

  return (
    <button className={`${buttonClass} ${className}`} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
