import '../../components/globals/boilerplate.css';
import '../../components/globals/BrowseJobs.css';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import { formatFiltersData, getFilterDataWithCodeIds, getSelectedItems, isEmpty, mapLocationValues, updateDropdownWithAll } from '../../common/helper';
import { getAllSavedJobs, getJobFeedBanners, getJobs, saveJob, unsaveJob } from '../../services/jobService';
import { getCodeTableChecksum, getInformation } from '../../services/HomeService';

import Button from "../../components/button/Button";
import Dropdown from '../../components/dropdown/Dropdown';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import { JOBS_PAGE_SIZE } from '../../common/constants';
import JobCard from '../../components/jobcard/JobCard';
import JobCategories from "./JobCategories";
import JobFiltersMobile from './JobFiltersMobile';
import JobSliderMobile from './JobSliderMobile';
import Loader from '../../components/loader/Loader';
import MobileSearch from '../../components/mobilesearch/MobileSearch';
import NoResults from '../../components/NoResults/NoResults';
import Pagination from '../../components/pagination/Pagination';
import SalaryRangeFilter from '../../containers/browsejobs/SalaryRangeFilter';
import TextField from '../../components/textfield/Textfield';
import Toast from '../../components/toast/Toast';
import analytics from '../../firebase';
import { logEvent } from "firebase/analytics";

const toastInitialState = {
    type: '',
    header: '',
    message: ''
}

const BrowseJobs = () => {
    // props getting from home page redirection and page number
    const locationProps = useLocation()
    // jobs
    const [jobs, setJobs] = useState([]);
    const [jobFeedBanners, setJobFeedBanners] = useState([]);
    const [jobsTotal, setJobsTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(0)
    const [bookmarkedCards, setBookmarkedCards] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const [toastProps, setToastProps] = useState(toastInitialState)
    const [savedJobs, setSavedJobs] = useState([]);

    // Mobile ui
    const [openFilterList_m, setOpenFilterList_m] = useState(false);
    const [openSearchPage_m, setOpenSearchPage_m] = useState(false);
    // slider states 
    const [showSlider, setShowSlider] = useState(false);
    const [sliderHeader, setSliderHeader] = useState('');
    //dropdown data
    const [locations, setLocations] = useState([])
    const [locationMappings, setLocationMappings] = useState([])
    const [jobPositions, setJobPositions] = useState([])
    const [jobPositionsWithKeywords, setJobPositionsWithKeywords] = useState([])
    const [employmentTypes, setEmploymentTypes] = useState([])
    const [employmentTypeIds, setEmploymentTypeIds] = useState([]);
    const [jobCategories, setJobCategories] = useState([])
    const [jobCategoriesWithKeywords, setJobCategoriesWithKeywords] = useState([])
    //filters
    const [inputValue, setInputValue] = useState(locationProps.state?.input || '');
    const [searchText, setSearchText] = useState(locationProps.state?.input || '');
    const [minSalary, setMinSalary] = useState('');
    const [maxSalary, setMaxSalary] = useState('');
    const [selectedItems, setSelectedItems] = useState({
        locations: !isEmpty(locationProps.state?.locations) ? locationProps.state?.locations : ['All locations'],
        jobPositions: !isEmpty(locationProps.state?.jobPosition) ? locationProps.state?.jobPosition : ['All job positions'],
        jobCategories: !isEmpty(locationProps.state?.jobCategory) ? [locationProps.state?.jobCategory] : ['All categories'],
        employmentTypes: !isEmpty(locationProps.state?.jobTypes) ? locationProps.state?.jobTypes : ['All employment types']
    });
    // State to manage selected filters from mobile or tablet view
    const [selectedFiltersMobile, setSelectedFiltersMobile] = useState({
        locations: selectedItems.locations,
        jobPositions: selectedItems.jobPositions,
        jobCategories: selectedItems.jobCategories,
        employmentTypes: selectedItems.employmentTypes
    });
    const [minSalaryMobile, setMinSalaryMobile] = useState(minSalary);
    const [maxSalaryMobile, setMaxSalaryMobile] = useState(maxSalary);

    // loader state
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchJobsCompleted, setIsFetchJobsCompleted] = useState(false)
    const [isFilterSticky, setFilterSticky] = useState(false);
    const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
    const [inSearchMode, setInSearchMode] = useState(false);

    const navigate = useNavigate();

    const resetDropdowns = (placeholder) => {
        if (placeholder === 'Location') {
            setSelectedItems((prevItems) => ({
                ...prevItems,
                locations: ['All locations']
            }))
        }
        else if (placeholder === 'Employment type') {
            setSelectedItems((prevItems) => ({
                ...prevItems,
                employmentTypes: ['All employment types']
            }))
        }
        else if (placeholder === 'Job position') {
            setSelectedItems((prevItems) => ({
                ...prevItems,
                jobPositions: ['All job positions']
            }))
        }
    }

    const onCheckboxClick = (placeholder, itemName, isChecked) => {
        let items = []
        if (placeholder === 'Location') {
            items = getSelectedItems(isChecked, itemName, 'All locations', locations, selectedItems.locations)
            setSelectedItems((prevItems) => ({
                ...prevItems,
                locations: items
            }))
        }
        else if (placeholder === 'Job position') {
            items = getSelectedItems(isChecked, itemName, 'All job positions', jobPositions, selectedItems.jobPositions)
            setSelectedItems((prevItems) => ({
                ...prevItems,
                jobPositions: items
            }))
        }
        else if (placeholder === 'Employment type') {
            items = getSelectedItems(isChecked, itemName, 'All employment types', employmentTypes, selectedItems.employmentTypes)
            setSelectedItems((prevItems) => ({
                ...prevItems,
                employmentTypes: items
            }))
        }
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value)
    }

    const onClearInput = () => {
        setInputValue("")
        setCurrentPage(0)
        setInSearchMode(false)
    }

    const handleMinPriceChange = (e) => {
        setMinSalary(e.target.value);
    };

    const handleMaxPriceChange = (e) => {
        setMaxSalary(e.target.value);
    };

    const fetchAllSavedJobs = async () => {
        try {
            const allJobs = await getAllSavedJobs();
            const jobsIds = allJobs.map(x => x.jobid);
            setSavedJobs(allJobs);
            setBookmarkedCards(jobsIds);
        }
        catch (error) {
            console.log(`Failed to fetch all saved jobs ${error}`)
        }
    }

    const saveJobs = async (cardId) => {
        try {
            const savedRes = await saveJob(cardId);
            if (savedRes.code === '00') {
                const savedJobsAll = fetchAllSavedJobs();
                setSavedJobs(savedJobsAll);
                setShowToast(true)
                setToastProps({
                    type: 'success',
                    header: 'Job saved successfully',
                    message: <>The job has been added to your <Link to='/saved-jobs'>Saved Jobs</Link> listing.</>
                })
            }
        }
        catch (error) {
            await fetchAllSavedJobs();
            setIsLoading(false);
            setShowToast(true)
            setToastProps({
                type: 'error',
                header: 'Something went wrong',
                message: 'Please try saving the job again'
            })
            console.log(`Failed to save a job ${error}`)
        }
    }

    const unsaveJobs = async (cardId, favId) => {
        try {
            const unsavedRes = await unsaveJob(cardId, favId);
            if (unsavedRes.code === '00') {
                const savedJobsAll = fetchAllSavedJobs();
                setSavedJobs(savedJobsAll);
                setShowToast(true)
                setToastProps({
                    type: 'success',
                    header: 'Job unsaved successfully',
                    message: <>The job has been removed from your <Link to='/saved-jobs'>Saved Jobs</Link> listing.</>
                })
            }
        }
        catch (error) {
            await fetchAllSavedJobs();
            setIsLoading(false);
            setShowToast(true)
            setToastProps({
                type: 'error',
                header: 'Something went wrong',
                message: 'Please try unsaving the job again'
            })
            console.log(`Failed to unsave a job ${error}`)
        }
    }

    const handleBookmarkClick = async (cardId, bookmarkedCards, e, job) => {
        e.preventDefault();
        e.stopPropagation();
        setBookmarkedCards((prevBookmarkedCards) =>
            prevBookmarkedCards.includes(cardId)
                ? prevBookmarkedCards.filter((id) => id !== cardId)
                : [...prevBookmarkedCards, cardId]
        );
        setIsLoading(true);
        if (bookmarkedCards.includes(cardId)) {
            logEvent(analytics, 'job_unsaved', { 'click_source': 'browse jobs', 'job_name': job.jobttl, 'job_id': job.jobid, 'company_name': job.coydispm, 'company_id': job.coyid });
            const savedJob = savedJobs.find(item => item.jobid === cardId);
            await unsaveJobs(cardId, savedJob.favid)
        }
        else {
            logEvent(analytics, 'job_saved', { 'click_source': 'browse jobs', 'job_name': job.jobttl, 'job_id': job.jobid, 'company_name': job.coydispm, 'company_id': job.coyid });
            await saveJobs(cardId);
        }
        setIsLoading(false);
    };


    const handleScroll = () => {
        const currentScrollPos = window.pageYOffset;

        if (currentScrollPos > prevScrollPos) {
            setFilterSticky(false);
        } else {
            setFilterSticky(true);
        }

        setPrevScrollPos(currentScrollPos);
    };

    const OnClickFilterIcon = () => {
        setOpenFilterList_m(!openFilterList_m)
    }

    const handleOnFocus = () => {
        if (window.innerWidth <= 992) {
            setOpenSearchPage_m(!openSearchPage_m)
        }
    }

    const onSearchButtonClick = () => {
        setCurrentPage(0)
        setSearchText(inputValue)
        getJobsData()
    }

    // Mobile filters list search button handler
    const onMobileFiltersSearchButtonClick = () => {
        let page = 0
        setCurrentPage(page)
        getJobsWithGivenFilters(page)
    }

    // Mobile Input field page search button handler
    const onMobileSearchButtonClick = (searchInput) => {
        let page = 0
        setCurrentPage(page)
        setInputValue(searchInput)
        getJobsWithGivenInput(searchInput, page)
    }

    const onJobApply = (jobId, job) => {
        logEvent(analytics, 'job_details_viewed', { 'click_source': 'browse jobs', 'job_name': job?.jobttl, 'job_id': job?.jobid, 'company_name': job?.coydispm, 'company_id': job?.coyid });
        navigate(`/jobs/${jobId}`);
    }

    const getJobTypeIds = () => {
        const updatedEmploymentTypes = selectedItems.employmentTypes.includes("All employment types") ? [] : selectedItems.employmentTypes
        const seletedJobTypes = employmentTypeIds.filter(obj => updatedEmploymentTypes.includes(obj.val));
        const jobIds = seletedJobTypes.map(val => val.codeid)
        return jobIds.join(',');
    }

    const getJobTypes = () => {
        const updatedEmploymentTypes = selectedItems.employmentTypes.includes("All employment types") ? [] : selectedItems.employmentTypes
        return updatedEmploymentTypes.join(',');
    }

    const getDropdownValues = (jps) => {
        return jps.map((jp) => Object.keys(jp)[0]);
    };

    const mapAndGetKeywords = (keywordArray, items) => {
        const filteredJobData = keywordArray.filter(jpkw => {
            const value = Object.keys(jpkw)[0];
            return items.includes(value);
        }).flatMap((jpkw) => Object.values(jpkw))
        return filteredJobData.flat()
    }

    const handleToastClose = () => {
        setShowToast(false)
        setToastProps(toastInitialState)
    }

    const onClickReset = () => {
        setInputValue('');
        setCurrentPage(0);
        setInSearchMode(false);
        setMinSalary('');
        setMaxSalary('');
        setSelectedItems({
            locations: ['All locations'],
            jobPositions: ['All job positions'],
            jobCategories: ['All categories'],
            employmentTypes: ['All employment types']
        })
    }

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    const IsUserLoggedIn = () => {
        return localStorage.getItem('isLogin') !== null;
    }

    const getFeedBanners = async () => {
        try {
            const response = await getJobFeedBanners();
            setJobFeedBanners(response)
        }
        catch (error) {
            console.log(`Get jobs error: ${error}`);
        }
    }

    const getJobsWithGivenFilters = async (page) => {
        try {
            setIsLoading(true)
            const jobTypeIds = getJobTypeIds()
            const updatedLocations = selectedFiltersMobile.locations.includes("All locations") ? [] : getLocationCodeIds(selectedFiltersMobile.locations);
            const updatedPositions = selectedFiltersMobile.jobPositions.includes("All job positions") ? [] : selectedFiltersMobile.jobPositions;
            const updatedCategories = (selectedFiltersMobile.jobCategories.includes("All categories") || selectedFiltersMobile.jobCategories.includes("Latest jobs")) ? [] : selectedItems.jobCategories;
            const jobPositionKeywords = mapAndGetKeywords(jobPositionsWithKeywords, updatedPositions)
            const jobCategoryKeywords = mapAndGetKeywords(jobCategoriesWithKeywords, updatedCategories)
            const response = await getJobs(inputValue, updatedLocations, jobPositionKeywords, jobCategoryKeywords, jobTypeIds, page, minSalary, maxSalary);
            if (inputValue.length > 0) {
                setInSearchMode(true)
            }
            else {
                setInSearchMode(false)
            }
            setJobsTotal(response.totalData)
            setJobs(response.data)
            setIsLoading(false)
            setIsFetchJobsCompleted(true)
        } catch (error) {
            setIsLoading(false)
            setIsFetchJobsCompleted(true)
            setInSearchMode(false)
            console.log(`Get jobs error: ${error}`);
        }
    }

    const getLocationCodeIds = (vals) => {
        const codeIds = [];
        vals.forEach(val => {
            for (let key in locationMappings) {
                if (locationMappings[key].val === val) {
                    codeIds.push(locationMappings[key].codeid);
                    break;
                }
            }
        });
        return codeIds;
    }

    const getJobsWithGivenSalaryRange = async (page, minSalaryPrice, maxSalaryPrice) => {
        try {
            setIsLoading(true)
            const jobTypeIds = getJobTypeIds()
            const updatedLocations = selectedFiltersMobile.locations.includes("All locations") ? [] : getLocationCodeIds(selectedFiltersMobile.locations);
            const updatedPositions = selectedFiltersMobile.jobPositions.includes("All job positions") ? [] : selectedFiltersMobile.jobPositions;
            const updatedCategories = (selectedFiltersMobile.jobCategories.includes("All categories") || selectedFiltersMobile.jobCategories.includes("Latest jobs")) ? [] : selectedItems.jobCategories;
            const jobPositionKeywords = mapAndGetKeywords(jobPositionsWithKeywords, updatedPositions)
            const jobCategoryKeywords = mapAndGetKeywords(jobCategoriesWithKeywords, updatedCategories)
            let minSalary = minSalaryPrice
            let maxSalary = maxSalaryPrice
            const response = await getJobs(inputValue, updatedLocations, jobPositionKeywords, jobCategoryKeywords, jobTypeIds, page, minSalary, maxSalary);
            if (inputValue.length > 0) {
                setInSearchMode(true)
            }
            else {
                setInSearchMode(false)
            }
            setJobsTotal(response.totalData)
            setJobs(response.data)
            setIsLoading(false)
            setIsFetchJobsCompleted(true)
        } catch (error) {
            setIsLoading(false)
            setIsFetchJobsCompleted(true)
            setInSearchMode(false)
            console.log(`Get jobs error: ${error}`);
        }
    }

    const getJobsWithGivenInput = async (searchInput, page) => {
        try {
            setIsLoading(true)
            const jobTypeIds = getJobTypeIds()
            const updatedLocations = selectedItems.locations.includes("All locations") ? [] : getLocationCodeIds(selectedItems.locations);
            const updatedPositions = selectedItems.jobPositions.includes("All job positions") ? [] : selectedItems.jobPositions;
            const updatedCategories = (selectedItems.jobCategories.includes("All categories") || selectedItems.jobCategories.includes("Latest jobs")) ? [] : selectedItems.jobCategories;
            const jobPositionKeywords = mapAndGetKeywords(jobPositionsWithKeywords, updatedPositions)
            const jobCategoryKeywords = mapAndGetKeywords(jobCategoriesWithKeywords, updatedCategories)
            const response = await getJobs(searchInput, updatedLocations, jobPositionKeywords, jobCategoryKeywords, jobTypeIds, page, minSalary, maxSalary);
            if (searchInput.length > 0) {
                setInSearchMode(true)
            }
            else {
                setInSearchMode(false)
            }
            setJobsTotal(response.totalData)
            setJobs(response.data)
            setIsLoading(false)
            setIsFetchJobsCompleted(true)
        } catch (error) {
            setIsLoading(false)
            setIsFetchJobsCompleted(true)
            setInSearchMode(false)
            console.log(`Get jobs error: ${error}`);
        }
    }

    const getJobsData = async () => {
        try {
            setIsLoading(true)
            logEvent(analytics, 'job_search_initiated', { 'click_source': 'browse jobs' });
            logEvent(analytics, 'browse_jobs_screen_viewed', { 'pagination': currentPage + 1 });
            const jobTypeIds = getJobTypeIds()
            const updatedLocations = selectedItems.locations.includes("All locations") ? [] : getLocationCodeIds(selectedItems.locations);
            const updatedPositions = selectedItems.jobPositions.includes("All job positions") ? [] : selectedItems.jobPositions;
            const updatedCategories = (selectedItems.jobCategories.includes("All categories") || selectedItems.jobCategories.includes("Latest jobs")) ? [] : selectedItems.jobCategories;
            if (updatedCategories.length > 0 && updatedCategories[0] !== "All categories") {
                logEvent(analytics, 'job_categories_clicked', { 'click_source': 'browse jobs', 'job_category_options': updatedCategories[0] });
            }
            const jobPositionKeywords = mapAndGetKeywords(jobPositionsWithKeywords, updatedPositions)
            const jobCategoryKeywords = mapAndGetKeywords(jobCategoriesWithKeywords, updatedCategories)
            const response = await getJobs(inputValue, updatedLocations, jobPositionKeywords, jobCategoryKeywords, jobTypeIds, currentPage, minSalary, maxSalary);
            logEvent(analytics, 'job_search_completed', { 'click_source': 'browse jobs', 'search_term': inputValue, 'locations': updatedLocations.join(','), 'employment_types': getJobTypes(), 'job_positions': updatedPositions.join(',') });
            if (inputValue.length > 0) {
                setInSearchMode(true)
            }
            else {
                setInSearchMode(false)
            }
            setJobsTotal(response.totalData)
            setJobs(response.data)
            setIsLoading(false)
            setIsFetchJobsCompleted(true)
        } catch (error) {
            setIsLoading(false)
            setIsFetchJobsCompleted(true)
            setInSearchMode(false)
            console.log(`Get jobs error: ${error}`);
        }
    }

    useEffect(() => {
        getFeedBanners()
    }, [])

    useEffect(() => {
        let isApicalled = true;
        const fetchFiltersData = async () => {
            try {
                // Call info api
                const info = await getInformation();
                if (info.data && isApicalled) {
                    const positions = getDropdownValues(info.data.jobPositions)
                    const sortedJobTitles = positions.slice().sort();
                    const updatedPositions = updateDropdownWithAll('All job positions', sortedJobTitles)
                    const categories = getDropdownValues(info.data.jobCategories)
                    setJobPositions(updatedPositions);
                    setJobPositionsWithKeywords(info.data.jobPositions)
                    setJobCategories(categories)
                    setJobCategoriesWithKeywords(info.data.jobCategories)
                }
                // call code table checksum
                const checksum = await getCodeTableChecksum();
                if (checksum.data && isApicalled) {
                    const jobTypes = formatFiltersData(checksum.data.Job_Type.data);
                    const updatedEmploymenttypes = updateDropdownWithAll('All employment types', jobTypes);
                    const employmentTypesIds = getFilterDataWithCodeIds(checksum.data.Job_Type.data);
                    const locations = formatFiltersData(checksum.data.Region.data);
                    const updatedLocations = mapLocationValues(locations);
                    setLocationMappings(checksum.data.Region.data);
                    setEmploymentTypes(updatedEmploymenttypes);
                    setLocations(updatedLocations);
                    setEmploymentTypeIds(employmentTypesIds)
                }

            } catch (error) {
                console.error('Error fetching filters data:', error);
            }
        }
        fetchFiltersData()
        return () => {
            isApicalled = false;
        };
    }, [])

    useEffect(() => {
        setIsLoading(true)
        getFeedBanners()
        if (!isEmpty(employmentTypeIds)) {
            getJobsData()
            setTimeout(() => {
                scrollToTop();
            }, 0);
            navigate(`?page=${currentPage + 1}`, { replace: true });
        }
    }, [currentPage, employmentTypeIds, selectedItems.jobCategories, inSearchMode])

    const preparedData = useMemo(() => {
        const combinedData = [];
        let bannerIndex = 0;

        for (let i = 0; i < jobs.length; i++) {
            combinedData.push({ type: 'job', data: jobs[i] });

            // Insert banner after every 5th job, up to 3 banners
            if ((i + 1) % 5 === 0 && bannerIndex < jobFeedBanners.length && bannerIndex < 3) {
                combinedData.push({ type: 'banner', data: jobFeedBanners[bannerIndex] });
                bannerIndex++;
            }
        }

        return combinedData;
    }, [jobs, jobFeedBanners]);

    useEffect(() => {
        const isLogin = JSON.parse(localStorage.getItem('isLogin'))
        if (isLogin) {
            fetchAllSavedJobs()
        }
    }, [])

    useEffect(() => {
        const params = new URLSearchParams(locationProps.search);
        if (!params.has('page')) {
            params.set('page', '1');
            navigate(`?${params.toString()}`, { replace: true });
        }
        const pageParam = parseInt(params.get('page')) || 0;
        const updatedPage = pageParam > 0 ? pageParam - 1 : 0;
        setCurrentPage(updatedPage);
    }, [locationProps.search, navigate]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [prevScrollPos])

    useEffect(() => {
        const redirectedFromLoginSignup = JSON.parse(localStorage.getItem('fromLoginSignup'));
        if (redirectedFromLoginSignup) {
            setShowToast(true);
            setToastProps({
                type: 'success',
                header: 'Login Successful',
                message: 'You can start applying for jobs!'
            })
            localStorage.removeItem('fromLoginSignup');
        }
    }, []);

    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.key === 'Enter') {
                onSearchButtonClick();
            }
        };

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [onSearchButtonClick]);

    if (openFilterList_m) {
        return (
            <JobFiltersMobile minSalaryMobile={minSalaryMobile} maxSalaryMobile={maxSalaryMobile} setMaxSalaryMobile={setMaxSalaryMobile} setMinSalaryMobile={setMinSalaryMobile} selectedFiltersMobile={selectedFiltersMobile} setSelectedFiltersMobile={setSelectedFiltersMobile} OnClickFilterIcon={OnClickFilterIcon} locations={locations} jobPositions={jobPositions} employmentTypes={employmentTypes} jobCategories={jobCategories} setSelectedItems={setSelectedItems} selectedItems={selectedItems} onSearchButtonClick={onMobileFiltersSearchButtonClick} setOpenSearchPage_m={setOpenSearchPage_m} setMinSalary={setMinSalary} setMaxSalary={setMaxSalary} minSalary={minSalary} maxSalary={maxSalary} getJobsWithGivenSalaryRange={getJobsWithGivenSalaryRange} />
        )
    }

    if (openSearchPage_m) {
        return (
            <MobileSearch handleOnFocus={handleOnFocus} OnClickFilterIcon={OnClickFilterIcon} value={inputValue} onSearchButtonClick={onMobileSearchButtonClick} />
        )
    }

    return (
        <>
            <div>
                {isLoading && <Loader></Loader>}
                {showToast && <Toast type={toastProps.type} header={toastProps.header} message={toastProps.message} onClose={handleToastClose} />}
                <Header />
                <div className={`fab-jobs-search-filter ${isFilterSticky === true ? 'fab-browse-jobs-filters-sticky' : ""}`}>
                    <div className='fab-container'>
                        <div className='fab-jobs-search-fields'>
                            <div className='fab-input-search'>
                                <TextField placeholder='Search' handleOnFocus={handleOnFocus} isPassword={false} value={inputValue} onChange={handleInputChange} onClear={onClearInput} hideRemoveIcon={window.innerWidth <= 992 ? true : false} />
                            </div>
                            <div className='fab-browse-jobs-dropdown-container'>
                                <div className='fab-jobs-filter-icon'>
                                    <button onClick={OnClickFilterIcon}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M1.79154 0.938246C4.46066 0.485617 7.20314 0.25 10.0001 0.25C12.7969 0.25 15.5394 0.485609 18.2085 0.938222C19.1108 1.09125 19.75 1.87676 19.75 2.77402V3.81802C19.75 4.61367 19.4339 5.37673 18.8713 5.93934L12.6893 12.1213C12.408 12.4026 12.25 12.7842 12.25 13.182V16.1094C12.25 17.2457 11.608 18.2845 10.5916 18.7927L8.83541 19.6708C8.60292 19.7871 8.32681 19.7746 8.1057 19.638C7.88459 19.5013 7.75 19.2599 7.75 19V13.182C7.75 12.7842 7.59196 12.4026 7.31066 12.1213L1.12868 5.93934C0.56607 5.37673 0.25 4.61367 0.25 3.81802V2.77404C0.25 1.87678 0.889169 1.09127 1.79154 0.938246Z" fill="#D31145" />
                                        </svg>
                                    </button>
                                </div>
                                <Dropdown menuItems={locations} placeholder='Location' onCheckboxClick={onCheckboxClick} setShowSlider={setShowSlider} setSliderHeader={setSliderHeader} selectedItems={selectedItems.locations} resetDropdowns={resetDropdowns} />
                                <Dropdown menuItems={jobPositions} placeholder='Job position' onCheckboxClick={onCheckboxClick} setShowSlider={setShowSlider} setSliderHeader={setSliderHeader} selectedItems={selectedItems.jobPositions} resetDropdowns={resetDropdowns} />
                                <Dropdown menuItems={employmentTypes} placeholder='Employment type' onCheckboxClick={onCheckboxClick} setShowSlider={setShowSlider} setSliderHeader={setSliderHeader} selectedItems={selectedItems.employmentTypes} resetDropdowns={resetDropdowns} id={"fab-jobs-employment-type"} />
                                <div className='fab-job-categories-dropdown'>
                                    <Dropdown menuItems={jobCategories} placeholder='Job categories' setShowSlider={setShowSlider} setSliderHeader={setSliderHeader} selectedItems={selectedItems.jobCategories} />
                                </div>
                                <div className='fab-job-categories-dropdown'>
                                    <Dropdown placeholder='Salary range' setShowSlider={setShowSlider} setSliderHeader={setSliderHeader} menuItems={[]} selectedItems={[]} />
                                </div>
                            </div>
                            <div className='fab-jobs-filter-buttons'>
                                <Button variant="primary" size="lg" className='fab-jobs-filter-button' onClick={onSearchButtonClick}>Search for jobs</Button>
                            </div>
                            <button className="fab-jobs-filter-reset" onClick={onClickReset}>
                                Reset all
                            </button>
                        </div>
                    </div>
                </div>
                <div className={`fab-bj-wrapper ${(isFetchJobsCompleted && !isLoading) && jobs?.length === 0 && 'fab-bj-no-results'}`}>
                    <div className='fab-container fab-browse-jobs-container'>
                        <div className='fab-jobs-container'>
                            <div className='fab-jobs-content'>
                                {(isFetchJobsCompleted && !isLoading) && jobs?.length === 0 && <div className='fab-container fab-no-results-container'>
                                    <NoResults justifyContent='start' />
                                </div>}
                                {jobs?.length > 0 && <>
                                    <div className='fab-browse-jobs-title-container'>
                                        {!inSearchMode ? <h3 className='fab-browse-jobs-title'>Browse jobs</h3> : <h3 className='fab-browse-jobs-title'>{`Showing ${jobsTotal} \‘${searchText}\‘ jobs`}</h3>}
                                        <span className='fab-browse-jobs-count'>{`Showing ${(currentPage * JOBS_PAGE_SIZE) + 1}-${currentPage * JOBS_PAGE_SIZE + jobs?.length} of ${jobsTotal} jobs`}</span>
                                    </div>
                                    <div className='fab-jobs-list'>
                                        <div className='fab-jobs-ad-container'>
                                            {preparedData.map((item, index) => (
                                                <>
                                                    {item.type === 'job' ? <>
                                                        <JobCard key={index} job={item.data} buttonText={'Apply now'} bookmarkedCards={bookmarkedCards} handleBookmarkClick={handleBookmarkClick} handleApply={onJobApply} changeWidth={false} isLoggedIn={IsUserLoggedIn()} />
                                                    </> : <>
                                                        {(item.data.image_url && item.data.banner_url) ? (
                                                            <div className='fab-carousel-wrapper'>

                                                                <a
                                                                    href={item.data.banner_url}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    className='fab-carousel-slide'
                                                                    style={{
                                                                        backgroundImage: `url('${item.data.image_url}')`,
                                                                        display: 'block',
                                                                        textDecoration: 'none',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                ></a>
                                                            </div>
                                                        ) :
                                                            (
                                                                <div className='fab-carousel-wrapper'>
                                                                    <div
                                                                        className='fab-carousel-slide'
                                                                        style={{
                                                                            backgroundImage: `url('${item.data.image_url}')`,
                                                                        }}
                                                                    ></div>
                                                                </div>
                                                            )}
                                                    </>}
                                                </>
                                            ))}
                                        </div >
                                    </div >
                                    <Pagination pageSize={JOBS_PAGE_SIZE} total={jobsTotal} setCurrentPage={setCurrentPage} currentPage={currentPage} />
                                </>}
                            </div>
                        </div>
                        <div className='fab-job-category-list'>
                            <JobCategories jobCategories={jobCategories} selectedItems={selectedItems} setSelectedItems={setSelectedItems} setCurrentPage={setCurrentPage} />
                            <SalaryRangeFilter minSalary={minSalary} setMinSalary={handleMinPriceChange} setMaxSalary={handleMaxPriceChange} maxSalary={maxSalary} setCurrentPage={setCurrentPage} handleApply={getJobsData} />
                        </div>
                    </div>
                </div>
                {jobs?.length > 0 && !isLoading ? <Footer setSelectedItems={setSelectedItems} setCurrentPage={setCurrentPage} setSelectedFiltersMobile={setSelectedFiltersMobile} /> : <></>}
                <JobSliderMobile setMinSalary={setMinSalary} setMaxSalary={setMaxSalary} sliderHeader={sliderHeader} showSlider={showSlider} setShowSlider={setShowSlider} locations={locations} jobPositions={jobPositions} employmentTypes={employmentTypes} jobCategories={jobCategories} selectedItems={selectedItems} setSelectedItems={setSelectedItems} getJobs={onMobileFiltersSearchButtonClick} setCurrentPage={setCurrentPage} setSelectedFiltersMobile={setSelectedFiltersMobile} selectedFiltersMobile={selectedFiltersMobile} getJobsWithGivenSalaryRange={getJobsWithGivenSalaryRange} minSalaryMobile={minSalaryMobile} maxSalaryMobile={maxSalaryMobile} setMaxSalaryMobile={setMaxSalaryMobile} setMinSalaryMobile={setMinSalaryMobile} />
            </div>
        </>
    )
}

export default BrowseJobs;