import React, { useEffect, useRef } from 'react';
import './Toast.css';

const Toast = ({ type, header, message, onClose }) => {
    const toastRef = useRef(null);

    const getIcon = () => {
        switch (type) {
            case 'error':
                return <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0ZM10.7071 9.29289C10.3166 8.90237 9.68342 8.90237 9.29289 9.29289C8.90237 9.68342 8.90237 10.3166 9.29289 10.7071L11.5858 13L9.29289 15.2929C8.90237 15.6834 8.90237 16.3166 9.29289 16.7071C9.68342 17.0976 10.3166 17.0976 10.7071 16.7071L13 14.4142L15.2929 16.7071C15.6834 17.0976 16.3166 17.0976 16.7071 16.7071C17.0976 16.3166 17.0976 15.6834 16.7071 15.2929L14.4142 13L16.7071 10.7071C17.0976 10.3166 17.0976 9.68342 16.7071 9.29289C16.3166 8.90237 15.6834 8.90237 15.2929 9.29289L13 11.5858L10.7071 9.29289Z" fill="#B91C1C" />
                </svg>;
            case 'info':
                return <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 13C0 5.8203 5.8203 0 13 0C20.1797 0 26 5.8203 26 13C26 20.1797 20.1797 26 13 26C5.8203 26 0 20.1797 0 13ZM11.6083 11.0779C13.1366 10.3138 14.8575 11.6942 14.443 13.3519L13.4977 17.1333L13.553 17.1056C14.047 16.8586 14.6477 17.0588 14.8946 17.5528C15.1416 18.0468 14.9414 18.6475 14.4474 18.8944L14.3921 18.9221C12.8638 19.6863 11.143 18.3059 11.5574 16.6482L12.5028 12.8668L12.4474 12.8944C11.9535 13.1414 11.3528 12.9412 11.1058 12.4472C10.8588 11.9533 11.059 11.3526 11.553 11.1056L11.6083 11.0779ZM13 9C13.5523 9 14 8.55229 14 8C14 7.44771 13.5523 7 13 7C12.4477 7 12 7.44771 12 8C12 8.55229 12.4477 9 13 9Z" fill="#2563EB" />
                </svg>;
            case 'success':
                return <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 13C0 5.8203 5.8203 0 13 0C20.1797 0 26 5.8203 26 13C26 20.1797 20.1797 26 13 26C5.8203 26 0 20.1797 0 13ZM17.8137 10.5812C18.1347 10.1318 18.0307 9.50728 17.5812 9.18627C17.1318 8.86526 16.5073 8.96935 16.1863 9.41876L11.8724 15.4582L9.70711 13.2929C9.31658 12.9024 8.68342 12.9024 8.29289 13.2929C7.90237 13.6834 7.90237 14.3166 8.29289 14.7071L11.2929 17.7071C11.5007 17.915 11.7895 18.0208 12.0825 17.9966C12.3754 17.9723 12.6429 17.8204 12.8137 17.5812L17.8137 10.5812Z" fill="#059669" />
                </svg>;
            case 'warning':
                return <svg xmlns="http://www.w3.org/2000/svg" width="28" height="25" viewBox="0 0 28 25" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M10.5345 2.00502C12.0738 -0.66313 15.9247 -0.663131 17.464 2.00502L27.2697 19.0017C28.8082 21.6684 26.8836 25.0006 23.805 25.0006H4.19346C1.11483 25.0006 -0.80974 21.6684 0.728721 19.0017L10.5345 2.00502ZM13.9995 9.00049C14.5518 9.00049 14.9995 9.4482 14.9995 10.0005V15.0005C14.9995 15.5528 14.5518 16.0005 13.9995 16.0005C13.4472 16.0005 12.9995 15.5528 12.9995 15.0005V10.0005C12.9995 9.4482 13.4472 9.00049 13.9995 9.00049ZM13.9995 20.0005C14.5518 20.0005 14.9995 19.5528 14.9995 19.0005C14.9995 18.4482 14.5518 18.0005 13.9995 18.0005C13.4472 18.0005 12.9995 18.4482 12.9995 19.0005C12.9995 19.5528 13.4472 20.0005 13.9995 20.0005Z" fill="#EAB308" />
                </svg>;
            default:
                return '';
        }
    };

    const getClassName = () => {
        switch (type) {
            case 'error':
                return 'error';
            case 'info':
                return 'info';
            case 'success':
                return 'success';
            case 'warning':
                return 'warning';
            default:
                return '';
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            if (onClose) {
                onClose();
            }
        }, 10000);
        return () => {
            clearTimeout(timer);
        };
    }, [onClose]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (toastRef.current && !toastRef.current.contains(event.target)) {
                if (onClose) {
                    onClose();
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    return (
        <div className={`toast-container`} ref={toastRef}>
            <div className={`toast`}>
                <div className={`line ${getClassName()}`}></div>
                <div className="icon">{getIcon()}</div>
                <div className="content">
                    <div className="header">{header}</div>
                    <div className="message">{message}</div>
                </div>
                <div className="close-btn" onClick={onClose}><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0.46967 0.46967C0.762563 0.176777 1.23744 0.176777 1.53033 0.46967L7 5.93934L12.4697 0.469671C12.7626 0.176778 13.2374 0.176778 13.5303 0.469671C13.8232 0.762564 13.8232 1.23744 13.5303 1.53033L8.06066 7L13.5303 12.4697C13.8232 12.7626 13.8232 13.2374 13.5303 13.5303C13.2374 13.8232 12.7626 13.8232 12.4697 13.5303L7 8.06066L1.53033 13.5303C1.23744 13.8232 0.762563 13.8232 0.46967 13.5303C0.176777 13.2374 0.176777 12.7626 0.46967 12.4697L5.93934 7L0.46967 1.53033C0.176777 1.23744 0.176777 0.762563 0.46967 0.46967Z" fill="#A1A1AA" />
                </svg></div>
            </div>
        </div>
    );
};

export default Toast;
