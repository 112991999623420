import React from 'react';
import Button from '../../components/button/Button';
import '../../components/globals/SalaryRangeFilter.css';

const SalaryRangeFilter = ({ minSalary, setMinSalary, maxSalary, setMaxSalary, setCurrentPage, handleApply }) => {
    const handleApplyClick = () => {
        setCurrentPage(0)
        handleApply()
    }

    return (
        <div className='fab-jobs-salary-range-container'>
            <h3 className='fab-jobs-categories-title'>Salary range</h3>
            <div className='fab-salary-range-filter'>
                <input
                    type="number"
                    pattern="\d*"
                    inputmode="numeric"
                    value={minSalary}
                    onChange={setMinSalary}
                    placeholder="Minimum"
                    className="fab-jobs-salary-input"
                />
                <span className="fab-jobs-salary-separator">-</span>
                <input
                    type="number"
                    pattern="\d*"
                    inputmode="numeric"
                    value={maxSalary}
                    onChange={setMaxSalary}
                    placeholder="Maximum"
                    className="fab-jobs-salary-input"
                />
            </div>
            <Button variant="outline" size="md" onClick={handleApplyClick}>Apply</Button>
        </div>
    )
}

export default SalaryRangeFilter;
