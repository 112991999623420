import '../../components/globals/VerifyMobileNumber.css';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { getUserInfoAndToken, isEmpty } from '../../common/helper';
import { sendOTP, updateProfile, verifyPhoneNumber } from '../../services/ProfileService';

import Button from '../../components/button/Button';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import Loader from '../../components/loader/Loader';
import { MobileVerificationErrorMessageMapping } from '../../common/constants';
import Toast from '../../components/toast/Toast';
import _ from 'lodash';
import analytics from '../../firebase';
import { logEvent } from "firebase/analytics";

const toastInitialState = {
    type: '',
    header: '',
    message: ''
}

const VerifyMobileNumber = () => {
    const resendTimeout = 60;
    const { userId, username, token, displayName, fullName } = getUserInfoAndToken();
    const inputRefs = useRef([]);
    const initialized = useRef(false);
    const locationProps = useLocation();
    const navigate = useNavigate();
    const [showToast, setShowToast] = useState(false);
    const [toastProps, setToastProps] = useState(toastInitialState)
    const [mobileNumber, setMobileNumber] = useState(locationProps?.state?.phoneNumber || '');
    const [showOtpField, setShowOtpField] = useState(locationProps?.state?.showOtpScreen || false);
    const [timer, setTimer] = useState(resendTimeout);
    const [otp, setOtp] = useState(['', '', '', '']);
    const [errorMessage, setErrorMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [isOtpError, setIsOtpError] = useState(false);
    const [otpErrorMessage, setOtpErrorMessage] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [resendButtonText, setResendButtonText] = useState('Resend code');

    const handleOnChange = (event) => {
        setIsError(false)
        setMobileNumber(event.target.value)
    }

    const handleToastClose = () => {
        setShowToast(false)
        setToastProps(toastInitialState)
    }

    const handleKeyDown = (index, event) => {
        const value = event.target.value;

        if ((event.key === 'Backspace' || event.key === 'Delete') && index > 0 && value === '') {
            const newOTP = [...otp];
            newOTP[index] = '';
            setOtp(newOTP);
            inputRefs.current[index - 1]?.focus();
            return;
        }
    };

    const handleOtpChange = (index, event) => {
        const value = event.target.value;

        if (!isNaN(value)) {
            const newOTP = [...otp];
            newOTP[index] = value;
            setOtp(newOTP);

            if (index < 3 && value !== '') {
                inputRefs.current[index + 1]?.focus();
            }
        }
    }

    const handleEditButton = () => {
        setShowOtpField(false)
        setIsError(false)
        setErrorMessage('')
    }

    const focusFirstInputField = ref => {
        inputRefs.current[0]?.focus();
    };

    const handleResend = () => {
        setTimer(resendTimeout);
        setIsError(false)
        setIsOtpError(false)
        setOtp(['', '', '', ''])
        setErrorMessage('')
        setOtpErrorMessage('')
        setResendButtonText("Code sent!")
        focusFirstInputField()
        sendOtpToPhone()
    };

    const formatPhoneNumberWithLodash = (phoneNumber) => {
        phoneNumber = phoneNumber.replace(/\s/g, '');
        const chunks = _.chunk(phoneNumber, 4);
        const formattedPhoneNumber = chunks.map(chunk => chunk.join('')).join(' ');
        return formattedPhoneNumber;
    }

    const sendOtpToPhone = async () => {
        try {
            setShowLoader(true)
            const { userId, username, token } = getUserInfoAndToken();
            const response = await sendOTP(userId, username, token)
            if (response.code === "00") {
                setShowLoader(false)
                setTimer(resendTimeout)
                setShowOtpField(true)
            }
            else if (response.code === "98") {
                setShowLoader(false)
                setIsError(true)
                setShowOtpField(true)
                setTimer(resendTimeout)
                setErrorMessage(response?.message[0])
            }
            setShowLoader(false)
        }
        catch (error) {
            // TODO: handle error
            setShowLoader(false)
            console.log(`Error occurred while sending OTP to user mobile number: ${error}`);
        }
    }

    const handleVerifyPhoneNumber = async () => {
        const otpValue = otp.join('');
        try {
            setShowLoader(true)
            setIsOtpError(false)
            setOtpErrorMessage('')
            const response = await verifyPhoneNumber(userId, username, token, otpValue);
            if (response.code === '00') {
                logEvent(analytics, 'apply_mobile_verify_successful');
                logEvent(analytics, 'mobile_verify_successful', {'userid': userId});
                navigate("/jobs");
                setShowLoader(false)
            }
            else if (response.code === '98') {
                const errorMessage = MobileVerificationErrorMessageMapping[response.message[0]]
                setIsOtpError(true)
                setOtpErrorMessage(errorMessage)
                setShowLoader(false)
            }

        } catch (error) {
            setIsOtpError(true)
            setShowLoader(false)
            setOtpErrorMessage("Invalid code. Please try again.")
        }
    };

    const updateUserPhoneNumber = async () => {
        try {
            setShowLoader(true)
            const response = await updateProfile(userId, username, token, {}, { 'phone': mobileNumber, 'fullm': fullName, 'eml': username, 'dispm': displayName })
            if (response.code === "00") {
                setShowLoader(false)
                sendOtpToPhone()
            }
        }
        catch (error) {
            setShowLoader(false)
            setIsError(true)
            setErrorMessage("Error occurred while sending OTP to user mobile number")
            console.log(`Error occurred while sending OTP to user mobile number: ${error}`);
        }
    }

    const handleSendOtp = () => {
        if (isEmpty(mobileNumber) || mobileNumber.length !== 8) {
            setIsError(true)
            setErrorMessage('Please enter a valid mobile number')
        }
        else {
            updateUserPhoneNumber()
        }
    }

    useEffect(() => {
        if (!initialized.current && locationProps?.state?.phoneNumber) {
            initialized.current = true
            sendOtpToPhone();
        }
    }, [locationProps]);

    useEffect(() => {
        if (showOtpField) {
            focusFirstInputField();
        }
    }, [showOtpField]);

    useEffect(() => {
        let intervalId;
        if (timer > 0) {
            intervalId = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
        }
        if (timer === 0) {
            setResendButtonText("Resend code")
            setIsError(false)
            setIsOtpError(false)
        }

        return () => clearInterval(intervalId);
    }, [timer]);

    useEffect(() => {
        const redirectedFromSignup = JSON.parse(localStorage.getItem('fromSignup'));
        if (redirectedFromSignup) {
            setShowToast(true);
            setToastProps({
                type: 'success',
                header: 'Registration Successful',
                message: <>Verify your mobile number and start applying for <Link to="/jobs">jobs</Link>!</>
            })
            localStorage.removeItem('fromSignup');
        }
    }, []);

    return (
        <div className='fab-verify-mobile-container'>
            {showLoader && <Loader></Loader>}
            {showToast && <Toast type={toastProps.type} header={toastProps.header} message={toastProps.message} onClose={handleToastClose} />}
            <Header />
            <div className='fab-verify-mobile-content'>
                <div className='fab-verify-mobile-header-container'>
                    <h2 className='fab-verify-mobile-header'>Verify your mobile number</h2>
                    {!showOtpField
                        ? <h3 className='fab-verify-mobile-sub-heading'>Enter your mobile number below. We will send you a verification code on this mobile number to verify it's you.</h3>
                        : <h3 className='fab-verify-mobile-sub-heading'>We've sent a verification code to</h3>
                    }
                    {showOtpField
                        ? <div className='fab-verify-mobile-number-edit'>
                            <div className='fab-verify-mobile-number-edit-text'>+65 {formatPhoneNumberWithLodash(mobileNumber)}</div>
                            {<svg onClick={handleEditButton} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M17.1093 0.890704C16.255 0.036432 14.87 0.036432 14.0157 0.890704L13.0514 1.855L16.145 4.94859L17.1093 3.9843C17.9636 3.13002 17.9636 1.74498 17.1093 0.890704Z" fill="#18181B" />
                                <path d="M15.2611 5.83248L12.1675 2.73888L2.04184 12.8646C1.52785 13.3786 1.15003 14.0125 0.94251 14.7092L0.276027 16.9466C0.21051 17.1665 0.270799 17.4047 0.433074 17.5669C0.595348 17.7292 0.833504 17.7895 1.05344 17.724L3.29085 17.0575C3.9875 16.85 4.62145 16.4722 5.13544 15.9582L15.2611 5.83248Z" fill="#18181B" />
                            </svg>}
                        </div>
                        : <></>}
                </div>
                {!showOtpField ?
                    <>
                        <div className='fab-verify-mobile-input-container'>
                            <label className='fab-verify-mobile-label'>Verify mobile number</label>
                            <div className={'fab-verify-mobile-input-wrapper'}>
                                <div className="fab-verify-mobile-number-country-wrapper">
                                    <svg className="fab-verify-mobile-number-country-icon" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 17 12" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M0.5 0.666504H16.5V11.3332H0.5V0.666504Z" fill="white" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M0.5 0.666504H16.5V5.99984H0.5V0.666504Z" fill="#DF0000" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M4.18853 1.55996C3.7676 1.65042 3.39061 1.88295 3.12084 2.2185C2.85108 2.55405 2.70498 2.9722 2.70706 3.40273C2.70915 3.83327 2.8593 4.24998 3.13229 4.5829C3.40529 4.91583 3.78452 5.14469 4.20631 5.23107C3.96468 5.30099 3.71162 5.32252 3.46166 5.29443C3.21169 5.26633 2.96972 5.18917 2.74964 5.06736C2.52956 4.94555 2.33569 4.78149 2.17915 4.5846C2.02261 4.3877 1.90648 4.16184 1.83742 3.91996C1.70026 3.43376 1.76118 2.91302 2.00687 2.47162C2.25256 2.03021 2.66303 1.70403 3.14853 1.5644C3.50409 1.46218 3.83298 1.45996 4.19075 1.55996H4.18853Z" fill="white" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M3.9003 3.11087L4.00919 3.4442L3.7203 3.23975L3.43586 3.44864L3.5403 3.11087L3.25586 2.9042L3.60919 2.89975L3.7203 2.56642L3.83141 2.89975H4.18253L3.9003 3.11087ZM4.28919 4.26642L4.4003 4.60198L4.11141 4.39753L3.82475 4.6042L3.93141 4.26864L3.64697 4.05975L4.0003 4.05753L4.10919 3.72198L4.2203 4.05531H4.57586L4.28919 4.26642ZM5.58919 4.25753L5.69808 4.59531L5.40919 4.38864L5.12475 4.59531L5.22919 4.25975L4.94475 4.05309L5.29808 4.04864L5.40919 3.71531L5.5203 4.04864H5.87141L5.58919 4.25753ZM5.97586 3.11087L6.08475 3.44642L5.79586 3.24198L5.51141 3.44864L5.61808 3.11309L5.33141 2.9042L5.68697 2.90198L5.79364 2.56642L5.90475 2.89975H6.2603L5.97586 3.11087ZM4.94697 2.34864L5.05808 2.68642L4.76919 2.47975L4.48253 2.68864L4.58919 2.35087L4.30475 2.14198L4.65586 2.13975L4.76697 1.8042L4.87808 2.13753H5.23364L4.94697 2.34864Z" fill="white" />
                                    </svg>
                                    <span className="fab-verify-mobile-number-country" value="+65">+65</span>
                                </div>
                                <input
                                    type="number"
                                    pattern="\d*"
                                    inputmode="numeric"
                                    className={'fab-verify-mobile-input'}
                                    placeholder='0000 0000'
                                    value={mobileNumber}
                                    onChange={handleOnChange}
                                />
                            </div>
                            {isError && <div className="fab-verify-mobile-error-message">{errorMessage}</div>}
                        </div>
                        <div className='fab-verify-mobile-button'>
                            <Button variant="primary" size="lg" className='fab-verify-mobile-send-button' onClick={handleSendOtp}>Send verification code</Button>
                        </div>
                    </>
                    : <>
                        <div class="fab-verify-mobile-otp-container">
                            {otp.map((digit, index) => (
                                <input
                                    key={index}
                                    value={digit}
                                    type="text"
                                    maxLength="1"
                                    className={`fab-verify-mobile-otp-input ${isOtpError ? 'fab-verify-mobile-error-border' : ''}`}
                                    ref={ref => inputRefs.current[index] = ref}
                                    onChange={(event) => handleOtpChange(index, event)}
                                    onKeyDown={(event) => handleKeyDown(index, event)}
                                />
                            ))}
                        </div>
                        {isOtpError && <div className='fab-verify-mobile-timer-otp-error-text'>
                            <span>{otpErrorMessage}</span>
                        </div>}
                        <div className='fab-verify-mobile-send-button-container'>
                            <Button variant="primary" size="lg" className='fab-verify-mobile-send-button' onClick={handleVerifyPhoneNumber}>Verify phone number</Button>
                        </div>
                        <div className='fab-verify-mobile-timer-text'>
                            <span>Didn't get the code? Resend code in <span className='fab-verify-mobile-timer-resend-text'>{timer} seconds.</span></span>
                        </div>
                        <div className='fab-verify-mobile-resend-button-container'>
                            <Button variant="outline" size="md" className='fab-verify-mobile-resend-button' onClick={handleResend} isDisabled={timer > 0}>{resendButtonText}</Button>
                        </div>
                        {isError && <div className='fab-verify-mobile-timer-error-text'>
                            <span>{errorMessage}</span>
                        </div>}
                    </>
                }
            </div>
            <Footer />
        </div >
    )

}

export default VerifyMobileNumber;