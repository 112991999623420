import React from 'react';
import './NoResults.css';

const NoResults = ({ justifyContent = 'center' }) => {
    return (
        <div style={{ justifyContent }} className='fab-jobs-no-data'>
            <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 120 120" fill="none">
                <path d="M68.25 102.751C39.75 102.751 11.25 88.5008 9 55.5007C6.75 22.5006 51.75 -10.3479 68.25 24.0008C84.75 58.3494 110.25 52.7749 110.25 72.0008C110.25 91.2267 96.75 102.751 68.25 102.751Z" fill="#FAFAFA" />
                <rect x="57.5628" y="63.7891" width="6.5" height="18.5" transform="rotate(-45 57.5628 63.7891)" fill="white" stroke="#A1A1AA" />
                <path d="M62.6896 33.5598C71.7693 42.6395 71.7693 57.3605 62.6896 66.4402C53.6099 75.5199 38.8888 75.5199 29.8091 66.4402C20.7294 57.3605 20.7294 42.6395 29.8091 33.5598C38.8888 24.4801 53.6099 24.4801 62.6896 33.5598Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M69.3061 47C68.6702 51.9147 66.4647 56.6651 62.6895 60.4402C53.6098 69.5199 38.8887 69.5199 29.809 60.4402C26.0339 56.6651 23.8284 51.9147 23.1925 47C22.299 53.9057 24.5045 61.1357 29.809 66.4402C38.8887 75.5199 53.6098 75.5199 62.6895 66.4402C67.994 61.1357 70.1996 53.9057 69.3061 47Z" fill="#E5E5E5" />
                <path fillRule="evenodd" clipRule="evenodd" d="M61.6289 65.3796C70.1228 56.8857 70.1228 43.1143 61.6289 34.6204C53.135 26.1265 39.3637 26.1265 30.8698 34.6204C22.3759 43.1143 22.3759 56.8857 30.8698 65.3796C39.3637 73.8735 53.135 73.8735 61.6289 65.3796ZM62.6896 66.4402C71.7693 57.3605 71.7693 42.6395 62.6896 33.5598C53.6099 24.4801 38.8888 24.4801 29.8091 33.5598C20.7294 42.6395 20.7294 57.3605 29.8091 66.4402C38.8888 75.5199 53.6099 75.5199 62.6896 66.4402Z" fill="#A1A1AA" />
                <path d="M69.5837 82.8794C67.2406 80.5362 67.2406 76.7372 69.5837 74.3941L71.705 72.2728C74.0482 69.9296 77.8472 69.9296 80.1903 72.2728L99.2822 91.3646C101.625 93.7078 101.625 97.5068 99.2822 99.8499L97.1609 101.971C94.8177 104.314 91.0187 104.314 88.6756 101.971L69.5837 82.8794Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M69.3477 74.6445C69.4626 76.007 70.0413 77.3376 71.0838 78.3801L90.1757 97.472C92.5188 99.8151 96.3178 99.8151 98.6609 97.472L100.782 95.3507C100.864 95.2692 100.942 95.1859 101.018 95.101C101.162 96.8008 100.583 98.55 99.2823 99.8507L97.1609 101.972C94.8178 104.315 91.0188 104.315 88.6757 101.972L69.5838 82.8801C67.3221 80.6185 67.2435 77.0005 69.3477 74.6445Z" fill="#E5E5E5" />
                <path fillRule="evenodd" clipRule="evenodd" d="M72.7657 73.3334L70.6444 75.4547C68.887 77.2121 68.887 80.0613 70.6444 81.8187L89.7362 100.911C91.4936 102.668 94.3428 102.668 96.1002 100.911L98.2215 98.7893C99.9789 97.0319 99.9789 94.1827 98.2215 92.4253L79.1296 73.3334C77.3723 71.5761 74.523 71.5761 72.7657 73.3334ZM69.5837 74.3941C67.2406 76.7372 67.2406 80.5362 69.5837 82.8794L88.6756 101.971C91.0187 104.314 94.8177 104.314 97.1609 101.971L99.2822 99.8499C101.625 97.5068 101.625 93.7078 99.2822 91.3646L80.1903 72.2728C77.8472 69.9296 74.0482 69.9296 71.705 72.2728L69.5837 74.3941Z" fill="#A1A1AA" />
                <path d="M79.5 35.25C79.5 40.6348 75.1348 45 69.75 45C64.3652 45 60 40.6348 60 35.25C60 29.8652 64.3652 25.5 69.75 25.5C75.1348 25.5 79.5 29.8652 79.5 35.25Z" fill="#EC656E" />
                <path fillRule="evenodd" clipRule="evenodd" d="M65.1975 30.6982C64.6676 31.2281 64.6676 32.0872 65.1975 32.6171L67.811 35.2306L65.1975 37.8441C64.6676 38.374 64.6676 39.2331 65.1975 39.763C65.7274 40.2929 66.5865 40.2929 67.1164 39.763L69.73 37.1495L72.2337 39.6532C72.7636 40.1831 73.6227 40.1831 74.1526 39.6532C74.6825 39.1233 74.6825 38.2642 74.1526 37.7343L71.6489 35.2306L74.1526 32.7269C74.6825 32.197 74.6825 31.3379 74.1526 30.808C73.6227 30.2781 72.7636 30.2781 72.2337 30.808L69.73 33.3117L67.1164 30.6982C66.5865 30.1683 65.7274 30.1683 65.1975 30.6982Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M97.5 43.5C99.9853 43.5 102 41.4853 102 39C102 36.5147 99.9853 34.5 97.5 34.5C95.0147 34.5 93 36.5147 93 39C93 41.4853 95.0147 43.5 97.5 43.5ZM97.5 45C100.814 45 103.5 42.3137 103.5 39C103.5 35.6863 100.814 33 97.5 33C94.1863 33 91.5 35.6863 91.5 39C91.5 42.3137 94.1863 45 97.5 45Z" fill="#E5E5E5" />
                <circle cx="18" cy="22.5" r="4.5" fill="#A1A1AA" />
                <path d="M26.2588 98.6294C26.2588 100.91 24.41 102.759 22.1294 102.759C19.8488 102.759 18 100.91 18 98.6294C18 96.3488 19.8488 94.5 22.1294 94.5C24.41 94.5 26.2588 96.3488 26.2588 98.6294Z" fill="#E5E5E5" />
                <path fillRule="evenodd" clipRule="evenodd" d="M106.76 85.0737C107.107 85.4207 107.67 85.4207 108.017 85.0737L110.202 82.8884L112.277 84.9634C112.624 85.3104 113.187 85.3104 113.534 84.9634C113.881 84.6164 113.881 84.0538 113.534 83.7069L111.459 81.6319L113.462 79.6287C113.809 79.2817 113.809 78.7192 113.462 78.3722C113.115 78.0252 112.552 78.0252 112.205 78.3722L110.202 80.3753L108.089 78.2618C107.742 77.9149 107.179 77.9149 106.832 78.2618C106.485 78.6088 106.485 79.1714 106.832 79.5184L108.946 81.6319L106.76 83.8172C106.413 84.1642 106.413 84.7268 106.76 85.0737Z" fill="#D4D4D4" />
            </svg>
            <div className='fab-no-results-text'>No results found</div>
            <div className='fab-try-again-text'>Try again with different keywords</div>
        </div>
    )
}

export default NoResults;
